import { embedDashboard } from "@superset-ui/embedded-sdk";
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";

import "./style.scss";
import SearchDropDownPaginator from "../../CommonElements/SearchDropDownPaginator";
import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import { getSupersetReportsList } from "../../ReduxToolkit/Reducers/MasterSlice";
import {
  DashboardNotFound,
  ErrorLoadingDashboard,
  Select,
  Type,
} from "../../utils/Constant";
import { customStyles, showToast } from "../../utils/helper/helper";

const SUPERESET_BASE_URL = `${process.env.REACT_APP_SUPERSET_BASE_URL}`;

const Reports: React.FC = () => {
  const dispatch = useAppDispatch();
  const [reportTypes, setReportTypes] = useState([]);
  const [selectedReportId, setSelectedReportId] = useState<string>("");

  const fetchGuestToken = async dashboardId => {
    const data = await dispatch(
      getSupersetReportsList({ type: "guest_token", dashboard_id: dashboardId })
    );
    if (data?.payload?.guest_token && data?.payload?.guest_token !== "")
      return data?.payload?.guest_token;
  };

  const embedSelectedDashboard = async (dashboardId: string) => {
    try {
      const guestToken = await fetchGuestToken(dashboardId);

      if (!guestToken || guestToken === "") {
        showToast(DashboardNotFound, "error");
        return;
      }

      embedDashboard({
        id: dashboardId,
        supersetDomain: SUPERESET_BASE_URL,
        mountPoint: document.getElementById("superset-container")!,
        fetchGuestToken: () => guestToken,
        dashboardUiConfig: {
          filters: { visible: false, expanded: false },
          urlParams: {
            standalone: 0,
            id: 2,
          },
        },
      });

      const iframe = document.querySelector("iframe");
      if (iframe) {
        iframe.style.width = "100%";
        iframe.style.minHeight = "100vh";
      }
    } catch (error) {
      console.error(ErrorLoadingDashboard, error);
    }
  };

  const handleTabClick = async selectedId => {
    setSelectedReportId(selectedId);
    if (selectedId !== "") await embedSelectedDashboard(selectedId);
  };

  useEffect(() => {
    getReports();
  }, [dispatch]);

  const getReports = async () => {
    const data = await dispatch(getSupersetReportsList({ type: "dashboard" }));
    const recievedData = data?.payload?.reports;
    if (recievedData && recievedData?.length > 0) {
      const transformedData = recievedData?.map(item => ({
        label: item?.name,
        value: item?.dashboard_id?.trim(),
      }));

      setReportTypes(transformedData);
      setSelectedReportId(recievedData?.[0]?.dashboard_id?.trim() || "");
    }
  };

  useEffect(() => {
    if (selectedReportId && selectedReportId !== "")
      embedSelectedDashboard(selectedReportId);
  }, [selectedReportId]);

  return (
    <div className="page-body dashboard-container">
      <div className="mt-4">
        <ReactSelect
          name="selectedMentee"
          className={"text-gray"}
          onChange={selectedOption => {
            handleTabClick(selectedOption?.value);
          }}
          value={
            reportTypes?.find(user => user.value === selectedReportId) || null
          }
          options={reportTypes?.map(user => ({
            value: user?.value,
            label: user?.label,
          }))}
          isSearchable={true}
          isClearable={false}
          placeholder={`${Select} ${Type}`}
          classNamePrefix="react-select"
          styles={customStyles}
          components={{
            MenuList: props => (
              <SearchDropDownPaginator {...props} maxHeight={400} />
            ),
          }}
          filterOption={(option, inputValue) =>
            option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
          }
        />
        <div id="superset-container" className="mt-4"></div>
      </div>
    </div>
  );
};

export default Reports;
