import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { Row } from "reactstrap";

import { H2, H3, H4, P } from "../../AbstractElements";
import {
  ALL_USERS_STATUS,
  DRAFT_STATUS,
  PUBLISHED,
  UNPUBLISHED,
  SCHEDULED_FOR_PUBLISH,
  NEW_STATUS,
  LO_APPROVED_TM,
  CONTENT_APPROVED_TM,
  SUBMITTED,
  CONTENT_SUBMITTED,
  LO_SUBMITTED,
  CONTENT_APPROVED_SME,
  LO_REJECTED_TM,
  LO_REJECTED_SME,
  CONTENT_REJECTED_TM,
  CONTENT_REJECTED_SME,
  LO_APPROVED_SME,
} from "../../Api/constants";
import { Version } from "../../Api/entities/CurriculumEntity";
import SimpleAccordion from "../../CommonElements/Accordion";
import SliderModal from "../../CommonElements/SliderModal";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getUsersList } from "../../ReduxToolkit/Reducers/UserSlice";
import {
  Archive,
  DraftStatus,
  Scheduled,
  VersionText,
  VersionControlText,
  Current,
  New,
  Submitted,
  ApprovedHeader,
  RejectedHeader,
} from "../../utils/Constant";

interface VersionControlProps {
  isShowVersionModal: boolean;
  handleVersionModal: () => void;
  versions: Version[];
  handleClickVersion: (item) => void;
  activeId?: number;
}

interface FormattedVersion {
  date: string;
  year: string;
  userName: any;
  version: number;
  id: number;
}

interface VersionStatusData {
  draft: FormattedVersion[];
  published: FormattedVersion[];
  unpublished: FormattedVersion[];
  readyForPublish: FormattedVersion[];
  newStatus?: FormattedVersion[];
  submitted?: FormattedVersion[];
  approved?: FormattedVersion[];
  rejected?: FormattedVersion[];
}

interface UserMap {
  [key: string]: { user_name: string; role: string; employee_code: string };
}

const VersionControl: React.FC<VersionControlProps> = ({
  isShowVersionModal,
  handleVersionModal,
  versions,
  handleClickVersion,
  activeId,
}) => {
  const [versionStatusData, setVersionStatusData] = useState<VersionStatusData>(
    {
      draft: [],
      published: [],
      unpublished: [],
      readyForPublish: [],
      newStatus: [],
      submitted: [],
      approved: [],
      rejected: [],
    }
  );
  const [userNameMap, setUserNameMap] = useState<UserMap | null>(null);
  const dispatch = useAppDispatch();

  const { usersList } = useAppSelector(state => state.user);

  useEffect(() => {
    if (versions?.length > 0 && usersList?.users?.length === 0) {
      dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    }
  }, [versions, dispatch]);

  useEffect(() => {
    const userMapObj: UserMap = {};
    usersList?.users?.forEach((user: any) => {
      userMapObj[user.id] = user;
    });
    setUserNameMap(userMapObj);
  }, [usersList]);

  const formatDate = (dateString: string): { day: string; year: string } => {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = dateObj.toLocaleString("default", { month: "short" });
    const year = dateObj.getFullYear().toString().slice(-2);
    return { day, year: `${month}, ${year}` };
  };

  const getVersionContent = (element: FormattedVersion) => {
    return (
      <div
        onClick={() => handleClickVersion(element)}
        className={`d-flex gap-3 version-control__version-details pointer ${activeId === element.id ? "version-control__active-version" : ""}`}
      >
        <div className="version-control__date-wrapper">
          <H2 className="version-control__date">{element.date}</H2>
          <P className="version-control__date-year">{element.year}</P>
        </div>
        <div className="version-control__version-wrapper">
          <P className="version-control__version mb-2">
            {VersionText} {element.version}
          </P>
          <P className="version-control__user-name">
            {userNameMap?.[element.userName]?.user_name} |{" "}
            {userNameMap?.[element.userName]?.employee_code}
          </P>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const draft: FormattedVersion[] = [];
    const published: FormattedVersion[] = [];
    const unpublished: FormattedVersion[] = [];
    const readyForPublish: FormattedVersion[] = [];

    // For Course Version

    const newStatus: FormattedVersion[] = [];
    const submitted: FormattedVersion[] = [];
    const approved: FormattedVersion[] = [];
    const rejected: FormattedVersion[] = [];

    versions?.forEach(item => {
      const { day, year } = formatDate(item.created_at);
      const formattedItem: FormattedVersion = {
        date: day,
        year: year,
        userName: item.created_by,
        version: item.version,
        id: item.id,
      };

      switch (item.status) {
        case DRAFT_STATUS:
        case LO_APPROVED_TM:
        case LO_APPROVED_SME:
          draft.push(formattedItem);
          break;
        case PUBLISHED:
          published.push(formattedItem);
          break;
        case UNPUBLISHED:
          unpublished.push(formattedItem);
          break;
        case SCHEDULED_FOR_PUBLISH:
          readyForPublish.push(formattedItem);
          break;
        case NEW_STATUS:
          newStatus.push(formattedItem);
          break;
        case SUBMITTED:
        case CONTENT_SUBMITTED:
        case LO_SUBMITTED:
          submitted.push(formattedItem);
          break;
        case CONTENT_APPROVED_TM:
        case CONTENT_APPROVED_SME:
          approved.push(formattedItem);
          break;
        case LO_REJECTED_TM:
        case LO_REJECTED_SME:
        case CONTENT_REJECTED_TM:
        case CONTENT_REJECTED_SME:
          rejected.push(formattedItem);
          break;
        default:
          break;
      }
    });

    setVersionStatusData({
      draft,
      published,
      unpublished,
      readyForPublish,
      newStatus,
      submitted,
      approved,
      rejected,
    });
  }, [versions]);

  const createAccordionConfig = (id, heading, data) => ({
    id,
    accordionItemClass: "item-class",
    accordionHeaderClass: "header-class",
    spanClass: "span-class",
    accordionHeading: heading,
    icon: true,
    bodyText: data.map(item => getVersionContent(item)),
  });

  const {
    readyForPublish,
    unpublished,
    draft,
    newStatus,
    submitted,
    approved,
    rejected,
  } = versionStatusData;

  return (
    <div className="version-control">
      <SliderModal
        isOpen={isShowVersionModal}
        toggle={() => {
          handleVersionModal();
        }}
      >
        <H3>{VersionControlText}</H3>

        <div className="version-control__wrapper">
          <div className="version-control__section">
            <Row className="my-4">
              {versionStatusData.published.length > 0 && (
                <div className="version-control__current-version">
                  <H4 className="mb-3">{Current}</H4>
                  {getVersionContent(versionStatusData.published[0])}
                </div>
              )}

              {readyForPublish?.length > 0 && (
                <SimpleAccordion
                  accordionList={[
                    createAccordionConfig("1", Scheduled, readyForPublish),
                  ]}
                />
              )}
              {unpublished?.length > 0 && (
                <SimpleAccordion
                  accordionList={[
                    createAccordionConfig("2", Archive, unpublished),
                  ]}
                />
              )}
              {draft?.length > 0 && (
                <SimpleAccordion
                  accordionList={[
                    createAccordionConfig("3", DraftStatus, draft),
                  ]}
                />
              )}
              {newStatus?.length > 0 && (
                <SimpleAccordion
                  accordionList={[createAccordionConfig("3", New, newStatus)]}
                />
              )}

              {submitted?.length > 0 && (
                <SimpleAccordion
                  accordionList={[
                    createAccordionConfig("3", Submitted, submitted),
                  ]}
                />
              )}

              {approved?.length > 0 && (
                <SimpleAccordion
                  accordionList={[
                    createAccordionConfig("3", ApprovedHeader, approved),
                  ]}
                />
              )}

              {rejected?.length > 0 && (
                <SimpleAccordion
                  accordionList={[
                    createAccordionConfig("3", RejectedHeader, rejected),
                  ]}
                />
              )}
            </Row>
          </div>
        </div>
      </SliderModal>
    </div>
  );
};

VersionControl.propTypes = {
  isShowVersionModal: PropTypes.bool.isRequired,
  handleVersionModal: PropTypes.func.isRequired,
  versions: PropTypes.array.isRequired,
  handleClickVersion: PropTypes.func,
};

export default VersionControl;
