import PropTypes from "prop-types";
import { useEffect } from "react";
import { Card, CardBody } from "reactstrap";

import { H4, P } from "../../../../../AbstractElements";
import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import MultiFileUpload from "../../../../../CommonElements/MultiFileUpload/MultiFileUpload";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import { getDownloadableUrl } from "../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  BulkUploadNote,
  ClickHereToAddFile,
  Download,
  QBQuestionBulkUploadFileToolTipMessage,
  UploadFile,
  UseThisTemplate,
} from "../../../../../utils/Constant";
import { sanitizedContent } from "../../../../../utils/helper/helper";

const BulkUpload = ({ onFileChange, flushData }) => {
  const dispatch = useAppDispatch();
  const { urlList } = useAppSelector(state => state.user);

  useEffect(() => {
    dispatch(getDownloadableUrl());
  }, [dispatch]);

  return (
    <div>
      <Card>
        <CardBody className="m-2 mt-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-2">
              <div className="choose-option">
                <H4 className="title">{UploadFile}</H4>
              </div>
            </div>
          </div>
          <div className="d-flex gap-4">
            <P>{UseThisTemplate}</P>
            <a href={urlList?.data?.templates?.qb_template || ""} download>
              <P className="primary-text-color">{Download}</P>
            </a>
          </div>
          <LabelTooltip
            label={ClickHereToAddFile}
            tooltipText={QBQuestionBulkUploadFileToolTipMessage}
            important={false}
          />
          <div
            className="primary-text-color sanitized-content"
            dangerouslySetInnerHTML={{
              __html: sanitizedContent(BulkUploadNote),
            }}
          />
          <MultiFileUpload
            flushData={flushData}
            accept=".csv"
            multiple={false}
            onFileChange={file => {
              onFileChange(file);
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
};

BulkUpload.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  flushData: PropTypes.bool,
};

export default BulkUpload;
