import React from "react";
import DataTable from "react-data-table-component";
import { Container, FormGroup, Input } from "reactstrap";

import { H4 } from "../../AbstractElements";
import Divider from "../../CommonElements/Divider";
import StarRating from "../../CommonElements/StarRating";
import {
  Description,
  Enter,
  Questions,
  YourFeedback,
} from "../../utils/Constant";

import "./style.scss";
interface QuestionData {
  id: number;
  category: string;
  question_text: string;
  answer_type: string;
  response?: any;
  error?: string;
}

interface CourseContentTableProps {
  category: string;
  questions: QuestionData[];
  handleRating: (id: number, rate: number) => void;
  handleInputChange: (id: number, value: string) => void;
  review?: boolean;
}

const CourseContentTable: React.FC<CourseContentTableProps> = ({
  category,
  questions,
  handleRating,
  handleInputChange,
  review,
}) => {
  const columns = [
    {
      name: Questions,
      selector: (row: QuestionData) => row.question_text,
      wrap: true,
    },
    {
      name: YourFeedback,
      cell: (row: QuestionData) =>
        row.answer_type === "star" ? (
          <div>
            <StarRating
              disabled={review}
              size={30}
              onClick={rate => handleRating(row.id, rate)}
              initialValue={review ? Number(row.response) : 0}
            />
            {row.error && <div className="error-field my-2">{row.error}</div>}
          </div>
        ) : (
          <FormGroup className="w-100">
            {review ? (
              <div>{row.response}</div>
            ) : (
              <Input
                type="textarea"
                placeholder={`${Enter} ${Description}`}
                onChange={e => handleInputChange(row.id, e.target.value)}
                rows={4}
                className="my-3"
                invalid={!!row.error}
              />
            )}
            {row.error && <div className="invalid-feedback">{row.error}</div>}
          </FormGroup>
        ),
    },
  ];

  return (
    <Container className="course-content-feedback">
      <H4>{category}</H4>
      <Divider />
      <DataTable
        columns={columns}
        data={questions}
        fixedHeader
        className="mt-3"
      />
    </Container>
  );
};

export default CourseContentTable;
