import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SessionList from "../../../../container/SessionList";
import UserProfileCard from "../../../../container/UserProfileCard";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getMentorSessionList } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import { dynamicImage } from "../../../../Service";
import {
  Contact,
  CWSAndCatRecId,
  Grade,
  Location,
  Mail,
  RMContactNumber,
  RMEmail,
  RMName,
  SBU,
} from "../../../../utils/Constant";
import { UserProfileInformationDetails } from "../../../../utils/helper/propTypes";

const MentorDetailsPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = location;
  const { mentorMenteeId, mentorId } = state || {};
  const navigate = useNavigate();
  const { getMentorSessionLists } = useAppSelector(state => state.learner);
  const [userProfileData, setUserProfileData] = useState(null);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getMentorSessionList({ mentorMenteeId }));
  }, [dispatch]);

  useEffect(() => {
    if (getMentorSessionLists?.session_list) {
      const mappedData = getMentorSessionLists?.session_list?.map(session => {
        return {
          sessionId: session.session_id,
          topic: session.topic,
          duration: String(session.duration),
          sessionDate: session.start_date,
          status: session.status,
        };
      });

      setFilteredData(mappedData);
    }
    if (getMentorSessionLists?.user_dict) {
      const mentorDetails = getMentorSessionLists.user_dict[mentorId];
      if (mentorDetails) {
        const mapUserToProfileDetails = (
          user: any
        ): UserProfileInformationDetails => ({
          userProfileUrl: dynamicImage("dashboard-4/user.png"),
          name: user.user_name,
          designation: user.designation ? user.designation : "-",
          employee_code: user.employee_code ? user.employee_code : "-",
          userDetails: [
            {
              icon: "BadgeOutlined",
              title: CWSAndCatRecId,
              detail: user?.cws_cat_rec ? user?.cws_cat_rec : "-",
            },
            {
              icon: "SchoolOutlined",
              title: Grade,
              detail: user?.grade ? user?.grade : "-",
            },
            {
              icon: "PinOutlined",
              title: SBU,
              detail: user?.sbu ? user?.sbu : "-",
            },
            {
              icon: "PersonOutline",
              title: RMName,
              detail: user.mapped_rm?.length > 0 ? user.mapped_rm[0].name : "-",
            },
            {
              icon: "PhoneOutlined",
              title: Contact,
              detail: user?.phone ? user?.phone : "-",
            },
            {
              icon: "EmailOutlined",
              title: Mail,
              detail: user.email ? user.email : "-",
            },
            {
              icon: "EmailOutlined",
              title: RMEmail,
              detail:
                user.mapped_rm?.length > 0 && user.mapped_rm[0].email
                  ? user.mapped_rm[0].email
                  : "-",
            },
            {
              icon: "PhoneOutlined",
              title: RMContactNumber,
              detail: user?.mapped_rm?.[0]?.phone || "-",
            },
            {
              icon: "LocationOnOutlined",
              title: Location,
              detail:
                [user?.office_city || "", user?.office_state || ""]
                  .filter(Boolean)
                  .join(", ") || "-",
            },
          ],
        });
        setUserProfileData(mapUserToProfileDetails(mentorDetails));
      }
    }
  }, [getMentorSessionLists]);

  const handleSearch = searchTerm => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const filtered = getMentorSessionLists?.session_list?.filter(session =>
      session.topic.toLowerCase().includes(lowercasedSearchTerm)
    );
    setFilteredData(filtered);
  };

  const onRowClick = row => {
    if (row.status === "completed") {
      navigate(`${process.env.PUBLIC_URL}/mentoring/session-details`, {
        state: { sessionId: row.sessionId },
      });
    }
  };

  return (
    <div className="page-body page-body-margin">
      <UserProfileCard userData={userProfileData || {}} />
      <div>
        <SessionList
          data={filteredData}
          handleSearch={handleSearch}
          showActions
          onRowClick={onRowClick}
        />
      </div>
    </div>
  );
};

export default MentorDetailsPage;
