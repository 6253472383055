import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { TAG_ASSESSMENT } from "../../Api/constants";
import FilterSliderModal from "../../CommonElements/FilterSliderModal";
import JustifyTabs from "../../CommonElements/JustifyTabs";
import SearchBar from "../../CommonElements/SearchBar";
import GenericCard from "../../container/GenericCard";
import NoData from "../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { setLearnerAssessmentDetailsToInitial } from "../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import { getAssignedAssessmentForLearner } from "../../ReduxToolkit/Reducers/AssessmentSlice";
import { getTagsList } from "../../ReduxToolkit/Reducers/MasterSlice";
import {
  AssessmentType,
  CompletedOn,
  CoolingPeriod,
  Days,
  End,
  ExamAlreadyInProgress,
  FinalScore,
  Mins,
  Modules,
  NoDataText,
  Note,
  NotYetAdded,
  Questions,
  Resume,
  Retake,
  Select,
  Start,
  StartNote,
  SubmittedOn,
  Tags,
  Time,
} from "../../utils/Constant";
import {
  AssessmentTabs,
  formatCustomDate,
  calculateDayDifference,
  formatDate,
  assessmentStatus,
  examStatus,
  assessmentTabStatus,
  assessmentTypesMap,
  assessmentTypes,
  showToast,
} from "../../utils/helper/helper";
import "./style.scss";
import useIsMobile from "../../utils/helper/responsive";

const AssessmentList = () => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("tab");
  const [activeTab, setActiveTab] = useState<string>(
    statusParam || assessmentTabStatus.current
  );
  const { tagList } = useAppSelector(state => state.master);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [tagsListData, setTagsListData] = useState([]);
  const [tagsListDataIdsMap, setTagsListDataIdsMap] = useState({});
  const assessmentOptions = assessmentTypes.map(item => ({
    ...item,
    name: item.value,
  }));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { learnerAssessmentList } = useAppSelector(state => state.assessment);
  const isMobile = useIsMobile();

  useEffect(() => {
    dispatch(getTagsList({ tagType: TAG_ASSESSMENT }));
  }, []);

  useEffect(() => {
    const params: { assessment_type: string[]; tag: string } = {
      assessment_type: selectedFilterValues["assessment_type"],
      tag: selectedFilterValues["tags"],
    };
    getAssessmentList(params);
  }, [activeTab]);

  useEffect(() => {
    const tags = [];
    const tagsMap = {};
    if (tagList) {
      tagList?.tags?.forEach((item, ind) => {
        tagsMap[ind] = item?.ids;
        tags.push({
          name: ind,
          value: item?.tag_name,
          label: item?.tag_name,
        });
      });
    }
    setTagsListData(tags);
    setTagsListDataIdsMap(tagsMap);
  }, [tagList]);

  const getAssessmentList = params => {
    const updatedParams = {
      ...params,
      status: activeTab,
    };
    dispatch(getAssignedAssessmentForLearner(updatedParams)).then(response => {
      if (response?.payload?.assessments?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
  };

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  const isResumeExam = status => {
    return status !== assessmentStatus.inProgress && isResumeExamAvailable();
  };

  const handleExam = assessment => {
    if (isResumeExam(assessment?.status)) {
      showToast(ExamAlreadyInProgress, "error");
      return;
    }

    if (assessment?.assessment_type === assessmentTypesMap.competency) {
      dispatch(setLearnerAssessmentDetailsToInitial());
      return navigate("/self-assessment", {
        state: {
          participantId: assessment?.participant_id,
        },
      });
    }
    if (assessment?.status === assessmentStatus.yetToStart) {
      //Came via Start
      return navigate("/assessment-instruction", {
        state: {
          participantId: assessment?.participant_id,
          name: assessment?.name,
          termsAndCondition: assessment?.term_and_condition,
        },
      });
    } else {
      //Came via Resume
      return navigate("/learning-assessment", {
        state: {
          participantId: assessment?.participant_id,
          name: assessment.name,
        },
      });
    }
  };

  const getButtonTitle = status => {
    if (status === assessmentStatus.inProgress) {
      return Resume;
    } else if (status === assessmentStatus.yetToStart) {
      return Start;
    } else if (status === assessmentStatus.retake) {
      return Retake;
    }
  };

  const isResumeExamAvailable = () => {
    return learnerAssessmentList?.assessments?.some(
      item => item.status === assessmentStatus.inProgress
    );
  };

  const getButtonType = assessment => {
    const { status, cooling_period_date } = assessment;
    const coolingPeriod = calculateDayDifference(cooling_period_date);
    const isCoolingPeriod = coolingPeriod > 0;

    if (
      activeTab === assessmentTabStatus.underReview ||
      activeTab === assessmentTabStatus.past
    ) {
      return null;
    }

    if (activeTab === assessmentTabStatus.upcoming) {
      return {
        title: Start,
        onClick: () => {
          return;
        },
        disabled: true,
      };
    }

    if (activeTab === assessmentTabStatus.current) {
      return {
        title: getButtonTitle(status),
        disabled: isCoolingPeriod,
        onClick: isCoolingPeriod
          ? () => {
              return;
            }
          : () => handleExam(assessment),
      };
    }

    return null;
  };

  const renderAssessmentCard = assessment => {
    const {
      participant_id,
      name,
      start_date,
      end_date,
      estimated_time,
      cooling_period_date,
      module_count,
      overall_question_count,
      end_time,
      score,
      status,
      assessment_type,
    } = assessment;
    const coolingPeriod = calculateDayDifference(cooling_period_date);

    const footerDate = (() => {
      if (assessment.status === assessmentStatus.overdue) {
        return "-";
      }
      if (activeTab === assessmentTabStatus.past) {
        return `${CompletedOn} ${formatDate(end_time)}`;
      } else if (activeTab === assessmentTabStatus.underReview) {
        return `${SubmittedOn} ${formatDate(end_time)}`;
      }
      return null;
    })();

    return (
      <Col key={participant_id} sm={12} lg={4}>
        <Link
          to={"/assessment-list/assessment-details"}
          state={{
            assessment,
            activeTab: activeTab,
            isResume: isResumeExam(assessment.status),
          }}
        >
          <GenericCard
            id={participant_id}
            header={name}
            moduleDetails={[
              {
                label: Start,
                value: start_date ? formatCustomDate(start_date) : "-",
                key: `${participant_id}_${Start}`,
              },
              {
                label: End,
                value: end_date ? formatCustomDate(end_date) : "-",
                key: `${participant_id}_${End}`,
              },
              {
                label: Time,
                value: estimated_time ? `${estimated_time} ${Mins}` : "-",
                key: `${participant_id}_${Time}`,
              },
              {
                label: Questions,
                value: overall_question_count ?? "-",
                key: `${participant_id}_${Questions}`,
              },
              {
                label: Modules,
                value: module_count ?? "-",
                key: `${participant_id}_${Modules}`,
              },
            ]}
            isHideMoreOption={true}
            footerBadgeRight={
              coolingPeriod && activeTab !== assessmentTabStatus.past
                ? `${CoolingPeriod} ${coolingPeriod} ${Days}`
                : ""
            }
            footerBadge={
              assessment_type !== assessmentTypesMap.competency &&
              activeTab === assessmentTabStatus.past &&
              assessment.status !== assessmentStatus.overdue &&
              `${FinalScore} ${score}%`
            }
            footerBadgeStatus={
              activeTab === assessmentTabStatus.past &&
              status === examStatus.passed
                ? "completed"
                : "rejected"
            }
            btnType={getButtonType(assessment)}
            altText={
              activeTab === assessmentTabStatus.upcoming &&
              `${Note}: ${StartNote}`
            }
            footerDate={footerDate}
          />
        </Link>
      </Col>
    );
  };

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
    setSearchResults([]);
    navigate(`/assessment-list?tab=${tabId}`);
  };

  const handleSearch = (query: string) => {
    const filteredAssessment = learnerAssessmentList?.assessments?.filter(
      assessment => assessment.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredAssessment || []);
    setNoDataFound(!filteredAssessment || filteredAssessment?.length === 0);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    const tagIds = [];
    if (values["tags"]) {
      values["tags"]?.forEach(item => {
        if (tagsListDataIdsMap[item]) tagIds.push(...tagsListDataIdsMap[item]);
      });
    }
    const params: { assessment_type: string[]; tag: string } = {
      assessment_type: values["assessment_type"],
      tag: tagIds?.toString() || null,
    };
    getAssessmentList(params);
  };

  const handleClear = () => {
    const params: { assessment_type: string[]; tag: string } = {
      assessment_type: [],
      tag: "",
    };
    getAssessmentList(params);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  const renderContent = () => {
    if (noDataFound) {
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );
    }

    if (learnerAssessmentList?.assessments?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          showCard={false}
        />
      );
    }
    if (searchResults?.length > 0) {
      return searchResults.map(assessment => renderAssessmentCard(assessment));
    }

    return learnerAssessmentList?.assessments?.map(assessment =>
      renderAssessmentCard(assessment)
    );
  };

  return (
    <div className="page-body assessment-list page-body-margin">
      <Card className="p-1">
        <CardBody>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${!isMobile ? "align-items-center" : ""}`}
          >
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={AssessmentTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>

            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={handleSearch} />
              <div className="mt-1">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: Tags,
                      key: "tags",
                      tooltipText: `${Select} ${Tags}`,
                      options: tagsListData,
                      isMultiSelect: true,
                    },
                    {
                      label: AssessmentType,
                      key: "assessment_type",
                      tooltipText: `${Select} ${AssessmentType}`,
                      options: assessmentOptions,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">{renderContent()}</Row>
    </div>
  );
};

export default AssessmentList;
