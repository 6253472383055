import PropTypes from "prop-types";
import { useState } from "react";
import ReactSelect from "react-select";

import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import { mapIlpSga } from "../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  Select,
  Submit,
  MapSGA,
  SGA,
  ILPMapSGAToolTipMessage,
} from "../../utils/Constant";
import { customStyles } from "../../utils/helper/helper";
import LabelTooltip from "../LabelTooltip";
import CommonModal from "../Modal";
import SearchDropDownPaginator from "../SearchDropDownPaginator";

interface MapSgaModalProps {
  isOpen: boolean;
  toggle: (isMapped?: boolean) => void;
  sga: { label: string; id: number }[];
  userId?: number;
}

const MapSgaModal: React.FC<MapSgaModalProps> = ({
  isOpen,
  toggle,
  sga = [],
  userId,
}) => {
  const dispatch = useAppDispatch();
  const [selectedSga, setSelectedSga] = useState<string>("");

  const handleSgaChange = (value: any) => {
    setSelectedSga(value);
  };

  const getMapSgaModalBody = () => {
    return (
      <div className="mb-3">
        <LabelTooltip
          label={SGA}
          tooltipText={ILPMapSGAToolTipMessage}
          important={true}
          placement={"bottom"}
        />
        <ReactSelect
          name="sga"
          className="text-gray"
          onChange={(selectedOption: any) =>
            handleSgaChange(selectedOption ? selectedOption.id : "")
          }
          value={sga?.find((item: any) => item.id === selectedSga) || null}
          options={
            sga?.map(item => ({
              id: item.id,
              label: item.label,
            })) as { label: string; id: number }[]
          }
          isSearchable
          isClearable={false}
          placeholder={`${Select} ${SGA}`}
          styles={customStyles}
          components={{
            MenuList: props => (
              <SearchDropDownPaginator {...props} maxHeight={250} />
            ),
          }}
          filterOption={(option, inputValue) =>
            option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
          }
        />
      </div>
    );
  };

  const handleSubmit = () => {
    dispatch(
      mapIlpSga({
        mapPayload: {
          type: "sga",
          user_id: userId,
          id: Number(selectedSga),
        },
      })
    ).then(res => {
      if (res?.payload) {
        setSelectedSga("");
        toggle(true);
      }
    });
  };

  return (
    <CommonModal
      backdrop="static"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      sizeTitle={MapSGA}
      showFooter={true}
      primaryBtnText={Submit}
      onPrimaryBtnClick={handleSubmit}
    >
      {getMapSgaModalBody()}
    </CommonModal>
  );
};

MapSgaModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  sga: PropTypes.array.isRequired,
  userId: PropTypes.number.isRequired,
};

export default MapSgaModal;
