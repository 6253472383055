import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Tooltip,
} from "reactstrap";

import {
  Badges,
  DefaultButton,
  H4,
  MUIIcons,
  P,
  SecondaryButton,
} from "../../../AbstractElements";
import {
  EXTERNAL_LEARNER,
  INTERNAL_LEARNER,
  listStatusMapping,
  WBT,
  WEBINAR,
  YtsStatus,
} from "../../../Api/constants";
import CourseActivity from "../../../CommonElements/CourseActivity";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import LearningPlanCourseCard from "../../../container/LearningPlanCourseCard";
import PlayCourseMaterial from "../../../container/PlayCourseMaterial";
import ProjectDescription from "../../../container/ProjectDescription";
import ScheduleListContainer from "../../../container/ScheduleListContainer";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  downloadCertificate,
  getScheduleCourse,
  getScheduleDetails,
  mapScheduleCourse,
} from "../../../ReduxToolkit/Reducers/CourseSlice";
import {
  BatchSize,
  CourseDuration,
  CourseID,
  CourseIsAlreadyMapped,
  CoursePeriod,
  CourseName,
  CourseType,
  Days,
  DesignedToEvaluateYourFoundational,
  EnrollMe,
  FailedToDownloadFileFrom,
  Hours,
  Instructor,
  KnowledgeAssessment,
  LearnerDocument,
  LearningOutcomes,
  Location,
  MailMe,
  MailMeAddToILP,
  Material,
  Minutes,
  NominationPeriod,
  OJTAssessment,
  PreCourseWorkHeader,
  ProficiencyLevel,
  Resources,
  SkillAssessment,
  Room,
  ThisCourseIncludes,
  TrainerDocument,
  TrainerName,
  WelcomeToPrerequisite,
  WhatYouWillLearnInThisCourse,
  launchWebinar,
  Completed,
  YetToStart,
} from "../../../utils/Constant";
import {
  convertMinutesToDaysHoursMinutes,
  formatCustomDate,
  getEllipsedFileName,
  sanitizeString,
  scheduledTabs,
  showToast,
} from "../../../utils/helper/helper";
import {
  LOGGED_IN_USER,
  getFromLocalStorage,
} from "../../../utils/helper/localStorageutils";

import "./style.scss";

type ValidMUIIcons =
  | "CalendarTodayOutlined"
  | "HelpOutline"
  | "SignalCellularAltOutlined"
  | "PlaceOutlined"
  | "PeopleAltOutlined"
  | "PersonOutlineOutlined"
  | "FileCopyOutlined"
  | "TextSnippetOutlined"
  | "EmojiEventsOutlined"
  | "AccessTimeOutlined";

const CourseDetails = () => {
  const [courseTab, setCourseTab] = useState("details");
  const [isModal, setIsModal] = useState(false);
  const [scheduledDetailTooltipOpen, setScheduledDetailTooltipOpen] = useState<
    number | null
  >(null);
  const [courseIncludesTooltipOpen, setCourseIncludesTooltipOpen] = useState<
    number | null
  >(null);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const course_id = params.get("course_id");

  const { scheduledDetails } = useAppSelector(state => state.course);

  const { scheduleCourse } = useAppSelector(state => state.course);

  const loginData = getFromLocalStorage(LOGGED_IN_USER);

  const { course_detail, course_materials, learning_outcome, user_dict } =
    scheduledDetails;

  useEffect(() => {
    if (course_id) {
      dispatch(
        getScheduleDetails({
          courseId: Number(course_id),
        })
      );
    }
  }, [course_id]);

  const getScheduleList = tabStatus => {
    dispatch(
      getScheduleCourse({
        status: tabStatus,
        course_id: course_id,
      })
    );
  };

  const getTrainer = trainers => {
    if (!trainers) {
      return {};
    }
    const firstTrainerId = user_dict?.[trainers[0].user_id];
    const remainingTrainers = trainers.slice(1);
    const remainingNames = remainingTrainers.map(trainer => {
      return user_dict?.[trainer.user_id]?.user_name;
    });
    return {
      userName: firstTrainerId?.user_name,
      userData: remainingNames,
    };
  };

  const courseData: {
    id: number;
    icon: ValidMUIIcons | "";
    value: string;
    iconName: string;
    extraData?: any;
    uniqueId?: any;
  }[] = [
    {
      id: 1,
      uniqueId: 7,
      icon: "AccessTimeOutlined",
      iconName: CoursePeriod,
      value:
        course_detail?.schedule_details?.start_date &&
        course_detail?.schedule_details?.end_date
          ? `${formatCustomDate(course_detail?.schedule_details?.start_date)} - ${formatCustomDate(course_detail?.schedule_details?.end_date)}`
          : "",
    },
    {
      id: 2,
      uniqueId: 8,
      icon: "CalendarTodayOutlined",
      iconName: NominationPeriod,
      value:
        course_detail?.schedule_details?.nomination_start_date &&
        course_detail?.schedule_details?.nomination_end_date
          ? `Nomination starts: ${formatCustomDate(course_detail?.schedule_details?.nomination_start_date)} - ${formatCustomDate(course_detail?.schedule_details?.nomination_end_date)}`
          : "",
    },
    {
      id: 3,
      uniqueId: 9,
      icon: "SignalCellularAltOutlined",
      iconName: ProficiencyLevel,
      value:
        course_detail?.curriculum?.length > 0
          ? course_detail?.curriculum
              .map(item => item.expertise_level)
              .filter((level, index, self) => self.indexOf(level) === index)
              .join(", ")
          : "",
    },
    {
      id: 4,
      uniqueId: 10,
      icon: "PlaceOutlined",
      iconName: Location,
      value: course_detail?.schedule_details?.location_name || "",
    },
    {
      id: 5,
      uniqueId: 11,
      icon: "PeopleAltOutlined",
      iconName: BatchSize,
      value:
        course_detail?.schedule_details?.batch_minimum_size &&
        course_detail?.schedule_details?.batch_maximum_size
          ? `${course_detail?.schedule_details?.batch_minimum_size} - ${course_detail?.schedule_details?.batch_maximum_size}`
          : "",
    },
  ];

  if (course_detail?.schedule_details?.trainer?.length > 0) {
    courseData.push({
      id: 6,
      uniqueId: 12,
      icon: "PersonOutlineOutlined",
      value: `${getTrainer(course_detail?.schedule_details?.trainer)?.userName}`,
      extraData: getTrainer(course_detail?.schedule_details?.trainer)?.userData,
      iconName: TrainerName,
    });
  }

  const filteredCourseData = courseData.filter(item => item.value);

  const courseInlcudes = () => {
    const courseIncludes: {
      id: number;
      icon: ValidMUIIcons | "";
      value: string;
      iconName: string;
    }[] = [];
    const overview = course_detail?.overview;
    const duration = convertMinutesToDaysHoursMinutes(overview?.duration);

    overview?.duration &&
      courseIncludes.push({
        id: 1,
        icon: "AccessTimeOutlined",
        iconName: CourseDuration,
        value:
          course_detail.course_type === WBT
            ? `${duration.days} ${Days} ${duration.hours} ${Hours} ${duration.minutes} ${Minutes}`
            : `${overview?.estimated_time} ${Days}`,
      });

    overview?.knowledge_assessment_count &&
      courseIncludes.push({
        id: 2,
        icon: "FileCopyOutlined",
        value: `${overview.knowledge_assessment_count} ${KnowledgeAssessment}`,
        iconName: KnowledgeAssessment,
      });

    overview?.ojt_assessment_count &&
      courseIncludes.push({
        id: 3,
        icon: "TextSnippetOutlined",
        value: `${overview.ojt_assessment_count} ${OJTAssessment}`,
        iconName: SkillAssessment,
      });

    overview?.learner_document_count &&
      courseIncludes.push({
        id: 4,
        icon: "TextSnippetOutlined",
        value: `${overview.learner_document_count} ${LearnerDocument}`,
        iconName: LearnerDocument,
      });

    overview?.trainer_document_count &&
      courseIncludes.push({
        id: 5,
        icon: "TextSnippetOutlined",
        value: `${overview.trainer_document_count} ${TrainerDocument}`,
        iconName: TrainerDocument,
      });

    overview?.learning_outcome_count &&
      courseIncludes.push({
        id: 6,
        icon: "TextSnippetOutlined",
        value: `${overview.learning_outcome_count} ${LearningOutcomes}`,
        iconName: LearningOutcomes,
      });

    return courseIncludes;
  };

  const getIconName = (icon: ValidMUIIcons | ""): ValidMUIIcons => {
    return icon || "HelpOutline";
  };

  const filterCourseMaterial = () => {
    if ([EXTERNAL_LEARNER, INTERNAL_LEARNER].includes(loginData.currentRole)) {
      return course_materials.filter(
        file => file.file_category === "learner_document"
      );
    }
    return course_materials;
  };

  const handlePrequiesitesClick = item => {
    setIsModal(false);
    if (item?.id) {
      return navigate(`/course-catalog/course-details?course_id=${item.id}`);
    }
  };

  const getPrerequisiteStatusText = status => {
    if (status) {
      return listStatusMapping[status]?.message;
    }

    if (status === null) {
      return YetToStart;
    }
    return status;
  };

  const getPrerequisiteStatus = item => {
    if (item.status === "completed") {
      return (
        <div className="badge-approved-bg px-2 rounded-pill d-flex align-items-center gap-1">
          <MUIIcons className="icon" iconName="CheckOutlined" size={12} />
          {Completed}
        </div>
      );
    }

    return (
      <button
        onClick={() => handlePrequiesitesClick(item)}
        className="border-0 bg-transparent pointer primary-text-color"
      >
        {getPrerequisiteStatusText(item.status)}
      </button>
    );
  };

  const transformedData = course_detail?.pre_course_work?.map(item => ({
    id: item.id.toString(),
    courseId: item.id.toString(),
    badgeTextColor: true,
    status: item?.status === null ? YtsStatus : item.status,
    courseName: item.name,
    domainName: [],
    completedOn: null,
    scheduledOn: null,
    assignedBy: null,
    courseType: null,
  }));

  if (
    course_detail?.other_pre_course_works &&
    course_detail.other_pre_course_works.trim() !== ""
  ) {
    transformedData.push({
      id: null,
      courseId: null,
      badgeTextColor: true,
      status: "completed",
      courseName: course_detail.other_pre_course_works,
      domainName: [],
      completedOn: null,
      scheduledOn: null,
      assignedBy: null,
      courseType: null,
    });
  }

  const handleDownload = async (urls: string[]) => {
    for (const url of urls) {
      if (url) {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const link = document.createElement("a");
          const objectURL = URL.createObjectURL(blob);
          link.href = objectURL;
          link.setAttribute("download", url.split("/").pop() || "file");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error(`${FailedToDownloadFileFrom} ${url}: `, error);
        }
      }
    }
  };

  const handleTabClick = (tabId: any) => {
    setCourseTab(tabId);
    if (tabId !== "details") {
      getScheduleList(tabId);
    }
  };

  const handleModal = () => {
    setIsModal(prevState => !prevState);
  };

  const isAllCompleted = () => {
    return scheduledDetails?.course_detail?.pre_course_work.every(
      item => item.status === "completed"
    );
  };

  const hasAnyNullElement = () => {
    return scheduledDetails?.course_detail?.pre_course_work.some(
      item => item.status === null
    );
  };

  const handleEnroll = () => {
    const payload = {
      type: "interested_course",
      user_id: loginData.id,
      id: [Number(course_id)],
    };
    dispatch(
      mapScheduleCourse({
        courseContent: payload,
      })
    );
    handleModal();
  };

  const handleMailAndEnroll = async (enroll = false) => {
    const notCompletedIds = scheduledDetails?.course_detail?.pre_course_work
      .filter(item => item.status === null)
      .map(item => item.id);

    if (enroll && notCompletedIds.length === 0) {
      showToast(CourseIsAlreadyMapped, "info");
      return;
    }

    const allIds = scheduledDetails?.course_detail?.pre_course_work.map(
      item => item.id
    );

    const payload = {
      type: enroll ? "mail_me_and_add_ilp" : "mail_me",
      user_id: loginData.id,
      id: enroll ? notCompletedIds : allIds,
      course_id: scheduledDetails?.course_detail?.id,
    };

    dispatch(
      mapScheduleCourse({
        courseContent: payload,
      })
    );
    handleModal();
  };

  const handleDownloadCertificate = () => {
    const payload = {
      type: course_detail?.course_type,
      courseName: course_detail?.course_name,
    };
    if (course_detail.course_type === WBT) {
      payload["courseId"] = course_detail.id;
    } else {
      payload["courseScheduleId"] =
        course_detail.schedule_details.course_schedule_id;
    }
    dispatch(downloadCertificate({ ...payload }));
  };

  const handleLaunchWebinar = () => {
    window.open(course_detail?.webinar_link, "_blank", "noopener,noreferrer");
  };

  const scheduledDetailsToggleTooltip = (id: number) => {
    setScheduledDetailTooltipOpen(prevState => (prevState === id ? null : id));
  };

  const courseIncludesToggleTooltip = (id: number) => {
    setCourseIncludesTooltipOpen(prevState => (prevState === id ? null : id));
  };
  return (
    <div className="page-body schedule-details">
      <Row>
        <Col md="8" sm="4">
          <div className="mb-3">
            <ProjectDescription
              title={course_detail?.course_name}
              description={course_detail?.description}
              courseValues={[
                {
                  label: CourseType,
                  value: course_detail?.course_type,
                  id: CourseType,
                },
                {
                  label: CourseID,
                  value: course_detail?.course_id,
                  id: CourseID,
                },
              ]}
              scheduledOn={course_detail?.schedule_details?.start_date}
              isDownloadCertificate={{
                name:
                  course_detail?.completion_status === "completed" &&
                  course_detail.id,
                handleDownload: handleDownloadCertificate || course_detail.id,
              }}
            />
          </div>

          {course_detail?.course_type === WBT && (
            <Row className="mt-5">
              {course_materials.map((item, index) => (
                <Col md="6" sm="6" key={item.file_name + index}>
                  <PlayCourseMaterial
                    material={item}
                    courseId={course_detail?.id}
                    courseName={course_detail?.course_name}
                  />
                </Col>
              ))}
            </Row>
          )}

          {course_detail?.course_type !== WBT &&
            course_detail?.course_type !== WEBINAR && (
              <Card>
                <CardBody>
                  <JustifyTabs
                    tabs={[
                      { id: "details", label: "Details" },
                      ...scheduledTabs,
                    ]}
                    activeTab={courseTab}
                    onTabClick={handleTabClick}
                  />
                </CardBody>
              </Card>
            )}

          {courseTab === "details" && learning_outcome.length > 0 && (
            <Card>
              <CardHeader>
                <H4>{WhatYouWillLearnInThisCourse}</H4>
              </CardHeader>
              <CardBody className="">
                {learning_outcome.map(item => (
                  <div
                    className="d-flex gap-2 mb-3 align-items-center"
                    key={item.id}
                  >
                    <MUIIcons
                      className="primary-icon-color"
                      iconName="CheckCircleOutlined"
                      size={27}
                    />
                    <P className="course-description__schedule-on m-0">
                      {item.outcome}
                    </P>
                  </div>
                ))}
              </CardBody>
            </Card>
          )}

          {courseTab !== "details" &&
            course_detail?.course_type !== WBT &&
            course_detail?.course_type !== WEBINAR && (
              <Card>
                <CardBody className="">
                  <ScheduleListContainer
                    userDetails={scheduleCourse?.user_dict}
                    data={scheduleCourse?.scheduled_courses}
                    filterColumns={[CourseName, Instructor, Room]}
                  />
                </CardBody>
              </Card>
            )}

          {course_detail?.course_type === WEBINAR && (
            <Card>
              <CardHeader>
                <H4>{Resources}</H4>
              </CardHeader>
              <CardBody>
                <>
                  <ul className="schedule-details__resources">
                    {course_detail?.tool_aid?.tool?.map(item => (
                      <li className="mb-1" key={item.id}>
                        <div>{item.name}</div>
                      </li>
                    ))}
                  </ul>
                  <Button
                    color="primary"
                    className="d-flex align-items-center gap-1 my-3"
                    onClick={handleLaunchWebinar}
                  >
                    {launchWebinar}
                  </Button>
                </>
              </CardBody>
            </Card>
          )}
        </Col>

        <Col md="4">
          {filteredCourseData.length > 0 && (
            <Card>
              <CardBody className="schedule-details__course-data">
                {course_detail?.schedule_details &&
                  filteredCourseData?.map((item: any) => {
                    return (
                      <div className="d-flex gap-2 mb-3" key={item.icon}>
                        <span
                          id={`TooltipIcon${sanitizeString(item.value)}`}
                          className="ml-2 pointer"
                        >
                          <MUIIcons
                            className="course-description__calender"
                            size={18}
                            iconName={getIconName(item.icon)}
                          />
                        </span>

                        <Tooltip
                          placement="top"
                          isOpen={scheduledDetailTooltipOpen === item.id}
                          target={`TooltipIcon${sanitizeString(item.value)}`}
                          toggle={() => scheduledDetailsToggleTooltip(item.id)}
                        >
                          {item.iconName}
                        </Tooltip>

                        {item?.icon === "PersonOutlineOutlined" &&
                        item?.extraData?.length > 0 ? (
                          <>
                            <span
                              id={`TooltipPersonIcon${sanitizeString(item.value)}`}
                              className="ml-2 pointer"
                            >
                              {item?.value} {" +"} {item?.extraData?.length}
                            </span>

                            <Tooltip
                              placement="top"
                              isOpen={
                                scheduledDetailTooltipOpen === item.uniqueId
                              }
                              target={`TooltipPersonIcon${sanitizeString(item.value)}`}
                              toggle={() =>
                                scheduledDetailsToggleTooltip(item.uniqueId)
                              }
                            >
                              {item?.extraData}
                            </Tooltip>
                          </>
                        ) : (
                          <P className="course-description__schedule-on m-0">
                            {item.value}
                          </P>
                        )}
                      </div>
                    );
                  })}
                {course_detail?.schedule_details &&
                  course_detail?.schedule_details?.self_enrollment && (
                    <div className="border-top">
                      <DefaultButton
                        className="w-100 mt-3"
                        color="primary"
                        onClick={handleModal}
                      >
                        I’m Interested !
                      </DefaultButton>
                    </div>
                  )}
              </CardBody>
            </Card>
          )}
          {(scheduledDetails?.course_detail?.course_prerequisite?.length > 0 ||
            (scheduledDetails?.course_detail?.other_prerequisites &&
              scheduledDetails?.course_detail?.other_prerequisites !== "")) && (
            <Card>
              <CardHeader>
                <H4>Prerequisite</H4>
              </CardHeader>
              <CardBody className="mt-2">
                {scheduledDetails?.course_detail?.course_prerequisite?.map(
                  item => (
                    <div className="" key={item.id}>
                      <CourseActivity
                        name={item.name}
                        getStatus={() => getPrerequisiteStatus(item)}
                      />
                    </div>
                  )
                )}
              </CardBody>
              {scheduledDetails?.course_detail?.other_prerequisites &&
                scheduledDetails?.course_detail?.other_prerequisites !== "" && (
                  <CardBody>
                    <div className="">
                      <CourseActivity
                        name={
                          scheduledDetails?.course_detail?.other_prerequisites
                        }
                        getStatus={() =>
                          getPrerequisiteStatus({ status: "completed" })
                        }
                      />
                    </div>
                  </CardBody>
                )}
            </Card>
          )}

          <Modal
            isOpen={isModal}
            toggle={handleModal}
            className="modal-body"
            centered={true}
          >
            <ModalHeader toggle={handleModal}>
              <H4>Prerequisite Course</H4>
            </ModalHeader>
            <ModalBody>
              <P className="mb-3">
                {WelcomeToPrerequisite} {course_detail?.course_name}{" "}
                {DesignedToEvaluateYourFoundational}
              </P>
              {scheduledDetails?.course_detail?.course_prerequisite?.map(
                item => (
                  <div className="" key={item.id}>
                    <CourseActivity
                      name={item.name}
                      getStatus={() => getPrerequisiteStatus(item)}
                    />
                  </div>
                )
              )}
            </ModalBody>
            <ModalFooter>
              {isAllCompleted() ? (
                <DefaultButton
                  className="w-100"
                  color="primary"
                  onClick={handleEnroll}
                >
                  {EnrollMe}
                </DefaultButton>
              ) : (
                <div className="d-flex w-100">
                  {(() => {
                    const hasNullElements = hasAnyNullElement();
                    return (
                      <>
                        <SecondaryButton
                          onClick={() => handleMailAndEnroll()}
                          className={`secondary-button ${hasNullElements ? "w-50" : "w-100"}`}
                        >
                          {MailMe}
                        </SecondaryButton>
                        {hasNullElements && (
                          <DefaultButton
                            className="w-50"
                            color="primary"
                            onClick={() => handleMailAndEnroll(true)}
                          >
                            {MailMeAddToILP}
                          </DefaultButton>
                        )}
                      </>
                    );
                  })()}
                </div>
              )}
            </ModalFooter>
          </Modal>

          {(course_detail?.pre_course_work.length > 0 ||
            (course_detail?.other_pre_course_works &&
              course_detail.other_pre_course_works.trim() !== "")) && (
            <Card>
              <CardHeader>
                <H4>{PreCourseWorkHeader}</H4>
              </CardHeader>
              <CardBody className="mt-2">
                <LearningPlanCourseCard
                  showUserDetails={false}
                  data={transformedData}
                  handleClick={handlePrequiesitesClick}
                />
              </CardBody>
            </Card>
          )}

          {filterCourseMaterial().length > 0 &&
            course_detail?.course_type !== WBT && (
              <Card>
                <CardHeader>
                  <H4>{Material}</H4>
                </CardHeader>
                <CardBody className="mt-2">
                  {filterCourseMaterial()?.map((item, index) => (
                    <div className="mb-3" key={item.file_name + index}>
                      <Badges className="w-100" color="light text-dark">
                        <div className="d-flex align-items-center">
                          <MUIIcons
                            size={24}
                            iconName="InsertDriveFileOutlined"
                          />
                          <span className="text-start m-1 w-100 gap-1 p-1 mb-2">
                            {getEllipsedFileName(item.file_name)}
                          </span>
                          <MUIIcons
                            className="pointer"
                            onClick={() => handleDownload([item.file_url])}
                            size={24}
                            iconName="FileDownloadOutlined"
                          />
                        </div>
                      </Badges>
                    </div>
                  ))}
                </CardBody>
              </Card>
            )}

          {courseInlcudes()?.length > 0 && (
            <Card>
              <CardHeader>
                <H4>{ThisCourseIncludes}:</H4>
              </CardHeader>
              <CardBody className="schedule-details__course-includes">
                {courseInlcudes().map(item => (
                  <div className="d-flex gap-2 mb-3" key={item.icon}>
                    <span
                      id={`TooltipIcon${sanitizeString(item.value)}`}
                      className="ml-2 pointer"
                    >
                      <MUIIcons
                        className="course-description__calender"
                        size={18}
                        iconName={getIconName(item.icon)}
                      />
                    </span>

                    <Tooltip
                      placement="top"
                      isOpen={courseIncludesTooltipOpen === item.id}
                      target={`TooltipIcon${sanitizeString(item.value)}`}
                      toggle={() => courseIncludesToggleTooltip(item.id)}
                    >
                      {item.iconName}
                    </Tooltip>
                    <P className="course-description__schedule-on m-0">
                      {item.value}
                    </P>
                  </div>
                ))}
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CourseDetails;
