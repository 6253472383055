import { useEffect, useState } from "react";
import DataTable, { TableStyles } from "react-data-table-component";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Badge,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Tooltip,
} from "reactstrap";

import { Badges, Btn, H3, H4, MUIIcons } from "../../../../AbstractElements";
import FilterSliderModal from "../../../../CommonElements/FilterSliderModal";
import CommonModal from "../../../../CommonElements/Modal";
import SearchBar from "../../../../CommonElements/SearchBar";
import UpdateAssetModal from "../../../../CommonElements/UpdateAssetModal";
import NoDataFound from "../../../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  getAssetsListById,
  getDocuments,
} from "../../../../ReduxToolkit/Reducers/AssetManagementSlice";
import { getLocationList } from "../../../../ReduxToolkit/Reducers/MasterSlice";
import {
  Action,
  AddNew,
  AssetName,
  AssetsID,
  Calibration,
  Close,
  DateReceipt,
  Document,
  Edit,
  Every,
  FailedToDownloadFileFrom,
  Files,
  History,
  InvoiceDate,
  InvoiceNumber,
  InvoiceValue,
  Location,
  Locations,
  Maintenance,
  PartNumber,
  Select,
  Status,
  UpdateCalibration,
  UpdateMaintenance,
} from "../../../../utils/Constant";
import "./style.scss";
import {
  AssetsManagementFilterStatus,
  assetStatusMap,
  capitalizeFirstLetter,
  extractFileName,
  formatCustomDate,
  formatNumberWithCommas,
  frequencyType,
  getEllipsedFileName,
  sanitizeString,
} from "../../../../utils/helper/helper";

const AssetsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { assetTypeList, documents } = useAppSelector(state => state.assets);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const assetId = queryParams.get("asset_id");
  const headerName = queryParams.get("name");
  const [dropdownOpen, setDropdownOpen] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [documentModalOpen, setDocumentModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState<{ [key: string]: boolean }>(
    {}
  );

  const toggleTooltip = (index: string) => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const [locationNameMap, setLocationNameMap] = useState([]);
  const { locationList } = useAppSelector(state => state.master);

  useEffect(() => {
    getUpdatedData();
  }, [dispatch, assetId]);

  const getUpdatedData = async () => {
    if (assetId) {
      await dispatch(getAssetsListById({ id: assetId }));
    }
  };

  useEffect(() => {
    const updatedData = [];
    if (assetTypeList) {
      assetTypeList?.resource?.forEach(item => {
        updatedData.push({
          resource_id: item?.resource_id,
          maintenance_valid_till: item?.maintenance_valid_till,
          calibration_valid_till: item?.calibration_valid_till,
          assetId: item?.asset_id,
          assetsName: item?.asset_name,
          partNumber: item?.part_no,
          locationName: item?.location_name
            ? capitalizeFirstLetter(item.location_name)
            : "-",
          assetNumber: item?.asset_name,
          invoiceNumber: item?.invoice_number,
          invoiceValue: item?.invoice_value,
          invoiceDate: formatCustomDate(item?.invoice_date),
          dateOfReciept: formatCustomDate(item?.receipt_date),
          frequency:
            item?.calibration_value && item?.calibration_frequency
              ? `${Every} ${item?.calibration_value} ${frequencyType[item?.calibration_frequency]}`
              : "",
          maintenances:
            item?.maintenance_value && item?.maintenance_frequency
              ? `${Every} ${item?.maintenance_value} ${frequencyType[item?.maintenance_frequency]}`
              : "",
          document: item?.document_count,
          status: item?.status,
        });
      });
    }
    setParsedData(updatedData);
  }, [assetTypeList]);

  useEffect(() => {
    dispatch(getLocationList());
  }, []);

  useEffect(() => {
    const locationMapObj = [];
    locationList?.location?.forEach(location => {
      locationMapObj.push({
        name: location?.id?.toString(),
        value: location?.name,
        label: location?.name,
      });
    });
    setLocationNameMap(locationMapObj);
  }, [locationList]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = parsedData?.filter(assetsList =>
        assetsList?.assetsName
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(parsedData);
    }
  }, [searchQuery, parsedData]);

  const onDocumentClick = async id => {
    await dispatch(getDocuments({ id: id }));
    toggleDocumentModal();
  };

  const isPastOrInvalidDate = (date: string | null | undefined) => {
    return new Date(date) <= new Date() && date != null;
  };

  const AssetsTableColumns = [
    {
      name: AssetName,
      selector: row => row.assetsName || "-",
      minWidth: "16rem",
      sortable: true,
    },
    {
      name: AssetsID,
      selector: row => row.assetId || "-",
      minWidth: "14rem",
      sortable: true,
    },
    {
      name: PartNumber,
      selector: row => row.partNumber || "-",
      minWidth: "12rem",
      sortable: true,
    },
    {
      name: Location,
      selector: row => row.locationName || "-",
      minWidth: "12rem",
      sortable: true,
    },
    {
      name: Calibration,
      selector: row => row.frequency,
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: Maintenance,
      selector: row => row.maintenances,
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: Document,
      cell: row => (
        <Badges
          onClick={() => onDocumentClick(row.resource_id)}
          color="light text-dark"
        >
          <MUIIcons
            className="pointer"
            iconName={"InsertDriveFileOutlined"}
            size={24}
          />
          {row?.document > 0 && (
            <Badge className="asset-doc-notification-badge ">
              {row.document}
            </Badge>
          )}
        </Badges>
      ),
      minWidth: "7rem",
    },
    {
      name: DateReceipt,
      selector: row => row.dateOfReciept,
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: InvoiceNumber,
      selector: row => row.invoiceNumber,
      minWidth: "12rem",
      sortable: true,
    },
    {
      name: InvoiceDate,
      selector: row => row.invoiceDate,
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: InvoiceValue,
      selector: row => "₹ " + formatNumberWithCommas(row.invoiceValue),
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: Status,
      sortable: true,
      cell: row => {
        const status = assetStatusMap[row.status];

        if (row.status === "need_attention") {
          let status = assetStatusMap[row.status];

          if (
            isPastOrInvalidDate(row?.calibration_valid_till) &&
            isPastOrInvalidDate(row?.maintenance_valid_till)
          ) {
            status += " (C/M)";
          } else {
            if (isPastOrInvalidDate(row?.calibration_valid_till)) {
              status += " (C)";
            }
            if (isPastOrInvalidDate(row?.maintenance_valid_till)) {
              status += " (M)";
            }
          }

          return <div className={row.status}>{status}</div>;
        }

        return <div className={row.status}>{status}</div>;
      },
      minWidth: "12rem",
    },
    {
      name: Action,
      selector: row => row.assetId,
      minWidth: "6rem",
      center: true,
      cell: row => (
        <div>
          <Dropdown
            isOpen={row?.resource_id === dropdownOpen}
            toggle={e => toggleDropdown(e, row.resource_id)}
            direction="start"
          >
            <DropdownToggle color="" className="three-dots primary-text-color">
              <MUIIcons
                iconName="MoreVertOutlined"
                className="primary-icon-color"
                size={17}
              />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {row.status === "need_attention" &&
                isPastOrInvalidDate(row?.calibration_valid_till) && (
                  <DropdownItem
                    onClick={() => handleUpdate(row, "calibration")}
                  >
                    {UpdateCalibration}
                  </DropdownItem>
                )}
              {row.status === "need_attention" &&
                isPastOrInvalidDate(row?.maintenance_valid_till) && (
                  <DropdownItem
                    onClick={() => handleUpdate(row, "maintenance")}
                  >
                    {UpdateMaintenance}
                  </DropdownItem>
                )}
              {(row.status === "active" ||
                row.status === "need_attention" ||
                row.status === "replaced" ||
                row.status === "retired") && (
                <DropdownItem
                  onClick={() =>
                    handleHistory(row?.resource_id, row.assetsName)
                  }
                >
                  {History}
                </DropdownItem>
              )}
              {(row.status === "active" ||
                row.status === "need_attention" ||
                row.status === "replaced") && (
                <DropdownItem onClick={() => handleCreate(row?.resource_id)}>
                  {Edit}
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      ),
    },
  ];

  const customStyles: TableStyles = {
    rows: {
      style: {
        padding: "1rem 0",
      },
    },
    table: {
      style: {
        marginTop: "2rem",
      },
    },
  };

  const toggleDropdown = (e, rowId) => {
    e.preventDefault();
    setDropdownOpen(prevDropdownOpen =>
      prevDropdownOpen === rowId ? "" : rowId
    );
  };

  const handleUpdate = (row, type) => {
    setSelectedData(row);
    setSelectedType(type);
    toggleModal();
  };

  const toggleModal = () => {
    if (modalOpen) {
      setSelectedData({});
      setSelectedType("");
    }
    setModalOpen(!modalOpen);
  };

  const toggleDocumentModal = () => {
    setDocumentModalOpen(!documentModalOpen);
  };

  const handleHistory = (resource_id, assetName) => {
    return navigate(
      `${process.env.PUBLIC_URL}/master-data/asset-management/assets-history?resource_id=${resource_id}&name=${headerName}&asset_name=${assetName}`
    );
  };

  const handleCreate = resource_id => {
    return navigate(
      `${process.env.PUBLIC_URL}/master-data/asset-management/add-asset-management?asset_id=${resource_id}&edit=${true}`
    );
  };

  const handleDownload = async (urls: string[]) => {
    for (const url of urls) {
      if (url) {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const link = document.createElement("a");
          const objectURL = URL.createObjectURL(blob);
          link.href = objectURL;
          link.setAttribute("download", url.split("/").pop() || "file");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error(`${FailedToDownloadFileFrom} ${url}: `, error);
        }
      }
    }
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    const status = values["status"] ? values["status"].join(",") : "";
    const location = values["location"] ? values["location"].join(",") : "";
    await dispatch(
      getAssetsListById({ id: assetId, status: status, location })
    );
  };

  const handleClear = async () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    await dispatch(getAssetsListById({ id: assetId }));
  };

  return (
    <div className="page-body">
      <CommonModal
        sizeTitle={Files}
        isOpen={documentModalOpen}
        toggle={toggleDocumentModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={toggleDocumentModal}
        primaryBtnText={Close}
      >
        {(() => {
          if (!Array.isArray(documents?.document)) {
            return <NoDataFound />;
          }

          const categorizedDocuments = documents?.document?.reduce(
            (acc, item) => {
              if (!acc[item.type]) {
                acc[item.type] = [];
              }
              acc[item.type].push(item);
              return acc;
            },
            {}
          );

          return Object.keys(categorizedDocuments)?.length > 0 ? (
            Object.keys(categorizedDocuments).map((category, catIndex) => (
              <div key={catIndex} className="mt-4">
                <H4>
                  <span className="m-1">
                    {capitalizeFirstLetter(category) + " " + Files}
                  </span>
                </H4>
                <Row>
                  {categorizedDocuments[category].map((item, docIndex) => {
                    const uniqueId = `${category}-${docIndex}`;

                    return (
                      <Col lg="6" className="mt-2" key={docIndex}>
                        <H3>
                          <Badges color="light text-dark">
                            <MUIIcons
                              size={24}
                              iconName="InsertDriveFileOutlined"
                            />
                            <span
                              className="m-1"
                              id={sanitizeString(
                                `tooltip-course1-name-${uniqueId}`
                              )}
                            >
                              {getEllipsedFileName(
                                extractFileName(item.document),
                                35
                              )}
                            </span>
                            <Tooltip
                              placement="top"
                              isOpen={tooltipOpen[uniqueId] || false}
                              target={sanitizeString(
                                `tooltip-course1-name-${uniqueId}`
                              )}
                              toggle={() => toggleTooltip(uniqueId)}
                            >
                              {extractFileName(item.document)}
                            </Tooltip>
                            <MUIIcons
                              onClick={() => handleDownload([item.document])}
                              iconName="FileDownload"
                              className="pointer"
                              size={16}
                            />
                          </Badges>
                        </H3>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ))
          ) : (
            <NoDataFound />
          );
        })()}
      </CommonModal>

      <Card>
        <CardBody className="d-flex flex-row justify-content-end align-items-center gap-1">
          <div className="d-flex gap-3 align-items-center">
            <SearchBar onSearch={query => setSearchQuery(query)} />
            <div className="mt-2">
              <FilterSliderModal
                dropdowns={[
                  {
                    label: Status,
                    key: "status",
                    tooltipText: `${Select} ${Status}`,
                    options: AssetsManagementFilterStatus,
                    isMultiSelect: true,
                  },
                  {
                    label: Locations,
                    key: "location",
                    tooltipText: `${Select} ${Locations}`,
                    options: locationNameMap,
                    isMultiSelect: true,
                  },
                ]}
                selectedFilterValues={selectedFilterValues}
                defaultFilterValues={defaultFilterValues}
                setSelectedFilterValues={setSelectedFilterValues}
                setDefaultFilterValues={setDefaultFilterValues}
                onDone={handleDone}
                onClear={handleClear}
              />
            </div>
            <Link
              to={`/master-data/asset-management/add-asset-management?type=${assetId || null}&edit=${true}`}
            >
              <Btn
                icon={
                  <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                }
                color="primary"
              >
                {AddNew}
              </Btn>
            </Link>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <H3 className="fw-bold p-1">{headerName}</H3>
          <DataTable
            className="asset-list-table"
            pagination
            highlightOnHover
            columns={AssetsTableColumns}
            data={filteredData}
            customStyles={customStyles}
          />
          <UpdateAssetModal
            selectedData={selectedData}
            selectedType={selectedType}
            isOpen={modalOpen}
            toggle={toggleModal}
            getUpdatedData={getUpdatedData}
            onSubmit={() => {
              toggleModal();
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default AssetsList;
