import PropTypes from "prop-types";
import { FormGroup, Input } from "reactstrap";

import { H1, H2 } from "../../../../AbstractElements";
import { WILL_BE_UPLOADED } from "../../../../Api/constants";
import FileHandler from "../../../../CommonElements/FileHandler";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import { Description, Enter, WriteHere } from "../../../../utils/Constant";
import "./style.scss";
import { extractFileName } from "../../../../utils/helper/helper";

const FreeText = ({
  questionId,
  questionNumber,
  title,
  handleChange,
  value,
  mediaUrl,
}) => {
  return (
    <div className="free-choise">
      <H1 className="free-choise__question">{questionNumber}</H1>
      <H2 className="free-choise__title">{title}</H2>
      {mediaUrl && extractFileName(mediaUrl) !== WILL_BE_UPLOADED && (
        <FileHandler showPreview={true} mediaUrl={mediaUrl} />
      )}

      <div className="free-choise__input">
        <FormGroup>
          <LabelTooltip
            label={WriteHere}
            important={false}
            tooltipText={WriteHere}
          />
          <Input
            onChange={e => {
              handleChange(questionId, e.target.value);
            }}
            rows={4}
            name="objective"
            type="textarea"
            placeholder={`${Enter} ${Description}`}
            value={value}
          />
        </FormGroup>
      </div>
    </div>
  );
};

FreeText.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  questionNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  title: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  mediaUrl: PropTypes.string,
};

export default FreeText;
