import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container } from "reactstrap";

import { Home, PageNotFoundIssue } from "../../utils/Constant";
import H2 from "../Headings/H2Element";
import P from "../Paragraph";

const CommonError = ({ error, color }) => {
  const navigate = useNavigate();

  const redirectToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <div className="error-wrapper">
        <Container>
          <div className="error-heading">
            <H2 className={`headline font-${color}`}>{error}</H2>
          </div>
          <Col md="8" className="offset-md-2">
            <P className="sub-content">{PageNotFoundIssue}</P>
          </Col>
          <div>
            <Button color="primary" onClick={redirectToDashboard}>
              {Home}
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
};

CommonError.propTypes = {
  error: PropTypes.string,
  color: PropTypes.string,
};

export default CommonError;
