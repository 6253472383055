import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { H4, MUIIcons } from "../../AbstractElements";
import FilterSliderModal from "../../CommonElements/FilterSliderModal";
import SearchBar from "../../CommonElements/SearchBar";
import {
  Action,
  Duration,
  GiveFeedback,
  Mentee,
  Mentor,
  Select,
  SessionDate,
  SessionLists,
  Status,
  Topic,
} from "../../utils/Constant";
import {
  convertMinutesToHours,
  formatCustomDate,
  SessionStatusFilterDropdownList,
} from "../../utils/helper/helper";
import UserDetailsCard from "../UserDetailsCard";

interface SessionListProps {
  data: SessionListData[];
  handleSearch: (searchTerm: string) => void;
  onRowClick?: (row: SessionListData) => void;
  showActions?: boolean;
  showMentor?: boolean;
  showMentee?: boolean;
}

interface SessionListData {
  sessionId: number;
  topic: string;
  duration: string;
  sessionDate: string;
  status: string;
  mentorId?: number;
  name?: string;
  empId?: string;
  mobile?: string;
  mentorMenteeId?: number;
}

const SessionList: React.FC<SessionListProps> = ({
  data,
  handleSearch,
  onRowClick,
  showActions,
  showMentor,
  showMentee,
}) => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleFeedback = (sessionId: number) => {
    navigate(`${process.env.PUBLIC_URL}/mentoring/i-am-mentor/instruction`, {
      state: { instructionFor: "mentee", sessionId: sessionId },
    });
  };

  const toggleDropdown = (e, rowId) => {
    e.preventDefault();
    setDropdownOpen(prevDropdownOpen =>
      prevDropdownOpen === rowId ? null : rowId
    );
  };

  const handleSearchTermChange = (searchTerm: string) => {
    setFilteredData(
      data?.filter(session => {
        return (
          session.topic?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          session.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          session.empId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          session.mobile?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    );
    handleSearch(searchTerm);
  };

  const handleDone = newFilterValues => {
    setSelectedFilterValues(newFilterValues);

    const filtered = data.filter(session => {
      const selectedStatuses = newFilterValues.session_status || [];

      const statusMatch =
        selectedStatuses.length === 0 ||
        selectedStatuses.includes(session.status.toLowerCase());

      return statusMatch;
    });

    setFilteredData(filtered);
  };

  const handleClear = () => {
    setSelectedFilterValues({});
    setFilteredData(data);
  };

  const SessionTableColumns = [
    {
      name: Topic,
      cell: row => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "45rem",
          }}
        >
          {row.topic ? row.topic : "-"}
        </div>
      ),
      minWidth: "20rem",
      center: false,
    },
    {
      name: Duration,
      cell: row =>
        row.duration ? convertMinutesToHours(row.duration) + "h" : "-",
    },
    {
      name: SessionDate,
      cell: row => formatCustomDate(row?.sessionDate),
      minWidth: "2rem",
    },
    {
      name: Status,
      cell: row => (
        <span style={{ color: row.status === "completed" ? "green" : "gray" }}>
          {row.status}
        </span>
      ),
      minWidth: "2rem",
    },
  ];

  if (showActions) {
    SessionTableColumns.push({
      name: Action,
      center: true,
      cell: row => (
        <div>
          {row.status === "completed" && (
            <Dropdown
              isOpen={dropdownOpen === row.sessionId}
              toggle={e => toggleDropdown(e, row.sessionId)}
            >
              <DropdownToggle color="" className="three-dots">
                <MUIIcons
                  iconName="MoreVertOutlined"
                  className="primary-icon-color"
                  size={17}
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => handleFeedback(row.sessionId)}>
                  {GiveFeedback}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      ),
      minWidth: "2rem",
    });
  }

  if (showMentor) {
    SessionTableColumns.unshift({
      name: Mentor,
      cell: row => (
        <button
          className="border-0 bg-transparent text-start"
          onClick={() => handleRowClick(row)}
        >
          <UserDetailsCard
            userDetails={{
              id: row.id,
              name: row.name,
              empId: row.empId,
              mobile: row.mobile,
            }}
          />
        </button>
      ),
      minWidth: "20rem",
    });
  }

  if (showMentee) {
    SessionTableColumns.unshift({
      name: Mentee,
      cell: row => (
        <>
          {row.name ? (
            <UserDetailsCard
              userDetails={{
                id: row.id,
                name: row.name,
                empId: row.empId,
                mobile: row.mobile,
              }}
            />
          ) : (
            "-"
          )}
        </>
      ),
      minWidth: "20rem",
    });
  }

  const customStyles = {
    rows: {
      style: {
        padding: "1rem 0",
        cursor: "pointer",
      },
    },
  };

  const handleRowClick = row => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  return (
    <Card>
      <CardBody>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <H4>{SessionLists}</H4>
          <div className="d-flex justify-content-end align-items-center">
            <SearchBar onSearch={handleSearchTermChange} />
            <div className="mt-2">
              <FilterSliderModal
                dropdowns={[
                  {
                    label: Status,
                    key: "session_status",
                    tooltipText: `${Select} ${Status}`,
                    options: SessionStatusFilterDropdownList,
                    isMultiSelect: true,
                  },
                ]}
                selectedFilterValues={selectedFilterValues}
                defaultFilterValues={defaultFilterValues}
                setSelectedFilterValues={setSelectedFilterValues}
                setDefaultFilterValues={setDefaultFilterValues}
                onDone={handleDone}
                onClear={handleClear}
              />
            </div>
          </div>
        </div>
        <DataTable
          highlightOnHover
          columns={SessionTableColumns}
          data={filteredData}
          customStyles={customStyles}
          onRowClicked={handleRowClick}
        />
      </CardBody>
    </Card>
  );
};

SessionList.propTypes = {
  data: PropTypes.array.isRequired,
  handleSearch: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  showActions: PropTypes.bool,
  showMentor: PropTypes.bool,
  showMentee: PropTypes.bool,
};

export default SessionList;
