import { useState } from "react";
import { Col, Container, Form, FormGroup, Input, Row } from "reactstrap";

import { DefaultButton, H4, Image } from "../../AbstractElements";
import { STATUS_200 } from "../../Api/constants";
import LabelTooltip from "../../CommonElements/LabelTooltip";
import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import { triggerResetPasswordExternalUser } from "../../ReduxToolkit/Reducers/AuthenticationSlice";
import { dynamicImage } from "../../Service";
import {
  EmailId,
  EnterEmail,
  EnterEmailError,
  EnterProperEmailError,
  ResetYourPasswordText,
  ResetMyPasswordText,
} from "../../utils/Constant";
import { EMAIL_REGEX, showToast } from "../../utils/helper/helper";
import useIsMobile from "../../utils/helper/responsive";

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const [email, setEmail] = useState("");

  const onResetPassword = async () => {
    if (!email || email === "") {
      showToast(EnterEmailError, "error");
      return;
    }
    const validated = EMAIL_REGEX.test(email);
    if (!validated) {
      showToast(EnterProperEmailError, "error");
      return;
    }
    const payload = {
      email: email,
    };
    const res: any = await dispatch(
      triggerResetPasswordExternalUser({ data: payload })
    );
    if (res?.payload?.status_code === STATUS_200) {
      showToast(res?.payload?.message, "success");
    } else {
      const parsedResponse = JSON.parse(res?.error?.message);
      const message = parsedResponse.message;
      showToast(message, "error");
    }
  };

  return (
    <Row className="min-vh-100">
      {!isMobile && (
        <Col className="bg-white d-flex align-items-center justify-content-center">
          <Image
            className="img-fluid for-light"
            src={dynamicImage("login/login_page.png")}
            alt="logo"
          />
        </Col>
      )}
      <Col lg="6" sm="12" xs="12" className="bg-white">
        <Container fluid className="p-0 min-vh-100 d-flex align-items-center">
          <Row className="m-0 w-100 justify-content-center">
            <Col>
              <div className="p-5">
                <div className="mb-2 text-center">
                  <Image
                    className="img-fluid for-light"
                    src={dynamicImage("logo/login_logo.png")}
                    alt="loginpage"
                  />
                </div>
                <div className="login-main">
                  <H4 className="mt-3">{ResetYourPasswordText}</H4>
                  <Form className="theme-form">
                    <FormGroup>
                      <LabelTooltip
                        important={false}
                        label={EmailId}
                        tooltipText={EnterEmail}
                      />
                      <Input
                        type="email"
                        required
                        placeholder={EnterEmail}
                        value={email}
                        name="email"
                        onChange={event => setEmail(event.target.value)}
                      />
                    </FormGroup>
                    <FormGroup className="mb-0 form-sub-title">
                      <div className="mt-3">
                        <DefaultButton
                          onClick={e => {
                            e.preventDefault();
                            onResetPassword();
                          }}
                          type="button"
                          color="primary"
                          block
                        >
                          {ResetMyPasswordText}
                        </DefaultButton>
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

export default ResetPassword;
