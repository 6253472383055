import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";

import {
  Badges,
  H4,
  MUIIcons,
  P,
  Progressbar,
} from "../../../AbstractElements";
import SimpleAccordion from "../../../CommonElements/Accordion";
import DetailHeader from "../../../CommonElements/DetailHeader";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import CommonModal from "../../../CommonElements/Modal";
import MultiFileUpload from "../../../CommonElements/MultiFileUpload/MultiFileUpload";
import ILPPendingSkillTable from "../../../container/ILPPendingSkillTable";
import "./style.scss";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  approveSkillDocs,
  getLOForAssessment,
  getPendingSkillUpdateList,
  uploadSkillDocs,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import { uploadFileApi } from "../../../ReduxToolkit/Reducers/UserSlice";
import {
  Approved,
  ApproveFile,
  ApproveSkillFile,
  Close,
  Confirm,
  DeclineFile,
  Enter,
  Location,
  LocationAlphanumeric,
  LocationRequired,
  MachineSerialNumber,
  MachineSerialNumberAlphanumeric,
  MachineSerialNumberRequired,
  NoDataText,
  PendingSkillFileSizeNote,
  PleaseUploadFile,
  RejectSkillFile,
  Select,
  SideBarTitleLocation,
  SkillAssessment,
  StartDate,
  StartDateRequired,
  Upload,
  UploadFile,
  WorkOrderNumber,
  WorkOrderNumberAlphanumeric,
  WorkOrderNumberRequired,
} from "../../../utils/Constant";
import {
  assessmentTypeOptionsMap,
  extractFileName,
  formatCustomDate,
  getEllipsedFileName,
  handleAllFilesDownload,
  PendingSkillTableHeader,
  showToast,
} from "../../../utils/helper/helper";
import useIsMobile from "../../../utils/helper/responsive";

const PendingSkillUpdate = ({ userId }) => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const { pendingSkillUpdateList, loading } = useAppSelector(
    state => state.learningPlan
  );
  const [errors, setErrors] = useState(null);
  const [formValue, setFormValue] = useState(null);
  const [updateData, setUpdateData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [document, setDocument] = useState([]);
  const [outcomesData, setOutcomesData] = useState<any>({});
  const [isSkillModalOpen, setIsSkillModalOpen] = useState(false);

  useEffect(() => {
    getData();
  }, [dispatch, userId]);

  const getData = async () => {
    if (userId) {
      await dispatch(getPendingSkillUpdateList({ userId: userId }));
    } else {
      await dispatch(getPendingSkillUpdateList({ userId: null }));
    }
  };

  useEffect(() => {
    if (pendingSkillUpdateList?.data?.skill_document) {
      getAccordionData();
    }
  }, [pendingSkillUpdateList]);

  const toggleSkillModal = () => {
    if (isSkillModalOpen) {
      setOutcomesData({});
    }
    setIsSkillModalOpen(!isSkillModalOpen);
  };

  const handleSkillCompletionClick = async (lo, url, data) => {
    const recievedData: any = {
      lo: lo,
      url: url,
      data: data,
    };

    setOutcomesData(recievedData);
    toggleSkillModal();
  };

  const getAccordionData = async () => {
    const assessmentIds = [];
    const accordionData = {};
    const processedData = [];
    pendingSkillUpdateList?.data?.skill_document?.forEach(item => {
      assessmentIds.push(item?.course_assessment_id);
    });

    const LOs = await dispatch(
      getLOForAssessment({ ids: assessmentIds?.toString() })
    );

    const percentage = {};

    if (LOs?.payload?.data) {
      pendingSkillUpdateList?.data?.skill_document?.forEach(item => {
        let name = "";
        if (!percentage[item?.course_id]) {
          percentage[item?.course_id] = {
            total: 0,
            completed: 0,
          };
        }

        percentage[item?.course_id].total += 1;
        if (item?.status === Approved) {
          percentage[item?.course_id].completed += 1;
        }

        const outcomes = [];
        if (LOs?.payload?.data[item?.course_assessment_id]) {
          name = LOs?.payload?.data[item?.course_assessment_id]?.course_name;
          LOs?.payload?.data[item?.course_assessment_id]?.outcomes?.forEach(
            lo => {
              outcomes.push(lo?.outcome);
            }
          );
        }

        const skills = [];
        if (item?.skill_document?.field_skill?.learner_url)
          item?.skill_document?.field_skill?.learner_url?.forEach(docs => {
            if (docs?.file_url) skills.push(docs?.file_url);
          });

        if (item?.skill_document?.ojt_skill)
          item?.skill_document?.ojt_skill?.forEach(docs => {
            if (docs?.file_url) skills.push(docs?.file_url);
          });

        if (!accordionData[item?.course_id]) {
          accordionData[item?.course_id] = {
            courseName: name,
            percentageCompleted:
              Math.floor(
                (percentage[item?.course_id].completed /
                  percentage[item?.course_id].total) *
                  100
              ) || 0,
            SkillDetails: [],
          };
        }

        const extraData = {
          work_order_number: item?.work_order_number,
          start_date: item?.start_date,
          machine_serial_number: item?.machine_serial_number,
          location: item?.location,
          uploaded_learner_document: item?.uploaded_learner_document,
        };

        accordionData[item?.course_id].courseName = name;
        accordionData[item?.course_id].percentageCompleted =
          Math.floor(
            (percentage[item?.course_id].completed /
              percentage[item?.course_id].total) *
              100
          ) || 0;
        accordionData[item?.course_id].SkillDetails.push({
          id: item?.id,
          lo: outcomes,
          type: assessmentTypeOptionsMap[item?.type],
          learnerDocs: skills,
          courseScheduleId: item?.course_schedul_id,
          extraData: extraData,
          trainerDocs: item?.uploaded_learner_document,
          status: item?.status,
        });
      });
    }

    Object.keys(accordionData)?.forEach(item => {
      const modifyObj = {
        id: item?.toString(),
        icon: true,
        accordionHeading: (
          <div
            className={`${isMobile ? "custom-accordion-header-responsive" : "custom-accordion-header"}`}
          >
            <H4>{accordionData[item]?.courseName}</H4>
            <div
              className={`${isMobile ? "custom-skillUpdate-progressWrapper-responsive" : "custom-skillUpdate-progressWrapper"}`}
            >
              <Progressbar
                barClassName="custom-skillUpdate-Bar"
                value={Math.floor(
                  (percentage[item]?.completed / percentage[item]?.total) * 100
                )}
                className="custom-skillUpdate-ProgressBar"
              />
              <span>
                {Math.floor(
                  (percentage[item]?.completed / percentage[item]?.total) * 100
                )}{" "}
                %
              </span>
            </div>
          </div>
        ),
        spanClass: "custom-skillUpdate-heading",
        bodyText: (
          <ILPPendingSkillTable
            data={accordionData[item]?.SkillDetails}
            TableHeaders={PendingSkillTableHeader}
            onDropdownClick={onDropdownClick}
            onUpdateStatus={onUpdateStatus}
            onToggleModal={handleSkillCompletionClick}
          />
        ),
      };
      processedData.push(modifyObj);
    });

    setUpdateData(processedData);
  };

  const onDropdownClick = id => {
    setSelectedId(id);
    setDocument([]);
    setSelectedType("");
    setFormValue(null);
    setErrors(null);
    setIsModalOpen(true);
  };

  const onUpdateStatus = (id, status) => {
    setSelectedId(id);
    setSelectedType(status);
    setDocument([]);
    setFormValue(null);
    setErrors(null);
    setIsModalOpen(true);
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setDocument([]);
      setSelectedId(null);
      setSelectedType("");
      setFormValue(null);
      setErrors(null);
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  };

  const uploadFile = async documents => {
    const postData = new FormData();
    documents?.forEach(file => {
      postData.append("files", file);
    });
    const response = await dispatch(uploadFileApi({ content: postData }));
    return response?.payload?.data?.file_urls;
  };

  const handlePrimaryClick = async () => {
    if (selectedType === "approved") {
      const payload = {
        action: "approved",
        skill_document: [
          {
            id: selectedId,
          },
        ],
      };
      await dispatch(approveSkillDocs({ docs: payload }));
    } else if (selectedType === "declined") {
      const payload = {
        action: "rejected",
        skill_document: [
          {
            id: selectedId,
          },
        ],
      };
      await dispatch(approveSkillDocs({ docs: payload }));
    } else {
      if (validateForm()) {
        return;
      }
      if (document?.length === 0) {
        showToast(PleaseUploadFile, "error");
        return;
      }

      await uploadFile(document).then(async uploaded => {
        const payload = {
          action: "upload_document",
          skill_document: [
            {
              id: selectedId,
              uploaded_learner_document: uploaded,
              location: formValue?.location,
              machine_serial_number: formValue?.machineSerialNumber,
              start_date: formValue?.startDate,
              work_order_number: formValue?.workOrderNumber,
            },
          ],
        };
        await dispatch(uploadSkillDocs({ docs: payload }));
      });
    }
    await getData();
    setDocument([]);
    setSelectedId(null);
    setSelectedType("");
    setFormValue(null);
    setErrors(null);
    setIsModalOpen(false);
  };

  const getModalTitle = () => {
    if (selectedType === "approved") return ApproveFile;
    if (selectedType === "declined") return DeclineFile;
    return UploadFile;
  };

  const getPrimaryButtonText = () => {
    if (selectedType === "approved" || selectedType === "declined") {
      return Confirm;
    }
    return Upload;
  };

  const handleChange = e => {
    setFormValue(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const validateForm = () => {
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;

    const validationRules = [
      {
        field: "workOrderNumber",
        requiredMessage: WorkOrderNumberRequired,
        regex: alphanumericRegex,
        regexMessage: WorkOrderNumberAlphanumeric,
      },
      {
        field: "startDate",
        requiredMessage: StartDateRequired,
      },
      {
        field: "location",
        requiredMessage: LocationRequired,
        regex: alphanumericRegex,
        regexMessage: LocationAlphanumeric,
      },
      {
        field: "machineSerialNumber",
        requiredMessage: MachineSerialNumberRequired,
        regex: alphanumericRegex,
        regexMessage: MachineSerialNumberAlphanumeric,
      },
    ];

    const newErrors = {};
    let isError = false;

    validationRules.forEach(
      ({ field, requiredMessage, regex, regexMessage }) => {
        const value = formValue?.[field];

        if (!value) {
          newErrors[field] = requiredMessage;
          isError = true;
        } else if (regex && !regex.test(value)) {
          newErrors[field] = regexMessage;
          isError = true;
        }
      }
    );

    setErrors(newErrors);
    return isError;
  };

  const renderModalBody = () => {
    if (selectedType === "approved") {
      return <P>{ApproveSkillFile}</P>;
    }
    if (selectedType === "declined") {
      return <P>{RejectSkillFile}</P>;
    }
    return (
      <>
        <Form className="g-3 custom-input" noValidate>
          <Row>
            <Col sm="12" lg="6">
              <FormGroup>
                <LabelTooltip
                  tooltipText={`${Enter} ${WorkOrderNumber}`}
                  label={WorkOrderNumber}
                  important
                />
                <Input
                  onChange={handleChange}
                  value={formValue?.workOrderNumber}
                  name="workOrderNumber"
                  type="text"
                  placeholder={`${Enter} ${WorkOrderNumber}`}
                  maxLength={100}
                  invalid={!!errors?.workOrderNumber}
                />
                {errors?.workOrderNumber && (
                  <div className="invalid-feedback">
                    {errors?.workOrderNumber}
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col sm="12" lg="6">
              <FormGroup>
                <LabelTooltip
                  label={StartDate}
                  tooltipText={`${Enter} ${StartDate}`}
                  important={true}
                />
                <div className={"w-100"}>
                  <DatePicker
                    onChange={(date: Date | null) =>
                      handleChange({
                        target: {
                          name: "startDate",
                          value: date,
                        },
                      })
                    }
                    selected={formValue?.startDate}
                    minDate={new Date()}
                    placeholderText={`${Select} ${StartDate}`}
                    className={`form-control ${errors?.startDate ? "is-invalid" : ""}`}
                    dateFormat="dd-MM-yyyy"
                    disabledKeyboardNavigation={true}
                    onKeyDown={e => e.preventDefault()}
                  />

                  {errors?.startDate && (
                    <div className="invalid-date-picker-feedback">
                      {errors?.startDate}
                    </div>
                  )}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12" lg="6">
              <FormGroup>
                <LabelTooltip
                  tooltipText={`${Enter} ${SideBarTitleLocation}`}
                  label={SideBarTitleLocation}
                  important
                />
                <Input
                  onChange={handleChange}
                  value={formValue?.location}
                  name="location"
                  type="text"
                  placeholder={`${Enter} ${SideBarTitleLocation}`}
                  maxLength={100}
                  invalid={!!errors?.location}
                />
                {errors?.location && (
                  <div className="invalid-feedback">{errors?.location}</div>
                )}
              </FormGroup>
            </Col>
            <Col sm="12" lg="6">
              <FormGroup>
                <LabelTooltip
                  tooltipText={`${Enter} ${MachineSerialNumber}`}
                  label={MachineSerialNumber}
                  important
                />
                <Input
                  onChange={handleChange}
                  value={formValue?.machineSerialNumber}
                  name="machineSerialNumber"
                  type="text"
                  placeholder={`${Enter} ${MachineSerialNumber}`}
                  maxLength={100}
                  invalid={!!errors?.machineSerialNumber}
                />
                {errors?.machineSerialNumber && (
                  <div className="invalid-feedback">
                    {errors?.machineSerialNumber}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <MultiFileUpload
          accept={".pdf,.docx,.txt"}
          note={PendingSkillFileSizeNote}
          onFileChange={(file, status) => {
            if (status === "removed") {
              setDocument(prevDocuments =>
                prevDocuments.filter(item => item.name !== file.name)
              );
            } else if (status === "done" && file) {
              setDocument(prevDocuments => [...prevDocuments, file]);
            }
          }}
        />
      </>
    );
  };

  const renderAcordion = () => {
    if (loading) {
      return null;
    }

    if (updateData.length === 0) {
      return (
        <NoData svg="empty-folder-icon" title={NoDataText} showCard={false} />
      );
    }
    return <SimpleAccordion accordionList={updateData} />;
  };

  return (
    <div className="pb-5">
      <CommonModal
        sizeTitle={getModalTitle()}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handlePrimaryClick}
        primaryBtnText={getPrimaryButtonText()}
      >
        {renderModalBody()}
      </CommonModal>

      <CommonModal
        sizeTitle={SkillAssessment}
        modalBodyClassName=""
        isOpen={isSkillModalOpen}
        toggle={toggleSkillModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={async () => {
          toggleSkillModal();
        }}
        primaryBtnText={Close}
      >
        <div>
          {outcomesData && (
            <Card className="mt-2 p-2 border border-grey">
              <CardBody>
                <P className="fw-bold">{outcomesData?.lo}</P>
                <div className="mt-4">
                  <Row>
                    <Col>
                      {
                        <DetailHeader
                          assign={Location}
                          name={outcomesData?.data?.location}
                        />
                      }
                    </Col>
                    <Col>
                      {
                        <DetailHeader
                          assign={MachineSerialNumber}
                          name={outcomesData?.data?.machine_serial_number}
                        />
                      }
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      {
                        <DetailHeader
                          assign={StartDate}
                          name={
                            outcomesData?.data?.start_date
                              ? formatCustomDate(outcomesData?.data?.start_date)
                              : "-"
                          }
                        />
                      }
                    </Col>
                    <Col>
                      {
                        <DetailHeader
                          assign={WorkOrderNumber}
                          name={outcomesData?.data?.work_order_number}
                        />
                      }
                    </Col>
                  </Row>
                </div>

                <Row className="mt-4">
                  {outcomesData?.url?.map((file, ind) => (
                    <Col lg="4" className="mb-3 w-50" key={`file-${ind}`}>
                      <Badges className="w-100" color="light text-dark">
                        <div className="d-flex align-items-center">
                          <MUIIcons
                            size={24}
                            iconName="InsertDriveFileOutlined"
                            className="mb-1"
                          />
                          <span className="text-start m-1 w-100 gap-1 p-1 ">
                            {getEllipsedFileName(extractFileName(file), 45)}
                          </span>
                          <MUIIcons
                            className="pointer mb-1"
                            onClick={() => handleAllFilesDownload([file])}
                            size={24}
                            iconName="FileDownloadOutlined"
                          />
                        </div>
                      </Badges>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          )}
        </div>
      </CommonModal>
      {renderAcordion()}
    </div>
  );
};

PendingSkillUpdate.propTypes = {
  userId: PropTypes.number,
};

export default PendingSkillUpdate;
