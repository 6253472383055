import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import {
  DefaultButton,
  H2,
  H4,
  MUIIcons,
  SecondaryButton,
} from "../../../../../../AbstractElements";
import { ALL_USERS_STATUS, STATUS_200 } from "../../../../../../Api/constants";
import SimpleAccordion from "../../../../../../CommonElements/Accordion";
import BadgeWithIcon from "../../../../../../CommonElements/BadgeWithIcon";
import Divider from "../../../../../../CommonElements/Divider";
import FixedFooter from "../../../../../../CommonElements/FixedFooter";
import SliderModal from "../../../../../../CommonElements/SliderModal";
import Comments from "../../../../../../container/Comments";
import LessonPlanContainer from "../../../../../../container/LessonPlanContainer";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../ReduxToolkit/Hooks";
import {
  addCommentToStages,
  getCourseContent,
  updateCommentStatus,
} from "../../../../../../ReduxToolkit/Reducers/CourseSlice";
import { updateLessonPlan } from "../../../../../../ReduxToolkit/Reducers/CourseStagesSlice";
import { getUsersList } from "../../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  Comment,
  EnterComments,
  LessonPlans,
  Min,
  PleaseAddDayPlans,
  Save,
  SaveAsDraft,
} from "../../../../../../utils/Constant";
import {
  convertMinutesToHours,
  showToast,
  sortCommentsByDate,
} from "../../../../../../utils/helper/helper";

const CreateLessonPlan = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [totalTime, setTotalTime] = useState(0);
  const [isComentRead, setIsComentRead] = useState(null);
  const { courseId } = location.state || "";
  const { courseContent } = useAppSelector(state => state.course);
  const [sliderModal, setSliderModal] = useState(false);
  const [lessonPlans, setLessonPlans] = useState([]);
  const [iconComments, setIconComments] = useState(null);
  const { usersList } = useAppSelector(state => state.user);
  const [commentList, setCommentList] = useState([]);
  const [userMap, setUserMap] = useState(null);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    if (courseId) {
      dispatch(getCourseContent({ id: courseId }));
    }
  }, [dispatch, courseId]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    let comment = [];
    if (courseContent?.comment?.lesson_plan) {
      comment = sortCommentsByDate(courseContent.comment.lesson_plan);
    }
    if (courseContent) {
      let time = 0;
      courseContent?.lesson_plans?.forEach(item => {
        time = time + item.estimated_time;
      });
      setTotalTime(time);
    }
    setCommentList(comment);
    if (courseContent?.comment?.comment_status) {
      try {
        const infoStage = courseContent?.comment?.comment_status?.find(
          (stage: { stages: string }) => stage.stages === "lesson_plan"
        );
        setIsComentRead(infoStage ? !infoStage.is_read : false);
      } catch (error) {
        setIsComentRead(false);
      }
    }
  }, [courseContent]);

  useEffect(() => {
    if (courseContent) {
      const days = Math.ceil(
        parseFloat(courseContent?.competency_statement?.estimated_time)
      );

      const lessonPlansData = Array.from({ length: days }, (_, index) => {
        const dayPlans = courseContent.lesson_plans.filter(
          plan => plan.day_number === index + 1
        );

        const dayTotalTime = dayPlans.reduce(
          (total, plan) => total + plan.estimated_time,
          0
        );
        return {
          id: `${index + 1}`,
          accordionItemClass: "item-class",
          accordionHeaderClass: "header-class",
          courseTime: dayTotalTime + Min,
          spanClass: "span-class",
          accordionHeading: `Day ${index + 1}`,
          icon: true,

          bodyText: (
            <LessonPlanContainer
              showCreate={true}
              id={index + 1}
              courseId={courseId}
              dayTotalTime={dayTotalTime}
            />
          ),
        };
      });

      setLessonPlans(lessonPlansData);
    }
  }, [courseContent, courseId]);

  const sliderToggle = async () => {
    setSliderModal(!sliderModal);
    await dispatch(
      updateCommentStatus({
        correspondingStatus: {
          component_name: "lesson_plan",
          course_id: courseId,
        },
      })
    );
    setIsComentRead(false);
  };

  const onCommentsChange = e => {
    if (
      (e.target.value && e.target.value?.length <= 255) ||
      e.target.value === ""
    )
      setIconComments(e.target.value);
  };

  const onSendComments = async () => {
    if (iconComments === "") {
      showToast(EnterComments, "error");
      return;
    }

    const payload = {
      course_id: courseId,
      is_comment: true,
      comment_data: {
        entity_type: "lesson_plan",
        comment: iconComments,
      },
    };
    await dispatch(addCommentToStages({ comment: payload }));
    setIconComments("");
    await dispatch(getCourseContent({ id: courseId }));
  };

  const updateLessonPlanStatus = async type => {
    const plans = [];
    const uniqueDayNumbers = new Set();

    courseContent?.lesson_plans?.forEach(item => {
      if (item?.day_number !== undefined) {
        uniqueDayNumbers.add(item.day_number);
      }

      plans.push({
        day_number: item?.day_number,
        topic: item?.topic,
        learning_activities: item?.learning_activities,
        delivery: item?.delivery,
        estimated_time: item?.estimated_time,
        resource: courseContent?.lesson_plan_resources[item?.id]
          ? Object.values(courseContent.lesson_plan_resources[item.id]).map(
              (resource: any) => resource?.id
            )
          : null,
      });
    });

    if (type === "completed" && uniqueDayNumbers.size !== lessonPlans?.length) {
      showToast(PleaseAddDayPlans, "error");
      return;
    }

    const planUpdated = {
      course_id: courseId,
      status: type,
      lesson_plans: plans,
    };

    const res = await dispatch(updateLessonPlan({ lessonPlan: planUpdated }));
    if (res?.payload?.status_code === STATUS_200 && res?.payload?.message) {
      showToast(res?.payload?.message, "success");
    }
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details?courseId=${courseId}`
    );
  };

  return (
    <div className="page-body pb-5">
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{Comment}</H2>
        <Divider />
        <Comments
          userMap={userMap}
          text={iconComments}
          comments={commentList}
          send
          onSendComments={onSendComments}
          onCommentsChange={onCommentsChange}
        />
      </SliderModal>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-3">
              <div></div>
              <div className="m-2 d-flex gap-2 justify-content-center">
                <H4 className="mt-1">{LessonPlans}</H4>{" "}
                <BadgeWithIcon
                  text={convertMinutesToHours(totalTime) + " h"}
                  iconSize={24}
                  iconName="QueryBuilderOutlined"
                />
              </div>
            </div>
            <div className="d-flex gap-4">
              <div className="comment-icon-container">
                <MUIIcons
                  size={24}
                  onClick={sliderToggle}
                  className="primary-icon-color mt-2 pointer"
                  iconName="ChatBubbleOutlineOutlined"
                />
                {isComentRead != null && isComentRead && (
                  <span className="red-dot"></span>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <div className="mt-4 pb-5">
        <SimpleAccordion accordionList={lessonPlans} showTimer />
      </div>
      <FixedFooter>
        <SecondaryButton onClick={() => updateLessonPlanStatus("draft")}>
          {SaveAsDraft}
        </SecondaryButton>
        <DefaultButton
          onClick={() => updateLessonPlanStatus("completed")}
          color="primary"
        >
          {Save}
        </DefaultButton>
      </FixedFooter>
    </div>
  );
};

export default CreateLessonPlan;
