import { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";

import { Btn, H3, H5, MUIIcons, SecondaryButton } from "../../AbstractElements";
import Divider from "../../CommonElements/Divider";
import LabelTooltip from "../../CommonElements/LabelTooltip";
import CommonModal from "../../CommonElements/Modal";
import MultiSelectDropdown from "../../CommonElements/MultiSelectDropdown";
import SearchBar from "../../CommonElements/SearchBar";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getCurriculumList } from "../../ReduxToolkit/Reducers/CurriculumSlice";
import {
  fetchLoadFactorData,
  refreshLoadFactorData,
  updateLoadFactorData,
} from "../../ReduxToolkit/Reducers/LoadFactorSlice";
import {
  getIndustryJobRolesList,
  getIndustryList,
  getProficiencyList,
} from "../../ReduxToolkit/Reducers/MasterSlice";
import {
  Confirm,
  Curriculum,
  FinancialYearToolTipMessage,
  Freeze,
  FreezeBody,
  FreezeHeader,
  Industry,
  IndustryToolTipMessage,
  JobRole,
  LastRefreshedOn,
  LoadFactorCurriculumToolTipMessage,
  LoadFactorJobRoleToolTipMessage,
  LoadFactorTitle,
  MasterDataProficiencyToolTipMessage,
  ModifyPlan,
  ProficiencyLevel,
  Select,
  Year,
} from "../../utils/Constant";
import {
  formatDateTime,
  generateLoadFactorFiscalYears,
  loadFactorColumns,
} from "../../utils/helper/helper";
import "./style.scss";

const LoadFactor = () => {
  const fiscalYears = useMemo(() => generateLoadFactorFiscalYears(0, 10), []);
  const [dropVals, setDropVals] = useState({
    industry: [],
    job_role: [],
    curriculum: [],
    proficiency: [],
    year: "0",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [tableData, setTableData] = useState([]);
  const { industryList, roleOnFilter, proficiencyList } = useAppSelector(
    state => state.master
  );
  const { curriculumList } = useAppSelector(state => state.curriculum);
  const { loadFactorList } = useAppSelector(state => state.loadFactor);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [showModifyPlan, setShowModifyPlan] = useState(false);
  const toggleDropdown = rowId => {
    setDropdownOpen(prev => (prev === rowId ? null : rowId));
  };

  useEffect(() => {
    dispatch(getIndustryList());
    dispatch(getProficiencyList());
  }, []);

  useEffect(() => {
    const isAnyFrozen = loadFactorList?.load_factor?.some(
      item => item.is_frozen
    );
    setShowModifyPlan(isAnyFrozen);
    setTableData(loadFactorList?.load_factor);
  }, [loadFactorList]);

  const fetchLoadFactor = async () => {
    const filters: any = {
      industry_id: dropVals.industry.map(item => item.name),
      job_role_id: dropVals.job_role.map(item => item.name),
      curriculum_id: dropVals.curriculum.map(item => item.name),
      proficiency: dropVals.proficiency.map(item => item.name),
      year:
        dropVals.year.length > 0 ? fiscalYears[dropVals.year] : fiscalYears[0],
    };

    dispatch(fetchLoadFactorData(filters));
  };

  useEffect(() => {
    fetchLoadFactor();
  }, [dropVals]);

  const dropdownOptions = useMemo(() => {
    const mapOptions = (data, labelKey, valueKey) =>
      data?.map(item => ({
        name: item[valueKey]?.toString(),
        value: item[labelKey],
        label: item[labelKey],
      })) || [];

    return {
      industryOptions: mapOptions(industryList?.industry, "name", "id"),
      jobRoleOptions: mapOptions(roleOnFilter?.job_role, "name", "id"),
      curriculumOptions: mapOptions(curriculumList?.curriculum, "name", "id"),
      proficiencyOptions: mapOptions(
        proficiencyList?.proficiency,
        "level_name",
        "id"
      ),
      fiscalYearOptions: fiscalYears.map((year, index) => {
        return {
          name: index.toString(),
          value: year,
          label: year,
        };
      }),
    };
  }, [
    industryList,
    roleOnFilter,
    curriculumList,
    proficiencyList,
    fiscalYears,
  ]);

  const handleMultiSelectChange = (field, selectedIds) => {
    let updatedValues = [];
    let dataList;
    switch (field) {
      case "industry":
        dataList = industryList;
        break;
      case "job_role":
        dataList = roleOnFilter;
        break;
      case "curriculum":
        dataList = curriculumList;
        break;
      case "proficiency":
        dataList = proficiencyList;
        break;
      case "year":
        dataList = fiscalYears;
        setDropVals(prevState => ({
          ...prevState,
          [field]: selectedIds,
        }));
        return;
      default:
        return;
    }

    updatedValues = selectedIds
      ?.map(id => {
        const foundItem = dataList[field]?.find(
          item => item?.id?.toString() === id
        );
        return foundItem
          ? {
              name: foundItem.id?.toString(),
              value:
                field === "proficiency" ? foundItem.level_name : foundItem.name,
              label:
                field === "proficiency" ? foundItem.level_name : foundItem.name,
            }
          : null;
      })
      .filter(Boolean);

    setDropVals(prevState => ({
      ...prevState,
      [field]: updatedValues,
    }));
  };

  useEffect(() => {
    if (dropVals.industry.length > 0) {
      const industryIds = dropVals.industry.map(item => item.name);
      dispatch(getIndustryJobRolesList({ ind_id: industryIds?.toString() }));
    }
  }, [dropVals.industry, dispatch]);

  useEffect(() => {
    if (dropVals.job_role.length > 0) {
      const jobRoleIds = dropVals.job_role.map(item => item.name);
      dispatch(
        getCurriculumList({
          status: ["published"],
          roles: jobRoleIds?.toString(),
        })
      );
    }
  }, [dropVals.job_role, dispatch]);

  const handleSearch = query => {
    const filteredData = loadFactorList?.load_factor?.filter(row =>
      Object.values(row).some(
        value =>
          value.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1
      )
    );
    setTableData(filteredData);
  };

  const handleFreezeClick = async year => {
    const yearToFreeze = { year: year };
    await dispatch(updateLoadFactorData(yearToFreeze));
    toggleModal();
    await fetchLoadFactor();
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="page-body">
      <CommonModal
        sizeTitle={FreezeHeader(fiscalYears[dropVals?.year])}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={() => {
          handleFreezeClick(fiscalYears[dropVals?.year]);
        }}
        primaryBtnText={Confirm}
      >
        {FreezeBody(fiscalYears[dropVals?.year])}
      </CommonModal>
      <Card className="p-2 mt-2">
        <CardBody>
          <div className="d-flex justify-content-between">
            <H3 className="mt-3">{LoadFactorTitle}</H3>
            <div className="d-flex gap-2 align-items-center">
              <MUIIcons
                className="text-gray mb-1"
                size={18}
                iconName="CalendarTodayOutlined"
              />
              <div className="d-flex gap-2">
                <H5 className="text-muted mt-3">
                  {LastRefreshedOn} :{" "}
                  {loadFactorList?.last_refresh_time
                    ? formatDateTime(loadFactorList?.last_refresh_time)
                    : "-"}
                </H5>
                <button
                  type="button"
                  className="d-flex justify-content-center align-items-center bg-light custom-circle mt-1 pointer refresh-no-border"
                  onClick={() => {
                    dispatch(refreshLoadFactorData());
                    fetchLoadFactor();
                  }}
                  aria-label="Refresh Load Factor"
                >
                  <MUIIcons
                    className="text-dark"
                    size={24}
                    iconName="CachedOutlined"
                  />
                </button>

                <SearchBar onSearch={handleSearch} />
              </div>
              <SecondaryButton onClick={toggleModal}>{Freeze}</SecondaryButton>
              {showModifyPlan && (
                <Link
                  to={"/modify-plan"}
                  state={{ id: 1, year: dropVals?.year }}
                  className="text-white"
                >
                  <Btn color="primary">{ModifyPlan}</Btn>
                </Link>
              )}
            </div>
          </div>
          <Divider />
          <Row>
            <Col>
              <FormGroup>
                <LabelTooltip
                  label={Industry}
                  tooltipText={IndustryToolTipMessage}
                  important={false}
                />
                <MultiSelectDropdown
                  onChange={values =>
                    handleMultiSelectChange("industry", values)
                  }
                  options={dropdownOptions.industryOptions}
                  placeholder={`${Select} ${Industry}`}
                  defaultSelected={dropVals.industry}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <LabelTooltip
                  label={JobRole}
                  tooltipText={LoadFactorJobRoleToolTipMessage}
                  important={false}
                />
                <MultiSelectDropdown
                  onChange={values =>
                    handleMultiSelectChange("job_role", values)
                  }
                  options={dropdownOptions.jobRoleOptions}
                  placeholder={`${Select} ${JobRole}`}
                  defaultSelected={dropVals.job_role}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <LabelTooltip
                  label={Curriculum}
                  tooltipText={LoadFactorCurriculumToolTipMessage}
                  important={false}
                />
                <MultiSelectDropdown
                  onChange={values =>
                    handleMultiSelectChange("curriculum", values)
                  }
                  options={dropdownOptions.curriculumOptions}
                  placeholder={`${Select} ${Curriculum}`}
                  defaultSelected={dropVals.curriculum}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <LabelTooltip
                  label={ProficiencyLevel}
                  tooltipText={MasterDataProficiencyToolTipMessage}
                  important={false}
                />
                <MultiSelectDropdown
                  onChange={values =>
                    handleMultiSelectChange("proficiency", values)
                  }
                  options={dropdownOptions.proficiencyOptions}
                  placeholder={`${Select} ${ProficiencyLevel}`}
                  defaultSelected={dropVals.proficiency}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <LabelTooltip
                  label={Year}
                  tooltipText={FinancialYearToolTipMessage}
                  important={false}
                />
                <Input
                  type="select"
                  value={dropVals.year}
                  onChange={e => {
                    handleMultiSelectChange("year", e.target.value);
                  }}
                  name="assessment_type"
                >
                  {dropdownOptions?.fiscalYearOptions?.map(option => (
                    <option key={option.name} value={option.name}>
                      {option.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <div className="mt-2">
            <DataTable
              className="load-factor-table"
              columns={loadFactorColumns(
                dropdownOpen,
                toggleDropdown,
                navigate
              )}
              data={tableData}
              pagination
              fixedHeader
              striped
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default LoadFactor;
