import { Divider } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";

import "./style.scss";
import { Btn, H4 } from "../../../../AbstractElements";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import {
  Continue,
  InstructionHeadingForMentorAndMentee,
} from "../../../../utils/Constant";
import {
  InstructionDataForMentees,
  InstructionDataForMentors,
  sanitizedContent,
} from "../../../../utils/helper/helper";
import useIsMobile from "../../../../utils/helper/responsive";

const InstructionPage: React.FC = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { sessionId, instructionFor } = state || {};
  let sections = [];
  let clickInfo = "";

  if (instructionFor === "mentor") {
    sections = InstructionDataForMentors.sections;
    clickInfo = InstructionDataForMentors.clickInfo;
  } else {
    sections = InstructionDataForMentees.sections;
    clickInfo = InstructionDataForMentees.clickInfo;
  }

  const handleButtonClick = () => {
    if (instructionFor === "mentor") {
      return navigate(
        `${process.env.PUBLIC_URL}/mentoring/i-am-mentor/add-session`
      );
    } else {
      navigate(
        `${process.env.PUBLIC_URL}/mentoring/mentoring-details/session-feedback`,
        { state: { sessionId } }
      );
    }
  };

  return (
    <div className="page-body page-body-margin custom-instruction-page">
      <Card>
        <CardBody>
          <H4 className="p-2 mb-2">{InstructionHeadingForMentorAndMentee}</H4>
          <Divider />
          {sections.map(section => (
            <div key={section.id} className="mt-3 p-1">
              <h5 className="fw-bold">{section.heading}</h5>
              <div
                className="sanitized-content"
                dangerouslySetInnerHTML={{
                  __html: sanitizedContent(section.content),
                }}
              />
            </div>
          ))}
          <div
            className="sanitized-content"
            dangerouslySetInnerHTML={{
              __html: sanitizedContent(clickInfo),
            }}
          />
        </CardBody>
        <FixedFooter>
          <Row>
            <Col xs="auto" className={isMobile ? "mb-5" : ""}>
              <Btn onClick={handleButtonClick} color="primary">
                {Continue}
              </Btn>
            </Col>
          </Row>
        </FixedFooter>
      </Card>
    </div>
  );
};

export default InstructionPage;
