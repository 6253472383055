import React, { useEffect } from "react";

import useIsMobile from "../../utils/helper/responsive";
import CommonModal from "../Modal";
import "./style.scss";
import MUIIcons from "../MUIIcon/MUIIcons";

interface SliderModalProps {
  isOpen: boolean;
  toggle: () => void;
  children: React.ReactNode;
  customClass?: string;
}

const SliderModal: React.FC<SliderModalProps> = ({
  isOpen,
  toggle,
  children,
  customClass,
}) => {
  const isMobileView = useIsMobile();

  useEffect(() => {
    const appContainer = document.getElementById("root");
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;

    if (isOpen && appContainer) {
      appContainer.classList.add("scroll-lock");
      appContainer.style.paddingRight = `${scrollbarWidth}px`;
    } else if (appContainer) {
      appContainer.classList.remove("scroll-lock");
      appContainer.style.paddingRight = "";
    }

    return () => {
      if (appContainer) {
        appContainer.classList.remove("scroll-lock");
        appContainer.style.paddingRight = "";
      }
    };
  }, [isOpen]);

  if (isMobileView) {
    return (
      <CommonModal
        modalBodyClassName=""
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        size="lg"
      >
        <MUIIcons
          size={24}
          iconName="CloseOutlined"
          className="close-button"
          onClick={toggle}
          aria-label="Close"
        />
        {children}
      </CommonModal>
    );
  } else {
    return (
      <>
        <div
          className={`overlay ${isOpen ? "open" : ""}`}
          onClick={toggle}
        ></div>
        <div className={`slider-modal ${isOpen ? "open" : ""} ${customClass}`}>
          <div className="slider-modal-content">
            <MUIIcons
              size={24}
              iconName="CloseOutlined"
              className="close-button"
              onClick={toggle}
              aria-label="Close"
            />
            {children}
          </div>
        </div>
      </>
    );
  }
};

export default SliderModal;
