import "./index.scss";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import Store from "./ReduxToolkit/Store";

import "./utils/helper/APIWrapper.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={Store}>
    <App />
  </Provider>
);
