import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { CardHeaderDropDownProps } from "../../Types/CommonElements/CommonElementsTypes";
import MUIIcons from "../MUIIcon/MUIIcons";

const CardHeaderDropDown = ({
  firstItem,
  secondItem,
  thirdItem,
  mainTitle,
  menuTitle,
  onClickItem,
}: CardHeaderDropDownProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const handleItemClick = (key: string) => {
    if (onClickItem) {
      onClickItem(key);
    }
  };

  return (
    <Dropdown className="icon-dropdown" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret color="">
        {mainTitle ? (
          <MUIIcons
            iconName="MoreVertOutlined"
            className="primary-icon-color"
            size={24}
          />
        ) : (
          menuTitle
        )}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        {firstItem && (
          <DropdownItem
            onClick={() => {
              handleItemClick("item1");
            }}
          >
            {firstItem}
          </DropdownItem>
        )}
        {secondItem && (
          <DropdownItem onClick={() => handleItemClick("item2")}>
            {secondItem}
          </DropdownItem>
        )}
        {thirdItem && (
          <DropdownItem onClick={() => handleItemClick("item3")}>
            {thirdItem}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default CardHeaderDropDown;
