import CryptoJS from "crypto-js";

const ENCRYPTION_KEY = process.env.REACT_APP_SECRET_KEY;

// Encrypt data
export const encrypt = (data: any): string => {
  const serializedData = JSON.stringify(data);
  return CryptoJS.AES.encrypt(serializedData, ENCRYPTION_KEY).toString();
};

// Decrypt data
export const decrypt = (encryptedData: string): any => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

// Save data to local storage with encryption
export const saveToLocalStorage = (key: string, value: any) => {
  try {
    const encryptedValue = encrypt(value);
    localStorage.setItem(key, encryptedValue);
  } catch (error) {
    console.error("Failed to save data to localStorage:", error);
  }
};

// Get data from local storage with decryption
export const getFromLocalStorage = (key: string): any => {
  try {
    const encryptedValue = localStorage.getItem(key);
    if (encryptedValue) {
      return decrypt(encryptedValue);
    }
  } catch (error) {
    console.error("Failed to retrieve data from localStorage:", error);
  }
  return null;
};

// Remove a specific item from local storage
export const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

// Clear all local storage
export const removeAllLocalStorage = () => {
  localStorage.clear();
};

// Constants for storage keys
export const LOGGED_IN_USER = "LOGGED_IN_USER";
export const SAVE_LOCATION_STATE = "LOCATION_STATE";
export const LEARNER_COMPETENCY_ASSESSMENT = "LEARNER_COMPETENCY_ASSESSMENT";
export const OPENED_SCORM_DETAILS = "OPENED_SCORM_DETAILS";
export const COURSE_ASSESSMENT = "COURSE_ASSESSMENT";
