import PropTypes from "prop-types";
import React, { useState } from "react";
import { Card, CardBody, Col, Row, Tooltip } from "reactstrap";

import { Btn, MUIIcons, H4, H5 } from "../../AbstractElements";
import DetailHeaderContainer from "../../CommonElements/DetailHeaderContainer";
import Divider from "../../CommonElements/Divider";
import { AddNew } from "../../utils/Constant";
import { getRandomInteger, sanitizeString } from "../../utils/helper/helper";
import {
  AssessmentModuleListProps,
  DetailHeaderProps,
} from "../../utils/helper/propTypes";
import useIsMobile from "../../utils/helper/responsive";

const AssessmentModuleList: React.FC<AssessmentModuleListProps> = ({
  heading,
  headerRow,
  dataRows,
  onEdit,
  onDelete,
  onAddNew,
  isEdit = false,
  isDelete = false,
  isAddNew = false,
}) => {
  const isMobile = useIsMobile();
  const [tooltipOpen, setTooltipOpen] = useState<{ [key: string]: boolean }>(
    {}
  );

  const toggleTooltip = (index: string) => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const renderColumns = (
    columns: DetailHeaderProps[],
    isHeader = false,
    isLastRow = false
  ) => {
    const columnPairs = isMobile
      ? columns.reduce<DetailHeaderProps[][]>((result, col, index) => {
          const pairIndex = Math.floor(index / 2);
          if (!result[pairIndex]) result[pairIndex] = [];
          result[pairIndex].push(col);
          return result;
        }, [])
      : [columns];

    const getTruncatedColName = (header: string) => {
      return header?.length > 20 ? `${header?.substring(0, 10)}...` : header;
    };

    return (
      <>
        {columnPairs.map((pair, pairIndex) => (
          <React.Fragment key={pairIndex}>
            <Row className="mt-2">
              {pair.map((col, colIndex) => (
                <Col
                  key={colIndex}
                  className={`d-flex ${isMobile ? "col-6" : ""}`}
                >
                  {isHeader || isMobile ? (
                    <DetailHeaderContainer
                      assign={col.assign}
                      name={col.name}
                      key={getRandomInteger(1, 100)}
                      isToolTip={colIndex === 0}
                    />
                  ) : (
                    <>
                      {colIndex === 0 ? (
                        <div>
                          <span
                            id={sanitizeString(
                              `tooltip-course-name-${pair[0]?.key}-${colIndex}`
                            )}
                          >
                            <H5 className="fw-bold">
                              {getTruncatedColName(col.name)}
                            </H5>
                          </span>
                          <Tooltip
                            placement="top"
                            isOpen={
                              tooltipOpen[`${pair[0]?.key}-${colIndex}`] ||
                              false
                            }
                            target={sanitizeString(
                              `tooltip-course-name-${pair[0]?.key}-${colIndex}`
                            )}
                            toggle={() =>
                              toggleTooltip(`${pair[0]?.key}-${colIndex}`)
                            }
                          >
                            {col.name}
                          </Tooltip>
                        </div>
                      ) : (
                        <H5 className="fw-bold">{col.name}</H5>
                      )}
                    </>
                  )}
                </Col>
              ))}
              {!isMobile && (isEdit || isDelete) && (
                <Col className="d-flex gap-3 justify-content-center" lg="1">
                  {isEdit && (
                    <MUIIcons
                      className={`primary-icon-color pointer ${isHeader ? "mt-3" : ""}`}
                      size={18}
                      iconName="EditOutlined"
                      onClick={() => {
                        onEdit(pair[0]?.key);
                      }}
                    />
                  )}
                  {isDelete && (
                    <MUIIcons
                      className={`primary-icon-color pointer ${isHeader ? "mt-3" : ""}`}
                      size={18}
                      iconName="DeleteOutlineOutlined"
                      onClick={() => {
                        onDelete(pair[0]?.key);
                      }}
                    />
                  )}
                </Col>
              )}
            </Row>
            {!isMobile && !isLastRow && <Divider />}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <Card>
      <CardBody className="m-2">
        <H4 className=" mt-2">{heading}</H4>
        <Divider />
        {renderColumns(headerRow, true)}
        {isMobile && <div className="mt-3"></div>}
        {dataRows.map((row, rowIndex) => (
          <div key={rowIndex}>
            {renderColumns(
              row.columns,
              false,
              rowIndex === dataRows.length - 1
            )}
            {isMobile && (isEdit || isDelete) && (
              <Row className="d-flex gap-4 mt-2">
                {isEdit && (
                  <MUIIcons
                    className="primary-icon-color pointer"
                    size={18}
                    iconName="EditOutlined"
                    onClick={() => {
                      onEdit(row?.columns[0]?.key);
                    }}
                  />
                )}
                {isDelete && (
                  <MUIIcons
                    className="primary-icon-color pointer"
                    size={18}
                    iconName="DeleteOutlineOutlined"
                    onClick={() => {
                      onDelete(row?.columns[0].key);
                    }}
                  />
                )}
              </Row>
            )}
          </div>
        ))}
        {isAddNew && (
          <div>
            <Divider />
            <Btn
              icon={<MUIIcons iconName="AddCircleOutlineOutlined" size={16} />}
              color="primary"
              onClick={onAddNew}
            >
              {AddNew}
            </Btn>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

AssessmentModuleList.propTypes = {
  heading: PropTypes.string.isRequired,
  headerRow: PropTypes.array.isRequired,
  dataRows: PropTypes.array.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isEdit: PropTypes.bool,
  isDelete: PropTypes.bool,
  isAddNew: PropTypes.bool,
};

export default AssessmentModuleList;
