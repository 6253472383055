import { useCallback, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";

import { Btn, H3, MUIIcons } from "../../../AbstractElements";
import { STATUS_200 } from "../../../Api/constants";
import { UpsertClassRoomPayload } from "../../../Api/entities/ManageMasterDataEntity";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import MasterDataAccordion from "../../../CommonElements/MasterDataAccordion";
import MasterDataAddNewModal from "../../../CommonElements/MasterDataAddNewModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  createClassRoom,
  deleteClassRoom,
  getClassRoomList,
  getClassRoomDetailsById,
  getClassRoomListByLocation,
  getManageMasterLocationList,
  setClassRoomListByIdToInitialValue,
  updateClassRoom,
} from "../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import {
  AddNew,
  Classroom,
  ClassroomDescriptionToolTipMessage,
  ClassroomEmptyDescription,
  ClassroomNameToolTipMessage,
  Confirm,
  DeleteClassRoomHeader,
  DeleteClassRoomMessage,
  Enter,
  Location,
  LocationNameToolTipMessage,
  NoDataText,
  NotYetAdded,
  Select,
} from "../../../utils/Constant";
import { mapListToOptions } from "../../../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../../utils/helper/localStorageutils";

const ClassRoomPage = () => {
  const dispatch = useAppDispatch();
  const loginData = getFromLocalStorage(LOGGED_IN_USER);
  const {
    classRoomList,
    classRoomDetailsById,
    classRoomListByLocationId,
    loading,
    locationList,
  } = useAppSelector(state => state.manageMasterData);
  const [modalOpen, setModalOpen] = useState(false);
  const [locationListData, setLocationListData] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [modalFormValues, setModalFormValues] = useState({
    name: "",
    description: "",
    selectedOption: "",
  });
  const [classRoomToDelete, setClassRoomToDelete] = useState(null);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [accordionData, setAccordionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [locationFilterData, setLocationFilterData] = useState([]);

  useEffect(() => {
    dispatch(getClassRoomList());
    if (!modalOpen) {
      dispatch(getManageMasterLocationList());
    }
  }, [dispatch]);

  useEffect(() => {
    let filteredClassrooms = [];

    if (selectedFilterValues["location"]?.length > 0) {
      filteredClassrooms = classRoomListByLocationId?.class_room;
    } else {
      filteredClassrooms = classRoomList?.class_room;
    }

    if (filteredClassrooms?.length > 0) {
      const newAccordionData = filteredClassrooms?.map(item => ({
        id: item.id.toString(),
        name: item.name,
        description: item.description,
        createdOn: item.created_at,
        modifiedOn: item.updated_at,
        depedentBadges: [
          {
            id: 1,
            badge: `${Location}: ${item.location}`,
          },
        ],
      }));
      setAccordionData(newAccordionData);
    } else {
      setAccordionData([]);
    }
  }, [classRoomList, classRoomListByLocationId]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = accordionData.filter(clasroom =>
        clasroom.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(accordionData);
    }
  }, [searchQuery, accordionData]);

  useEffect(() => {
    const locations = mapListToOptions(locationList?.location, "name", "id");
    setLocationListData(locations);
  }, [locationList]);

  useEffect(() => {
    const value = [];
    if (locationList) {
      locationList?.location?.forEach(item => {
        value.push({
          name: item.id.toString(),
          value: item.name,
          label: item.name,
        });
      });
    }
    setLocationFilterData(value);
  }, [locationList]);

  useEffect(() => {
    if (classRoomDetailsById) {
      setModalFormValues(prevState => ({
        ...prevState,
        name: classRoomDetailsById.name,
        description: classRoomDetailsById.description,
        selectedOption: classRoomDetailsById.location_id.toString(),
      }));
    }
  }, [classRoomDetailsById]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleDeleteConfirmModal = () =>
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);

  const handleSave = useCallback(
    async (name: string, description: string, locationId: string) => {
      const selectedLocation = locationListData.find(
        location => location.id === locationId
      );

      const payload: UpsertClassRoomPayload = {
        name,
        description,
        location_id: selectedLocation.id,
      };

      let res = null;

      if (classRoomDetailsById) {
        payload.id = classRoomDetailsById.id;
        res = await dispatch(
          updateClassRoom({ updateClassRoomPayload: payload })
        ).then(val => {
          return val;
        });
      } else {
        res = await dispatch(
          createClassRoom({ createClassRoomPayload: payload })
        ).then(val => {
          return val;
        });
      }

      if (res?.payload?.status_code === STATUS_200) {
        toggleModal();
      }
      await dispatch(getClassRoomList());
    },
    [dispatch, toggleModal, locationListData]
  );

  const handleEdit = (id: string) => {
    const selectedId = parseInt(id);
    dispatch(getClassRoomDetailsById({ id: selectedId }));
    toggleModal();
  };

  const handleDelete = (id: string) => {
    const selectedId = parseInt(id);
    setClassRoomToDelete(selectedId);
    toggleDeleteConfirmModal();
  };

  const confirmDelete = () => {
    if (classRoomToDelete) {
      dispatch(deleteClassRoom({ id: classRoomToDelete })).then(() => {
        dispatch(getClassRoomList());
        toggleDeleteConfirmModal();
      });
    }
  };

  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (
      filteredData?.length === 0 &&
      (searchQuery?.length > 0 || selectedFilterValues["location"]?.length > 0)
    ) {
      return <NoData svg={"empty-folder-icon"} title={NoDataText} />;
    }

    if (accordionData?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          description={ClassroomEmptyDescription}
          buttonText={AddNew}
          onclick={toggleModal}
        />
      );
    }

    return filteredData?.map(item => (
      <MasterDataAccordion
        key={item.id}
        accordionItems={item}
        onEditClick={() => handleEdit(item.id)}
        onDeleteClick={() => handleDelete(item.id)}
        loginData={loginData}
      />
    ));
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    const locationIds = values["location"]?.map(id => Number(id));

    await dispatch(
      getClassRoomListByLocation({
        locationId: locationIds,
      })
    );
  };

  const handleClear = async () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    dispatch(getClassRoomList());
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteClassRoomHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={confirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteClassRoomMessage}
      </CommonModal>
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3 className="fw-bold">{Classroom}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={query => setSearchQuery(query)} />
              <FilterSliderModal
                dropdowns={[
                  {
                    label: Location,
                    key: "location",
                    tooltipText: `${Select} ${Location}`,
                    options: locationFilterData,
                    isMultiSelect: true,
                  },
                ]}
                selectedFilterValues={selectedFilterValues}
                defaultFilterValues={defaultFilterValues}
                setSelectedFilterValues={setSelectedFilterValues}
                setDefaultFilterValues={setDefaultFilterValues}
                onDone={handleDone}
                onClear={handleClear}
              />
              {accordionData?.length > 0 && (
                <Btn
                  icon={
                    <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                  }
                  color="primary"
                  onClick={() => {
                    setSelectedLocationId("");
                    dispatch(setClassRoomListByIdToInitialValue());
                    setModalFormValues(null);
                    toggleModal();
                  }}
                >
                  {AddNew}
                </Btn>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      {renderContent()}
      <MasterDataAddNewModal
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSave}
        labelText={Classroom}
        placeholderText={`${Enter} ${Classroom}`}
        toolTipText={ClassroomNameToolTipMessage}
        dropdownLabel={Location}
        dropdownLabelTooltip={LocationNameToolTipMessage}
        dropdownOptions={locationListData}
        selectedDropdownValue={selectedLocationId}
        onDropdownChange={value => setSelectedLocationId(value)}
        initialValues={modalFormValues}
        toolTipDescriptionText={ClassroomDescriptionToolTipMessage}
      />
    </div>
  );
};

export default ClassRoomPage;
