import React, { useState, useEffect } from "react";
import { Badge } from "reactstrap";

import Divider from "../../CommonElements/Divider";
import SearchBar from "../../CommonElements/SearchBar";
import {
  Available,
  Booked,
  DeSelectAll,
  EligibleList,
  SelectAll,
  selected,
} from "../../utils/Constant";
import "./style.scss";
import UserActionCard from "../UserActionCard";

interface EligibleListingProps {
  eligibleData: any[];
  participantTotalCount?: ParticipantTotalCountData;
  employees?: any;
  setEmployees?: any;
  selectAll?: any;
  setSelectAll?: any;
  replace_id?: any;
  setSelectedUserId?: any;
  selectedUserId?: any;
  available?: any;
  booked?: any;
  totalEligible?: number;
}

interface ParticipantTotalCountData {
  available: number;
  booked: number;
}

const EligibleListing: React.FC<EligibleListingProps> = ({
  eligibleData,
  participantTotalCount,
  employees,
  setEmployees,
  selectAll,
  setSelectAll,
  replace_id,
  setSelectedUserId,
  selectedUserId,
  available,
  booked,
  totalEligible,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(eligibleData);

  useEffect(() => {
    setFilteredData(
      eligibleData?.filter(
        employee =>
          employee?.user_name
            ?.toLowerCase()
            ?.includes(searchTerm?.toLowerCase()) ||
          employee?.empId?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          employee?.mobile?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      )
    );
  }, [searchTerm, eligibleData]);

  useEffect(() => {
    if (eligibleData?.length === employees?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [employees, eligibleData, setSelectAll]);

  const toggleSelectAll = () => {
    if (selectAll) {
      setEmployees([]);
    } else {
      const allEmployeeIds = filteredData?.map(emp => emp?.id);
      setEmployees(allEmployeeIds);
    }
    setSelectAll(!selectAll);
  };

  const handleSelect = (id: number) => {
    setSelectAll(false);
    if (replace_id) {
      setSelectedUserId(id === selectedUserId ? null : id);
      setEmployees([id]);
    } else {
      if (employees.includes(id)) {
        const updatedEmployees = employees.filter(
          employeeId => employeeId !== id
        );
        setEmployees(updatedEmployees);
      } else {
        setEmployees([...employees, id]);
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-space-between">
        <div className="gap-1 w-100">
          <div className="d-flex flex-row gap-2">
            <h3>{EligibleList}</h3>
            <Badge className="text-black mx-2 mt-1 custom-eligible-badge">
              {employees.length + "/" + totalEligible + " " + selected}
            </Badge>
          </div>
          {participantTotalCount && !replace_id && (
            <div className="custom-participant-count d-flex gap-2">
              <span>
                {Available} {available || 0}
              </span>
              <span> | </span>
              <span>
                {Booked} {booked || 0}
              </span>
            </div>
          )}
        </div>

        <div>
          <div className="d-flex gap-4 custom-padding-select-text">
            <div>
              <SearchBar
                showSearch
                className="mb-4 custom-userList-search-bar custom-search-bar-large"
                onSearch={value => setSearchTerm(value)}
              />
            </div>

            {filteredData?.length > 0 && !replace_id && (
              <div className="mt-2">
                <div
                  color="link"
                  onClick={toggleSelectAll}
                  className="no-wrap-btn-text text-danger justify-content-end pointer"
                >
                  {selectAll ? DeSelectAll : SelectAll}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {filteredData.map((employee, index) => (
        <div key={employee.name} className="mt-3">
          <UserActionCard
            userDetails={{
              ...employee,
              selected: employees.includes(employee.id),
            }}
            info
            checkBox
            onSelect={handleSelect}
            report={employee?.is_info}
            selectedUserId={selectedUserId}
            replace_id={replace_id}
          />
          {index < filteredData.length - 1 && <Divider />}
        </div>
      ))}
    </>
  );
};

export default EligibleListing;
