import React, { useState } from "react";
import DatePicker from "react-datepicker";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Tooltip,
} from "reactstrap";

import {
  Badges,
  MUIIcons,
  H3,
  H4,
  H5,
  DefaultButton,
  P,
} from "../../AbstractElements";
import { PUBLISHED, WBT } from "../../Api/constants";
import Divider from "../../CommonElements/Divider";
import LabelTooltip from "../../CommonElements/LabelTooltip";
import CommonModal from "../../CommonElements/Modal";
import {
  Confirm,
  Dates,
  DeleteFileHeader,
  DeleteMaterialFile,
  Duration,
  Enter,
  FutureDate,
  FutureDateToolTipMessage,
  NoScormPackageAdded,
  Play,
  PublishLater,
} from "../../utils/Constant";
import {
  convertMinutesToHours,
  getEllipsedFileName,
  getTomorrowDate,
  sanitizeString,
} from "../../utils/helper/helper";
import { hasPermissionToComponent } from "../../utils/helper/permission";

export interface MaterialDataItem {
  paths?: string[];
  documents: string[];
  greeting: string;
}

interface MaterialDetailsProps {
  title: string;
  data: MaterialDataItem[];
  showDelete?: boolean;
  showPlay?: boolean;
  duration?: string;
  onDeleteFile?: (fileType: number, fileIndex: number) => void;
  onPlayFile?: (fileType: number, fileIndex: number) => void;
  setDuration?: (val: string) => void;
  courseContent?: any;
  isPublishLater?;
  setIsPublishLater?;
  futureDate?;
  setFutureDate?;
  editingData?;
}

const MaterialDetails: React.FC<MaterialDetailsProps> = ({
  title,
  data,
  showDelete = false,
  showPlay = false,
  onDeleteFile,
  onPlayFile,
  duration = "",
  setDuration = () => {
    return;
  },
  courseContent,
  isPublishLater,
  setIsPublishLater,
  futureDate,
  setFutureDate,
  editingData,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteFileInfo, setDeleteFileInfo] = useState<{
    fileType: number;
    fileIndex: number;
  } | null>(null);

  const toggleTooltip = (index: string) => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleDownload = async (urls: string[]) => {
    for (const url of urls) {
      if (url) {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const link = document.createElement("a");
          const objectURL = URL.createObjectURL(blob);
          link.href = objectURL;
          link.setAttribute("download", url.split("/").pop() || "file");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error(`Failed to download file from ${url}: `, error);
        }
      }
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (isModalOpen) {
      setDeleteFileInfo(null);
    }
  };

  const handleDeleteClick = (fileType: number, fileIndex: number) => {
    setDeleteFileInfo({ fileType, fileIndex });
    toggleModal();
  };

  const playScorm = (fileType: number, fileIndex: number) => {
    onPlayFile(fileType, fileIndex);
  };

  const handleConfirmDelete = () => {
    setDuration("");
    if (deleteFileInfo && onDeleteFile) {
      onDeleteFile(deleteFileInfo.fileType, deleteFileInfo.fileIndex);
      toggleModal();
    }
  };

  const isDocumentsAndPathsEmpty = () => {
    return data?.every(
      item =>
        item?.documents &&
        item?.paths &&
        item?.documents?.length === 0 &&
        item?.paths?.length === 0
    );
  };

  return (
    <>
      <CommonModal
        sizeTitle={DeleteFileHeader}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handleConfirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteMaterialFile}
      </CommonModal>
      <Card className="p-2">
        {title && (
          <CardHeader>
            <H4>{title}</H4>
          </CardHeader>
        )}
        <CardBody>
          {data?.map((item, index) => (
            <React.Fragment key={index}>
              <Row>
                <Col lg="3" sm="3">
                  {item.documents && <H5 className="mb-2">{item.greeting}</H5>}
                </Col>
                <Col>
                  <Row>
                    {item.documents?.map((doc, docIndex) => {
                      const uniqueId = `${index}-${docIndex}`;
                      return (
                        <Col key={uniqueId} lg="3" sm="3" className="mb-3">
                          <H3>
                            <Badges color="light text-dark">
                              <div className="d-flex align-items-center">
                                <MUIIcons
                                  size={24}
                                  iconName="InsertDriveFileOutlined"
                                />
                                <span
                                  className="m-1"
                                  id={sanitizeString(
                                    `tooltip-course-name-${uniqueId}`
                                  )}
                                >
                                  {getEllipsedFileName(doc)}
                                </span>
                                <Tooltip
                                  placement="top"
                                  isOpen={tooltipOpen[uniqueId] || false}
                                  target={sanitizeString(
                                    `tooltip-course-name-${uniqueId}`
                                  )}
                                  toggle={() => toggleTooltip(uniqueId)}
                                >
                                  {doc}
                                </Tooltip>
                                {showDelete && (
                                  <MUIIcons
                                    iconName="DeleteOutlineOutlined"
                                    className="primary-icon-color pointer"
                                    size={24}
                                    onClick={() =>
                                      handleDeleteClick(index, docIndex)
                                    }
                                  />
                                )}
                              </div>
                              {showPlay && (
                                <div className="m-1">
                                  <P>
                                    {Duration} :{" "}
                                    {duration
                                      ? convertMinutesToHours(
                                          parseInt(duration)
                                        ) + " h"
                                      : "-"}
                                  </P>
                                </div>
                              )}
                              {showPlay && (
                                <div className="d-flex justify-content-center m-1">
                                  <DefaultButton
                                    className="d-flex justify-content-center w-100"
                                    onClick={() => playScorm(index, docIndex)}
                                    color="primary"
                                  >
                                    {Play}
                                  </DefaultButton>
                                </div>
                              )}
                            </Badges>
                          </H3>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col lg="1" sm="1">
                  {item?.documents?.length > 0 && (
                    <MUIIcons
                      size={24}
                      className="primary-icon-color pointer"
                      iconName="FileDownloadOutlined"
                      onClick={() => handleDownload(item?.paths)}
                    />
                  )}
                </Col>
              </Row>
              {index !== data.length - 1 && <Divider />}
            </React.Fragment>
          ))}

          {isDocumentsAndPathsEmpty() && courseContent?.course_type === WBT && (
            <P className="mt-3 text-center primary-text-color">
              {NoScormPackageAdded}
            </P>
          )}
          {courseContent?.course_type === WBT &&
            hasPermissionToComponent("WBT_PUBLISH") &&
            !isDocumentsAndPathsEmpty() && (
              <div>
                {courseContent?.status !== PUBLISHED && (
                  <div className="mt-5">
                    <div className="form-check text-dark">
                      <Input
                        className="primary-checkbox"
                        id="flexCheckPublishLater"
                        type="checkbox"
                        checked={isPublishLater}
                        onChange={() => setIsPublishLater(!isPublishLater)}
                        disabled={
                          courseContent?.status === PUBLISHED || editingData
                        }
                      />
                      <Label
                        className="text-dark"
                        htmlFor="flexCheckPublishLater"
                        check
                      >
                        {PublishLater}
                      </Label>
                    </div>
                  </div>
                )}
                {isPublishLater && courseContent?.status !== PUBLISHED && (
                  <Row className="mt-1">
                    <Col sm="12" lg="6">
                      <FormGroup>
                        <LabelTooltip
                          label={FutureDate}
                          tooltipText={FutureDateToolTipMessage}
                          important
                        />

                        <div className={"w-100"}>
                          <DatePicker
                            selected={futureDate}
                            onChange={(date: Date | null) =>
                              setFutureDate(date)
                            }
                            minDate={getTomorrowDate()}
                            placeholderText={`${Enter} ${Dates}`}
                            className={"w-100 form-control"}
                            dateFormat="dd-MM-yyyy"
                            disabledKeyboardNavigation={true}
                            onKeyDown={e => e.preventDefault()}
                            disabled={
                              courseContent?.status === PUBLISHED || editingData
                            }
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col sm="12" lg="6"></Col>
                  </Row>
                )}
              </div>
            )}
        </CardBody>
      </Card>
    </>
  );
};

export default MaterialDetails;
