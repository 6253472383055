import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
  GET,
  GET_ASSESSMENT_BY_STATUS,
  GET_ASSESSMENT_BY_STATUS_URL,
  GET_ILP_COURSE,
  GET_ILP_COURSE_URL,
  GET_MAPPED_ASSESSMENT,
  GET_MAPPED_ASSESSMENT_URL,
  GET_LIST_OF_SGA,
  GET_LIST_OF_SGA_URL,
  GET_MAPPED_CURRICULUM_LIST,
  GET_MAPPED_CURRICULUM_LIST_URL,
  GET_MAPPED_SGA_LIST,
  GET_MAPPED_SGA_LIST_URL,
  GET_OVERALL_STATISTICS,
  GET_OVERALL_STATISTICS_URL,
  GET_TRAINING_HISTORY,
  GET_TRAINING_HISTORY_URL,
  GET_UN_MAPPED_COURSE,
  GET_UN_MAPPED_COURSE_URL,
  MAP_ASSESSMENT,
  MAP_ASSESSMENT_URL,
  MAP_SGA,
  MAP_SGA_URL,
  MAP_CURRICULUM,
  MAP_COURSE_RELEVANT_URL,
  POST,
  GET_PENDING_SKILL_UPDATE,
  GET_PENDING_SKILL_UPDATE_URL,
  GET_LO_FOR_ASSESSMENT,
  GET_LO_FOR_ASSESSMENT_URL,
  UPLOAD_SKILL_DOCS,
  UPLOAD_SKILL_DOCS_URL,
  PUT,
  APPROVE_SKILL_DOCS,
  APPROVE_SKILL_DOCS_URL,
  GET_COURSE_COUNT_FOR_YEAR,
  GET_COURSE_COUNT_FOR_YEAR_URL,
  MAP_COURSE,
  GET_MAPPED_CERTIFICATION_LIST,
  GET_MAPPED_CERTIFICATION_LIST_URL,
  MAP_YEAR,
  MAP_YEAR_URL,
  APPROVE_MAPPED_YEAR,
  GET_MODIFICATION_REQUEST_DETAILS,
  GET_MODIFICATION_REQUEST_DETAILS_URL,
  GET_COURSE_BY_CURRICULUM_USER_ID,
  GET_COURSE_BY_CURRICULUM_USER_ID_URL,
  // GET_USER_OVERVIEW_DATA,
  // GET_USER_OVERVIEW_DATA_URL,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import {
  ILPCourseListResponse,
  UnMappedCourseResponse,
  MapCurriculumListResponse,
  OverallStaticsResponse,
  TrainingHistoryResponse,
  MappedUserAssessments,
  MapSGAPayloadRequest,
  ListOfSga,
  MappedSgaResponse,
  CourseCountForTheYearResponse,
  AssessmentDataResponse,
  PendingSkillUpdate,
  CertificationResponse,
} from "../../Api/entities/LearningPlanEntity";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface LearningPlanInterface {
  ilpCourseList: ILPCourseListResponse;
  unMappedCourse: UnMappedCourseResponse;
  mappedCurriculum: MapCurriculumListResponse;
  mappedSga: MappedSgaResponse;
  overallStatistics: OverallStaticsResponse;
  courseCountForTheYear: CourseCountForTheYearResponse;
  trainingHistory: TrainingHistoryResponse;
  listOfSga: ListOfSga;
  loading: boolean;
  mappedAssessment: MappedUserAssessments;
  error: string | null;
  assessmentList: AssessmentDataResponse;
  pendingSkillUpdateList: PendingSkillUpdate;
  loForAssessment: any;
  certificationsList: CertificationResponse;
  userOverviewData: any;
  modificationRequestDetail: any;
  mappedCurriculumUserCourse: any;
}

const initialState: LearningPlanInterface = {
  ilpCourseList: null,
  unMappedCourse: null,
  mappedCurriculum: null,
  mappedSga: null,
  overallStatistics: null,
  courseCountForTheYear: null,
  trainingHistory: null,
  listOfSga: null,
  loading: false,
  mappedAssessment: null,
  error: null,
  assessmentList: null,
  pendingSkillUpdateList: null,
  loForAssessment: null,
  certificationsList: null,
  userOverviewData: null,
  modificationRequestDetail: null,
  mappedCurriculumUserCourse: null,
};

export const getILPCourse = createAsyncThunk(
  GET_ILP_COURSE,
  async ({
    courseType,
    selfEnrollment,
    userId,
    fyYear,
  }: {
    courseType: string[];
    selfEnrollment: boolean;
    fyYear: string;
    userId?: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_ILP_COURSE_URL(courseType.toString(), selfEnrollment, fyYear, userId)
    );
    return response.data;
  }
);

export const getUnMappedCourse = createAsyncThunk(
  GET_UN_MAPPED_COURSE,
  async ({ userId }: { userId?: number }) => {
    const response = await apiRequest(GET, GET_UN_MAPPED_COURSE_URL(userId));
    return response.data;
  }
);

export const getMappedCurriculumList = createAsyncThunk(
  GET_MAPPED_CURRICULUM_LIST,
  async ({ userId }: { userId?: number }) => {
    const response = await apiRequest(
      GET,
      GET_MAPPED_CURRICULUM_LIST_URL(userId)
    );
    return response;
  }
);

export const getCourseByUserCurriculumId = createAsyncThunk(
  GET_COURSE_BY_CURRICULUM_USER_ID,
  async ({ userCurriculumId }: { userCurriculumId?: number }) => {
    const response = await apiRequest(
      GET,
      GET_COURSE_BY_CURRICULUM_USER_ID_URL(userCurriculumId)
    );
    return response;
  }
);

export const getMappedSgaList = createAsyncThunk(
  GET_MAPPED_SGA_LIST,
  async ({
    type,
    userId,
    jobRoleId,
  }: {
    type: string;
    userId?: number;
    jobRoleId?: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_MAPPED_SGA_LIST_URL(type, userId, jobRoleId)
    );
    return response.data;
  }
);

export const getModificationRequestDetails = createAsyncThunk(
  GET_MODIFICATION_REQUEST_DETAILS,
  async ({ id }: { id: string }) => {
    const response = await apiRequest(
      GET,
      GET_MODIFICATION_REQUEST_DETAILS_URL(id)
    );
    return response.data;
  }
);

export const getOverallStatistics = createAsyncThunk(
  GET_OVERALL_STATISTICS,
  async ({
    selfEnrollment,
    userId,
  }: {
    selfEnrollment: boolean;
    userId?: number;
  }) => {
    const response = await apiRequest(
      GET,
      GET_OVERALL_STATISTICS_URL(selfEnrollment, userId)
    );
    return response.data;
  }
);

export const getTrainingHistory = createAsyncThunk(
  GET_TRAINING_HISTORY,
  async ({
    userId,
    fyYear,
    course_type,
    status,
  }: {
    userId?: number;
    fyYear?: string;
    course_type?: string;
    status?: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_TRAINING_HISTORY_URL(userId, fyYear, course_type, status)
    );
    return response.data;
  }
);

export const getMappedAssessment = createAsyncThunk(
  GET_MAPPED_ASSESSMENT,
  async ({
    fyYear,
    userId,
    user_sga_id,
  }: {
    fyYear?: string;
    userId?: number;
    user_sga_id?: number;
  }) => {
    const response = await apiRequest(
      GET,
      GET_MAPPED_ASSESSMENT_URL(fyYear, userId, user_sga_id)
    );
    return response;
  }
);

export const getListOfSga = createAsyncThunk(GET_LIST_OF_SGA, async () => {
  const response = await apiRequest(GET, GET_LIST_OF_SGA_URL());
  return response.data;
});

export const mapIlpSga = createAsyncThunk(
  MAP_SGA,
  async ({ mapPayload }: { mapPayload: MapSGAPayloadRequest }) => {
    const response = await apiRequest(POST, MAP_SGA_URL(), mapPayload);
    return response;
  }
);

export const mapCurriculum = createAsyncThunk(
  MAP_CURRICULUM,
  async ({ curriculumContent }: { curriculumContent: Object }) => {
    const response = await apiRequest(
      POST,
      MAP_COURSE_RELEVANT_URL(),
      curriculumContent
    );
    return response;
  }
);

export const mapCourse = createAsyncThunk(
  MAP_COURSE,
  async ({ courseContent }: { courseContent: Object }) => {
    const response = await apiRequest(
      POST,
      MAP_COURSE_RELEVANT_URL(),
      courseContent
    );
    return response;
  }
);

export const mapYearAPI = createAsyncThunk(
  MAP_YEAR,
  async ({ content }: { content: Object }) => {
    const response = await apiRequest(POST, MAP_YEAR_URL(), content);
    return response;
  }
);

export const approveMappedYear = createAsyncThunk(
  APPROVE_MAPPED_YEAR,
  async ({ content }: { content: Object }) => {
    const response = await apiRequest(POST, MAP_YEAR_URL(), content);
    return response;
  }
);

export const getAssessmentByStatus = createAsyncThunk(
  GET_ASSESSMENT_BY_STATUS,
  async ({
    status,
    requestType,
    userId,
  }: {
    status: string;
    requestType: string;
    userId?: number;
  }) => {
    const response = await apiRequest(
      GET,
      GET_ASSESSMENT_BY_STATUS_URL(status, requestType, userId)
    );
    return response;
  }
);

export const getCourseCountForYear = createAsyncThunk(
  GET_COURSE_COUNT_FOR_YEAR,
  async ({ year, userId }: { year: string; userId: string }) => {
    const response = await apiRequest(
      GET,
      GET_COURSE_COUNT_FOR_YEAR_URL(year, userId)
    );
    return response.data;
  }
);

export const mapAssessment = createAsyncThunk(
  MAP_ASSESSMENT,
  async ({ assessmentPayload }: { assessmentPayload: Object }) => {
    const response = await apiRequest(
      POST,
      MAP_ASSESSMENT_URL(),
      assessmentPayload
    );
    return response;
  }
);

export const getPendingSkillUpdateList = createAsyncThunk(
  GET_PENDING_SKILL_UPDATE,
  async ({ userId }: { userId?: number }) => {
    const response = await apiRequest(
      GET,
      GET_PENDING_SKILL_UPDATE_URL(userId)
    );
    return response;
  }
);

export const getLOForAssessment = createAsyncThunk(
  GET_LO_FOR_ASSESSMENT,
  async ({ ids }: { ids: string }) => {
    const response = await apiRequest(GET, GET_LO_FOR_ASSESSMENT_URL(ids));
    return response;
  }
);

export const uploadSkillDocs = createAsyncThunk(
  UPLOAD_SKILL_DOCS,
  async ({ docs }: { docs: Object }) => {
    const response = await apiRequest(PUT, UPLOAD_SKILL_DOCS_URL(), docs);
    return response;
  }
);

export const approveSkillDocs = createAsyncThunk(
  APPROVE_SKILL_DOCS,
  async ({ docs }: { docs: Object }) => {
    const response = await apiRequest(PUT, APPROVE_SKILL_DOCS_URL(), docs);
    return response;
  }
);

export const getCertificationList = createAsyncThunk(
  GET_MAPPED_CERTIFICATION_LIST,
  async () => {
    const response = await apiRequest(GET, GET_MAPPED_CERTIFICATION_LIST_URL());
    return response;
  }
);

// export const getUserOverviewData = createAsyncThunk(
//   GET_USER_OVERVIEW_DATA,
//   async ({ userId }: { userId: string }) => {
//     const response = await apiRequest(GET, GET_USER_OVERVIEW_DATA_URL(userId));
//     return response;
//   }
// );

const LearningPlanSlice = createSlice({
  name: "LearningPlan",
  initialState,
  reducers: {
    setMappedAssessmentsToInitialValues: state => {
      state.mappedAssessment = initialState.mappedAssessment;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getModificationRequestDetails.pending, state => {
        state.error = null;
      })
      .addCase(getModificationRequestDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.modificationRequestDetail = action.payload;
      })
      .addCase(getModificationRequestDetails.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(uploadSkillDocs.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadSkillDocs.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(uploadSkillDocs.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(approveSkillDocs.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveSkillDocs.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(approveSkillDocs.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getILPCourse.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getILPCourse.fulfilled,
        (state, action: PayloadAction<ILPCourseListResponse>) => {
          state.loading = false;
          state.ilpCourseList = action.payload;
        }
      )
      .addCase(getILPCourse.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getLOForAssessment.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLOForAssessment.fulfilled, (state, action) => {
        state.loading = false;
        state.loForAssessment = action.payload;
      })
      .addCase(getLOForAssessment.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(mapAssessment.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(mapAssessment.fulfilled, state => {
        state.loading = false;
      })
      .addCase(mapAssessment.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getPendingSkillUpdateList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPendingSkillUpdateList.fulfilled,
        (state, action: PayloadAction<PendingSkillUpdate>) => {
          state.loading = false;
          state.pendingSkillUpdateList = action.payload;
        }
      )
      .addCase(getPendingSkillUpdateList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getMappedAssessment.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getMappedAssessment.fulfilled,
        (state, action: PayloadAction<MappedUserAssessments>) => {
          state.loading = false;
          state.mappedAssessment = action.payload;
        }
      )
      .addCase(getMappedAssessment.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getUnMappedCourse.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getUnMappedCourse.fulfilled,
        (state, action: PayloadAction<UnMappedCourseResponse>) => {
          state.loading = false;
          state.unMappedCourse = action.payload;
        }
      )
      .addCase(getUnMappedCourse.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getMappedCurriculumList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMappedCurriculumList.fulfilled, (state, action) => {
        state.loading = false;
        state.mappedCurriculum = action.payload.data;
      })
      .addCase(getMappedCurriculumList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseByUserCurriculumId.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCourseByUserCurriculumId.fulfilled, (state, action) => {
        state.loading = false;
        state.mappedCurriculumUserCourse = action.payload.data;
      })
      .addCase(getCourseByUserCurriculumId.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getMappedSgaList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMappedSgaList.fulfilled, (state, action) => {
        state.loading = false;
        state.mappedSga = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getMappedSgaList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getOverallStatistics.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getOverallStatistics.fulfilled,
        (state, action: PayloadAction<OverallStaticsResponse>) => {
          state.loading = false;
          state.overallStatistics = action.payload;
        }
      )
      .addCase(getOverallStatistics.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getTrainingHistory.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getTrainingHistory.fulfilled,
        (state, action: PayloadAction<TrainingHistoryResponse>) => {
          state.loading = false;
          state.trainingHistory = action.payload;
        }
      )
      .addCase(getTrainingHistory.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(mapCurriculum.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(mapCurriculum.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(mapCurriculum.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(mapCourse.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(mapCourse.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(mapCourse.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(mapYearAPI.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(mapYearAPI.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(mapYearAPI.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(approveMappedYear.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveMappedYear.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(approveMappedYear.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseCountForYear.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCourseCountForYear.fulfilled, (state, action) => {
        state.loading = false;
        state.courseCountForTheYear = action.payload;
      })
      .addCase(getCourseCountForYear.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssessmentByStatus.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssessmentByStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.assessmentList = action.payload.data;
      })
      .addCase(getAssessmentByStatus.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(mapIlpSga.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(mapIlpSga.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(mapIlpSga.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getListOfSga.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getListOfSga.fulfilled,
        (state, action: PayloadAction<ListOfSga>) => {
          state.loading = false;
          state.listOfSga = action.payload;
        }
      )
      .addCase(getListOfSga.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getCertificationList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCertificationList.fulfilled, (state, action) => {
        state.loading = false;
        state.certificationsList = action.payload.data;
      })
      .addCase(getCertificationList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    // builder
    //   .addCase(getUserOverviewData.pending, state => {
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(getUserOverviewData.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.userOverviewData = action.payload.data;
    //   })
    //   .addCase(getUserOverviewData.rejected, (state, action) => {
    //     try {
    //       const error = JSON.parse(action.error.message);
    //       state.error = error.message;
    //       showToast(state?.error, "error");
    //     } catch (e) {
    //       state.error = SomethingWentWrong;
    //     }
    //   });
  },
});

export const { setMappedAssessmentsToInitialValues } =
  LearningPlanSlice.actions;

export default LearningPlanSlice.reducer;
