import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import ScrollableJustifyTabs from "../../../CommonElements/ScrollableJustifyTabs";
import UserProfileCard from "../../../container/UserProfileCard";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getUserDetailsList,
  setUserDetailsToInitialState,
} from "../../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../../Service";
import {
  CWSAndCatRecId,
  Contact,
  SBU,
  RMName,
  RMEmail,
  RMContactNumber,
  Location,
  Mail,
  Grade,
} from "../../../utils/Constant";
import { userManagementTabs } from "../../../utils/helper/helper";
import { UserProfileInformationDetails } from "../../../utils/helper/propTypes";
import AssignedILP from "../../MyLearningPlan/AssignedILP";
import ILPCurriculum from "../../MyLearningPlan/ILPCurriculum";
import ILPOverview from "../../MyLearningPlan/ILPOverview";
import ILPSelfEnrollment from "../../MyLearningPlan/ILPSelfEnrollment";
import ILPSga from "../../MyLearningPlan/ILPSga";
import ILPTrainingHistory from "../../MyLearningPlan/ILPTrainingHistory";
import ILPUnMapped from "../../MyLearningPlan/ILPUnMapped";
import PendingSkillUpdate from "../../MyLearningPlan/PendingSkillUpdate";
import ILPAssessment from "../ILPAssessment";

import "./style.scss";

const UserDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [userProfileData, setUserProfileData] = useState(null);
  const params = new URLSearchParams(location.search);
  const { userDetailsList } = useAppSelector(state => state.user);
  const statusParam = params.get("tab");
  const userId = parseFloat(params.get("user_id"));
  const jobRoleId = parseFloat(params.get("job_role_id"));

  const [activeTab, setActiveTab] = useState<string>(statusParam || "overview");

  useEffect(() => {
    dispatch(getUserDetailsList({ id: [userId] }));
    return () => {
      dispatch(setUserDetailsToInitialState());
    };
  }, []);

  useEffect(() => {
    if (userDetailsList?.users[0]) {
      const mapUserToProfileDetails = (
        user: any
      ): UserProfileInformationDetails => ({
        userProfileUrl: dynamicImage("dashboard-4/user.png"),
        name: user.user_name,
        designation: user.designation ? user.designation : "-",
        employee_code: user.employee_code ? user.employee_code : "-",
        userDetails: [
          {
            icon: "BadgeOutlined",
            title: CWSAndCatRecId,
            detail: user?.cws_cat_rec ? user?.cws_cat_rec : "-",
          },
          {
            icon: "SchoolOutlined",
            title: Grade,
            detail: user?.grade ? user?.grade : "-",
          },
          {
            icon: "PinOutlined",
            title: SBU,
            detail: user?.sbu ? user?.sbu : "-",
          },
          {
            icon: "PersonOutline",
            title: RMName,
            detail: user.mapped_rm?.length > 0 ? user.mapped_rm[0].name : "-",
          },
          {
            icon: "PhoneOutlined",
            title: Contact,
            detail: user?.phone ? user?.phone : "-",
          },
          {
            icon: "EmailOutlined",
            title: Mail,
            detail: user.email ? user.email : "-",
          },
          {
            icon: "EmailOutlined",
            title: RMEmail,
            detail:
              user.mapped_rm?.length > 0 && user.mapped_rm[0].email
                ? user.mapped_rm[0].email
                : "-",
          },
          {
            icon: "PhoneOutlined",
            title: RMContactNumber,
            detail: user?.mapped_rm?.[0]?.rm_phone || "-",
          },
          {
            icon: "LocationOnOutlined",
            title: Location,
            detail:
              [user?.office_city || "", user?.office_state || ""]
                .filter(Boolean)
                .join(", ") || "-",
          },
        ],
      });
      const constructedProfileData = userDetailsList.users.map(
        mapUserToProfileDetails
      );

      setUserProfileData(constructedProfileData[0]);
    }
  }, [userDetailsList]);

  const handleTabClick = (tabId: any) => {
    const baseUrl = `/user-management/user-details?user_id=${userId}&tab=${tabId}`;
    const url = jobRoleId ? `${baseUrl}&job_role_id=${jobRoleId}` : baseUrl;
    setActiveTab(tabId);
    navigate(url);
  };
  return (
    <div className="page-body page-body-margin">
      <UserProfileCard userData={userProfileData || {}} />
      <Card className="p-1">
        <CardBody className="justify-tabs-container">
          <div>
            <ScrollableJustifyTabs
              tabs={userManagementTabs}
              activeTab={activeTab}
              onTabClick={handleTabClick}
            />
          </div>
        </CardBody>
      </Card>
      {activeTab === "overview" && (
        <ILPOverview userId={userId} jobRoleId={jobRoleId} />
      )}
      {activeTab === "assigned_ilp" && <AssignedILP userId={userId} />}
      {activeTab === "curriculum" && <ILPCurriculum userId={userId} />}
      {activeTab === "self_enrollment" && <ILPSelfEnrollment userId={userId} />}
      {activeTab === "unmapped" && <ILPUnMapped userId={userId} />}
      {activeTab === "sga" && <ILPSga userId={userId} />}
      {activeTab === "pending_skill_update" && (
        <PendingSkillUpdate userId={userId} />
      )}
      {activeTab === "assessment" && <ILPAssessment userId={userId} />}
      {activeTab === "training_history" && (
        <ILPTrainingHistory userId={userId} />
      )}
    </div>
  );
};

export default UserDetails;
