import React, { ChangeEvent } from "react";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";

import Divider from "../../../../../CommonElements/Divider";
import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import {
  CustomerName,
  CustomerNameToolTipMessage,
  Enter,
  Internal,
  Name,
  NatureOfBusiness,
  NatureOfBusinessToolTipMessage,
  PointOfContact,
  PointOfContactToolTipMessage,
  RequestType,
  RMRequestTypeToolTipMessage,
} from "../../../../../utils/Constant";
import { requestTypeOptions } from "../../../../../utils/helper/helper";

interface BasicInputFormValueInterface {
  customer_name: string;
  created_by: string;
  designation: string;
  region: string;
  point_of_contact: string;
  nature_of_business: string;
  business_group?: string;
  request_type: string;
  frequency_count: string;
  designation_id?: string;
  business_group_id?: string;
}

interface BasicInputFormValuePropsType {
  getUserData: (event: ChangeEvent<HTMLInputElement>) => void;
  basicInputFormValue: BasicInputFormValueInterface;
  regionListData: any;
  userListData: any;
  designationListData: DesignationDropdownList[];
  BusinessGroupListData: BusinessGroupDropdownList[];
  errors: {
    customer_name: string;
    point_of_contact: string;
    nature_of_business: string;
    created_by: string;
    region: string;
    frequency_count: string;
    business_group: string;
    designation_id: string;
    business_group_id: string;
  };
}

const CourseFormOne: React.FC<BasicInputFormValuePropsType> = ({
  basicInputFormValue,
  getUserData,
  errors,
}) => {
  const { request_type, customer_name, point_of_contact, nature_of_business } =
    basicInputFormValue;
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    let value = event.target.value;
    if (name === "customer_name" || name === "point_of_contact") {
      if (/^[a-zA-Z\s]*$/.test(value) && value.length <= 100) {
        getUserData(event);
      }
    } else if (name === "nature_of_business" && value.length <= 100) {
      getUserData(event);
    } else if (name === "frequency_count") {
      if (value === "") {
        value = "1";
      }

      if (
        value === "" ||
        value === "0" ||
        (/^\d{1,2}$/.test(value) &&
          parseInt(value) > 0 &&
          parseInt(value) <= 10)
      ) {
        getUserData(event);
      }
    }
  };

  return (
    <Form className="stepper-one g-3 needs-validation custom-input" noValidate>
      <Row>
        <Col lg="6">
          <FormGroup>
            <LabelTooltip
              label={RequestType}
              tooltipText={RMRequestTypeToolTipMessage}
              important={true}
            />
            <Input
              name="request_type"
              type="select"
              value={request_type}
              onChange={getUserData}
            >
              {requestTypeOptions?.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      {request_type !== Internal && <Divider />}
      <Row className="mt-3">
        {request_type !== Internal && (
          <Col sm="12" lg="6">
            <FormGroup>
              <LabelTooltip
                label={
                  basicInputFormValue.request_type === Internal
                    ? Name
                    : CustomerName
                }
                tooltipText={CustomerNameToolTipMessage}
                important={true}
              />
              <Input
                onChange={handleInputChange}
                value={customer_name}
                name="customer_name"
                type="text"
                placeholder={`${Enter} ${
                  basicInputFormValue.request_type === Internal
                    ? Name
                    : CustomerName
                }`}
                invalid={!!errors.customer_name}
                disabled={request_type === Internal}
              />
              <div className="invalid-feedback">{errors.customer_name}</div>
            </FormGroup>
          </Col>
        )}
        {request_type !== Internal && (
          <Col sm="12" lg="6">
            <FormGroup>
              <LabelTooltip
                label={PointOfContact}
                tooltipText={PointOfContactToolTipMessage}
                important={true}
              />
              <Input
                onChange={handleInputChange}
                value={point_of_contact}
                name="point_of_contact"
                type="text"
                placeholder={`${Enter} ${PointOfContact}`}
                invalid={!!errors.point_of_contact}
              />
              <div className="invalid-feedback">{errors.point_of_contact}</div>
            </FormGroup>
          </Col>
        )}
      </Row>
      {request_type !== Internal && (
        <Col className="mt-3">
          <FormGroup>
            <LabelTooltip
              label={NatureOfBusiness}
              tooltipText={NatureOfBusinessToolTipMessage}
              important={true}
            />
            <Input
              rows={4}
              onChange={handleInputChange}
              value={nature_of_business}
              name="nature_of_business"
              type="textarea"
              placeholder={`${Enter} ${NatureOfBusiness}`}
              invalid={!!errors.nature_of_business}
            />
            <div className="invalid-feedback">{errors.nature_of_business}</div>
          </FormGroup>
        </Col>
      )}
    </Form>
  );
};

export default CourseFormOne;
