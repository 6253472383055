import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";

import { Btn, H4, H5 } from "../../../../AbstractElements";
import {
  REPORTING_MANAGER_STATUS,
  rmTabsStatusMap,
  requestTypeMap,
  STATUS_200,
} from "../../../../Api/constants";
import { parseCourseRequestData } from "../../../../Api/parser/courseRequest";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import StepperHorizontal from "../../../../CommonElements/StepperHorizontal";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  createCourseRequest,
  updateCourseRequest,
} from "../../../../ReduxToolkit/Reducers/CourseRequestSlice";
import { getRegionList } from "../../../../ReduxToolkit/Reducers/MasterSlice";
import {
  handleBackButton,
  handleNextButton,
  setBasicInputFormValue,
  setBasicInputFormValueToInitials,
  setNumberLevel,
  setShowFinish,
} from "../../../../ReduxToolkit/Reducers/RequestCourseWizardSlice";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import {
  AutoSavedOn,
  Back,
  EnterCorrectPhoneNumber,
  EnterCorrectSecondaryPhoneNumber,
  Finish,
  FrequencyGreaterThanZero,
  FrequencyRequired,
  Internal,
  NewRequest,
  Next,
  PleaseProvideRequiredInformation,
  SelectDesignation,
  ValidEmailId,
  ValidExtension,
  ValidSecondaryEmailId,
} from "../../../../utils/Constant";
import {
  courseStepperData,
  EMAIL_REGEX,
  formatJSDate,
  scrollToTop,
  showToast,
} from "../../../../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../../../utils/helper/localStorageutils";

import CourseFormFour from "./forms/courseFormFour";
import CourseFormOne from "./forms/courseFormOne";
import CourseFormThree from "./forms/courseFormThree";
import CourseFormTwo from "./forms/courseFormTwo";

const CourseCreation = () => {
  const { numberLevel, basicInputFormValue, showFinish } = useAppSelector(
    state => state.createCourse
  );
  const { usersList } = useAppSelector(state => state.user);
  const { regionList, designationList, businessGroupList } = useAppSelector(
    state => state.master
  );
  const basicInputFormValueRef = useRef(basicInputFormValue);
  const numberLevelRef = useRef(numberLevel);
  const createdValue = useRef(null);
  const isRecurringRef = useRef(false);
  const autoSavedStatus = useRef<Date | null>(null);
  const [autoSaveDate, setAutoSaveDate] = useState(null);
  const [regionListData, setRegionListData] = useState(null);
  const [designationListData, setDesignationListData] = useState(null);
  const [businessGroupListData, setBusinessGroupListData] = useState(null);
  const [userListData, setUserListData] = useState(null);
  const [errors, setErrors] = useState({
    frequency_type: "",
    course_duration_type: "",
    customer_name: "",
    point_of_contact: "",
    nature_of_business: "",
    created_by: "",
    region: "",
    content_topic: "",
    summary: "",
    target_audiance: "",
    expected_target_audience: "",
    business_group: "",
    course_duration: "",
    objective: "",
    accomplishment: "",
    frequency_count: "",
    notes: "",
    primary_contact: "",
    phone_number: null,
    email_id: "",
    secondary_contact: "",
    secondary_phone_number: null,
    secondary_email_id: "",
    extension: "",
    secondary_extension: "",
    designation_id: "",
    business_group_id: "",
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [seconds, setSeconds] = useState(0);
  const [isRecurring, setIsRecurring] = useState(false);
  const { type, data } = location.state || {};
  const loginData = getFromLocalStorage(LOGGED_IN_USER);

  useEffect(() => {
    setIsRecurring(false);
    setAutoSaveDate(null);
    dispatch(getUsersList({ role: REPORTING_MANAGER_STATUS }));
    dispatch(getRegionList());

    if (type === "edit") {
      const updateData = data;
      if (isNaN(updateData?.phone_number)) {
        updateData.phone_number = "";
      }
      if (isNaN(updateData?.secondary_phone_number)) {
        updateData.secondary_phone_number = "";
      }
      dispatch(setBasicInputFormValue(updateData));
    } else {
      dispatch(setBasicInputFormValueToInitials());
    }
  }, [dispatch, type, data]);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      if (numberLevelRef.current !== 1) autoSaveDataToDb();
      setSeconds(seconds + 1);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const autoSaveDataToDb = async () => {
    if (basicInputFormValueRef.current?.content_topic === "") {
      return;
    }
    const inputFormData = {
      ...basicInputFormValueRef.current,
      status: rmTabsStatusMap["draft"],
    };
    const payload = parseCourseRequestData(
      inputFormData,
      isRecurringRef,
      loginData
    );
    const dataToSend = {
      courseRequestFormData: payload,
    };

    let createAction = null;
    if (createdValue.current) {
      dataToSend.courseRequestFormData.id = createdValue.current;
      await dispatch(updateCourseRequest(dataToSend));
      setAutoSaveDate(new Date());
    } else if (data?.id) {
      dataToSend.courseRequestFormData.id = data?.id;
      await dispatch(updateCourseRequest(dataToSend));
      setAutoSaveDate(new Date());
    } else {
      dataToSend.courseRequestFormData.id = data?.id;
      createAction = await dispatch(createCourseRequest(dataToSend));
      createdValue.current = createAction?.payload?.data?.id;
      setAutoSaveDate(new Date());
    }
  };

  useEffect(() => {
    autoSavedStatus.current = autoSaveDate;
  }, [autoSaveDate]);

  useEffect(() => {
    if (
      basicInputFormValue.frequency_count &&
      basicInputFormValue.frequency_count !== ""
    ) {
      isRecurringRef.current = true;
      setIsRecurring(true);
    }

    basicInputFormValueRef.current = basicInputFormValue;
  }, [basicInputFormValue]);

  useEffect(() => {
    numberLevelRef.current = numberLevel;
  }, [numberLevel]);

  useEffect(() => {
    if (type !== "edit") {
      let prefillData = {
        customer_name: "",
        created_by: "",
      };

      if (usersList?.users) {
        const findUser = usersList.users.find(
          (user: UsersList) => user.id === loginData.id
        );
        if (findUser) {
          prefillData = {
            customer_name:
              basicInputFormValue.request_type === requestTypeMap?.internal
                ? findUser?.user_name
                : "",
            created_by: findUser?.id?.toString(),
          };
        }
      }
      dispatch(
        setBasicInputFormValue({
          ...basicInputFormValue,
          ...prefillData,
        })
      );
    }
  }, [basicInputFormValue.request_type, usersList]);

  useEffect(() => {
    const users = [];
    users.push({ label: "Select Filled By", value: "" });
    usersList?.users?.forEach(user => {
      users.push({ label: user.user_name, value: user.id });
    });
    setUserListData(users);
  }, [usersList]);

  useEffect(() => {
    const regions = [];
    regions.push({ value: "", label: "Select Region" });
    regionList?.regions?.forEach(region => {
      const userObj = {
        value: region.id,
        label: region.name,
      };
      regions.push(userObj);
    });
    setRegionListData(regions);
  }, [regionList]);

  useEffect(() => {
    const designations = [];
    designations.push({ value: "", label: SelectDesignation });
    designationList?.designation?.forEach(designation => {
      const userObj = {
        value: designation.id,
        label: designation.name,
      };
      designations.push(userObj);
    });
    setDesignationListData(designations);
  }, [designationList]);

  useEffect(() => {
    const businesses = [];
    businesses.push({ value: "", label: "Select Business" });
    businessGroupList?.business_group?.forEach(busi => {
      const userObj = {
        value: busi.id,
        label: busi.name,
      };
      businesses.push(userObj);
    });
    setBusinessGroupListData(businesses);
  }, [businessGroupList]);

  const getUserData = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "request_type") {
      isRecurringRef.current = false;
      const findUser = usersList.users.find(
        (user: UsersList) => user.id === loginData.id
      );
      dispatch(
        setBasicInputFormValue({
          ...basicInputFormValue,
          [name]: value,
          ["customer_name"]:
            value === requestTypeMap?.internal ? findUser?.user_name : "",
          ["point_of_contact"]: "",
          ["nature_of_business"]: "",
          ["frequency_count"]: "",
        })
      );
      setIsRecurring(false);
      clearErrors();
    } else {
      dispatch(
        setBasicInputFormValue({ ...basicInputFormValue, [name]: value })
      );
    }
  };

  const getRichTextUserData = (name: string, value: string) => {
    dispatch(setBasicInputFormValue({ ...basicInputFormValue, [name]: value }));
  };

  const validateFields = () => {
    let isValid = true;

    const baseRequiredFields = {
      1: {
        customer_name:
          basicInputFormValue.request_type === requestTypeMap?.internal
            ? "Name"
            : "Customer Name",
      },
      2: {
        content_topic: "Content Topic",
        summary: "Summary",
        accomplishment: "Accomplishment",
        target_audiance: "Target Audience",
        expected_target_audience: "Count of Target Audience",
        course_duration: "Suggested Duration",
        objective: "Objective",
      },
      3: {
        primary_contact: "Primary Contact",
        phone_number: "Phone Number",
        email_id: "Email Id",
      },
      4: {
        notes: "Notes",
      },
    };

    const internalFields = {
      customer_name: "Name",
    };

    const externalFields = {
      customer_name: "Customer Name",
      nature_of_business: "Nature of Business",
      point_of_contact: "Point of Contact",
      created_by: "Filled By",
    };

    const requiredFields = {
      ...baseRequiredFields,
      1:
        basicInputFormValue.request_type === requestTypeMap?.internal
          ? internalFields
          : externalFields,
    };

    const newErrors = {
      frequency_type: "",
      course_duration_type: "",
      customer_name: "",
      point_of_contact: "",
      nature_of_business: "",
      business_group: "",
      created_by: "",
      region: "",
      frequency_count: "",
      content_topic: "",
      summary: "",
      target_audiance: "",
      expected_target_audience: "",
      course_duration: "",
      objective: "",
      accomplishment: "",
      notes: "",
      primary_contact: "",
      phone_number: null,
      email_id: "",
      secondary_contact: "",
      secondary_phone_number: null,
      secondary_email_id: "",
      extension: "",
      designation_id: "",
      business_group_id: "",
      secondary_extension: "",
    };

    if (
      numberLevel === 3 &&
      basicInputFormValue?.phone_number &&
      basicInputFormValue?.phone_number !== null &&
      basicInputFormValue?.phone_number !== 0 &&
      (basicInputFormValue?.phone_number?.toString()?.length !== 10 ||
        basicInputFormValue?.phone_number?.toString() === "0000000000")
    ) {
      newErrors["phone_number"] = EnterCorrectPhoneNumber;
      isValid = false;
    }

    if (
      numberLevel === 3 &&
      basicInputFormValue?.secondary_phone_number &&
      basicInputFormValue?.secondary_phone_number !== null &&
      basicInputFormValue?.secondary_phone_number !== 0 &&
      (basicInputFormValue?.secondary_phone_number?.toString()?.length !== 10 ||
        basicInputFormValue?.secondary_phone_number?.toString() ===
          "0000000000")
    ) {
      newErrors["secondary_phone_number"] = EnterCorrectSecondaryPhoneNumber;
      isValid = false;
    }

    if (
      numberLevel === 3 &&
      basicInputFormValue?.email_id !== "" &&
      !EMAIL_REGEX.test(basicInputFormValue?.email_id)
    ) {
      newErrors["email_id"] = ValidEmailId;
      isValid = false;
    }

    if (
      numberLevel === 3 &&
      basicInputFormValue?.secondary_email_id !== "" &&
      !EMAIL_REGEX.test(basicInputFormValue?.secondary_email_id)
    ) {
      newErrors["secondary_email_id"] = ValidSecondaryEmailId;
      isValid = false;
    }

    if (
      numberLevel === 3 &&
      basicInputFormValue?.extension !== "" &&
      !/^\+?\d{1,4}$/.test(basicInputFormValue?.extension)
    ) {
      newErrors["extension"] = ValidExtension;
      isValid = false;
    }

    if (
      numberLevel === 3 &&
      basicInputFormValue?.secondary_extension !== "" &&
      !/^\+?\d{1,4}$/.test(basicInputFormValue?.secondary_extension)
    ) {
      newErrors["secondary_extension"] = ValidExtension;
      isValid = false;
    }

    if (requiredFields[numberLevel]) {
      for (const [field] of Object.entries(requiredFields[numberLevel])) {
        const value = basicInputFormValue[field];
        const isInvalid =
          typeof value === "string"
            ? !value.trim()
            : value === undefined || value === null || value === "";

        if (
          (field === "phone_number" && value === 0) ||
          (field === "secondary_phone_number" && value === 0) ||
          (field === "course_duration" &&
            value &&
            (value === "." ||
              value === "0." ||
              value === "0" ||
              value === ".0" ||
              value?.toString() === ""))
        ) {
          newErrors[field] =
            `${requiredFields[numberLevel][field]} is required`;
          isValid = false;
        } else if (
          field === "expected_target_audience" &&
          value &&
          value === "0"
        ) {
          newErrors[field] =
            `${requiredFields[numberLevel][field]} should be more than 0`;
          isValid = false;
        } else if (isInvalid) {
          if (
            field === "point_of_contact" &&
            basicInputFormValue.request_type === Internal
          ) {
            newErrors[field] = "";
          } else {
            newErrors[field] =
              `${requiredFields[numberLevel][field]} is required`;
            isValid = false;
          }
        }
      }
    }
    if (
      numberLevel === 2 &&
      isRecurringRef?.current &&
      (!basicInputFormValue?.frequency_count ||
        basicInputFormValue?.frequency_count === "" ||
        basicInputFormValue?.frequency_count === ".")
    ) {
      newErrors["frequency_count"] = FrequencyRequired;
      isValid = false;
    }
    if (
      numberLevel === 2 &&
      isRecurringRef?.current &&
      (basicInputFormValue?.frequency_count === "0" ||
        basicInputFormValue?.frequency_count === "." ||
        basicInputFormValue?.frequency_count === "0." ||
        basicInputFormValue?.frequency_count === ".0")
    ) {
      newErrors["frequency_count"] = FrequencyGreaterThanZero;
      isValid = false;
    }
    setErrors(newErrors);
    if (!isValid) {
      showToast(PleaseProvideRequiredInformation, "error");
      scrollToTop();
    }
    return isValid;
  };

  const clearErrors = () => {
    setErrors({
      frequency_type: "",
      course_duration_type: "",
      customer_name: "",
      point_of_contact: "",
      nature_of_business: "",
      business_group: "",
      created_by: "",
      region: "",
      content_topic: "",
      summary: "",
      target_audiance: "",
      expected_target_audience: "",
      course_duration: "",
      objective: "",
      accomplishment: "",
      notes: "",
      primary_contact: "",
      phone_number: null,
      email_id: "",
      frequency_count: "",
      secondary_contact: "",
      secondary_phone_number: null,
      secondary_email_id: "",
      extension: "",
      secondary_extension: "",
      designation_id: "",
      business_group_id: "",
    });
  };

  const isSetRecurring = (val: boolean) => {
    if (!val)
      dispatch(
        setBasicInputFormValue({
          ...basicInputFormValue,
          ["frequency_count"]: null,
        })
      );
    isRecurringRef.current = val;
    setIsRecurring(val);
  };

  return (
    <div className="page-body pb-5">
      <Card>
        <CardHeader>
          <H4>{NewRequest}</H4>
        </CardHeader>
        <CardBody>
          <div className="basic-wizard important-validation">
            <StepperHorizontal data={courseStepperData} level={numberLevel} />
            <div>
              {numberLevel === 1 && (
                <CourseFormOne
                  userListData={userListData}
                  regionListData={regionListData}
                  designationListData={designationListData}
                  getUserData={getUserData}
                  basicInputFormValue={basicInputFormValue}
                  errors={errors}
                  BusinessGroupListData={businessGroupListData}
                />
              )}
              {numberLevel === 2 && (
                <CourseFormTwo
                  getRichTextUserData={getRichTextUserData}
                  getUserData={getUserData}
                  isRecurring={isRecurring}
                  setIsRecurring={isSetRecurring}
                  basicInputFormValue={basicInputFormValue}
                  errors={errors}
                />
              )}
              {numberLevel === 3 && (
                <CourseFormThree
                  getUserData={getUserData}
                  basicInputFormValue={basicInputFormValue}
                  errors={errors}
                />
              )}
              {numberLevel === 4 && (
                <CourseFormFour
                  getRichTextUserData={getRichTextUserData}
                  getUserData={getUserData}
                  basicInputFormValue={basicInputFormValue}
                  errors={errors}
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      <FixedFooter>
        {formatJSDate(autoSavedStatus.current) && (
          <H5 className="mt-2">
            {AutoSavedOn} {formatJSDate(autoSavedStatus.current)}
          </H5>
        )}
        {numberLevel > 1 && (
          <Btn
            outline
            className="alert-light-primary"
            color="primary"
            onClick={() => {
              dispatch(handleBackButton());
              clearErrors();
              scrollToTop();
            }}
          >
            {Back}
          </Btn>
        )}
        <Btn
          color="primary"
          onClick={async () => {
            if (!showFinish && validateFields()) {
              dispatch(handleNextButton());
            }
            scrollToTop();
            if (showFinish) {
              if (basicInputFormValue.notes === "") {
                setErrors({ ...errors, notes: "Notes is required" });
                return;
              }
              const inputFormData = {
                ...basicInputFormValue,
                status: rmTabsStatusMap["new"],
              };
              const payload = parseCourseRequestData(
                inputFormData,
                isRecurringRef,
                loginData
              );
              const dataToSend = {
                courseRequestFormData: payload,
              };
              let res = null;
              if (type === "edit" && data?.id) {
                dataToSend.courseRequestFormData.id = data?.id;
                res = await dispatch(updateCourseRequest(dataToSend));
              } else if (createdValue.current) {
                dataToSend.courseRequestFormData.id = createdValue.current;
                res = await dispatch(updateCourseRequest(dataToSend));
              } else {
                res = await dispatch(createCourseRequest(dataToSend));
              }
              if (
                res?.payload?.status_code === STATUS_200 ||
                res?.payload?.id
              ) {
                dispatch(setNumberLevel(1));
                dispatch(setShowFinish(1));
                dispatch(setBasicInputFormValueToInitials());
                return navigate(
                  `${process.env.PUBLIC_URL}/course-management/course-request`
                );
              }
            }
          }}
        >
          {showFinish ? Finish : Next}
        </Btn>
      </FixedFooter>
    </div>
  );
};

export default CourseCreation;
