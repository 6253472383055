import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Tooltip,
} from "reactstrap";

import "./style.scss";
import {
  DefaultButton,
  H4,
  H5,
  MUIIcons,
  SecondaryButton,
} from "../../../../AbstractElements";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../CommonElements/Modal";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  deleteAssetsForSchedule,
  fetchAssetsListForSchedule,
  getToolsList,
  getTrainingAidList,
  postAssetsForSchedule,
} from "../../../../ReduxToolkit/Reducers/MasterSlice";
import {
  Cancel,
  Enter,
  Select,
  Dates,
  Duration,
  Update,
  ResourceType,
  Delete,
  Add,
  Asset,
  Submit,
  AssetName,
  Type,
  AssetID,
  PartNumber,
  Action,
  StartDateRequired,
  DurationIsRequired,
  Resource,
  ResourceRequired,
  ResourceTypeRequired,
  AreYouSureDelete,
  Confirmation,
  Confirm,
  ResourceAlreadyPresent,
} from "../../../../utils/Constant";
import {
  assetTypeOptions,
  formatDate,
  handleKeyDownOnNumericWithoutDecimal,
  mapListToOptions,
  showToast,
  truncateText,
} from "../../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../../utils/helper/permission";

const Assets = ({
  startDate,
  endDate,
  scheduledId,
  assetModal,
  setAssetModal,
}) => {
  const { loading } = useAppSelector(state => state.learningPlan);
  const [formValue, setFormValue] = useState(null);
  const [errors, setErrors] = useState(null);
  const [resourceValues, setResourceValues] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);

  const [nameTooltipOpen, setNameTooltipOpen] = useState({});
  const [typeTooltipOpen, setTypeTooltipOpen] = useState({});

  const dispatch = useAppDispatch();

  const { toolsList, trainingList, assetsList } = useAppSelector(
    state => state.master
  );

  useEffect(() => {
    getAssetsList();
  }, []);

  useEffect(() => {
    if (assetModal?.resource_history_id) {
      setFormValue({
        resources: assetModal?.resource_id,
        startDate: formatDate(assetModal?.usage_date),
        duration: assetModal?.duration,
      });
    } else {
      setFormValue(null);
      setErrors(null);
    }
  }, [assetModal]);

  useEffect(() => {
    const options =
      formValue?.resourceType === "tools"
        ? mapListToOptions(toolsList.resources, "name", "id")
        : mapListToOptions(trainingList.resources, "name", "id");
    setResourceValues(options);
  }, [formValue?.resourceType, toolsList, trainingList]);

  const getAssetsList = () => {
    dispatch(fetchAssetsListForSchedule({ schedule_id: scheduledId }));
  };

  const handleDelete = async row => {
    const payload = {
      course_schedule_id: parseFloat(scheduledId),
      resource_history_id: [parseFloat(row?.resource_history_id)],
    };

    await dispatch(deleteAssetsForSchedule({ assetPayload: payload }));
    getAssetsList();
    closeModal();
  };

  const handleConfirmDelete = row => {
    setModalInfo({
      isOpen: true,
      title: AreYouSureDelete,
      callback: () => handleDelete(row),
    });
  };

  const handleUpdate = row => {
    setAssetModal(prevState => ({
      ...row,
      isOpen: !prevState?.isOpen,
    }));
  };

  const toggleDropdown = (e, rowId) => {
    e.preventDefault();
    setDropdownOpen(prevDropdownOpen =>
      prevDropdownOpen === rowId ? null : rowId
    );
  };

  const validateForm = () => {
    const newErrors = {
      resourceType: "",
      resources: "",
      startDate: "",
      duration: "",
    };

    let isError = false;
    if (!formValue?.startDate) {
      newErrors.startDate = StartDateRequired;
      isError = true;
    }

    if (!formValue?.duration) {
      newErrors.duration = DurationIsRequired;
      isError = true;
    }

    if (!formValue?.resources) {
      newErrors.resources = ResourceRequired;
      isError = true;
    }

    if (!isEdit && !formValue?.resourceType) {
      newErrors.resourceType = ResourceTypeRequired;
      isError = true;
    }

    const isResourceExist =
      !isEdit &&
      assetsList?.resources?.some(
        resource =>
          resource?.resource_id?.toString() ===
            formValue?.resources?.toString() &&
          resource.usage_date === formatDate(formValue?.startDate)
      );

    if (isResourceExist) {
      showToast(ResourceAlreadyPresent, "error");
      isError = true;
    }

    setErrors(newErrors);
    return isError;
  };

  const handleRoleTooltip = (id: number) => {
    setNameTooltipOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleTypeTooltip = (id: number) => {
    setTypeTooltipOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const assetColumns = [
    {
      name: AssetName,
      selector: row => row?.name,
      cell: row => (
        <>
          <span
            id={`tooltip-role-${row.resource_history_id}`}
            className="text-truncate d-flex align-items-center"
          >
            {truncateText(row?.name, 10)}
          </span>
          <Tooltip
            placement="top"
            target={`tooltip-role-${row.resource_history_id}`}
            isOpen={nameTooltipOpen[row.resource_history_id] || false}
            toggle={() => handleRoleTooltip(row.resource_history_id)}
          >
            {row?.name}
          </Tooltip>
        </>
      ),
      minWidth: "12rem",
      sortable: true,
    },
    {
      name: Type,
      selector: row => row?.resource_type,
      cell: row => (
        <>
          <span
            id={`tooltip-id-${row.resource_history_id}`}
            className="text-truncate d-flex align-items-center"
          >
            {truncateText(row?.resource_type, 10)}
          </span>
          <Tooltip
            placement="top"
            target={`tooltip-id-${row.resource_history_id}`}
            isOpen={typeTooltipOpen[row.resource_history_id] || false}
            toggle={() => handleTypeTooltip(row.resource_history_id)}
          >
            {row?.resource_type}
          </Tooltip>
        </>
      ),
      minWidth: "12rem",
      sortable: true,
    },
    {
      name: Dates,
      selector: row => row?.usage_date,
      sortable: true,
      minWidth: "6rem",
    },
    {
      name: AssetID,
      selector: row => row?.asset_id,
      sortable: true,
    },
    {
      name: PartNumber,
      selector: row => row?.part_no,
      sortable: true,
    },

    {
      name: Duration,
      selector: row => row?.duration,
      sortable: true,
    },
    {
      name: Action,
      center: true,
      cell: row => (
        <>
          {hasPermissionToComponent("UPSERT_ASSET_TO_SCHEDULE") ? (
            <div>
              <Dropdown
                direction={"start"}
                isOpen={dropdownOpen === row.resource_history_id}
                toggle={e => toggleDropdown(e, row.resource_history_id)}
              >
                <DropdownToggle color="" className="three-dots">
                  <MUIIcons
                    iconName="MoreVertOutlined"
                    className="primary-icon-color"
                    size={17}
                  />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem onClick={() => handleUpdate(row)}>
                    {Update}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleConfirmDelete(row)}>
                    {Delete}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];

  const renderAssets = () => {
    if (loading) {
      return null;
    }

    return (
      <DataTable
        columns={assetColumns}
        data={assetsList?.resources}
        pagination
        fixedHeader
        striped
      />
    );
  };

  const handleChange = e => {
    setFormValue(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const closeModal = () => {
    setModalInfo(null);
  };

  const getDateAndDuration = () => {
    return (
      <>
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={Dates}
              tooltipText={`${Enter} ${Dates}`}
              important={true}
            />
            <div className={"w-100"}>
              <DatePicker
                onChange={(date: Date | null) =>
                  handleChange({
                    target: {
                      name: "startDate",
                      value: date,
                    },
                  })
                }
                selected={formValue?.startDate}
                minDate={new Date(startDate)}
                maxDate={new Date(endDate)}
                placeholderText={`${Select} ${Dates}`}
                className={`form-control ${errors?.startDate ? "is-invalid" : ""}`}
                dateFormat="dd-MM-yyyy"
                disabledKeyboardNavigation={true}
                onKeyDown={e => e.preventDefault()}
              />

              {errors?.startDate && (
                <div className="invalid-date-picker-feedback">
                  {errors?.startDate}
                </div>
              )}
            </div>
          </FormGroup>
        </Col>

        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              tooltipText={`${Enter} ${Duration}`}
              label={Duration}
              important
            />
            <Input
              onChange={e => {
                const value = e.target.value;
                if (value.length <= 4) {
                  handleChange(e);
                }
              }}
              value={formValue?.duration}
              name="duration"
              type="number"
              placeholder={`${Enter} ${Duration}`}
              invalid={!!errors?.duration}
              onKeyDown={handleKeyDownOnNumericWithoutDecimal}
              min={1}
            />
            {errors?.duration && (
              <div className="invalid-feedback">{errors?.duration}</div>
            )}
          </FormGroup>
        </Col>
      </>
    );
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      return;
    }

    const payload = {
      course_schedule_id: parseFloat(scheduledId),
      resource_id: parseFloat(formValue?.resources),
      usage_date: formatDate(formValue?.startDate),
      duration: parseFloat(formValue?.duration),
    };

    if (assetModal?.resource_history_id) {
      payload["resource_history_id"] = assetModal?.resource_history_id;
    }

    await dispatch(postAssetsForSchedule({ assetPayload: payload }));
    getAssetsList();
    setAssetModal(null);
  };

  const handleResourceChange = e => {
    setFormValue(prevState => ({
      ...prevState,
      resourceType: e.target.value,
      resources: null,
    }));

    if (e.target.value) {
      if (e.target.value === "tools") {
        dispatch(getToolsList());
      } else {
        dispatch(getTrainingAidList());
      }
    }
  };

  const isEdit = assetModal?.resource_history_id;

  return (
    <div className="assests mt-4">
      <Row>
        <Col xl="12">{renderAssets()}</Col>
      </Row>

      <Modal
        isOpen={assetModal?.isOpen}
        toggle={() => setAssetModal(null)}
        className="modal-body"
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={() => setAssetModal(null)}>
          <H4>
            {!isEdit ? Add : Update} {`${Asset}`}
          </H4>
        </ModalHeader>
        <ModalBody>
          {isEdit && (
            <Form className="g-3 custom-input" noValidate>
              <H5 className="mb-2">{assetModal?.name}</H5>
              <Row>{getDateAndDuration()}</Row>
            </Form>
          )}
          {!isEdit && (
            <Form className="g-3 custom-input" noValidate>
              <Row>
                <Col sm="12" lg="6">
                  <LabelTooltip
                    label={ResourceType}
                    tooltipText={`${Select} ${ResourceType}`}
                    important={true}
                    placement={"bottom"}
                  />
                  <Input
                    type="select"
                    value={formValue?.resourceType}
                    onChange={handleResourceChange}
                    name="resourceType"
                    invalid={!!errors?.resourceType}
                  >
                    {assetTypeOptions.map(element => (
                      <option key={element.value} value={element.value}>
                        {element.label}
                      </option>
                    ))}
                  </Input>
                  {errors?.resourceType && (
                    <div className="invalid-feedback">
                      {errors?.resourceType}
                    </div>
                  )}
                </Col>
                {getDateAndDuration()}
                <Col sm="12" lg="6">
                  <LabelTooltip
                    label={Resource}
                    tooltipText={`${Select} ${Resource}`}
                    important={true}
                    placement={"bottom"}
                  />
                  {/* FIXME */}
                  <Input
                    type="select"
                    value={formValue?.resources}
                    onChange={handleChange}
                    name="resources"
                    invalid={!!errors?.resources}
                  >
                    {resourceValues?.map(element => (
                      <option key={element.value} value={element.value}>
                        {element.label}
                      </option>
                    ))}
                  </Input>
                  {errors?.resources && (
                    <div className="invalid-feedback">{errors?.resources}</div>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="d-flex">
            <SecondaryButton
              className="secondary-button"
              onClick={() => setAssetModal(null)}
            >
              {Cancel}
            </SecondaryButton>
            <DefaultButton color="primary" onClick={handleSubmit}>
              {isEdit ? Update : Submit}
            </DefaultButton>
          </div>
        </ModalFooter>
      </Modal>

      <CommonModal
        sizeTitle={Confirmation}
        modalBodyClassName=""
        isOpen={modalInfo?.isOpen}
        toggle={closeModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={modalInfo?.callback}
        primaryBtnText={Confirm}
      >
        {modalInfo?.title}
      </CommonModal>
    </div>
  );
};

Assets.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  scheduledId: PropTypes.any,
  assetModal: PropTypes.any,
  setAssetModal: PropTypes.any,
};

export default Assets;
