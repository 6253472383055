import React from "react";

import { H5, P } from "../../AbstractElements";

interface TodayTasksBodyProps {
  assign: string;
  name: string;
  link?: boolean;
}

const DetailHeader: React.FC<TodayTasksBodyProps> = ({
  assign,
  name,
  link,
}) => {
  return (
    <div className="flex-grow-1">
      <P className="mb-0 text-muted">{assign}</P>
      <H5 className="fw-bold mt-3">
        {link ? (
          <a
            href={name}
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none"
          >
            {name}
          </a>
        ) : (
          name
        )}
      </H5>
    </div>
  );
};

export default DetailHeader;
