import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H2, H3, SVG } from "../../AbstractElements";
import "./style.scss";

interface TotalCountCardProps {
  data: TotalCountData[];
  onIconClick?: any;
  activeStatus?: string;
  showColor?: boolean;
}

interface TotalCountData {
  count: number;
  title: string;
  icon: string;
  sideTitle?: string;
  id?: string;
}

const TotalCountCard: React.FC<TotalCountCardProps> = ({
  data,
  onIconClick,
  activeStatus,
  showColor = false,
}) => {
  return (
    <Row>
      {data.map((item, index) => (
        <Col
          key={index}
          onClick={() => {
            if (item?.id) onIconClick(item?.id);
          }}
          className={`${item?.id ? "pointer " : ""}`}
        >
          <Card
            className={`mb-3 ${activeStatus === item?.id && showColor ? "primary-bg-color" : ""}`}
          >
            <CardBody className="d-flex justify-content-between align-items-center total-card-body">
              <div className="d-flex flex-column gap-4">
                <H2 className="fw-bold">{item.count}</H2>
                <div className="d-flex flex-row gap-1 asset-header-content-wrapper">
                  <H3 className="asset-content-title">{item.title}</H3>
                  {item.sideTitle && (
                    <span className="fw-light asset-content-side-title">
                      {item.sideTitle}
                    </span>
                  )}
                </div>
              </div>
              <SVG iconId={item.icon} className="total-count-icon-circle" />
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default TotalCountCard;
