import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { Image, P } from "../../../../AbstractElements";
import { useAppDispatch } from "../../../../ReduxToolkit/Hooks";
import { getLoggedInUserDetails } from "../../../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../../../Service";
import { roleMapping } from "../../../../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
  saveToLocalStorage,
} from "../../../../utils/helper/localStorageutils";

import ProfileBox from "./ProfileBox";

import "./style.scss";

interface UserProfileProps {
  isHideLogoutBtn?: boolean;
}

const UserProfile: React.FC<UserProfileProps> = ({ isHideLogoutBtn }) => {
  const dispatch = useAppDispatch();
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const loginData = getFromLocalStorage(LOGGED_IN_USER);
    const storedUserName = loginData.name;

    const storedUserRole =
      roleMapping?.[loginData?.currentRole]?.value || loginData?.currentRole;

    if (storedUserName && storedUserRole) {
      setUserName(storedUserName);
      setUserRole(storedUserRole);
    }
  }, []);

  const handleToggle = async () => {
    if (isHideLogoutBtn) {
      return;
    }
    if (!dropdownOpen) {
      const loginData = getFromLocalStorage(LOGGED_IN_USER);
      const response = await dispatch(
        getLoggedInUserDetails({ id: loginData?.id })
      );
      if (response?.payload?.users?.length > 0) {
        loginData.mappedRoles = response.payload.users[0].mapped_lms_roles.map(
          item => item.role_name
        );
        saveToLocalStorage(LOGGED_IN_USER, loginData);
      }
    }
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div>
      <Dropdown isOpen={dropdownOpen} toggle={handleToggle}>
        <DropdownToggle
          tag="div"
          className="d-flex profile-media align-items-center gap-4 pointer"
          id="Popover"
        >
          <Image
            className="img-30"
            src={dynamicImage("dashboard/profile.png")}
            alt="user"
          />
          <div className="flex-grow-1">
            <span className="text-white">{userName}</span>
            <P className="mb-0 font-outfit text-white">{userRole}</P>
          </div>
        </DropdownToggle>
        <DropdownMenu className="custom-dropdown-menu  custom-dropdown-profile">
          <ProfileBox />
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

UserProfile.propTypes = {
  isHideLogoutBtn: PropTypes.bool,
};

export default UserProfile;
