import React, { useState } from "react";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import { Row, Col, FormGroup, Input } from "reactstrap";

import { Apply, ClearAll, Enter, Filter, Select } from "../../utils/Constant";
import {
  customStyles,
  handleKeyDownOnNumericWithoutDecimal,
} from "../../utils/helper/helper";
import DefaultButton from "../DefaultButton";
import Divider from "../Divider";
import FixedFooter from "../FixedFooter";
import H2 from "../Headings/H2Element";
import LabelTooltip from "../LabelTooltip";
import MUIIcons from "../MUIIcon/MUIIcons";
import MultiSelectDropdown from "../MultiSelectDropdown";
import SearchDropDownPaginator from "../SearchDropDownPaginator";
import SecondaryButton from "../SecondaryButton";
import SliderModal from "../SliderModal";
import CheckboxButton from "../SwitchButton";

interface DropdownOption {
  name?: string;
  value: string;
  label: string;
}

interface FilterSliderModalProps {
  dropdowns: {
    label: string;
    tooltipText: string;
    options?: DropdownOption[];
    isMultiSelect: boolean;
    key: string;
    defaultSelected?: string[];
    isCheckbox?: boolean;
    isDate?: boolean;
    additionalFields?: { label: string; key: string; type: string }[];
  }[];
  onDone: (values: { [key: string]: string[] }) => void;
  onClear: (values?: { [key: string]: string[] }) => void;
  defaultFilterValues: {};
  selectedFilterValues: {};
  setSelectedFilterValues: (val: {}) => void;
  setDefaultFilterValues: (val: {}) => void;
  isGrid?: boolean;
  globalSettings?: boolean;
}

const FilterSliderModal: React.FC<FilterSliderModalProps> = ({
  dropdowns,
  onDone,
  onClear,
  defaultFilterValues,
  selectedFilterValues,
  setSelectedFilterValues,
  setDefaultFilterValues,
  isGrid = false,
  globalSettings = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [defaultFilterValuesCopy, setDefaultFilterValuesCopy] = useState({});
  const [selectedFilterValuesCopy, setSelectedFilterValuesCopy] = useState({});

  const toggle = () => setIsOpen(!isOpen);

  const handleSelectChange = (key: string, values: string[]) => {
    setSelectedFilterValues(prev => ({ ...prev, [key]: values }));
    const dropdownItem = dropdowns.find(item => item.key === key);
    if (dropdownItem?.options) {
      const defaultValues = dropdownItem.options
        .filter(option => values.includes(option.name))
        .map(option => ({
          label: option.label,
          value: option.value,
          name: option.name,
        }));

      setDefaultFilterValues(prev => ({
        ...prev,
        [key]: defaultValues,
      }));
    }
  };

  const handleDone = () => {
    setDefaultFilterValuesCopy(defaultFilterValues);
    setSelectedFilterValuesCopy(selectedFilterValues);
    onDone(selectedFilterValues);
    toggle();
  };

  const handleClear = () => {
    setDefaultFilterValuesCopy({});
    setSelectedFilterValuesCopy({});
    setSelectedFilterValues({});
    onClear(selectedFilterValues);
  };

  const renderDropdownInput = dropdown => {
    if (dropdown.isDate) {
      return (
        <div className="d-flex gap-2">
          <DatePicker
            selected={
              selectedFilterValues[dropdown.key]?.[0]
                ? new Date(selectedFilterValues[dropdown.key][0])
                : null
            }
            onChange={(date: Date | null) =>
              handleSelectChange(
                dropdown.key,
                date ? [date.toLocaleDateString("en-CA")] : []
              )
            }
            placeholderText={dropdown.tooltipText}
            className={"form-control"}
            dateFormat="yyyy-MM-dd"
            disabledKeyboardNavigation={true}
            onKeyDown={e => e.preventDefault()}
          />
        </div>
      );
    } else if (dropdown.isCheckbox) {
      return (
        <div className="mt-3">
          <div className="d-flex gap-2">
            <p className="fw-bold">
              <LabelTooltip
                label={dropdown.label}
                tooltipText={dropdown.tooltipText}
                important={false}
                placement={"bottom"}
              />
            </p>
            <div className="mt-1">
              <CheckboxButton
                onChange={e => {
                  setSelectedFilterValues(prev => ({
                    ...prev,
                    [dropdown.key]: e.target.checked,
                  }));
                }}
                checked={selectedFilterValues[dropdown.key] || false}
              />
            </div>
          </div>
          {selectedFilterValues[dropdown.key] &&
            dropdown?.additionalFields?.map(field => (
              <FormGroup key={field.key} className="mt-2">
                <LabelTooltip
                  label={field.label}
                  tooltipText={`${Enter} ${field.label}`}
                  important={false}
                />
                <Input
                  type={field.type}
                  min={0}
                  max={100}
                  onKeyDown={
                    field.type === "number" &&
                    handleKeyDownOnNumericWithoutDecimal
                  }
                  onChange={e =>
                    setSelectedFilterValues(prev => ({
                      ...prev,
                      [field.key]: e.target.value,
                    }))
                  }
                  value={selectedFilterValues[field.key] || ""}
                />
              </FormGroup>
            ))}
        </div>
      );
    } else if (dropdown.isMultiSelect) {
      return (
        <MultiSelectDropdown
          onChange={values => handleSelectChange(dropdown.key, values)}
          options={dropdown.options}
          placeholder={dropdown.tooltipText}
          defaultSelected={defaultFilterValues[dropdown.key] || []}
        />
      );
    } else {
      return (
        <div>
          <ReactSelect
            name={dropdown.key}
            className="text-gray"
            onChange={selectedOption =>
              handleSelectChange(
                dropdown.key,
                selectedOption ? [selectedOption.value] : []
              )
            }
            value={
              selectedFilterValues[dropdown.key]?.[0]
                ? {
                    value: selectedFilterValues[dropdown.key][0],
                    label: dropdown.options?.find(
                      option =>
                        option.value === selectedFilterValues[dropdown.key][0]
                    )?.label,
                  }
                : null
            }
            options={dropdown.options?.map(option => ({
              value: option.value,
              label: option.label,
            }))}
            isSearchable
            isClearable={false}
            placeholder={`${Select} ${dropdown.label}`}
            styles={customStyles}
            components={{
              MenuList: props => (
                <SearchDropDownPaginator {...props} maxHeight={250} />
              ),
            }}
            filterOption={(option, inputValue) =>
              option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
            }
          />
        </div>
      );
    }
  };

  return (
    <>
      <MUIIcons
        className="pointer primary-icon-color"
        onClick={toggle}
        iconName="FilterAltOutlined"
        size={24}
      />
      <SliderModal
        isOpen={isOpen}
        toggle={() => {
          toggle();
          if (!globalSettings) {
            setDefaultFilterValues(defaultFilterValuesCopy);
            setSelectedFilterValues(selectedFilterValuesCopy);
          }
        }}
      >
        <H2>{Filter}</H2>
        <Divider />
        <div className="modal-body">
          <Row className="mt-3">
            {dropdowns.map(dropdown => (
              <Col key={dropdown.key} lg={isGrid ? 6 : 12}>
                <FormGroup>
                  {!dropdown.isCheckbox && (
                    <LabelTooltip
                      label={dropdown.label}
                      tooltipText={dropdown.tooltipText}
                      important={false}
                    />
                  )}
                  {renderDropdownInput(dropdown)}
                </FormGroup>
              </Col>
            ))}
          </Row>
        </div>
        <div>
          <FixedFooter>
            <SecondaryButton onClick={handleClear}>{ClearAll}</SecondaryButton>
            <DefaultButton color="primary" onClick={handleDone}>
              {Apply}
            </DefaultButton>
          </FixedFooter>
        </div>
      </SliderModal>
    </>
  );
};

export default FilterSliderModal;
