import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";

import {
  AddReason,
  AssetReasonRequired,
  Enter,
  NeedByDate,
  PartNumber,
  Quantity,
  Reason,
  ReplacementReason,
  Select,
  Submit,
  UrgencyLevel,
} from "../../utils/Constant";
import {
  handleKeyDownOnNumericWithoutDecimal,
  ReasonOptions,
  showToast,
  UrgencyLevelOptions,
} from "../../utils/helper/helper";
import LabelTooltip from "../LabelTooltip";
import CommonModal from "../Modal";

interface ReasonAssetModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSubmit: (reason: any) => void;
  setFormStateData: any;
  selectedStatus?: any;
}

const ReasonAssetModal: React.FC<ReasonAssetModalProps> = ({
  isOpen,
  toggle,
  onSubmit,
  setFormStateData,
  selectedStatus,
}) => {
  const [formState, setFormState] = useState({
    reason: null,
    otherReason: "",
    replacement_part_no: "",
    quantity: null,
    need_by_date: null,
    urgency_level: "",
  });

  const handleInputChange = (fieldName, value) => {
    if (
      fieldName === "quantity" &&
      value &&
      Number(value) &&
      Number(value) > 100
    ) {
      return;
    }
    setFormStateData(prev => ({ ...prev, [fieldName]: value }));
    setFormState(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    const { reason, otherReason } = formState;

    const selectedReason =
      reason?.value === "other_specify" ? otherReason : reason;
    if (!selectedReason || selectedReason === "") {
      showToast(AssetReasonRequired, "error");
      return;
    }

    onSubmit(selectedReason);
    toggle();
  };

  const onToggle = () => {
    toggle();
  };

  useEffect(() => {
    if (selectedStatus?.status === "replaced") {
      setFormState(prevState => ({
        ...prevState,
        replacement_part_no: selectedStatus?.replacement_part_no || "",
        quantity: selectedStatus?.quantity || null,
        need_by_date: selectedStatus?.need_by_date
          ? new Date(selectedStatus?.need_by_date)
          : null,
        urgency_level: selectedStatus?.urgency_level || "",
      }));
    }
  }, [selectedStatus]);

  const getReasonAssetModalBody = () => (
    <>
      {selectedStatus?.status === "replaced" && (
        <>
          <Row>
            <Col>
              <LabelTooltip
                label={PartNumber}
                tooltipText={`${Enter} ${PartNumber}`}
                important={false}
              />
              <Input
                type="text"
                name="replacement_part_no"
                placeholder={`${Enter} ${PartNumber}`}
                value={formState.replacement_part_no}
                onChange={e =>
                  handleInputChange("replacement_part_no", e.target.value)
                }
                maxLength={20}
              />
            </Col>
            <Col>
              <LabelTooltip
                label={Quantity}
                tooltipText={`${Enter} ${Quantity}`}
                important={false}
              />
              <Input
                onChange={e => handleInputChange("quantity", e.target.value)}
                value={formState.quantity}
                name="quantity"
                onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                type="number"
                placeholder={`${Enter} ${Quantity}`}
                min={0}
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <LabelTooltip
                label={NeedByDate}
                important={false}
                tooltipText={`${Select} ${NeedByDate}`}
              />
              <DatePicker
                selected={formState.need_by_date || undefined}
                onChange={(date: Date | null) =>
                  handleInputChange("need_by_date", date)
                }
                minDate={new Date()}
                placeholderText={`${Select} ${NeedByDate}`}
                dateFormat="dd-MM-yyyy"
                className={"w-100 form-control"}
                disabledKeyboardNavigation={true}
                onKeyDown={e => e.preventDefault()}
              />
            </Col>
            <Col>
              <LabelTooltip
                label={UrgencyLevel}
                tooltipText={`${Select} ${UrgencyLevel}`}
                placement={"top"}
                important={false}
              />
              <Input
                type="select"
                id="reason-dropdown"
                value={formState.urgency_level}
                onChange={e => {
                  const selectedValue = e.target.value;
                  const selectedOption = UrgencyLevelOptions.find(
                    option => option.value === selectedValue
                  );
                  handleInputChange("urgency_level", selectedOption?.value);
                }}
              >
                <option value="" disabled>
                  {`${Select} ${UrgencyLevel}`}
                </option>
                {UrgencyLevelOptions?.map(level => (
                  <option key={level.value} value={level.value}>
                    {level.label}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
        </>
      )}
      <Form className={selectedStatus?.status === "replaced" ? "mt-2" : ""}>
        <FormGroup>
          <LabelTooltip
            label={Reason}
            tooltipText={`${Select} ${Reason}`}
            placement={"top"}
            important
          />
          <Input
            type="select"
            id="reason-dropdown"
            value={formState?.reason || ""}
            onChange={e => {
              const selectedValue = e.target.value;
              const selectedOption = ReasonOptions.find(
                option => option.value === selectedValue
              );
              handleInputChange("reason", selectedOption?.value);
            }}
          >
            <option value="" disabled>
              {`${Select} ${Reason}`}
            </option>
            {ReasonOptions?.map(reason => (
              <option key={reason.value} value={reason.value}>
                {reason.label}
              </option>
            ))}
          </Input>
        </FormGroup>
        {formState.reason === "other_specify" && (
          <FormGroup>
            <LabelTooltip
              label={AddReason}
              tooltipText={`${Enter} ${Reason}`}
              placement={"top"}
              important
            />
            <Input
              type="textarea"
              name="invoiceNumber"
              placeholder={`${Enter} ${Reason}`}
              value={formState.otherReason}
              onChange={e => {
                if (
                  (e.target.value && e.target.value?.length <= 255) ||
                  e.target.value === ""
                )
                  handleInputChange("otherReason", e.target.value);
              }}
            />
          </FormGroup>
        )}
      </Form>
    </>
  );

  return (
    <CommonModal
      backdrop="static"
      size="lg"
      isOpen={isOpen}
      toggle={onToggle}
      sizeTitle={ReplacementReason}
      showFooter={true}
      primaryBtnText={Submit}
      onPrimaryBtnClick={handleSubmit}
    >
      {getReasonAssetModalBody()}
    </CommonModal>
  );
};

ReasonAssetModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setFormStateData: PropTypes.func.isRequired,
  selectedStatus: PropTypes.shape({
    status: PropTypes.string,
    replacement_part_no: PropTypes.string,
    quantity: PropTypes.number,
    need_by_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    urgency_level: PropTypes.string,
  }),
};

export default ReasonAssetModal;
