import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";

import { LMSRole, Select, SwitchRoleInformation } from "../../utils/Constant";
import { roleMapping, sanitizedContent } from "../../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../utils/helper/localStorageutils";
import LabelTooltip from "../LabelTooltip";

import "./style.scss";

interface SwitchModalContentProps {
  type: boolean;
  selectedRole: string;
  setSelectedRole: (value: string) => void;
  errors: {
    selectedRole?: string;
  };
}

const transformArray = (inputArray: string[]) => {
  const roles = [];
  inputArray?.forEach(label => {
    const roleDetails = roleMapping?.[label];
    if (roleDetails) {
      roles.push({
        id: label,
        label: roleMapping[label]?.value,
        value: roleMapping[label]?.key,
      });
    }
  });
  return roles;
};

const SwitchModalContent: React.FC<SwitchModalContentProps> = ({
  type,
  selectedRole,
  setSelectedRole,
  errors,
}) => {
  const [roleOptions, setRoleOptions] = useState<
    { id: string; label: string; value: string }[]
  >([]);

  useEffect(() => {
    const loginData = getFromLocalStorage(LOGGED_IN_USER);
    if (loginData?.mappedRoles) {
      const availableRolesToSwitch = loginData.mappedRoles.filter(
        item => item !== loginData?.currentRole
      );
      setRoleOptions(transformArray(availableRolesToSwitch));
    }
  }, []);

  return (
    <>
      {!type && (
        <FormGroup>
          <LabelTooltip
            label={`${Select} ${LMSRole}`}
            tooltipText={`${Select} ${LMSRole}`}
            important
            placement="bottom"
          />
          <Input
            type="select"
            id="dropdown"
            value={selectedRole}
            onChange={e => {
              const value = e.target.value;
              setSelectedRole(value);
            }}
          >
            <option value="">{`${Select} ${LMSRole}`}</option>
            {roleOptions.map(option => (
              <option key={option.id} value={option.id}>
                {option.label}
              </option>
            ))}
          </Input>
          {errors?.selectedRole && (
            <div className="text-danger">{errors.selectedRole}</div>
          )}
        </FormGroup>
      )}
      <div
        className="sanitized-content"
        dangerouslySetInnerHTML={{
          __html: sanitizedContent(SwitchRoleInformation),
        }}
      />
    </>
  );
};

SwitchModalContent.propTypes = {
  type: PropTypes.bool.isRequired,
  selectedRole: PropTypes.string.isRequired,
  setSelectedRole: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    selectedRole: PropTypes.string,
  }),
};

export default SwitchModalContent;
