import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { Btn, MUIIcons } from "../../../../AbstractElements";
import JustifyTabs from "../../../../CommonElements/JustifyTabs";
import SessionList from "../../../../container/SessionList";
import UserProfileCard from "../../../../container/UserProfileCard";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getAllMentorSessionListUrl } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import { getUserDetailsList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../../../Service";
import {
  Add,
  Contact,
  CWSAndCatRecId,
  Grade,
  Location,
  Mail,
  RMContactNumber,
  RMEmail,
  RMName,
  SBU,
  UpcomingSessionErrorToastMessage,
} from "../../../../utils/Constant";
import { MentorDetailsTabs, showToast } from "../../../../utils/helper/helper";
import { UserProfileInformationDetails } from "../../../../utils/helper/propTypes";
import MenteesList from "../MenteesList";

const MentorDetails = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("status");
  const { getAllMentorSessionList } = useAppSelector(state => state.learner);
  const { userDetailsList } = useAppSelector(state => state.user);
  const [userProfileData, setUserProfileData] = useState(null);
  const [activeTab, setActiveTab] = useState<string>(statusParam || "mentees");
  const { mentorId, mentorDomainId, domain } = state || {};
  const [borderActiveTab, setBorderActiveTab] = useState("1");
  const [filteredSessionData, setFilteredSessionData] = useState([]);

  useEffect(() => {
    dispatch(getUserDetailsList({ id: [mentorId] }));
  }, []);

  useEffect(() => {
    const mentorDetails = userDetailsList?.users?.find(
      user => user.id === mentorId
    );
    if (mentorDetails) {
      const mapUserToProfileDetails = (
        user: any
      ): UserProfileInformationDetails => ({
        userProfileUrl: dynamicImage("dashboard-4/user.png"),
        name: user.user_name,
        designation: user.designation ? user.designation : "",
        employee_code: user.employee_code ? user.employee_code : "",
        userDetails: [
          {
            icon: "BadgeOutlined",
            title: CWSAndCatRecId,
            detail: user?.cws_cat_rec ? user?.cws_cat_rec : "-",
          },
          {
            icon: "SchoolOutlined",
            title: Grade,
            detail: user?.grade ? user?.grade : "-",
          },
          {
            icon: "PinOutlined",
            title: SBU,
            detail: user?.sbu ? user?.sbu : "-",
          },
          {
            icon: "PersonOutline",
            title: RMName,
            detail: user.mapped_rm?.length > 0 ? user.mapped_rm[0].name : "-",
          },
          {
            icon: "PhoneOutlined",
            title: Contact,
            detail: user?.phone ? user?.phone : "-",
          },
          {
            icon: "EmailOutlined",
            title: Mail,
            detail: user.email ? user.email : "-",
          },
          {
            icon: "EmailOutlined",
            title: RMEmail,
            detail:
              user.mapped_rm?.length > 0 && user.mapped_rm[0].email
                ? user.mapped_rm[0].email
                : "-",
          },
          {
            icon: "PhoneOutlined",
            title: RMContactNumber,
            detail: user?.mapped_rm?.[0]?.rm_phone || "-",
          },
          {
            icon: "LocationOnOutlined",
            title: Location,
            detail:
              [user?.office_city || "", user?.office_state || ""]
                .filter(Boolean)
                .join(", ") || "-",
          },
        ],
      });
      setUserProfileData(mapUserToProfileDetails(mentorDetails));
    }
  }, [userDetailsList]);

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  useEffect(() => {
    if (mentorDomainId && activeTab === "session") {
      dispatch(
        getAllMentorSessionListUrl({
          mentorDomainId,
        })
      );
    }
  }, [dispatch, mentorDomainId, activeTab]);

  useEffect(() => {
    const menteeIds =
      getAllMentorSessionList?.session_list?.map(item => item.mentee_id) || [];
    if (menteeIds.length > 0) {
      dispatch(getUserDetailsList({ id: menteeIds }));
    }
  }, [dispatch, getAllMentorSessionList]);

  useEffect(() => {
    const mappedData =
      getAllMentorSessionList?.session_list?.map(session => {
        const menteeDetails = userDetailsList?.users?.find(
          user => user.id === session.mentee_id
        );

        return {
          sessionId: session.session_id,
          topic: session.topic,
          duration: session.duration,
          sessionDate: session.start_date,
          status: session.status,
          name: menteeDetails?.user_name || "-",
          empId: menteeDetails?.employee_code || "-",
          mobile: menteeDetails?.phone || "-",
        };
      }) || [];

    setFilteredSessionData(mappedData);
  }, [getAllMentorSessionList, userDetailsList]);

  const handleTabClick = tabId => {
    setActiveTab(tabId);
  };

  const handleBorderTabClick = tabId => {
    setBorderActiveTab(tabId);
  };

  const handleSearch = (searchTerm: string) => {
    const trimmedSearchTerm = searchTerm.trim().toLowerCase();

    const mappedSessions =
      getAllMentorSessionList?.session_list?.map(session => {
        const menteeDetails = userDetailsList?.users?.find(
          user => user.id === session.mentee_id
        );

        return {
          mobile: menteeDetails?.phone || "-",
          name: menteeDetails?.user_name || "-",
          topic: session.topic,
          duration: session.duration,
          sessionDate: session.start_date,
          status: session.status,
          sessionId: session.session_id,
        };
      }) || [];

    const filteredData =
      trimmedSearchTerm === ""
        ? mappedSessions
        : mappedSessions.filter(
            session =>
              session.topic?.toLowerCase().includes(trimmedSearchTerm) ||
              session.name?.toLowerCase().includes(trimmedSearchTerm) ||
              session.mobile?.toLowerCase().includes(trimmedSearchTerm)
          );

    setFilteredSessionData(filteredData);
  };

  const onSessionRowClick = row => {
    if (row.status === "completed") {
      navigate(`${process.env.PUBLIC_URL}/mentoring/session-details`, {
        state: { sessionId: row.sessionId },
      });
    } else if (row.status === "upcoming") {
      showToast(UpcomingSessionErrorToastMessage, "error");
    }
  };

  return (
    <div className="page-body page-body-margin">
      <UserProfileCard userData={userProfileData || {}} />
      <Card className="p-1">
        <CardBody>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={MentorDetailsTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
            {activeTab === "mentees" && borderActiveTab === "1" && (
              <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-4 ms-auto">
                <Link
                  to={"/mentoring/map-mentor-mentee"}
                  state={{ mentorId, mentorDomainId, domain }}
                >
                  <Btn
                    icon={
                      <MUIIcons size={16} iconName="AddCircleOutlineOutlined" />
                    }
                    color="primary"
                  >
                    {Add}
                  </Btn>
                </Link>
              </div>
            )}
          </div>
        </CardBody>
      </Card>

      {activeTab === "mentees" && (
        <MenteesList
          onActiveTabChange={handleBorderTabClick}
          mentorDomainId={mentorDomainId}
        />
      )}

      {activeTab === "session" && (
        <SessionList
          handleSearch={handleSearch}
          showMentee
          data={filteredSessionData}
          onRowClick={onSessionRowClick}
        />
      )}
    </div>
  );
};

export default MentorDetails;
