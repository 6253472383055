import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Forbidden from "../CommonElements/Forbidden";
import Layout from "../Layout/Layout";
import { scrollToTop } from "../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
  removeFromLocalStorage,
  SAVE_LOCATION_STATE,
  saveToLocalStorage,
} from "../utils/helper/localStorageutils";
import { pathPrevilages } from "../utils/helper/permission";

import routes from "./Route";

const LayoutRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const loginData = getFromLocalStorage(LOGGED_IN_USER);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (location.state) {
        saveToLocalStorage(SAVE_LOCATION_STATE, JSON.stringify(location.state));
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location.state]);

  useEffect(() => {
    scrollToTop();
    if (!location.state) {
      const savedState = getFromLocalStorage(SAVE_LOCATION_STATE);
      if (savedState) {
        const parsedState = JSON.parse(savedState);
        const { pathname, search } = location;

        const pathWithSearch = search ? `${pathname}${search}` : pathname;

        navigate(pathWithSearch, { state: parsedState });
        removeFromLocalStorage(SAVE_LOCATION_STATE);
      }
    }
  }, [location, navigate]);

  const hasAccess = routeAccess => {
    return pathPrevilages[loginData?.role]?.[routeAccess];
  };

  return (
    <Routes>
      {routes?.map(({ path, Component, key }) => {
        return (
          <Route element={<Layout />} key={path}>
            <Route
              index
              path={path}
              element={hasAccess(key) === false ? <Forbidden /> : Component}
            />
          </Route>
        );
      })}
    </Routes>
  );
};

export default LayoutRoutes;
