import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Row } from "reactstrap";

import { DefaultButton, Image, P } from "../../AbstractElements";
import LabelTooltip from "../../CommonElements/LabelTooltip";
import Loader from "../../Layout/Loader/Loader";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import {
  getSSOLoginUrl,
  loginExternalUser,
} from "../../ReduxToolkit/Reducers/AuthenticationSlice";
import { setSideBarMenuItems } from "../../ReduxToolkit/Reducers/LayoutSlice";
import { getEncryptionToken } from "../../ReduxToolkit/Reducers/UserManagement";
import { dynamicImage } from "../../Service";
import {
  CreateAccount,
  DoNotAccount,
  UserName,
  ForgotPassword,
  Password,
  LoginText,
  EnterUsername,
  EnterPassword,
  EnterEmailError,
  EnterProperEmailError,
  PleaseEnterPassword,
  SingInWithMicrosoft,
  LoginWith,
} from "../../utils/Constant";
import {
  EMAIL_REGEX,
  encryptPayload,
  roleMapping,
  showToast,
} from "../../utils/helper/helper";
import {
  LOGGED_IN_USER,
  saveToLocalStorage,
} from "../../utils/helper/localStorageutils";
import useIsMobile from "../../utils/helper/responsive";
import "./style.scss";
import {
  getFromSessionStorage,
  REDIRECT_URL,
  removeFromSessionStorage,
} from "../../utils/helper/sessionStorageUtils";

const Login = () => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginToken, setLoginToken] = useState(null);
  const navigate = useNavigate();

  const { loading } = useAppSelector(state => state.authentication);

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    const recievedToken = await dispatch(getEncryptionToken());
    if (recievedToken?.payload?.key) {
      setLoginToken(recievedToken?.payload?.key);
    } else {
      setLoginToken(null);
    }
  };

  const SimpleLoginHandle = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!email || email === "") {
      showToast(EnterEmailError, "error");
      return;
    }
    const validated = EMAIL_REGEX?.test(email);
    if (!validated) {
      showToast(EnterProperEmailError, "error");
      return;
    }
    if (!password) {
      showToast(PleaseEnterPassword, "error");
      return;
    }

    const iv = CryptoJS.enc.Utf8.parse(loginToken?.slice(0, 16));
    const encryptedPayload = encryptPayload(password, loginToken, iv);
    const payload = {
      email: email,
      password: encryptedPayload,
    };
    const user: any = await dispatch(loginExternalUser({ data: payload }));
    if (
      user?.payload?.token?.access_token &&
      user?.payload?.token?.refresh_token
    ) {
      const userData = {
        id: user?.payload?.id,
        name: user?.payload?.user_name,
        role: roleMapping?.[user?.payload?.current_role]?.key,
        currentRole: user?.payload?.current_role,
        email: user?.payload?.email,
        accessToken: user?.payload?.token?.access_token,
        refreshToken: user?.payload?.token?.refresh_token,
        mappedRoles: user?.payload?.mapped_lms_roles,
        business_group: user?.payload?.business_group,
        office_city: user?.payload?.office_city,
        designation: user?.payload?.designation,
      };
      saveToLocalStorage(LOGGED_IN_USER, userData);
      dispatch(setSideBarMenuItems());
      const redirection = getFromSessionStorage(REDIRECT_URL);
      if (redirection) {
        removeFromSessionStorage(REDIRECT_URL);
        return navigate(redirection);
      }
      return navigate(`${process.env.PUBLIC_URL}/dashboard`);
    }
  };

  const handleMicrosoftLogin = async () => {
    const res = await dispatch(getSSOLoginUrl());
    if (res?.payload?.url) {
      const microsoftLoginUrl = res?.payload?.url;
      window.location.href = microsoftLoginUrl;
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Row className="min-vh-100">
        {!isMobile && (
          <Col className="bg-white d-flex align-items-center justify-content-center">
            <Image
              className="img-fluid for-light w-100 vh-100"
              src={dynamicImage("login/login_page.png")}
              alt="logo"
            />
          </Col>
        )}
        <Col lg="6" sm="12" xs="12" className="bg-white">
          <Container fluid className="p-0 min-vh-100 d-flex align-items-center">
            <Row className="m-0 w-100 justify-content-center">
              <Col>
                <div className="p-5">
                  <div className="mb-2 text-center">
                    <Image
                      className="img-fluid for-light"
                      src={dynamicImage("logo/login_logo.png")}
                      alt="loginpage"
                    />
                  </div>
                  <div className="login-main">
                    <DefaultButton
                      color=""
                      onClick={handleMicrosoftLogin}
                      className="microsoft-signin-button w-100 mt-5 mb-5"
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
                        alt="Microsoft logo"
                        className="microsoft-logo"
                        width={20}
                        height={20}
                      />
                      {SingInWithMicrosoft}
                    </DefaultButton>
                    <p className="text-center or-login-with">{LoginWith}</p>
                    <Form
                      className="theme-form"
                      onSubmit={e => SimpleLoginHandle(e)}
                    >
                      <FormGroup>
                        <LabelTooltip
                          important={false}
                          label={UserName}
                          tooltipText={EnterUsername}
                        />
                        <Input
                          placeholder={EnterUsername}
                          value={email}
                          name="email"
                          onChange={event => setEmail(event.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <LabelTooltip
                          important={false}
                          label={Password}
                          tooltipText={EnterPassword}
                        />
                        <div className="form-input position-relative mb-4">
                          <Input
                            type={show ? "text" : "password"}
                            placeholder={EnterPassword}
                            onChange={event => setPassword(event.target.value)}
                            value={password}
                            name="password"
                          />

                          <div className="show-hide">
                            <span
                              onClick={() => setShow(!show)}
                              className={!show ? "show" : ""}
                            />
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-0 form-sub-title">
                        <div className="text-end">
                          <Link to={`${process.env.PUBLIC_URL}/reset-password`}>
                            {ForgotPassword}
                          </Link>
                        </div>
                        <div className="mt-3">
                          <DefaultButton type="submit" color="primary" block>
                            {LoginText}
                          </DefaultButton>
                        </div>
                      </FormGroup>
                      <P className="mt-4 mb-0 d-none text-center">
                        {DoNotAccount}
                        <Link
                          className="ms-2"
                          to={`${process.env.PUBLIC_URL}/authentication/register_simple`}
                        >
                          {CreateAccount}
                        </Link>
                      </P>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default Login;
