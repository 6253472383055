import React, { useState } from "react";
import { Col, Row } from "reactstrap";

import { MUIIcons, H5, Image, P, Badges } from "../../AbstractElements";
import {
  Assistant,
  Confirm,
  DeleteUserHeader,
  DeleteUserMessage,
  EmployeeId,
  Main,
  Mobile,
  Owner,
  Replacement,
  RM,
  SBU,
} from "../../utils/Constant";
import { UserCardWithExtraDetailsProps } from "../../utils/helper/propTypes";
import CommonModal from "../Modal";

const UserCardDetails: React.FC<UserCardWithExtraDetailsProps> = ({
  id,
  imagePath,
  name,
  detail,
  showDelete = false,
  onDelete = () => {
    return;
  },
  showOwner = false,
  showMain = false,
  showAssistant = false,
  showReplacement = false,
  empId = "",
  phoneNumber = "",
  showOtherDetails = false,
  sbuName = "",
  rmName = "",
  rmPhone = "",
  showUsersAllDetails = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <CommonModal
        sizeTitle={DeleteUserHeader}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={async () => {
          onDelete(id);
          toggleModal();
        }}
        primaryBtnText={Confirm}
      >
        {DeleteUserMessage}
      </CommonModal>
      <Row>
        <Col lg={showUsersAllDetails ? "5" : ""}>
          <div className="d-flex gap-2 align-items-center">
            <Image
              className="img-30 rounded-circle"
              src={imagePath}
              alt="users"
            />
            <div className="d-flex flex-column justify-content-center flex-grow-1">
              <div className="d-flex gap-3">
                <H5 className={`mb-0 ${detail ? "fw-bold" : ""}`}>{name}</H5>
                {showOwner && <Badges status="draft">{Owner}</Badges>}
                {showMain && <Badges status="draft">{Main}</Badges>}
                {showAssistant && <Badges>{Assistant}</Badges>}
                {showReplacement && (
                  <Badges status="draft">{Replacement}</Badges>
                )}
              </div>
              {showOtherDetails && (
                <div className="text-muted">
                  {`${EmployeeId}: ${empId || "-"}`}
                  {" | "}
                  {`${Mobile}: ${phoneNumber || "-"}`}
                </div>
              )}
              {detail && <P className="mb-0">{detail}</P>}
            </div>
          </div>
        </Col>

        {showUsersAllDetails && (
          <Col>
            <div className="d-flex align-items-center me-3 mt-2 text-muted">
              <MUIIcons iconName="BadgeOutlined" size={20} />
              <span className="ms-2">
                {SBU}: {sbuName || "-"}
              </span>
            </div>
          </Col>
        )}

        {showUsersAllDetails && (
          <Col>
            <div className="d-flex align-items-center mt-2 text-muted">
              <MUIIcons iconName="PersonOutline" size={20} />
              <span className="ms-2">
                {RM} {rmName || "-"} | {rmPhone || "-"}
              </span>
            </div>
          </Col>
        )}

        {showDelete && (
          <Col lg="1">
            <MUIIcons
              iconName="DeleteOutlineOutlined"
              className="primary-icon-color pointer"
              size={24}
              onClick={toggleModal}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default UserCardDetails;
