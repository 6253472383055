import PropTypes from "prop-types";
import { useState } from "react";
import { Tooltip } from "reactstrap";

import { extractFileName } from "../../utils/helper/helper";
import MUIIcons from "../MUIIcon/MUIIcons";

import MediaPlayer from "./MediaPlayer";
import PreviewModal from "./PreviewModal";

import "./style.scss";

const sanitizeString = (str: string) => str?.replace(/[^a-zA-Z0-9]/g, "");

const FileHandler = ({ mediaUrl, showIcon = false, showPreview = false }) => {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState({});
  const uniqueId = sanitizeString(mediaUrl);

  const extractedFileName = extractFileName(mediaUrl);

  const fileExtension = extractedFileName.split(".").pop().toLowerCase();

  const handleClick = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (e) {
      e.preventDefault();
    }

    if (["pdf", "docx", "doc"].includes(fileExtension)) {
      window.location.href = mediaUrl;
    } else if (["mp4", "png", "jpg", "jpeg", "mp3"].includes(fileExtension)) {
      setIsPreviewModalOpen(true);
    }
  };

  const toggleTooltip = (id: string) => {
    setTooltipOpen(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const renderMedia = (mediaUrl: string) => {
    if (["png", "jpg", "jpeg"].includes(fileExtension)) {
      return <img className="img-fluid" src={mediaUrl} alt="Preview" />;
    } else if (fileExtension === "mp4") {
      return (
        <video controls>
          <source src={mediaUrl} type="video/mp4" />
        </video>
      );
    } else if (fileExtension === "mp3") {
      return <MediaPlayer src={mediaUrl} />;
    } else {
      return (
        <a href={mediaUrl} target="_blank" rel="noreferrer">
          {mediaUrl}
        </a>
      );
    }
  };

  return (
    <div className="file-handler">
      {!showIcon && !showPreview && (
        <div className="">
          <span
            className="m-1"
            id={sanitizeString(`tooltip-media-name${uniqueId}`)}
            onClick={handleClick}
          >
            {extractedFileName?.length > 60
              ? `${extractedFileName?.substring(0, 60)}...`
              : extractedFileName}
          </span>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen[uniqueId] || false}
            target={sanitizeString(`tooltip-media-name${uniqueId}`)}
            toggle={() => toggleTooltip(uniqueId)}
          >
            {extractedFileName}
          </Tooltip>
        </div>
      )}
      {showIcon && (
        <MUIIcons
          size={24}
          className="pointer"
          iconName="ImageOutlined"
          onClick={handleClick}
        />
      )}

      {showPreview && (
        <div className="file-handler__preview-img">{renderMedia(mediaUrl)}</div>
      )}

      <PreviewModal
        isOpen={isPreviewModalOpen}
        toggle={() => setIsPreviewModalOpen(!isPreviewModalOpen)}
        mediaUrl={mediaUrl}
      />
    </div>
  );
};

FileHandler.propTypes = {
  mediaUrl: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  showPreview: PropTypes.bool,
};

export default FileHandler;
