import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { FormGroup, Input } from "reactstrap";

import { P } from "../../../../AbstractElements";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../CommonElements/Modal";
import SearchDropDownPaginator from "../../../../CommonElements/SearchDropDownPaginator";
import ParticipantsDetails from "../../../../container/ParticipantDetails";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  assignSecondaryTrainer,
  getScheduledTrainers,
  replaceTrainerOrNominee,
  reviewTrainerOrNominee,
} from "../../../../ReduxToolkit/Reducers/CourseSlice";
import { dynamicImage } from "../../../../Service";
import {
  Reason,
  Replacement,
  RequestedOn,
  Status,
  Remove,
  ApproveTrainerReason,
  Submit,
  AddComment,
  Description,
  Enter,
  AlternateTrainer,
  Select,
  AddAssistantTrainer,
  RejectTrainerReason,
  AssignReplacementTrainer,
  EmptyCommentsAlert,
  EmptyTrainerAlert,
  RemoveTrainer,
  RemoveTrainerConfirmation,
  Confirm,
  AlternativeTrainerToolTipMessage,
} from "../../../../utils/Constant";
import {
  customStyles,
  formatCustomDate,
  nonEditableScheduleStatus,
  scheduledTrainersStatus,
  showToast,
} from "../../../../utils/helper/helper";
import {
  currentUserId,
  hasPermissionToComponent,
} from "../../../../utils/helper/permission";

interface ScheduledTrainersListProps {
  userMap: Record<string, any>;
  scheduledId: any;
  getScheduledDetails: any;
  scheduleStatus?: string;
}

const ScheduledTrainersList = React.forwardRef(
  (props: ScheduledTrainersListProps, ref: any) => {
    const { userMap, scheduledId, getScheduledDetails, scheduleStatus } = props;
    const dispatch = useAppDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModalType, setSelectedModalType] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [selectedTrainerId, setSelectedTrainerId] = useState("");
    const [existingTrainers, setExistingTrainers] = useState([]);
    const [comments, setComments] = useState("");
    const { scheduledTrainers } = useAppSelector(state => state.course);
    const [scheduledTrainersList, setScheduledTrainersList] = useState([]);

    useEffect(() => {
      dispatch(getScheduledTrainers(scheduledId));
    }, [dispatch, scheduledId]);

    useEffect(() => {
      const trainers = [];
      const existingTrainersList = [];
      if (
        scheduledTrainers?.trainers &&
        userMap &&
        Object.keys(userMap)?.length > 0
      ) {
        const isPartOfSchedule = scheduledTrainers?.trainers?.some(item => {
          return (
            item?.trainer_type === "replaced" &&
            item?.trainer_id === currentUserId()
          );
        });

        scheduledTrainers?.trainers?.forEach(item => {
          if (item?.status !== "removed") {
            existingTrainersList.push(item?.trainer_id);
            const hasAssignPermission =
              hasPermissionToComponent("ASSIGN_TRAINERS");
            const isPrimaryTrainer = item?.trainer_type === "primary";
            const isReplacedTrainer = item?.trainer_type === "replaced";
            const isAssistantTrainer = item?.trainer_type === "assistant";

            const status = {
              label: Status,
              numbers: [scheduledTrainersStatus[item?.status] || "-"],
            };

            const extraStatus =
              item?.reason?.length > 0 &&
              (hasAssignPermission || isPartOfSchedule)
                ? { label: Reason, numbers: [item?.reason || "-"] }
                : {};

            let actions = [];
            if (
              item?.status === "pending_approval" &&
              isPrimaryTrainer &&
              (currentUserId() === item?.trainer_id || hasAssignPermission)
            ) {
              actions = [
                {
                  type: "approve_icon",
                  items: [],
                  id: item?.id,
                  status: item?.status,
                },
                {
                  type: "reject_icon",
                  items: [],
                  id: item?.id,
                  status: item?.status,
                },
                hasAssignPermission &&
                !nonEditableScheduleStatus.includes(scheduleStatus)
                  ? {
                      type: "dropdown",
                      items: [Replacement],
                      id: item?.id,
                      status: item?.trainer_type,
                    }
                  : null,
              ].filter(Boolean);
            } else if (
              item?.status === "pending_approval" &&
              isReplacedTrainer &&
              (currentUserId() === item?.trainer_id || hasAssignPermission)
            ) {
              actions = [
                {
                  type: "approve_icon",
                  items: [],
                  id: item?.id,
                  status: item?.status,
                },
                {
                  type: "reject_icon",
                  items: [],
                  id: item?.id,
                  status: item?.status,
                },
              ];
            } else if (
              isPrimaryTrainer &&
              hasAssignPermission &&
              item?.status !== "pending_approval" &&
              !nonEditableScheduleStatus.includes(scheduleStatus)
            ) {
              actions = [
                {
                  type: "dropdown",
                  items: [Replacement],
                  id: item?.id,
                  status: item?.trainer_type,
                },
              ];
            } else if (
              isAssistantTrainer &&
              ((isPrimaryTrainer && currentUserId() === item?.trainer_id) ||
                hasAssignPermission)
            ) {
              actions = [
                {
                  type: "dropdown",
                  items: [Remove],
                  id: item?.id,
                  status: item?.trainer_type,
                },
              ];
            }

            trainers.push({
              status,
              extraStatus,
              actions,
              imagePath: dynamicImage("dashboard-2/user/2.png"),
              name: userMap ? userMap[item?.trainer_id]?.user_name : null,
              empId: userMap ? userMap[item?.trainer_id]?.employee_code : null,
              phoneNumber: userMap ? userMap[item?.trainer_id]?.phone : null,
              detail: `${RequestedOn} : ` + formatCustomDate(item?.created_at),
              display_status: item?.status,
              main: isPrimaryTrainer,
              assistant: isAssistantTrainer,
              replacement: item?.trainer_type === "replaced",
            });
          }
        });
      }
      setExistingTrainers(existingTrainersList);
      setScheduledTrainersList(trainers);
    }, [scheduledTrainers, userMap, scheduleStatus]);

    const addTrainers = () => {
      setSelectedModalType("secondary");
      setSelectedId("");
      toggleModal();
    };

    React.useImperativeHandle(ref, () => ({
      addTrainers,
    }));

    const toggleModal = () => {
      if (isModalOpen) {
        setSelectedId("");
        setSelectedModalType("");
        setComments("");
        setSelectedTrainerId("");
      }
      setIsModalOpen(!isModalOpen);
    };

    let sizeTitle = "";
    if (selectedModalType === "approve") {
      sizeTitle = ApproveTrainerReason;
    } else if (selectedModalType === "reject") {
      sizeTitle = RejectTrainerReason;
    } else if (selectedModalType === "assign") {
      sizeTitle = AssignReplacementTrainer;
    } else if (selectedModalType === "secondary") {
      sizeTitle = AddAssistantTrainer;
    } else if (selectedModalType === "remove") {
      sizeTitle = RemoveTrainer;
    }

    const renderModalBody = type => {
      let modalContent;

      if (type === "assign") {
        modalContent = (
          <div className="mb-3">
            <LabelTooltip
              label={AlternateTrainer}
              tooltipText={AlternativeTrainerToolTipMessage}
              important
            />
            <ReactSelect
              name="assign"
              className="text-gray"
              onChange={selectedOption =>
                setSelectedTrainerId(
                  selectedOption ? selectedOption.value?.toString() : ""
                )
              }
              value={
                userMap
                  ? userMap[selectedTrainerId]
                    ? {
                        value: selectedTrainerId,
                        label: `${userMap[selectedTrainerId]?.employee_code || "-"} - ${userMap[selectedTrainerId]?.user_name} - ${userMap[selectedTrainerId]?.email || "-"}`,
                      }
                    : null
                  : null
              }
              options={Object.keys(userMap)
                ?.map(item => {
                  const user = userMap[item];
                  if (
                    !existingTrainers.includes(user?.id) &&
                    (user?.role?.includes("et") || user?.role?.includes("it"))
                  ) {
                    return {
                      value: user?.id,
                      label: `${user?.employee_code || "-"} - ${user?.user_name} - ${user?.email || "-"}`,
                    };
                  }
                  return null;
                })
                .filter(Boolean)}
              isSearchable
              isClearable={false}
              placeholder={`${Select} ${AlternateTrainer}`}
              styles={customStyles}
              components={{
                MenuList: props => (
                  <SearchDropDownPaginator {...props} maxHeight={200} />
                ),
              }}
              filterOption={(option, inputValue) =>
                option?.label
                  ?.toLowerCase()
                  ?.includes(inputValue?.toLowerCase())
              }
            />
          </div>
        );
      } else if (type === "secondary") {
        modalContent = (
          <div className="mb-3">
            <LabelTooltip
              label={AddAssistantTrainer}
              tooltipText={AddAssistantTrainer}
              important
            />
            <ReactSelect
              name="assign"
              className="text-gray"
              onChange={selectedOption => {
                setSelectedTrainerId(
                  selectedOption ? selectedOption.value?.toString() : ""
                );
              }}
              value={
                userMap[selectedTrainerId]
                  ? {
                      value: selectedTrainerId,
                      label: `${userMap[selectedTrainerId]?.employee_code || "-"} - ${userMap[selectedTrainerId]?.user_name} - ${userMap[selectedTrainerId]?.email || "-"}`,
                    }
                  : null
              }
              options={Object.keys(userMap)
                ?.map(item => {
                  const user = userMap[item];
                  if (
                    !existingTrainers.includes(user?.id) &&
                    user &&
                    (user?.role?.includes("et") || user?.role?.includes("it"))
                  ) {
                    return {
                      value: user?.id,
                      label: `${user?.employee_code || "-"} - ${user?.user_name} - ${user?.email || "-"}`,
                    };
                  }
                  return null;
                })
                .filter(Boolean)}
              isSearchable
              isClearable={false}
              placeholder={`${Select} ${AlternateTrainer}`}
              styles={customStyles}
              components={{
                MenuList: props => (
                  <SearchDropDownPaginator {...props} maxHeight={200} />
                ),
              }}
              filterOption={(option, inputValue) =>
                option?.label
                  ?.toLowerCase()
                  ?.includes(inputValue?.toLowerCase())
              }
            />
          </div>
        );
      } else if (type === "remove") {
        modalContent = (
          <div className="mb-3">
            <P>{RemoveTrainerConfirmation}</P>
          </div>
        );
      }

      return (
        <>
          {modalContent}
          {type !== "remove" && (
            <FormGroup>
              <LabelTooltip
                label={AddComment}
                tooltipText={`${Enter} ${Description}`}
                important
              />
              <Input
                value={comments}
                onChange={e => {
                  if (
                    (e.target.value && e.target.value.length <= 255) ||
                    e.target.value === ""
                  ) {
                    setComments(e.target.value);
                  }
                }}
                rows={4}
                name="objective"
                type="textarea"
                placeholder={`${Enter} ${Description}`}
                max={255}
              />
            </FormGroup>
          )}
        </>
      );
    };

    const handlePrimaryBtnClick = async () => {
      if (selectedModalType === "approve" || selectedModalType === "reject") {
        if (!comments || comments.trim() === "") {
          showToast(EmptyCommentsAlert, "error");
          return;
        }
      } else if (
        selectedModalType === "assign" ||
        selectedModalType === "secondary"
      ) {
        if (!selectedTrainerId || selectedTrainerId.trim() === "") {
          showToast(EmptyTrainerAlert, "error");
          return;
        }
        if (!comments || comments.trim() === "") {
          showToast(EmptyCommentsAlert, "error");
          return;
        }
      }

      let payload;
      if (selectedModalType === "approve") {
        payload = {
          action: "approved",
          type: "trainer",
          id: selectedId,
          reason: comments,
        };
        await dispatch(reviewTrainerOrNominee({ review: payload }));
      } else if (selectedModalType === "reject") {
        payload = {
          action: "rejected",
          type: "trainer",
          id: selectedId,
          reason: comments,
        };
        await dispatch(reviewTrainerOrNominee({ review: payload }));
      } else if (selectedModalType === "remove") {
        payload = {
          action: "removed",
          type: "trainer",
          id: selectedId,
          reason: comments,
        };
        await dispatch(reviewTrainerOrNominee({ review: payload }));
      } else if (selectedModalType === "assign") {
        payload = {
          type: "trainer",
          id: selectedId,
          reason: comments,
          replacement_user_id: selectedTrainerId,
        };
        await dispatch(replaceTrainerOrNominee({ replace: payload }));
      } else if (selectedModalType === "secondary") {
        payload = {
          course_schedule_id: scheduledId,
          trainer_id: [parseInt(selectedTrainerId)],
        };
        await dispatch(assignSecondaryTrainer({ trainers: payload }));
      }

      await dispatch(getScheduledTrainers(scheduledId));
      await getScheduledDetails(scheduledId);
      toggleModal();
    };

    return (
      <div className="mt-2">
        <CommonModal
          sizeTitle={sizeTitle}
          modalBodyClassName=""
          isOpen={isModalOpen}
          toggle={toggleModal}
          backdrop="static"
          size="lg"
          showFooter
          onPrimaryBtnClick={handlePrimaryBtnClick}
          primaryBtnText={selectedModalType === "assign" ? Confirm : Submit}
        >
          {renderModalBody(selectedModalType)}
        </CommonModal>
        <ParticipantsDetails
          showCard={false}
          sections={scheduledTrainersList}
          title="Participants Details"
          onApproveRequest={id => {
            setSelectedModalType("approve");
            setSelectedId(id);
            toggleModal();
          }}
          onRejectRequest={id => {
            setSelectedModalType("reject");
            setSelectedId(id);
            toggleModal();
          }}
          onDropdownClick={(id, _, status) => {
            if (status === "primary") {
              setSelectedModalType("assign");
            } else {
              setSelectedModalType("remove");
            }
            setSelectedId(id);
            toggleModal();
          }}
        />
      </div>
    );
  }
);

ScheduledTrainersList.displayName = "ScheduledTrainersList";

export default ScheduledTrainersList;
