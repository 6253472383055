import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { Col, Form, Row } from "reactstrap";

import { PUBLISH_STATUS } from "../../Api/constants";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getCurriculumList } from "../../ReduxToolkit/Reducers/CurriculumSlice";
import { mapCurriculum } from "../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  Domain,
  Curriculum,
  IsSpecialCurriculum,
  Submit,
  DomainRequired,
  RoleIsRequired,
  CurriculumIsRequired,
  CurriculumDomainToolTipMessage,
  IsSpecialCurriculumToolTipMessage,
  RoleToolTipMessage,
  ILPCurriculumToolTipMessage,
  JobRole,
  Select,
  Role,
} from "../../utils/Constant";
import { customStyles, mapListToOptions } from "../../utils/helper/helper";
import LabelTooltip from "../LabelTooltip";
import CommonModal from "../Modal";
import SearchDropDownPaginator from "../SearchDropDownPaginator";
import CheckboxButton from "../SwitchButton";

interface DropdownOption {
  id: number;
  value: string;
  label: string;
}

interface MapCurriculumModalProps {
  isOpen: boolean;
  toggle: (isMapped?: boolean) => void;
  domains: DropdownOption[];
  roles: DropdownOption[];
  userId?: number;
}

const MapCurriculumModal: React.FC<MapCurriculumModalProps> = ({
  isOpen,
  toggle,
  domains,
  roles,
  userId,
}) => {
  const [selectedDomain, setSelectedDomain] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [curriculumOptions, setCurriculumOptions] = useState([]);
  const [selectedCurriculum, setSelectedCurriculum] = useState<string>("");
  const [isSpecialCurriculum, setIsSpecialCurriculum] = useState(false);
  const [errors, setErrors] = useState({
    selectedDomain: "",
    selectedRole: "",
    selectedCurriculum: "",
  });
  const dispatch = useAppDispatch();
  const { curriculumList } = useAppSelector(state => state.curriculum);
  const { mappedCurriculum } = useAppSelector(state => state.learningPlan);

  useEffect(() => {
    if (selectedDomain && selectedRole) {
      fetchCurriculum();
    }
  }, [selectedDomain, selectedRole, isSpecialCurriculum]);

  const handleDomainChange = (selectedOption: any) => {
    setSelectedDomain(selectedOption?.value || "");
  };

  const handleRoleChange = (selectedOption: any) => {
    setSelectedRole(selectedOption?.value || "");
  };

  const handleCurriculumChange = (selectedOption: any) => {
    setSelectedCurriculum(selectedOption?.value || "");
  };

  useEffect(() => {
    const options = mapListToOptions(curriculumList?.curriculum, "name", "id");
    const uniqueOptions = options.filter(
      option =>
        !mappedCurriculum?.curriculum?.some(
          curriculum => curriculum.id.toString() === option.value
        )
    );
    setCurriculumOptions(uniqueOptions);
    setSelectedCurriculum("");
  }, [curriculumList]);

  const fetchCurriculum = () => {
    dispatch(
      getCurriculumList({
        status: PUBLISH_STATUS,
        curriculum_type: {
          domain: [selectedDomain],
          role: [selectedRole],
          specialCurriculum: isSpecialCurriculum,
        },
      })
    );
  };

  const getMapCurriculumModalBody = () => {
    return (
      <Form className="custom-input" noValidate>
        <div className="mb-3">
          <LabelTooltip
            label={Domain}
            tooltipText={CurriculumDomainToolTipMessage}
            important={true}
            placement={"bottom"}
          />
          <ReactSelect
            name="domain"
            className="text-gray"
            value={
              domains?.find(domain => domain.value === selectedDomain) || null
            }
            onChange={handleDomainChange}
            options={domains?.map(domain => ({
              value: domain.value,
              label: domain.label,
              id: domain.id,
            }))}
            isSearchable
            isClearable={false}
            placeholder={`${Select} ${Domain}`}
            styles={customStyles}
            components={{
              MenuList: props => (
                <SearchDropDownPaginator {...props} maxHeight={250} />
              ),
            }}
            filterOption={(option, inputValue) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase())
            }
          />
          {errors?.selectedDomain && (
            <div className="mt-1 text-danger">{errors?.selectedDomain}</div>
          )}
        </div>

        <div className="mb-3">
          <Row className="align-items-center">
            <Col xs="auto">
              <LabelTooltip
                label={IsSpecialCurriculum}
                tooltipText={IsSpecialCurriculumToolTipMessage}
                important={false}
                placement={"bottom"}
              />
            </Col>
            <Col xs="auto">
              <CheckboxButton
                onChange={e => {
                  setIsSpecialCurriculum(e.target.checked);
                }}
                checked={isSpecialCurriculum}
              />
            </Col>
          </Row>
        </div>

        <div className="mb-3">
          <LabelTooltip
            label={JobRole}
            tooltipText={RoleToolTipMessage}
            important={true}
            placement={"bottom"}
          />
          <ReactSelect
            name="role"
            className="text-gray"
            value={roles?.find(role => role.value === selectedRole) || null}
            onChange={handleRoleChange}
            options={roles?.map(role => ({
              value: role.value,
              label: role.label,
              id: role.id,
            }))}
            isSearchable
            isClearable={false}
            placeholder={`${Select} ${Role}`}
            styles={customStyles}
            components={{
              MenuList: props => (
                <SearchDropDownPaginator {...props} maxHeight={250} />
              ),
            }}
            filterOption={(option, inputValue) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase())
            }
          />
          {errors?.selectedRole && (
            <div className="mt-1 text-danger">{errors?.selectedRole}</div>
          )}
        </div>

        <div className="mb-3">
          <LabelTooltip
            label={Curriculum}
            tooltipText={ILPCurriculumToolTipMessage}
            important={true}
            placement={"bottom"}
          />
          <ReactSelect
            name="curriculum"
            value={
              curriculumOptions?.find(
                curriculum => curriculum.value === selectedCurriculum
              ) || null
            }
            onChange={handleCurriculumChange}
            options={curriculumOptions}
            isSearchable
            isClearable={false}
            placeholder={`${Select} ${Curriculum}`}
            styles={customStyles}
            components={{
              MenuList: props => (
                <SearchDropDownPaginator {...props} maxHeight={200} />
              ),
            }}
            filterOption={(option, inputValue) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase())
            }
            isDisabled={!selectedRole || !selectedDomain}
          />
          {errors?.selectedCurriculum && (
            <div className="mt-1 text-danger">{errors?.selectedCurriculum}</div>
          )}
        </div>
      </Form>
    );
  };

  const validateForm = () => {
    const newErrors = {
      selectedDomain: "",
      selectedRole: "",
      selectedCurriculum: "",
    };

    let isError = false;
    if (!selectedDomain) {
      newErrors.selectedDomain = DomainRequired;
      isError = true;
    }

    if (!selectedRole) {
      newErrors.selectedRole = RoleIsRequired;
      isError = true;
    }

    if (!selectedCurriculum) {
      newErrors.selectedCurriculum = CurriculumIsRequired;
      isError = true;
    }

    setErrors(newErrors);
    return isError;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      return;
    }

    dispatch(
      mapCurriculum({
        curriculumContent: {
          type: "curriculum",
          user_id: userId,
          id: Number(selectedCurriculum),
        },
      })
    ).then(res => {
      if (res?.payload) {
        toggle(true);
      }
    });
  };

  return (
    <CommonModal
      backdrop="static"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      sizeTitle={"Map Curriculum"}
      showFooter={true}
      primaryBtnText={Submit}
      onPrimaryBtnClick={handleSubmit}
    >
      {getMapCurriculumModalBody()}
    </CommonModal>
  );
};

MapCurriculumModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  domains: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  userId: PropTypes.number,
};

export default MapCurriculumModal;
