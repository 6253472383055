import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { Btn, MUIIcons } from "../../../AbstractElements";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import SearchBar from "../../../CommonElements/SearchBar";
import { AddNew } from "../../../utils/Constant";
import { learningOutcomeTabs } from "../../../utils/helper/helper";

import LOArchive from "./LOArchive";
import LOPublished from "./LOPublished";

const LearningOutcomePage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("status");
  const [activeTab, setActiveTab] = useState<string>(
    statusParam || "published"
  );
  const [searchQuery, setSearchQuery] = useState("");

  const [selctedIds, setSelectedIds] = useState({
    selectedDomainIds: [],
    selectedCompetencyIds: [],
    selectedLevelIds: [],
  });

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
  };

  return (
    <div className="page-body page-body-margin">
      <Card className="p-1">
        <CardBody>
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div className="d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={learningOutcomeTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>

            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-4 ms-auto">
              <SearchBar onSearch={query => setSearchQuery(query)} />
              {activeTab === "published" && (
                <Link
                  to={"/master-data/learning-outcome-list/add-learning-outcome"}
                >
                  <Btn
                    icon={
                      <MUIIcons size={16} iconName="AddCircleOutlineOutlined" />
                    }
                    color="primary"
                  >
                    {AddNew}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      {activeTab === "published" && (
        <LOPublished
          searchQuery={searchQuery}
          selctedIds={selctedIds}
          setSelectedIds={setSelectedIds}
        />
      )}
      {activeTab === "archive" && <LOArchive searchQuery={searchQuery} />}
    </div>
  );
};

export default LearningOutcomePage;
