import { getFromLocalStorage, saveToLocalStorage } from "./localStorageutils";

(function () {
  const LOGGED_IN_USER = "LOGGED_IN_USER";
  const OPENED_SCORM_DETAILS = "OPENED_SCORM_DETAILS";

  // Implement SCORM API methods
  function LMSInitialize(param) {
    // Initialization code here
    console.info("LMSInitialize called", param);
    return "true";
  }

  function LMSFinish(param) {
    // Cleanup code here
    console.info("LMSFinish called", param);
    triggerCustomEvent({ message: "LMSFinish" });
    return "true";
  }

  function triggerCustomEvent(data) {
    const event = new CustomEvent("syncScormData", {
      detail: data,
    });
    window.dispatchEvent(event);
  }

  function LMSGetValue(name) {
    console.info("LMSGetValue called", name);
    const courseScormObject = getFromLocalStorage(OPENED_SCORM_DETAILS);
    const userObject = getFromLocalStorage(LOGGED_IN_USER);
    if (userObject && courseScormObject) {
      switch (name) {
        case "cmi.core.student_id":
          return userObject.id || "";
        case "cmi.core.student_name":
          return userObject.name || "";
        default:
          return courseScormObject?.scorm_data?.[name] ?? "";
      }
    }
    return "";
  }

  function LMSSetValue(name, value) {
    console.info("LMSSetValue called", name, value);
    const courseScormObject = getFromLocalStorage(OPENED_SCORM_DETAILS);
    if (courseScormObject) {
      if (!courseScormObject.scorm_data) {
        courseScormObject.scorm_data = {};
      }
      courseScormObject.scorm_data[name] = value;
      saveToLocalStorage(OPENED_SCORM_DETAILS, courseScormObject);
      // Dispatch custom event for cmi.core.lesson_status
      if (name === "cmi.core.lesson_status") {
        triggerCustomEvent({ message: "ScormCourseStatus", value: value });
      }
      return "true";
    }
    return "false";
  }

  function LMSCommit(param) {
    console.info("LMSCommit called", param);
    // Commit data to LMS here
    return "true";
  }

  function LMSGetLastError() {
    console.info("LMSGetLastError called");
    // Return the last error code
    return "0";
  }

  function LMSGetErrorString(errorCode) {
    // Return a human-readable error string
    console.info("LMSGetErrorString called", errorCode);
    return "No error";
  }

  function LMSGetDiagnostic(errorCode) {
    // Return a detailed diagnostic string
    console.info("LMSGetDiagnostic called", errorCode);
    return "No diagnostic information available";
  }
  // Define the API object to expose globally
  const API = {
    LMSInitialize,
    LMSFinish,
    LMSGetValue,
    LMSSetValue,
    LMSCommit,
    LMSGetLastError,
    LMSGetErrorString,
    LMSGetDiagnostic,
  };

  if (typeof window !== "undefined") {
    window.API = API;
  }
})();
