import React, { useState } from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Input,
} from "reactstrap";

import { DeleteOption, Questions, RenameOption } from "../../utils/Constant";
import Badges from "../Badge";
import BadgeWithIcon from "../BadgeWithIcon";
import CardHeaderDropDown from "../CommonCardHeader/CardHeaderDropDown";
import H4 from "../Headings/H4Element";
import "./style.scss";
import MUIIcons from "../MUIIcon/MUIIcons";

interface AccordionItemProps {
  id: string;
  accordionItemClass?: string;
  accordionHeaderClass?: string;
  courseTime?: string;
  spanClass?: string;
  accordionHeading: React.ReactNode;
  icon?: boolean;
  bodyText: React.ReactNode;
  showCheckbox?: boolean;
  showTimer?: boolean;
  onCheckBoxChange: (id: string) => void;
  selectedIds: any[];
  showDropdown?: boolean;
  showNoOfQuestions?: boolean;
  noOfQuestions?: number;
  onAccordionClick?: (id: string) => void;
  onHeaderDropdownClick?: () => void;
  courseCount?: boolean;
  totalDuration?: string;
  open?: any;
}

const CommonAccordionItem: React.FC<AccordionItemProps> = ({
  id,
  accordionItemClass,
  accordionHeaderClass,
  spanClass,
  accordionHeading,
  icon,
  bodyText,
  showCheckbox,
  showTimer,
  selectedIds,
  courseTime,
  onCheckBoxChange,
  showDropdown,
  showNoOfQuestions,
  noOfQuestions,
  onAccordionClick = () => {
    return;
  },
  onHeaderDropdownClick = () => {
    return;
  },
  courseCount,
  totalDuration,
  open,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <AccordionItem
      className={accordionItemClass + " p-2 custom-accordion-border"}
    >
      <AccordionHeader
        targetId={id}
        className={accordionHeaderClass}
        onClick={() => {
          onAccordionClick(id);
          toggleAccordion();
        }}
      >
        {showCheckbox && (
          <div className="form-check text-dark">
            <Input
              onChange={() => onCheckBoxChange(id)}
              checked={selectedIds.includes(id)}
              className="primary-checkbox"
              type="checkbox"
            />
          </div>
        )}
        <div className="cutsom-accordion-header d-flex gap-1">
          <H4 className={spanClass}>
            {accordionHeading}
            {courseCount && (
              <div className="custom-course-time">
                <BadgeWithIcon
                  text={totalDuration}
                  iconSize={24}
                  iconName="QueryBuilderOutlined"
                />
              </div>
            )}
          </H4>
          {showNoOfQuestions && (
            <Badges>
              {noOfQuestions} {Questions}
            </Badges>
          )}
        </div>
        {showTimer && (
          <div className="custom-accordion-time">
            <BadgeWithIcon
              text={courseTime}
              iconSize={24}
              iconName="QueryBuilderOutlined"
            />
          </div>
        )}
        {icon && (
          <MUIIcons
            onClick={() => onAccordionClick(id)}
            className={`svg-color ${spanClass}`}
            size={24}
            iconName={open === id ? "ExpandLessOutlined" : "ExpandMoreOutlined"}
          />
        )}
        {showDropdown && (
          <div className="custom-accordion-dropdown">
            <CardHeaderDropDown
              onClickItem={onHeaderDropdownClick}
              mainTitle={true}
              firstItem={RenameOption}
              secondItem={DeleteOption}
            />
          </div>
        )}
      </AccordionHeader>
      <AccordionBody accordionId={id}>{bodyText}</AccordionBody>
    </AccordionItem>
  );
};

export default CommonAccordionItem;
