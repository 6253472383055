import { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import {
  Badge,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Tooltip,
} from "reactstrap";

import { Badges, Btn, H3, H4, MUIIcons } from "../../../AbstractElements";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import UpdateAssetModal from "../../../CommonElements/UpdateAssetModal";
import AssetFilterCards from "../../../container/AssetsFilterCards";
import NoData from "../../../container/NoData";
import NoDataFound from "../../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  clearAssetListDetails,
  getAssetsCategoryList,
  getAssetsList,
  getDocuments,
} from "../../../ReduxToolkit/Reducers/AssetManagementSlice";
import { getLocationList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  ActiveAssets,
  NeedAttentions,
  AddNew,
  LessThanThreeMonths,
  NeedAttention,
  Assets,
  NoDataText,
  NotYetAdded,
  AssetsManagementListingDescription,
  AssetsManagement,
  NeedsReplacementOrNeedsRepair,
  NeedReplacement,
  Retired,
  Files,
  Close,
  FailedToDownloadFileFrom,
  AssetName,
  AssetsID,
  PartNumber,
  Calibration,
  Maintenance,
  DateReceipt,
  InvoiceNumber,
  InvoiceDate,
  InvoiceValue,
  Status,
  Action,
  UpdateCalibration,
  UpdateMaintenance,
  History,
  Edit,
  Location,
  Document,
  Every,
  AssetCategory,
  TotalAssets,
  Select,
  Locations,
} from "../../../utils/Constant";
import "./style.scss";
import {
  assetStatusMap,
  capitalizeFirstLetter,
  extractFileName,
  formatCustomDate,
  formatNumberWithCommas,
  frequencyType,
  getEllipsedFileName,
  sanitizeString,
} from "../../../utils/helper/helper";

const AssestManagement = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [locationNameMap, setLocationNameMap] = useState([]);
  const [assetCategoryMap, setAssetCategoryMap] = useState([]);
  const { locationList } = useAppSelector(state => state.master);
  const [searchQuery, setSearchQuery] = useState("");
  const [parsedAssets, setParsedAssets] = useState([]);
  const { assetsList, assetsCategoryList } = useAppSelector(
    state => state.assets
  );
  const [documentModalOpen, setDocumentModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const { documents } = useAppSelector(state => state.assets);
  const [selectedData, setSelectedData] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [totalCountData, setTotalCountData] = useState([
    { count: 0, title: TotalAssets, icon: "total-asset", id: "total_assets" },
    { count: 0, title: ActiveAssets, icon: "active-asset", id: "active" },
    {
      count: 0,
      title: NeedAttentions,
      sideTitle: LessThanThreeMonths,
      icon: "attention-asset",
      id: "need_attention",
    },
    {
      count: 0,
      title: NeedsReplacementOrNeedsRepair,
      icon: "replacement-asset",
      id: "replaced",
    },
    { count: 0, title: Retired, icon: "retired-asset", id: "retired" },
  ]);
  const [activeStatus, setActiveStatus] = useState("total_assets");
  const [tooltipOpen, setTooltipOpen] = useState<{ [key: string]: boolean }>(
    {}
  );

  useEffect(() => {
    dispatch(getLocationList());
    dispatch(getAssetsCategoryList({ status: null }));
  }, [dispatch]);

  useEffect(() => {
    const locationMapObj = [];
    locationList?.location?.forEach(location => {
      locationMapObj.push({
        name: location?.id?.toString(),
        value: location?.name,
        label: location?.name,
      });
    });
    setLocationNameMap(locationMapObj);
  }, [locationList]);

  const toggleTooltip = (index: string) => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const toggleDocumentModal = () => {
    setDocumentModalOpen(!documentModalOpen);
  };

  useEffect(() => {
    getUpdatedData();
  }, [dispatch]);

  const getUpdatedData = async () => {
    dispatch(clearAssetListDetails());
    dispatch(
      getAssetsList({
        status: activeStatus === "total_assets" ? null : activeStatus,
      })
    );
  };

  useEffect(() => {
    const assetCategoryData = [];
    const addedCategoryData = [];
    assetsCategoryList?.resource_type?.forEach((item: any) => {
      if (!addedCategoryData?.includes(item?.resource_type_id?.toString())) {
        assetCategoryData.push({
          name: item?.resource_type_id?.toString(),
          value: item?.name,
          label: item?.name,
        });
        addedCategoryData.push(item?.resource_type_id?.toString());
      }
    });
    setAssetCategoryMap(assetCategoryData);
  }, [assetsCategoryList]);

  useEffect(() => {
    if (assetsList) {
      setTotalCountData([
        {
          count: assetsList?.resource_meta?.total_assets || 0,
          title: TotalAssets,
          icon: "total-asset",
          id: "total_assets",
        },
        {
          count: assetsList?.resource_meta?.total_active_assets || 0,
          title: ActiveAssets,
          icon: "active-asset",
          id: "active",
        },
        {
          count: assetsList?.resource_meta?.need_attention || 0,
          title: NeedAttentions,
          sideTitle: LessThanThreeMonths,
          icon: "attention-asset",
          id: "need_attention",
        },
        {
          count: assetsList?.resource_meta?.need_replaced || 0,
          title: NeedsReplacementOrNeedsRepair,
          icon: "replacement-asset",
          id: "replaced",
        },
        {
          count: assetsList?.resource_meta?.retired || 0,
          title: Retired,
          icon: "retired-asset",
          id: "retired",
        },
      ]);
      const addedAssets = [];
      assetsList?.resource_type?.forEach(item => {
        addedAssets.push({
          id: item?.resource_type_id,
          assetName: item?.name,
          depedentBadges: [
            {
              id: 1,
              badge: ` ${Assets}: ${item?.total_assets || 0}`,
            },
            {
              id: 1,
              badge: `${ActiveAssets}: ${item?.total_active_assets || 0}`,
            },
            {
              id: 2,
              badge: `${NeedAttention}: ${item?.need_attention || 0}`,
            },
            {
              id: 3,
              badge: `${NeedReplacement}: ${item?.need_replaced || 0}`,
            },
          ],
        });
      });
      setParsedAssets(addedAssets);
    }
  }, [assetsList]);

  const filteredAssets = useMemo(() => {
    if (searchQuery) {
      return parsedAssets?.filter(item =>
        item?.assetName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
    }
    return parsedAssets;
  }, [searchQuery, parsedAssets]);

  const handleCardClick = (id: number, name: string) => {
    return navigate(
      `${process.env.PUBLIC_URL}/master-data/asset-management/assets-list?asset_id=${id}&name=${name}`
    );
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    const location = values["location"] ? values["location"].join(",") : "";
    const resource_type_id = values["resource_type_id"]
      ? values["resource_type_id"].join(",")
      : "";
    dispatch(clearAssetListDetails());
    await dispatch(
      getAssetsList({
        status: activeStatus === "total_assets" ? null : activeStatus,
        location: location,
        resource_type_id: resource_type_id,
      })
    );
  };

  const handleClear = async () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    dispatch(clearAssetListDetails());
    await dispatch(
      getAssetsList({
        status: activeStatus === "total_assets" ? null : activeStatus,
      })
    );
  };

  const onIconClick = async id => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    if (activeStatus !== id) {
      setActiveStatus(id);
      dispatch(clearAssetListDetails());
      await dispatch(
        getAssetsList({
          status: id === "total_assets" ? null : id,
        })
      );
    }
  };

  const handleDownload = async (urls: string[]) => {
    for (const url of urls) {
      if (url) {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const link = document.createElement("a");
          const objectURL = URL.createObjectURL(blob);
          link.href = objectURL;
          link.setAttribute("download", url.split("/").pop() || "file");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error(`${FailedToDownloadFileFrom} ${url}: `, error);
        }
      }
    }
  };

  const customStyles = {
    rows: {
      style: {
        padding: "1rem 0",
      },
    },
    table: {
      style: {
        marginTop: "2rem",
      },
    },
  };

  const onDocumentClick = async id => {
    await dispatch(getDocuments({ id: id }));
    toggleDocumentModal();
  };

  const toggleDropdown = (e, rowId) => {
    e.preventDefault();
    setDropdownOpen(prevDropdownOpen =>
      prevDropdownOpen === rowId ? "" : rowId
    );
  };

  const handleUpdate = (row, type) => {
    setSelectedData(row);
    setSelectedType(type);
    toggleModal();
  };

  const isPastOrInvalidDate = (date: string | null | undefined) => {
    return new Date(date) <= new Date() && date != null;
  };

  const handleHistory = (resource_id, assetName) => {
    return navigate(
      `${process.env.PUBLIC_URL}/master-data/asset-management/assets-history?resource_id=${resource_id}&name=${""}&asset_name=${assetName}`
    );
  };

  const handleCreate = resource_id => {
    return navigate(
      `${process.env.PUBLIC_URL}/master-data/asset-management/add-asset-management?asset_id=${resource_id}`
    );
  };

  const AssetsTableColumns = [
    {
      name: AssetName,
      selector: row => row.assetsName || "-",
      minWidth: "16rem",
      sortable: true,
    },
    {
      name: AssetCategory,
      selector: row => row.category || "-",
      minWidth: "16rem",
      sortable: true,
    },
    {
      name: AssetsID,
      selector: row => row.assetId || "-",
      minWidth: "14rem",
      sortable: true,
    },
    {
      name: PartNumber,
      selector: row => row.partNumber || "-",
      minWidth: "12rem",
      sortable: true,
    },
    {
      name: Location,
      selector: row => row.locationName || "-",
      minWidth: "12rem",
      sortable: true,
    },
    {
      name: Calibration,
      selector: row => row.frequency,
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: Maintenance,
      selector: row => row.maintenances,
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: Document,
      cell: row => (
        <Badges
          onClick={() => onDocumentClick(row.resource_id)}
          color="light text-dark"
        >
          <MUIIcons
            className="pointer"
            iconName={"InsertDriveFileOutlined"}
            size={24}
          />
          {row?.document > 0 && (
            <Badge className="asset-doc-notification-badge ">
              {row.document}
            </Badge>
          )}
        </Badges>
      ),
      minWidth: "7rem",
    },
    {
      name: DateReceipt,
      selector: row => row.dateOfReciept,
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: InvoiceNumber,
      selector: row => row.invoiceNumber,
      minWidth: "12rem",
      sortable: true,
    },
    {
      name: InvoiceDate,
      selector: row => row.invoiceDate,
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: InvoiceValue,
      selector: row => "₹ " + formatNumberWithCommas(row.invoiceValue),
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: Status,
      sortable: true,
      cell: row => {
        const status = assetStatusMap[row.status];

        if (row.status === "need_attention") {
          let status = assetStatusMap[row.status];

          if (
            isPastOrInvalidDate(row?.calibration_valid_till) &&
            isPastOrInvalidDate(row?.maintenance_valid_till)
          ) {
            status += " (C/M)";
          } else {
            if (isPastOrInvalidDate(row?.calibration_valid_till)) {
              status += " (C)";
            }
            if (isPastOrInvalidDate(row?.maintenance_valid_till)) {
              status += " (M)";
            }
          }

          return <div className={row.status}>{status}</div>;
        }

        return <div className={row.status}>{status}</div>;
      },
      minWidth: "12rem",
    },
    {
      name: Action,
      selector: row => row.assetId,
      minWidth: "6rem",
      center: true,
      cell: row => (
        <div>
          <Dropdown
            isOpen={row?.resource_id === dropdownOpen}
            toggle={e => toggleDropdown(e, row.resource_id)}
            direction="start"
          >
            <DropdownToggle color="" className="three-dots primary-text-color">
              <MUIIcons
                iconName="MoreVertOutlined"
                className="primary-icon-color"
                size={17}
              />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {row.status === "need_attention" &&
                isPastOrInvalidDate(row?.calibration_valid_till) && (
                  <DropdownItem
                    onClick={() => handleUpdate(row, "calibration")}
                  >
                    {UpdateCalibration}
                  </DropdownItem>
                )}
              {row.status === "need_attention" &&
                isPastOrInvalidDate(row?.maintenance_valid_till) && (
                  <DropdownItem
                    onClick={() => handleUpdate(row, "maintenance")}
                  >
                    {UpdateMaintenance}
                  </DropdownItem>
                )}
              {(row.status === "active" ||
                row.status === "need_attention" ||
                row.status === "replaced" ||
                row.status === "retired") && (
                <DropdownItem
                  onClick={() =>
                    handleHistory(row?.resource_id, row.assetsName)
                  }
                >
                  {History}
                </DropdownItem>
              )}
              {(row.status === "active" ||
                row.status === "need_attention" ||
                row.status === "replaced") && (
                <DropdownItem onClick={() => handleCreate(row?.resource_id)}>
                  {Edit}
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      ),
    },
  ];

  const toggleModal = () => {
    if (modalOpen) {
      setSelectedData({});
      setSelectedType("");
    }
    setModalOpen(!modalOpen);
  };

  const renderContent = () => {
    if (activeStatus !== "active" && activeStatus !== "total_assets") {
      const modifyData = [];
      assetsList?.resource_type?.forEach((item: any) => {
        if (
          (searchQuery &&
            searchQuery?.length > 0 &&
            item?.resource_type_name
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase())) ||
          searchQuery === ""
        ) {
          modifyData.push({
            resource_id: item?.resource_id,
            category: item?.resource_type_name,
            maintenance_valid_till: item?.maintenance_valid_till,
            calibration_valid_till: item?.calibration_valid_till,
            assetId: item?.asset_id,
            assetsName: item?.asset_name,
            partNumber: item?.part_no,
            locationName: item?.location_name
              ? capitalizeFirstLetter(item.location_name)
              : "-",
            assetNumber: item?.asset_name,
            invoiceNumber: item?.invoice_number,
            invoiceValue: item?.invoice_value,
            invoiceDate: formatCustomDate(item?.invoice_date),
            dateOfReciept: formatCustomDate(item?.receipt_date),
            frequency:
              item?.calibration_value && item?.calibration_frequency
                ? `${Every} ${item?.calibration_value} ${frequencyType[item?.calibration_frequency]}`
                : "",
            maintenances:
              item?.maintenance_value && item?.maintenance_frequency
                ? `${Every} ${item?.maintenance_value} ${frequencyType[item?.maintenance_frequency]}`
                : "",
            document: item?.document_count,
            status: item?.status,
          });
        }
      });

      return (
        <>
          <CommonModal
            sizeTitle={Files}
            isOpen={documentModalOpen}
            toggle={toggleDocumentModal}
            backdrop="static"
            size="lg"
            showFooter
            onPrimaryBtnClick={toggleDocumentModal}
            primaryBtnText={Close}
          >
            {(() => {
              if (!Array.isArray(documents?.document)) {
                return <NoDataFound />;
              }

              const categorizedDocuments = documents?.document?.reduce(
                (acc, item) => {
                  if (!acc[item.type]) {
                    acc[item.type] = [];
                  }
                  acc[item.type].push(item);
                  return acc;
                },
                {}
              );

              return Object.keys(categorizedDocuments)?.length > 0 ? (
                Object.keys(categorizedDocuments).map((category, catIndex) => (
                  <div key={catIndex} className="mt-4">
                    <H4>
                      <span className="m-1">
                        {capitalizeFirstLetter(category) + " " + Files}
                      </span>
                    </H4>
                    <Row>
                      {categorizedDocuments[category].map((item, docIndex) => {
                        const uniqueId = `${category}-${docIndex}`;

                        return (
                          <Col lg="6" className="mt-2" key={docIndex}>
                            <H3>
                              <Badges color="light text-dark">
                                <MUIIcons
                                  size={24}
                                  iconName="InsertDriveFileOutlined"
                                />
                                <span
                                  className="m-1"
                                  id={sanitizeString(
                                    `tooltip-course1-name-${uniqueId}`
                                  )}
                                >
                                  {getEllipsedFileName(
                                    extractFileName(item.document),
                                    35
                                  )}
                                </span>
                                <Tooltip
                                  placement="top"
                                  isOpen={tooltipOpen[uniqueId] || false}
                                  target={sanitizeString(
                                    `tooltip-course1-name-${uniqueId}`
                                  )}
                                  toggle={() => toggleTooltip(uniqueId)}
                                >
                                  {extractFileName(item.document)}
                                </Tooltip>
                                <MUIIcons
                                  onClick={() =>
                                    handleDownload([item.document])
                                  }
                                  iconName="FileDownload"
                                  className="pointer"
                                  size={16}
                                />
                              </Badges>
                            </H3>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                ))
              ) : (
                <NoDataFound />
              );
            })()}
          </CommonModal>

          <Card>
            <CardBody>
              <DataTable
                className="asset-list-table"
                pagination
                highlightOnHover
                columns={AssetsTableColumns}
                data={modifyData}
                customStyles={customStyles}
              />
              <UpdateAssetModal
                selectedData={selectedData}
                selectedType={selectedType}
                isOpen={modalOpen}
                toggle={toggleModal}
                getUpdatedData={getUpdatedData}
                onSubmit={() => {
                  toggleModal();
                }}
              />
            </CardBody>
          </Card>
        </>
      );
    }
    if (parsedAssets?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          description={AssetsManagementListingDescription}
          buttonText={AddNew}
          onclick={() =>
            navigate("/master-data/asset-management/add-asset-management")
          }
        />
      );
    }
    if (filteredAssets?.length === 0) {
      return <NoData svg={"empty-folder-icon"} title={NoDataText} />;
    }
    if (activeStatus === "total_assets" || activeStatus === "active") {
      return filteredAssets?.map(item => {
        return (
          <Card
            className="p-2 pointer"
            key={item.id}
            onClick={() => handleCardClick(item?.id, item?.assetName)}
          >
            <CardBody>
              <div className="d-flex flex-row align-items-center">
                <H3 className="fw-bold">{item?.assetName}</H3>
                <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
                  {item?.depedentBadges?.map(badgeItem => (
                    <Badge
                      key={badgeItem?.id}
                      className="text-gray custom-asset-badge asset-list__badge"
                    >
                      {badgeItem?.badge}
                    </Badge>
                  ))}
                </div>
              </div>
            </CardBody>
          </Card>
        );
      });
    }
  };

  return (
    <div className="page-body asset-list">
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3>{AssetsManagement}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={query => setSearchQuery(query)} />
              {activeStatus !== "active" && activeStatus !== "total_assets" && (
                <div className="mt-2">
                  <FilterSliderModal
                    dropdowns={[
                      {
                        label: AssetCategory,
                        key: "resource_type_id",
                        tooltipText: `${Select} ${AssetCategory}`,
                        options: assetCategoryMap,
                        isMultiSelect: true,
                      },
                      {
                        label: Locations,
                        key: "location",
                        tooltipText: `${Select} ${Locations}`,
                        options: locationNameMap,
                        isMultiSelect: true,
                      },
                    ]}
                    selectedFilterValues={selectedFilterValues}
                    defaultFilterValues={defaultFilterValues}
                    setSelectedFilterValues={setSelectedFilterValues}
                    setDefaultFilterValues={setDefaultFilterValues}
                    onDone={handleDone}
                    onClear={handleClear}
                  />
                </div>
              )}
              {parsedAssets?.length > 0 && (
                <Link to={"/master-data/asset-management/add-asset-management"}>
                  <Btn
                    icon={
                      <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                    }
                    color="primary"
                  >
                    {AddNew}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="mb-3">
        <AssetFilterCards
          data={totalCountData}
          onIconClick={onIconClick}
          activeStatus={activeStatus}
          showColor={true}
        />
      </div>
      {renderContent()}
    </div>
  );
};

export default AssestManagement;
