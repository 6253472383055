import React from "react";
import { Container } from "reactstrap";

import CommonError from "../CommonError/CommonError";

import "./style.scss";

const Forbidden = () => {
  return (
    <Container className="forbidden d-flex justify-content-center align-items-center">
      <CommonError error="403" color="success" src="sad2" />
    </Container>
  );
};

export default Forbidden;
