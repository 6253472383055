import axios from "axios";

import { userApiUrl } from "..";
import {
  decrementActiveRequests,
  hideLoader,
  showLoader,
  incrementActiveRequests,
} from "../../ReduxToolkit/Reducers/LoaderSlice";
import Store from "../../ReduxToolkit/Store";
import { NoResponseFromServer } from "../../utils/Constant";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
  removeAllLocalStorage,
  saveToLocalStorage,
} from "../../utils/helper/localStorageutils";
import {
  NO_RESPONSE,
  REQUEST_SETUP_ERROR,
  STATUS_401,
  STATUS_403,
} from "../constants";

let isRefreshingToken = false;
let requestQueue = [];

const apiRequest = async (method, url, data?) => {
  const loginData = getFromLocalStorage(LOGGED_IN_USER);
  Store.dispatch(incrementActiveRequests());

  try {
    Store.dispatch(showLoader());

    const headers = loginData
      ? { "x-access-token": loginData.accessToken }
      : {};

    const config = {
      method,
      url,
      headers,
      data,
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    if (error?.response) {
      const { status, data } = error.response;

      if (status === STATUS_401) {
        if (!isRefreshingToken) {
          isRefreshingToken = true;
          try {
            const headers = loginData
              ? { "x-refresh-token": loginData.refreshToken }
              : {};

            const tokenConfig = {
              method: "GET",
              url: `${userApiUrl}/user/request_type=generate-token&type=refresh`,
              headers,
            };

            const newToken = await axios(tokenConfig);

            if (
              newToken?.status === STATUS_401 ||
              newToken?.status === STATUS_403
            ) {
              removeAllLocalStorage();
              window.location.href = `${process.env.PUBLIC_URL}/login`;
              return;
            }
            if (
              newToken?.data?.payload?.access_token &&
              newToken?.data?.payload?.refresh_token
            ) {
              saveToLocalStorage(LOGGED_IN_USER, {
                ...loginData,
                accessToken: newToken.data.payload.access_token,
                refreshToken: newToken.data.payload.refresh_token,
              });

              requestQueue.forEach(({ resolve, method, url, data }) => {
                resolve(apiRequest(method, url, data));
              });
              requestQueue = [];
            } else {
              removeAllLocalStorage();
              window.location.href = `${process.env.PUBLIC_URL}/login`;
            }
          } catch (refreshError) {
            requestQueue.forEach(({ reject }) => reject(refreshError));
            requestQueue = [];
            removeAllLocalStorage();
            window.location.href = `${process.env.PUBLIC_URL}/login`;
          } finally {
            isRefreshingToken = false;
          }
        }

        return new Promise((resolve, reject) => {
          requestQueue.push({
            resolve: () => resolve(apiRequest(method, url, data)),
            reject,
            method,
            url,
            data,
          });
        });
      } else {
        throw new Error(
          JSON.stringify({ status, message: data?.message || error.message })
        );
      }
    } else if (error.request) {
      throw new Error(
        JSON.stringify({
          status: NO_RESPONSE,
          message: NoResponseFromServer,
        })
      );
    } else {
      throw new Error(
        JSON.stringify({
          status: REQUEST_SETUP_ERROR,
          message: error.message,
        })
      );
    }
  } finally {
    Store.dispatch(hideLoader());
    Store.dispatch(decrementActiveRequests());
  }
};

export default apiRequest;
