import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, FormGroup, Input } from "reactstrap";

import { Btn, H4, H5, P } from "../../../../AbstractElements";
import { ALL_USERS_STATUS, PUBLISHED } from "../../../../Api/constants";
import BorderTabs from "../../../../CommonElements/BorderTabs";
import Divider from "../../../../CommonElements/Divider";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import Graph from "../../../../CommonElements/Graph";
import JustifyTabs from "../../../../CommonElements/JustifyTabs";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../CommonElements/Modal";
import StatusDisplay from "../../../../CommonElements/StatusDisplay";
import AssessmentModuleList from "../../../../container/AssessmentModuleList";
import CatlogAssessmentHeading from "../../../../container/CatlogAssessmentHeading";
import ParticipantsDetails from "../../../../container/ParticipantDetails";
import ScheduledDetails from "../../../../container/ScheduledDetails";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  deleteScheduled,
  getAssessmentCatlogById,
  getLearnerAndRmResponse,
  getScheduledByAssessmentId,
  getScheduledDetailsByScheduleId,
  requestForModificationAcceessAPI,
  reviewModificationRequest,
  setAssessmentCatlogPayloadToInitials,
  setScheduledDetailsToNull,
} from "../../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import { updateReAttemptDays } from "../../../../ReduxToolkit/Reducers/AssessmentSlice";
import { getReAttemptPolicyList } from "../../../../ReduxToolkit/Reducers/PolicySilce";
import { getAssessmentQuestionBankList } from "../../../../ReduxToolkit/Reducers/QuestionBankSlice";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../../../Service";
import {
  AccessRequestedOn,
  Active,
  AddProperReason,
  AddReason,
  ApprovedOn,
  AssessmentDurationInMinutes,
  AssessmentType,
  Description,
  Editor,
  Enter,
  Extension,
  MappedModule,
  MappedQuestionBank,
  ModificationAccessRequest,
  Modify,
  ModuleList,
  ModuleName,
  NoOfQuestions,
  Occurrences,
  OverallCount,
  Past,
  Reason,
  ReAttemptPolicy,
  RecurseEvery,
  RequestForModification,
  RevokeReason,
  CreateSchedule,
  Status,
  Submit,
  Upcoming,
  UserDetails,
  UsersEnrolled,
  Weightage,
  DeclineReason,
  AddReasonForDecline,
  AreYouSureDeleteSchedule,
  DeleteSchedule,
  Confirm,
  SelfAssessment,
  Review,
  ViewDetails,
  ViewReport,
  SelfAssessmentSummary,
  Close,
  CompletedOn,
  ScheduledOn,
  RequestedOn,
  YourReason,
  EndAfter,
  ReviewerReason,
  DeclineModification,
  Declined,
  SubmittedOn,
  Score,
  Course,
  ComingSoon,
  ReAttempt,
  FillReattemptFields,
} from "../../../../utils/Constant";
import {
  assessmentCatlogDetailsTabs,
  assessmentParticipantStatus,
  capitalizeFirstLetter,
  displayStatus,
  formatCustomDate,
  formatDate,
  frequencyMapping,
  getChartData,
  getRandomInteger,
  handleKeyDownOnNumericWithoutDecimal,
  pendingStatus,
  scheduledCompetencyTabs,
  sgaAssessmentCatlogDetailsTabs,
  showToast,
} from "../../../../utils/helper/helper";
import {
  currentUserId,
  hasPermissionToComponent,
  isCurrentUserIsCourseOwner,
} from "../../../../utils/helper/permission";

const AssessmentCatlogDetails = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>("details");
  const [activeCompetencyTab, setActiveCompetencyTab] =
    useState<string>("assigned");
  const { assessmentCatlogDetailData, scheduledDetails } = useAppSelector(
    state => state.assessmentCatlog
  );
  const queryParams = new URLSearchParams(location.search);
  const assessmentId = queryParams.get("assessmentId");
  const type = queryParams.get("type");
  const review = queryParams.get("review");
  const tab = queryParams.get("tab");

  const { questionBankList } = useAppSelector(state => state.questionBank);
  const [questionBankListDataMap, setQuestionBankListDataMap] = useState({});
  const [policyListDataMap, setPolicyListDataMap] = useState({});
  const [modificationRequests, setModificationRequests] = useState([]);
  const [editors, setEditors] = useState([]);
  const [modifications, setModifications] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [editorIds, setEditorIds] = useState([]);
  const [userMap, setUserMap] = useState(null);
  const { usersList } = useAppSelector(state => state.user);
  const [scheduledDataMap, setScheduledDataMap] = useState({
    current_data: [],
    upcoming_data: [],
    past_data: [],
  });
  const [selectedModal, setSelectedModal] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");

  const { policyList } = useAppSelector(state => state.policy);
  const [comments, setComments] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGraphModalOpen, setIsGraphModalOpen] = useState(false);
  const [updatedScheduledCompetencyTabs, setUpdatedScheduledCompetencyTabs] =
    useState(scheduledCompetencyTabs);
  const [chartData, setChartData] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);
  const [noOfDays, setNoOfDays] = useState(null);

  const toggleModal = () => {
    if (isModalOpen) {
      setComments("");
      setSelectedModal("");
      setSelectedUserId("");
      setNoOfDays(null);
    }
    setIsModalOpen(!isModalOpen);
  };

  const toggleGraphModal = () => {
    setIsGraphModalOpen(!isGraphModalOpen);
  };

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
  }, [dispatch]);

  useEffect(() => {
    if (tab) {
      handleTabClick(tab);
    }
  }, []);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    const users = [];
    const editors = [];
    const editorId = [];
    let modificationsList = [];

    if (assessmentCatlogDetailData?.modification_request) {
      assessmentCatlogDetailData?.modification_request?.forEach((item: any) => {
        users.push({
          status: {
            label: Reason,
            numbers: [item?.requester_comments],
          },
          actions: [
            {
              type: "reject",
              id: item?.id,
            },
            { type: "approve", id: item?.id },
          ],
          imagePath: dynamicImage("dashboard-2/user/2.png"),
          name: userMap ? userMap[item?.created_by]?.user_name : null,
          empId: userMap ? userMap[item?.created_by]?.employee_code : null,
          phoneNumber: userMap ? userMap[item?.created_by]?.phone : null,
          detail: AccessRequestedOn + formatCustomDate(item?.created_at),
        });
      });
    }

    if (
      assessmentCatlogDetailData?.owner_id &&
      assessmentCatlogDetailData?.owner_id !== null
    )
      editors.push({
        imagePath: dynamicImage("dashboard-2/user/2.png"),
        name: userMap
          ? userMap[assessmentCatlogDetailData?.owner_id]?.user_name
          : null,
        empId: userMap
          ? userMap[assessmentCatlogDetailData?.owner_id]?.employee_code
          : null,
        phoneNumber: userMap
          ? userMap[assessmentCatlogDetailData?.owner_id]?.phone
          : null,
        status: {
          label: "",
          numbers: [""],
        },
        actions:
          hasPermissionToComponent("REVOKE_EDITOR_ACCESS") &&
          assessmentCatlogDetailData?.editors?.length > 0 &&
          assessmentCatlogDetailData?.owner_id !== currentUserId()
            ? [
                {
                  type: "revoke",
                  id: assessmentCatlogDetailData?.owner_id,
                },
              ]
            : [],

        detail: "-",
        owner: true,
      });

    if (assessmentCatlogDetailData && assessmentCatlogDetailData?.editors) {
      assessmentCatlogDetailData?.editors?.forEach((item: any) => {
        editorId.push(item?.created_by);
        const isOwner =
          item?.created_by === assessmentCatlogDetailData?.owner_id;

        editors.push({
          status: {
            label: "",
            numbers: [""],
          },
          actions: isCurrentUserIsCourseOwner(
            assessmentCatlogDetailData?.owner_id
          )
            ? [
                {
                  type: "revoke",
                  id: item?.id,
                },
              ]
            : [],
          imagePath: dynamicImage("dashboard-2/user/2.png"),
          name: userMap ? userMap[item?.created_by]?.user_name : null,
          empId: userMap ? userMap[item?.created_by]?.employee_code : null,
          phoneNumber: userMap ? userMap[item?.created_by]?.phone : null,
          detail:
            item?.created_by !== assessmentCatlogDetailData?.owner_id &&
            ApprovedOn + formatCustomDate(item?.review_date),
          owner: isOwner,
        });
      });
    }

    const allModificaton = [
      {
        imagePath: dynamicImage("dashboard-2/user/2.png"),
        name: userMap
          ? userMap[assessmentCatlogDetailData?.owner_id]?.user_name
          : null,
        empId: userMap
          ? userMap[assessmentCatlogDetailData?.owner_id]?.employee_code
          : null,
        phoneNumber: userMap
          ? userMap[assessmentCatlogDetailData?.owner_id]?.phone
          : null,
        actions:
          hasPermissionToComponent("REVOKE_EDITOR_ACCESS") &&
          editors?.length > 0
            ? [
                {
                  type: "revoke",
                  id: assessmentCatlogDetailData?.owner_id,
                },
              ]
            : [],

        detail: "-",
        owner: true,
      },
    ];

    if (
      assessmentCatlogDetailData &&
      assessmentCatlogDetailData?.modification_requests_history &&
      assessmentCatlogDetailData?.modification_requests_history?.length > 0
    ) {
      modificationsList =
        assessmentCatlogDetailData?.modification_requests_history?.map(
          (modification: any) => {
            const status = modification.status;
            if (status === "approved" || status === "pending") {
              setHasAccess(true);
            } else {
              setHasAccess(false);
            }
            return {
              userDetails: {
                imagePath: dynamicImage("dashboard-2/user/2.png"),
                name: userMap
                  ? userMap[modification.created_by]?.user_name
                  : null,
                empId: userMap
                  ? userMap[modification?.created_by]?.employee_code
                  : null,
                phoneNumber: userMap
                  ? userMap[modification?.created_by]?.phone
                  : null,

                detail: `${RequestedOn} : ${formatCustomDate(modification.created_at)}`,
              },
              childStatus: [
                {
                  isLarge: 4,
                  label: YourReason,
                  numbers: [modification.requester_comments],
                  childLabel: ReviewerReason,
                  childNumbers: [modification.reviewer_comments],
                },
                {
                  label: Status,
                  numbers:
                    status === "rejected"
                      ? [Declined]
                      : [capitalizeFirstLetter(status)],
                },
              ],
            };
          }
        );
    }

    setModifications(allModificaton.concat(modificationsList));
    setEditorIds(editorId);
    setEditors(editors);
    setModificationRequests(users);
  }, [assessmentCatlogDetailData, userMap]);

  useEffect(() => {
    const banks = {};
    if (questionBankList) {
      questionBankList?.question_banks?.forEach(item => {
        banks[item?.id] = item?.name;
      });
    }
    setQuestionBankListDataMap(banks);
  }, [questionBankList]);

  useEffect(() => {
    const policy = {};
    if (policyList) {
      policyList?.policy?.forEach(item => {
        policy[item?.id] = item?.name;
      });
    }
    setPolicyListDataMap(policy);
  }, [policyList]);

  useEffect(() => {
    if (assessmentId && assessmentId !== null) {
      if (
        type === "competency" ||
        assessmentCatlogDetailData?.assessment_type !== "standalone"
      ) {
        dispatch(
          getScheduledDetailsByScheduleId({
            id: null,
            assessment_id: Number(assessmentId),
          })
        );
      }
    }
  }, [assessmentId, type, assessmentCatlogDetailData?.assessment_type]);

  useEffect(() => {
    if (assessmentId && assessmentId !== null) {
      dispatch(
        getAssessmentQuestionBankList({
          status: PUBLISHED,
          tag: null,
          self_assessment: null,
        })
      );
      dispatch(getReAttemptPolicyList({}));
      dispatch(getAssessmentCatlogById({ id: Number(assessmentId) }));
    }
  }, [assessmentId]);

  useEffect(() => {
    const users = [];
    let pending = 0;
    if (
      scheduledDetails &&
      userMap &&
      isNotSgaAssessment() &&
      (type === "competency" ||
        assessmentCatlogDetailData?.assessment_type !== "standalone")
    ) {
      scheduledDetails?.participants?.forEach(item => {
        let dropValues = [Review];
        let message = "";
        if (item?.status === "under_review" || item?.status === "draft") {
          pending = pending + 1;
          dropValues = [Review];
          message = CompletedOn;
        }
        if (
          item?.status === "in_progress" ||
          item?.status === "yts" ||
          item?.status === "completed"
        ) {
          dropValues = [];
          message = ScheduledOn;
        }
        let actions = [];

        if (hasPermissionToComponent("REVIEW_SELF_ASSESSMENT")) {
          if (dropValues?.length > 0) {
            actions = [
              {
                type: "dropdown",
                items: dropValues,
                id: item?.id,
                status: item?.status,
              },
            ];
          } else if (item?.status === "completed") {
            actions = [
              {
                type: "dropdown",
                items: [ViewDetails, ViewReport],
                id: item?.id,
                status: item?.status,
              },
            ];
          }
        } else if (item?.status === "completed") {
          actions = [
            {
              type: "dropdown",
              items: [ViewDetails, ViewReport],
              id: item?.id,
              status: item?.status,
            },
          ];
        }

        users.push({
          status: {
            label: Status,
            numbers: [assessmentParticipantStatus[item?.status] || "-"],
          },
          display_status: item?.status,
          actions: actions,
          imagePath: dynamicImage("dashboard-2/user/2.png"),
          name: userMap ? userMap[item?.user_id]?.user_name : null,
          empId: userMap ? userMap[item?.user_id]?.employee_code : null,
          phoneNumber: userMap ? userMap[item?.user_id]?.phone : null,
          detail: `${message} : ` + formatCustomDate(item?.updated_at),
        });
      });
    }
    if (scheduledDetails && userMap && isSgaAssessment()) {
      scheduledDetails?.participants?.forEach(item => {
        let dropValues = [Review, ReAttempt];
        let message = "";
        if (item?.status === "yts" || item?.status === "in_progress") {
          dropValues = [];
          message = ScheduledOn;
        }
        if (item?.status === "failed" || item?.status === "retake") {
          dropValues = [ReAttempt];
          message = CompletedOn;
        }
        if (item?.status === "passed" || item?.status === "completed") {
          dropValues = [];
          message = CompletedOn;
        }
        if (item?.status === "under_review" || item?.status === "draft") {
          dropValues = [Review];
          message = SubmittedOn;
        }

        if (pendingStatus.includes(assessmentParticipantStatus[item?.status])) {
          pending = pending + 1;
        }
        users.push({
          status: {
            label: Status,
            numbers: [assessmentParticipantStatus[item?.status] || "-"],
          },
          extraStatus: {
            label: Score,
            numbers: [item?.score || "-"],
          },
          actions:
            dropValues?.length > 0
              ? [
                  {
                    type: "dropdown",
                    items: dropValues,
                    id: item?.id,
                    status: item?.status,
                  },
                ]
              : [],
          imagePath: dynamicImage("dashboard-2/user/2.png"),
          name: userMap ? userMap[item?.user_id]?.user_name : null,
          empId: userMap ? userMap[item?.user_id]?.employee_code : null,
          phoneNumber: userMap ? userMap[item?.user_id]?.phone : null,
          detail: `${message} : ` + formatCustomDate(item?.updated_at),
          display_status: item?.status,
        });
      });
    }
    const tabs: any = [...scheduledCompetencyTabs];
    if (pending > 0) tabs[1].count = pending;
    setUpdatedScheduledCompetencyTabs(tabs);
    setUserDetails(users);
  }, [scheduledDetails, userMap]);

  const handleTabClick = async (tabId: any) => {
    if (tabId === "schedule_overview") {
      const data: any = await dispatch(
        getScheduledByAssessmentId({ id: Number(assessmentId) })
      );

      const schedules = data?.payload?.schedules;
      const today = new Date();

      const current_data = [];
      const upcoming_data = [];
      const past_data = [];

      schedules?.forEach(schedule => {
        const startDate = new Date(schedule.start_date);
        const endDate = new Date(schedule.end_date);
        const obj = {
          id: schedule?.id,
          startDate: formatDate(schedule.start_date),
          endDate: formatDate(schedule.end_date),
          statusLabel: UsersEnrolled,
          statusNumber: schedule?.participants_count,
          badgesLabel: Extension,
          badgesValue: schedule?.pending_extension_requests,
        };

        const stripTime = date => {
          return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        };

        const todayStripped = stripTime(today);
        const startDateStripped = stripTime(startDate);
        const endDateStripped = stripTime(endDate);

        if (
          todayStripped >= startDateStripped &&
          todayStripped <= endDateStripped
        ) {
          current_data.push(obj);
        } else if (todayStripped < startDateStripped) {
          upcoming_data.push(obj);
        } else if (todayStripped > endDateStripped) {
          past_data.push(obj);
        }
      });

      setScheduledDataMap({
        current_data,
        upcoming_data,
        past_data,
      });
    }
    setActiveTab(tabId);
  };

  const onRejectRequest = id => {
    setSelectedUserId(id);
    setSelectedModal("reject");
    toggleModal();
  };

  const onRevokeRequest = id => {
    setSelectedUserId(id);
    setSelectedModal("revoke");
    toggleModal();
  };

  const onDeleteSchedule = id => {
    setSelectedUserId(id);
    setSelectedModal("delete_schedule");
    toggleModal();
  };

  const onApproveRequest = async id => {
    const payload = {
      id: id,
      status: "approved",
      reviewer_comments: comments,
    };

    await dispatch(reviewModificationRequest({ data: payload }));
    await dispatch(getAssessmentCatlogById({ id: Number(assessmentId) }));
  };

  const onEditClick = (id, type) => {
    dispatch(setScheduledDetailsToNull());
    return navigate(`${process.env.PUBLIC_URL}/assessments/schedule-details`, {
      state: { scheduleId: id, assessmentId: assessmentId, type: type },
    });
  };

  const getModalHeader = () => {
    if (selectedModal === "reject") {
      if (activeTab !== "modification_request") return DeclineReason;
      else return DeclineModification;
    }
    if (selectedModal === "revoke") {
      return RevokeReason;
    }
    if (selectedModal === "modification") {
      return RequestForModification;
    }
    if (selectedModal === "delete_schedule") {
      return DeleteSchedule;
    }
    if (selectedModal === "reattempt") {
      return ReAttempt;
    }
  };

  const navigateToAssessment = (id, preview, review = false) => {
    const baseUrl = `${process.env.PUBLIC_URL}/assessments/`;
    const assessmentPath = review
      ? `review-self-assessments?participant_id=${id}&review=${review}`
      : "self-assessment-details";

    const fullUrl = baseUrl + assessmentPath;

    if (review) {
      return navigate(fullUrl);
    }

    return navigate(fullUrl, {
      state: { participantId: id, preview, review },
    });
  };

  const handleResponse = id => {
    dispatch(getLearnerAndRmResponse({ participant_id: id })).then(response => {
      const learners = [];
      const trainers = [];

      response?.payload?.responses?.forEach(item => {
        learners.push(item?.learner_response);
        trainers.push(item?.reviewer_response);
      });

      const data = getChartData(trainers, learners);
      setChartData(data);
      toggleGraphModal();
    });
  };

  const onDropdownClick = (id, item, status) => {
    if (isSgaAssessment()) {
      if (status === "yts" || status === "in_progress") {
        setSelectedUserId(id);
        setSelectedModal("reattempt");
        toggleModal();
      }
      if (status === "failed" || status === "retake") {
        if (item === "item1") {
          setSelectedUserId(id);
          setSelectedModal("reattempt");
          toggleModal();
        }
        if (item === "item2") {
          //Export as PDF for the particular user
          showToast(ComingSoon, "warning");
          return;
        }
      }
      if (status === "passed" || status === "completed") {
        //Export as PDF for the particular user
        showToast(ComingSoon, "warning");
        return;
      }
      if (status === "under_review" || status === "draft") {
        if (item === "item1") {
          return navigate(
            `${process.env.PUBLIC_URL}/assessments/review-standalone-assessment`,
            {
              state: { participantId: id },
            }
          );
        }
        if (item === "item2") {
          setSelectedModal("reattempt");
          toggleModal();
        }
      }
    } else if (item === "item1") {
      const hasPermission = hasPermissionToComponent("REVIEW_SELF_ASSESSMENT");

      if (hasPermission && status === "completed") {
        navigateToAssessment(id, true);
      } else {
        navigateToAssessment(id, !hasPermission, hasPermission);
      }
    } else if (item === "item2") {
      handleResponse(id);
    }
  };

  const getLabel = () => {
    if (selectedModal === "reject") {
      return AddReasonForDecline;
    }
    if (selectedModal === "revoke" || selectedModal === "modification") {
      return AddReason;
    }
  };

  const getStandaloneSecondRow = () => {
    const secondColumn = [
      {
        assign: AssessmentDurationInMinutes,
        name: assessmentCatlogDetailData?.estimated_time,
        key: getRandomInteger(1, 10000),
      },
      {
        assign: ReAttemptPolicy,
        name: policyListDataMap[assessmentCatlogDetailData?.policy_id] || "",
        key: getRandomInteger(1, 10000),
      },
    ];
    if (assessmentCatlogDetailData?.frequency_count) {
      secondColumn.push({
        assign: RecurseEvery,
        name:
          assessmentCatlogDetailData?.frequency_count +
          " " +
          frequencyMapping[assessmentCatlogDetailData?.frequency_type],
        key: getRandomInteger(1, 10000),
      });
    }
    if (assessmentCatlogDetailData?.occurrence) {
      secondColumn.push({
        assign: EndAfter,
        name: assessmentCatlogDetailData?.occurrence + " " + Occurrences,
        key: getRandomInteger(1, 10000),
      });
    }
    return secondColumn;
  };

  const isStandaloneAssessment = () => {
    return (
      assessmentCatlogDetailData?.assessment_type === "standalone" ||
      isSgaAssessment()
    );
  };

  const isSgaAssessment = () => {
    return (
      assessmentCatlogDetailData?.assessment_type === "sga" ||
      assessmentCatlogDetailData?.assessment_type === "course"
    );
  };

  const isNotSgaAssessment = () => {
    return (
      assessmentCatlogDetailData?.assessment_type !== "sga" &&
      assessmentCatlogDetailData?.assessment_type !== "course"
    );
  };

  const handlePrimaryBtnClick = async () => {
    if (
      comments === "" &&
      selectedModal !== "delete_schedule" &&
      selectedModal !== "reattempt"
    ) {
      showToast(AddProperReason, "error");
      return;
    }

    switch (selectedModal) {
      case "reject":
        await handleReviewRequest("rejected");
        break;
      case "revoke":
        await handleRevokeRequest();
        break;
      case "delete_schedule":
        await handleDeleteSchedule();
        break;
      case "reattempt":
        if (noOfDays === "" || !noOfDays) {
          showToast(FillReattemptFields, "error");
          return;
        }
        await handleReattemptRequest();
        break;
      default:
        await handleModificationRequest();
        break;
    }

    await dispatch(getAssessmentCatlogById({ id: Number(assessmentId) }));
    resetModalState();
  };

  const handleReviewRequest = async (status: string) => {
    const payload = {
      id: selectedUserId,
      status,
      reviewer_comments: comments,
    };
    await dispatch(reviewModificationRequest({ data: payload }));
  };

  const handleRevokeRequest = async () => {
    const payload = {
      id: selectedUserId,
      status: "revoked",
      reviewer_comments: comments,
    };
    const ownerPayload = {
      assessment_id: assessmentId,
      status: "revoked",
      reviewer_comments: comments,
    };

    if (
      selectedUserId?.toString() ===
      assessmentCatlogDetailData?.owner_id?.toString()
    ) {
      await dispatch(reviewModificationRequest({ data: ownerPayload }));
    } else {
      await dispatch(reviewModificationRequest({ data: payload }));
    }
  };

  const handleDeleteSchedule = async () => {
    const payload = {
      id: selectedUserId,
      assessment_id: assessmentId,
    };
    await dispatch(deleteScheduled({ data: payload }));
    await handleTabClick("schedule_overview");
  };

  const handleReattemptRequest = async () => {
    const request: UpdateReAttemptDaysRequest = {
      participant_id: parseInt(selectedUserId),
      re_attempt_count: noOfDays,
    };
    await dispatch(updateReAttemptDays({ request }));
  };

  const handleModificationRequest = async () => {
    const payload = {
      assessment_id: assessmentId,
      type: "modification",
      requester_comments: comments,
    };
    await dispatch(requestForModificationAcceessAPI({ data: payload }));
  };

  const resetModalState = () => {
    setSelectedUserId("");
    setComments("");
    toggleModal();
  };

  const renderReattemptForm = () => (
    <FormGroup>
      <LabelTooltip
        label={ReAttempt}
        tooltipText={`${Enter} ${ReAttempt}`}
        important
      />
      <Input
        name="re_attempt"
        type="number"
        inputMode="numeric"
        value={noOfDays}
        onKeyDown={handleKeyDownOnNumericWithoutDecimal}
        placeholder={`${Enter} ${ReAttempt}`}
        onChange={e => {
          const value = e.target.value;
          if (parseInt(value) < 100 || value === "") setNoOfDays(value);
        }}
      />
    </FormGroup>
  );

  const renderCommentsForm = () => (
    <FormGroup>
      <LabelTooltip
        label={getLabel()}
        tooltipText={`${Enter} ${Description}`}
        important
      />
      <Input
        onChange={e => {
          if (
            (e.target.value && e.target.value?.length <= 255) ||
            e.target.value === ""
          )
            setComments(e.target.value);
        }}
        maxLength={255}
        rows={4}
        name="objective"
        type="textarea"
        placeholder={`${Enter} ${Description}`}
      />
    </FormGroup>
  );

  const getDetailBtn = () => {
    return (
      (isCurrentUserIsCourseOwner(assessmentCatlogDetailData?.owner_id) ||
        editorIds.includes(currentUserId())) && (
        <Link
          to="/assessments/add-assessment"
          onClick={() => {
            dispatch(setAssessmentCatlogPayloadToInitials());
          }}
          state={{ assessmentId }}
        >
          <div className="edit-details-button">
            <H5 className="primary-text-color pointer">{Modify}</H5>
          </div>
        </Link>
      )
    );
  };

  return (
    <div className="page-body mb-5 pb-3">
      <CommonModal
        sizeTitle={SelfAssessmentSummary}
        modalBodyClassName=""
        isOpen={isGraphModalOpen}
        toggle={toggleGraphModal}
        backdrop="static"
        size="lg"
        showFooter
        primaryBtnText={Close}
        onPrimaryBtnClick={() => {
          toggleGraphModal();
        }}
      >
        <Graph options={chartData?.options} series={chartData?.series} />
      </CommonModal>
      <CommonModal
        backdrop="static"
        size="lg"
        isOpen={isModalOpen}
        toggle={toggleModal}
        sizeTitle={getModalHeader()}
        showFooter={true}
        primaryBtnText={selectedModal === "delete_schedule" ? Confirm : Submit}
        onPrimaryBtnClick={handlePrimaryBtnClick}
      >
        {selectedModal === "reattempt" && renderReattemptForm()}
        {selectedModal !== "delete_schedule" &&
          selectedModal !== "reattempt" &&
          renderCommentsForm()}
        {selectedModal === "delete_schedule" && (
          <P>{AreYouSureDeleteSchedule}</P>
        )}
      </CommonModal>
      <CatlogAssessmentHeading
        heading={assessmentCatlogDetailData?.name}
        headerChildren={getDetailBtn()}
        badgeText=""
        rows={
          isStandaloneAssessment()
            ? [
                {
                  columns: [
                    {
                      assign: AssessmentType,
                      name: isSgaAssessment()
                        ? Course
                        : capitalizeFirstLetter(
                            assessmentCatlogDetailData?.assessment_type
                          ) || "",
                      key: getRandomInteger(1, 10000),
                    },
                    {
                      assign: MappedQuestionBank,
                      name:
                        questionBankListDataMap[
                          assessmentCatlogDetailData?.question_bank_id
                        ] || "",
                      key: getRandomInteger(1, 10000),
                    },
                    {
                      assign: MappedModule,
                      name: assessmentCatlogDetailData?.module_count,
                      key: getRandomInteger(1, 10000),
                    },
                    {
                      assign: OverallCount,
                      name: assessmentCatlogDetailData?.overall_question_count,
                      key: getRandomInteger(1, 10000),
                    },
                  ],
                },
                {
                  columns: getStandaloneSecondRow(),
                },
              ]
            : [
                {
                  columns: [
                    {
                      assign: AssessmentType,
                      name: SelfAssessment,
                      key: getRandomInteger(1, 10000),
                    },
                    {
                      assign: MappedQuestionBank,
                      name:
                        questionBankListDataMap[
                          assessmentCatlogDetailData?.question_bank_id
                        ] || "",
                      key: getRandomInteger(1, 10000),
                    },
                    {
                      assign: MappedModule,
                      name: assessmentCatlogDetailData?.module_count,
                      key: getRandomInteger(1, 10000),
                    },
                  ],
                },
              ]
        }
      />

      {(isStandaloneAssessment() || isSgaAssessment()) &&
        (isCurrentUserIsCourseOwner(assessmentCatlogDetailData?.owner_id) ||
          hasPermissionToComponent("REVOKE_EDITOR_ACCESS") ||
          editorIds.includes(currentUserId())) && (
          <Card className="p-1">
            <CardBody>
              <div className="d-flex flex-column flex-md-row justify-content-between">
                <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
                  <JustifyTabs
                    tabs={
                      isSgaAssessment()
                        ? sgaAssessmentCatlogDetailsTabs
                        : assessmentCatlogDetailsTabs
                    }
                    activeTab={activeTab}
                    onTabClick={handleTabClick}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        )}

      {activeTab === "modification_request" && (
        <ParticipantsDetails
          sections={modificationRequests}
          title={ModificationAccessRequest}
          onRejectRequest={onRejectRequest}
          onApproveRequest={onApproveRequest}
        />
      )}

      {activeTab === "details" && (
        <div className="mb-5">
          <AssessmentModuleList
            heading={ModuleList}
            isEdit={false}
            isDelete={false}
            headerRow={
              assessmentCatlogDetailData?.modules?.length > 0
                ? assessmentCatlogDetailData.modules
                    .map((item: any, ind: number) => {
                      if (ind === 0) {
                        return [
                          {
                            assign: ModuleName,
                            name: item?.module_name,
                            key: getRandomInteger(1, 10000),
                          },
                          {
                            assign: NoOfQuestions,
                            name: item?.question_count,
                            key: getRandomInteger(1, 10000),
                          },
                          {
                            assign: Weightage,
                            name: item?.weightage,
                            key: getRandomInteger(1, 10000),
                          },
                        ];
                      }
                      return null;
                    })
                    .filter(Boolean)
                    .flat()
                : []
            }
            dataRows={
              assessmentCatlogDetailData?.modules?.length > 1
                ? assessmentCatlogDetailData.modules
                    .map((item: any, ind: number) => {
                      if (ind > 0) {
                        return {
                          columns: [
                            {
                              assign: ModuleName,
                              name: item?.module_name,
                              key: getRandomInteger(1, 10000),
                            },
                            {
                              assign: NoOfQuestions,
                              name: item?.question_count,
                              key: getRandomInteger(1, 10000),
                            },
                            {
                              assign: Weightage,
                              name: item?.weightage,
                              key: getRandomInteger(1, 10000),
                            },
                          ],
                        };
                      }
                      return null;
                    })
                    .filter(Boolean)
                : []
            }
          />
          {isStandaloneAssessment() &&
            editors?.length > 0 &&
            (isCurrentUserIsCourseOwner(assessmentCatlogDetailData?.owner_id) ||
              modifications?.length === 1) &&
            (isCurrentUserIsCourseOwner(assessmentCatlogDetailData?.owner_id) ||
              hasPermissionToComponent("REVOKE_EDITOR_ACCESS") ||
              editorIds.includes(currentUserId())) && (
              <div className="mb-5">
                <ParticipantsDetails
                  sections={editors}
                  title={Editor}
                  onRevokeRequest={onRevokeRequest}
                />
              </div>
            )}
          {isStandaloneAssessment() &&
            modifications?.length > 0 &&
            !isCurrentUserIsCourseOwner(assessmentCatlogDetailData?.owner_id) &&
            !hasPermissionToComponent("REVOKE_EDITOR_ACCESS") && (
              <ParticipantsDetails sections={modifications} title={Editor} />
            )}
          {((isSgaAssessment() &&
            isCurrentUserIsCourseOwner(assessmentCatlogDetailData?.owner_id)) ||
            (assessmentCatlogDetailData?.assessment_type === "competency" &&
              (isCurrentUserIsCourseOwner(
                assessmentCatlogDetailData?.owner_id
              ) ||
                hasPermissionToComponent("REVIEW_SELF_ASSESSMENT"))) ||
            hasPermissionToComponent("REVOKE_EDITOR_ACCESS")) &&
            assessmentCatlogDetailData?.assessment_type !== "standalone" && (
              <Card>
                <CardBody className="m-2 mb-3">
                  <H4 className="mt-3">{UserDetails}</H4>
                  <Divider />
                  <div className="mt-2">
                    <StatusDisplay
                      label={UsersEnrolled}
                      number={scheduledDetails?.participants_count}
                    />
                  </div>
                  <div className="mt-4">
                    <BorderTabs
                      showCard={false}
                      tabs={updatedScheduledCompetencyTabs}
                      activeTab={activeCompetencyTab}
                      handleTabClick={tabId => {
                        setActiveCompetencyTab(tabId);
                      }}
                    />
                  </div>
                  {activeCompetencyTab === "assigned" && (
                    <ParticipantsDetails
                      showCard={false}
                      sections={userDetails}
                      title={UserDetails}
                      onDropdownClick={onDropdownClick}
                    />
                  )}
                  {activeCompetencyTab === "pending_review" && (
                    <ParticipantsDetails
                      showCard={false}
                      sections={userDetails?.filter(item => {
                        return displayStatus.includes(item?.display_status);
                      })}
                      title={UserDetails}
                      onDropdownClick={onDropdownClick}
                    />
                  )}
                </CardBody>
              </Card>
            )}
        </div>
      )}
      {activeTab === "schedule_overview" && (
        <div className="mb-5">
          <div className="mt-3">
            <ScheduledDetails
              onDeleteClick={id => {
                onDeleteSchedule(id);
              }}
              onEditClick={id => {
                onEditClick(id, "current");
              }}
              title={Active}
              rows={scheduledDataMap?.current_data}
              showDelete={false}
            />
          </div>
          <div className="mt-3">
            <ScheduledDetails
              onDeleteClick={id => {
                onDeleteSchedule(id);
              }}
              onEditClick={id => {
                onEditClick(id, "upcoming");
              }}
              title={Upcoming}
              rows={scheduledDataMap?.upcoming_data}
            />
          </div>
          <div className="mt-3">
            <ScheduledDetails
              onDeleteClick={id => {
                onDeleteSchedule(id);
              }}
              onEditClick={id => {
                onEditClick(id, "past");
              }}
              title={Past}
              rows={scheduledDataMap?.past_data}
              showDelete={false}
            />
          </div>
        </div>
      )}

      {!review && (
        <div>
          {!isCurrentUserIsCourseOwner(assessmentCatlogDetailData?.owner_id) &&
            (isStandaloneAssessment() || isSgaAssessment()) &&
            !hasAccess &&
            !hasPermissionToComponent("RM_ASSESSMENT_CATLOG") &&
            !hasPermissionToComponent("REVOKE_EDITOR_ACCESS") &&
            !editorIds.includes(currentUserId()) && (
              <FixedFooter>
                <Btn
                  color="primary"
                  onClick={() => {
                    setSelectedModal("modification");
                    toggleModal();
                  }}
                >
                  {RequestForModification}
                </Btn>
              </FixedFooter>
            )}
          {(isCurrentUserIsCourseOwner(assessmentCatlogDetailData?.owner_id) ||
            hasPermissionToComponent("REVOKE_EDITOR_ACCESS")) &&
            isStandaloneAssessment() &&
            activeTab === "schedule_overview" &&
            isNotSgaAssessment() && (
              <FixedFooter>
                <Link
                  to={"/assessments/schedule-assessment"}
                  state={{ assessmentId: assessmentId }}
                  onClick={() => {
                    dispatch(setAssessmentCatlogPayloadToInitials());
                  }}
                >
                  <Btn color="primary">{CreateSchedule}</Btn>
                </Link>
              </FixedFooter>
            )}
        </div>
      )}
    </div>
  );
};

export default AssessmentCatlogDetails;
