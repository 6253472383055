import { Fragment } from "react";

import { H6, LI } from "../../AbstractElements";
import { useAppContext } from "../../AppContext";
import { useAppSelector } from "../../ReduxToolkit/Hooks";
import { MenuItem } from "../../Types/Layout/SidebarType";

import Menulist from "./Menulist";

const SidebarMenuList = () => {
  const { updateActiveMenu, activeMenu } = useAppContext();
  const { pinedMenu, sideBarMenuItems } = useAppSelector(state => state.layout);
  const shouldHideMenu = (mainMenu: MenuItem) => {
    return mainMenu?.Items?.map(data => data.title).every(titles =>
      pinedMenu.includes(titles || "")
    );
  };

  return (
    <>
      {sideBarMenuItems?.map((mainMenu: MenuItem) => (
        <Fragment key={mainMenu.id}>
          <LI
            className={`sidebar-main-title ${shouldHideMenu(mainMenu) ? "d-none" : ""}`}
          >
            <div>
              <H6 className={mainMenu.lanClass ? mainMenu.lanClass : ""}>
                {mainMenu.title}
              </H6>
            </div>
          </LI>
          <Menulist
            menu={mainMenu.Items}
            activeMenu={activeMenu}
            setActiveMenu={updateActiveMenu}
            level={0}
          />
        </Fragment>
      ))}
    </>
  );
};

export default SidebarMenuList;
