import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Row, Col, FormGroup, Input, Button } from "reactstrap";

import { GetFeedbackFromMenteePayload } from "../../../../Api/entities/MentoringCoachingEntity";
import Divider from "../../../../CommonElements/Divider";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import { useAppDispatch } from "../../../../ReduxToolkit/Hooks";
import { getMenteeFeedback } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import {
  Add,
  Done,
  Enter,
  KeyActionsLimitationMessage,
  KeyActionsOrSteps,
  KeyActionsValidationMessage,
  KeyChanges,
  KeyChangesAndDevelopmentGoals,
  KeyChangesLimitationMessage,
  KeyChangesValidationMessage,
  KeyThoughts,
  KeyThoughtsEmergingFromTheSession,
  KeyThoughtsLimitationMessage,
  KeyThoughtsValidationMessage,
  SessionFeedback,
} from "../../../../utils/Constant";

interface FormErrors {
  [key: string]: string;
}

const MenteeSessionFeedbackForm = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = location;
  const { sessionId } = state || {};
  const navigate = useNavigate();
  const [errors, setErrors] = useState<FormErrors>({});
  const [formState, setFormState] = useState({
    keyChanges: "",
    keyActions: "",
    keyThoughts: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};

    if (!formState.keyActions) {
      newErrors.keyActions = KeyActionsValidationMessage;
    } else if (formState.keyActions.length > 255) {
      newErrors.keyActions = KeyActionsLimitationMessage;
    }

    if (!formState.keyChanges) {
      newErrors.keyChanges = KeyChangesValidationMessage;
    } else if (formState.keyChanges.length > 255) {
      newErrors.keyChanges = KeyChangesLimitationMessage;
    }

    if (!formState.keyThoughts) {
      newErrors.keyThoughts = KeyThoughtsValidationMessage;
    } else if (formState.keyThoughts.length > 255) {
      newErrors.keyThoughts = KeyThoughtsLimitationMessage;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onDoneClick = async () => {
    if (validateForm()) {
      const payload: GetFeedbackFromMenteePayload = {
        key_changes_and_dev_goals: formState.keyChanges,
        Key_actions: formState.keyActions,
        key_thoughts: formState.keyThoughts,
      };
      await dispatch(
        getMenteeFeedback({ mentorFeedbackPayload: payload, sessionId })
      );
      return navigate(`${process.env.PUBLIC_URL}/mentoring/i-am-mentee`);
    }
  };

  return (
    <div className="page-body">
      <Card>
        <CardBody>
          <h4 className="p-1 mt-2">
            {Add} {SessionFeedback}
          </h4>

          <Divider />

          <Row>
            <Col md={12}>
              <FormGroup>
                <LabelTooltip
                  label={KeyChangesAndDevelopmentGoals}
                  tooltipText={`${Enter} ${KeyChangesAndDevelopmentGoals}`}
                  important
                />
                <Input
                  type="textarea"
                  name="keyChanges"
                  placeholder={`${Enter} ${KeyChanges}`}
                  value={formState.keyChanges}
                  onChange={handleInputChange}
                  invalid={!!errors.keyChanges}
                  maxLength={255}
                />
                {errors.keyChanges && (
                  <div className="text-danger">{errors.keyChanges}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <LabelTooltip
                  label={KeyActionsOrSteps}
                  tooltipText={`${Enter} ${KeyActionsOrSteps}`}
                  important
                />
                <Input
                  type="textarea"
                  name="keyActions"
                  placeholder={`${Enter} ${KeyActionsOrSteps}`}
                  value={formState.keyActions}
                  onChange={handleInputChange}
                  invalid={!!errors.keyActions}
                  maxLength={255}
                />
                {errors.keyActions && (
                  <div className="text-danger">{errors.keyActions}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <LabelTooltip
                  label={KeyThoughtsEmergingFromTheSession}
                  tooltipText={`${Enter} ${KeyThoughtsEmergingFromTheSession}`}
                  important
                />
                <Input
                  type="textarea"
                  name="keyThoughts"
                  placeholder={`${Enter} ${KeyThoughts}`}
                  value={formState.keyThoughts}
                  onChange={handleInputChange}
                  invalid={!!errors.keyThoughts}
                  maxLength={255}
                />
                {errors.keyThoughts && (
                  <div className="text-danger">{errors.keyThoughts}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row className="d-flex justify-content-end">
            <Col xs="auto">
              <Button color="primary" onClick={onDoneClick}>
                {Done}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default MenteeSessionFeedbackForm;
