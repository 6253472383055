import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Row } from "reactstrap";

import { Btn, H2, H4 } from "../../../AbstractElements";
import FixedFooter from "../../../CommonElements/FixedFooter";
import { Back, Start } from "../../../utils/Constant";
import { sanitizedContent, scrollToTop } from "../../../utils/helper/helper";
import Header from "../Header";

import "./style.scss";
const AssessmentInstruction = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleBack = () => {
    navigate(-1);
  };
  const handleStart = () => {
    return navigate("/learning-assessment", {
      state: {
        participantId: state?.participantId,
        name: state.name,
        scheduleCourse: state?.scheduleCourse,
      },
    });
  };
  return (
    <>
      <Header name={state?.name} />
      <Row className="instruction-assessment">
        <Card>
          <CardBody>
            <H2 className=" text-center p-4 text-gray">
              {"Need content from GMMCO Team"}
            </H2>
            <H4 className="p-2 text-gray">{"Terms and Conditions : "}</H4>
            <div
              className="p-2 sanitized-content"
              dangerouslySetInnerHTML={{
                __html: sanitizedContent(state?.termsAndCondition),
              }}
            />
          </CardBody>
        </Card>
      </Row>

      <FixedFooter>
        <Btn
          onClick={handleBack}
          outline
          className="alert-light-primary"
          color="primary"
        >
          {Back}
        </Btn>
        <Btn onClick={handleStart} color="primary">
          {Start}
        </Btn>
      </FixedFooter>
    </>
  );
};

export default AssessmentInstruction;
