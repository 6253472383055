import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Card, CardBody, FormGroup, Input } from "reactstrap";

import {
  Badges,
  DefaultButton,
  MUIIcons,
  H3,
  H4,
  SecondaryButton,
} from "../../../../../AbstractElements";
import { STATUS_200, WILL_BE_UPLOADED } from "../../../../../Api/constants";
import ButtonDropdown from "../../../../../CommonElements/ButtonDropdown";
import FileHandler from "../../../../../CommonElements/FileHandler";
import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../../CommonElements/Modal";
import MultiFileUpload from "../../../../../CommonElements/MultiFileUpload/MultiFileUpload";
import { useAppDispatch } from "../../../../../ReduxToolkit/Hooks";
import {
  deleteModuleOrQb,
  updateQuestioninQb,
} from "../../../../../ReduxToolkit/Reducers/QuestionBankSlice";
import { uploadFileApi } from "../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddQuestions,
  ClickHereToAddReferenceFile,
  Confirm,
  DeleteQuestionFromModule,
  DeleteQuestionHeader,
  Enter,
  PleaseEnterQuestion,
  Question,
  QBEnterQuestionsToolTipMessage,
  QuestionFileSizeNote,
  Save,
  QBQuestionReferenceFileToolTipMessage,
} from "../../../../../utils/Constant";
import {
  extractFileName,
  getQuestionsType,
  showToast,
} from "../../../../../utils/helper/helper";

const OpenChoiceAndCompetencyQuestions = ({
  selectedModule,
  selectedQuestion,
  questionBankPayloadData,
  type,
  setSelectedQuestionType,
  questionBankId,
  selectedQuestionType,
  eachModuleQuestionMap,
  setEachModuleQuestionMap,
  getEachModuleQuestion,
  getQuestionTypes,
  eachModuleQuestionNumberMap,
  getQuestionBankData,
  setSelectedQuestion,
}) => {
  const dispatch = useAppDispatch();
  const [questionName, setQuestionName] = useState("");
  const [mediaFile, setMediaFile] = useState<any>("");
  const [flushData, setFlushData] = useState(false);

  useEffect(() => {
    setQuestionName("");
    setMediaFile("");
    setFlushData(true);
    setFlushData(false);
    const module_id = selectedModule;
    if (
      module_id &&
      selectedQuestion &&
      eachModuleQuestionMap &&
      eachModuleQuestionMap[module_id] &&
      eachModuleQuestionMap[module_id][selectedQuestion]
    ) {
      setQuestionName(
        eachModuleQuestionMap[module_id][selectedQuestion]?.question_text || ""
      );
      setMediaFile(
        eachModuleQuestionMap[module_id][selectedQuestion]?.media_url || ""
      );
    }
  }, [
    questionBankPayloadData,
    eachModuleQuestionMap,
    selectedModule,
    selectedQuestion,
  ]);

  const getType = (clicked: string) => {
    setSelectedQuestionType(clicked);
    getQuestionTypes(clicked);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    if (!isModalOpen) {
      setFlushData(false);
    }
    setIsModalOpen(!isModalOpen);
  };

  const uploadFile = async documents => {
    if (documents?.length > 0) {
      const postData = new FormData();
      documents?.forEach(file => {
        postData.append("files", file[0]);
      });
      const response = await dispatch(uploadFileApi({ content: postData }));
      return response?.payload?.data?.file_urls;
    }
  };

  return (
    <div className="mb-5">
      <CommonModal
        sizeTitle={DeleteQuestionHeader}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={async () => {
          let deleted = null;
          const module_id = selectedModule;
          if (eachModuleQuestionMap[module_id][selectedQuestion]?.id) {
            const deleteObj = {
              type: "question",
              id: [eachModuleQuestionMap[module_id][selectedQuestion]?.id],
            };

            deleted = await dispatch(deleteModuleOrQb({ data: deleteObj }));
            if (deleted?.payload?.status_code === STATUS_200) {
              const deleteObj = { ...eachModuleQuestionMap };
              delete deleteObj[module_id][selectedQuestion].id;
              setEachModuleQuestionMap(deleteObj);
            }
            getQuestionTypes(selectedQuestionType);
            await getQuestionBankData(questionBankId);
          } else {
            setQuestionName("");
            setMediaFile("");
            if (!flushData) setFlushData(true);
          }
          toggleModal();
        }}
        primaryBtnText={Confirm}
      >
        {DeleteQuestionFromModule}
      </CommonModal>
      <Card>
        <CardBody className="m-2 mt-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-2">
              <div className="choose-option">
                <H4 className="title">
                  {Question}{" "}
                  {selectedQuestion &&
                  eachModuleQuestionMap &&
                  selectedModule &&
                  eachModuleQuestionMap[selectedModule]
                    ? Object.keys(eachModuleQuestionMap[selectedModule])
                        ?.map(Number)
                        ?.indexOf(selectedQuestion) + 1
                    : eachModuleQuestionMap &&
                        selectedModule &&
                        eachModuleQuestionNumberMap[selectedModule]
                      ? eachModuleQuestionNumberMap[selectedModule] + 1
                      : 1}
                </H4>
              </div>
              {!questionBankPayloadData?.is_competency && <span>{type}</span>}
            </div>
            <MUIIcons
              className="pointer primary-icon-color"
              iconName="DeleteOutlineOutlined"
              size={18}
              onClick={toggleModal}
            />
          </div>

          <FormGroup className="mt-2">
            <LabelTooltip
              label={Question}
              tooltipText={QBEnterQuestionsToolTipMessage}
              important
            />
            <Input
              rows={4}
              name="question"
              type="text"
              value={questionName}
              placeholder={`${Enter} ${Question}`}
              maxLength={4000}
              onChange={e => setQuestionName(e.target.value)}
            />
          </FormGroup>
          {typeof mediaFile === "string" &&
          mediaFile !== "" &&
          extractFileName(mediaFile) !== WILL_BE_UPLOADED ? (
            <H3>
              <Badges className="pointer" color="light text-dark">
                <div className="d-flex gap-2">
                  <MUIIcons size={24} iconName="InsertDriveFileOutlined" />
                  <FileHandler mediaUrl={mediaFile} />
                  <MUIIcons
                    iconName="DeleteOutlineOutlined"
                    className="primary-icon-color pointer"
                    size={24}
                    onClick={() => {
                      setMediaFile([]);
                    }}
                  />
                </div>
              </Badges>
            </H3>
          ) : (
            <>
              <LabelTooltip
                label={ClickHereToAddReferenceFile}
                tooltipText={QBQuestionReferenceFileToolTipMessage}
                important={false}
              />
              <div className="text-danger">{QuestionFileSizeNote}</div>
              <MultiFileUpload
                flushData={flushData}
                accept=".pdf,.docx,.doc,.mp4,.mp3,.png,.jpg,.jpeg"
                multiple={false}
                onFileChange={(file, status) => {
                  if (status === "removed") {
                    if (mediaFile)
                      setMediaFile(prevDocuments =>
                        prevDocuments.filter(item => item[0].name !== file.name)
                      );
                  } else if (status === "done" && file) {
                    setMediaFile(prevDocuments => [
                      ...(Array.isArray(prevDocuments) ? prevDocuments : []),
                      [file],
                    ]);
                  }
                }}
              />
            </>
          )}
        </CardBody>
      </Card>
      <div className="d-flex gap-3">
        {questionBankPayloadData?.is_competency ? (
          <DefaultButton color="primary" onClick={() => getType("open_text")}>
            {AddQuestions}
          </DefaultButton>
        ) : (
          <ButtonDropdown
            items={getQuestionsType(getType, selectedModule)}
            btnText={AddQuestions}
            direction="down"
          />
        )}
        <SecondaryButton
          onClick={async () => {
            const module_id = selectedModule;
            if (!questionName) {
              showToast(PleaseEnterQuestion, "error");
              return;
            }

            let uploadedFile = "";
            if (mediaFile && mediaFile !== "" && typeof mediaFile !== "string")
              uploadedFile = await uploadFile(mediaFile);

            const updatedModuleId: any =
              questionBankPayloadData?.modules?.filter(
                item => item?.id === selectedModule
              );

            const questionPayload = {
              question_bank_id: questionBankId,
              modules: [
                {
                  id: module_id,
                  module_name: updatedModuleId?.name,
                  questions: [
                    {
                      ...(selectedQuestion && { id: selectedQuestion }),
                      question_text: questionName,
                      question_type: selectedQuestionType,
                      media_url:
                        uploadedFile?.[0] ||
                        (typeof mediaFile === "string" && mediaFile) ||
                        "",
                      options: null,
                    },
                  ],
                },
              ],
            };
            await dispatch(updateQuestioninQb({ questions: questionPayload }));
            await getQuestionBankData(questionBankId);
            await getEachModuleQuestion(module_id, false, true);

            setSelectedQuestion(null);
            setQuestionName("");
            setMediaFile("");
            setFlushData(true);
          }}
        >
          {Save}
        </SecondaryButton>
      </div>
    </div>
  );
};

OpenChoiceAndCompetencyQuestions.propTypes = {
  selectedModule: PropTypes.number.isRequired,
  selectedQuestion: PropTypes.number.isRequired,
  questionBankPayloadData: PropTypes.shape({
    modules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        questions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            question_text: PropTypes.string,
            media_url: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.arrayOf(PropTypes.string),
            ]),
            options: PropTypes.arrayOf(
              PropTypes.shape({
                option_text: PropTypes.string,
                is_correct: PropTypes.bool,
              })
            ),
            saved: PropTypes.bool,
            question_type: PropTypes.string,
          })
        ),
      })
    ).isRequired,
    is_competency: PropTypes.bool,
  }).isRequired,
  type: PropTypes.string.isRequired,
  setSelectedQuestionType: PropTypes.func.isRequired,
  questionBankId: PropTypes.number.isRequired,
  selectedQuestionType: PropTypes.string.isRequired,
  eachModuleQuestionMap: PropTypes.object.isRequired,
  eachModuleQuestionNumberMap: PropTypes.object.isRequired,
  setEachModuleQuestionMap: PropTypes.func.isRequired,
  getEachModuleQuestion: PropTypes.func.isRequired,
  getQuestionTypes: PropTypes.func.isRequired,
  getQuestionBankData: PropTypes.func,
  setSelectedQuestion: PropTypes.func,
};

export default OpenChoiceAndCompetencyQuestions;
