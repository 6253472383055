import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";

import { DefaultButton, H3, P } from "../../../../AbstractElements";
import DateRangeDisplay from "../../../../CommonElements/DateRangeDisplay";
import Divider from "../../../../CommonElements/Divider";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import StatusDisplay from "../../../../CommonElements/StatusDisplay";
import CourseContentTable from "../../../../container/FeedbackCourseContent";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  getCourseModuleFeedabck,
  postFeedback,
} from "../../../../ReduxToolkit/Reducers/FeedbackRatingSlice";
import {
  Comment,
  Enter,
  FeedbackNumberList,
  Instructor,
  isRequired,
  Location,
  PleaseRateYourLevelAgreement,
  PracticalPercentage,
  Room,
  Submit,
  SumMustBe100,
  TheoryPercentage,
  ThisFieldRequired,
} from "../../../../utils/Constant";
import { handleKeyDownOnNumericWithoutDecimal } from "../../../../utils/helper/helper";

interface QuestionData {
  id: number;
  category: string;
  question_text: string;
  answer_type: string;
  response?: string;
}

const CourseFeedback = () => {
  const location = useLocation();
  const {
    course_name,
    location_name,
    classroom_name,
    tranierName,
    course_start_date,
    course_end_date,
    course_schedule_id,
    review,
    course_feedback_id,
    participant_id,
  } = location.state;

  const [formValues, setFormValues] = useState({
    theoryPercentage: null,
    practicalPercentage: null,
    comment: "",
  });

  const [errors, setErrors] = useState({
    theoryPercentage: "",
    practicalPercentage: "",
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [questionsData, setQuestionsData] = useState<QuestionData[]>([]);

  const { questionsList } = useAppSelector(state => state.feedback);

  useEffect(() => {
    dispatch(
      getCourseModuleFeedabck({
        type: "course",
        courseScheduleId: !review ? course_schedule_id : null,
        id: review && !participant_id ? course_feedback_id : null,
        schedule_id: review ? course_schedule_id : null,
        participant_id: review ? participant_id : null,
      })
    );
  }, []);

  const transformFeedbackData = data => {
    const updatedData = data?.feedback?.flatMap(feedbackItem =>
      Object.values(feedbackItem.overall_category).flatMap((category: any) =>
        category.question_responses.map(question => ({
          id: question.question_id,
          category: category.category,
          question_text: question.question_text,
          answer_type: question.response_type,
          response: question?.response,
        }))
      )
    );
    setQuestionsData(updatedData);
  };

  useEffect(() => {
    if (review && questionsList) {
      setFormValues({
        theoryPercentage: null,
        practicalPercentage: null,
        comment: questionsList?.feedback?.[0]?.overall_feedback_comment ?? "",
      });
      transformFeedbackData(questionsList);
    }
  }, [review, questionsList]);

  useEffect(() => {
    if (!review && questionsList?.questions?.length > 0) {
      setQuestionsData(questionsList.questions);
    }
  }, [questionsList]);

  const handleRating = (id: number, rate: number) => {
    setQuestionsData(prevData =>
      prevData.map(question =>
        question.id === id
          ? { ...question, response: rate.toString() }
          : question
      )
    );
  };

  const handleInputChange = (id: number, value: string) => {
    setQuestionsData(prevData =>
      prevData.map(question =>
        question.id === id ? { ...question, response: value } : question
      )
    );
  };

  const groupQuestionsByCategory = () => {
    return (
      questionsData?.reduce(
        (grouped, question) => {
          if (!grouped[question.category]) {
            grouped[question.category] = [];
          }
          grouped[question.category].push(question);
          return grouped;
        },
        {} as Record<string, QuestionData[]>
      ) || {}
    );
  };

  const handleChange = e => {
    setFormValues(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleError = () => {
    const newErrors = {
      theoryPercentage: "",
      practicalPercentage: "",
      questions: {},
    };

    let isError = false;

    const theory = parseInt(formValues.theoryPercentage) || 0;
    const practical = parseInt(formValues.practicalPercentage) || 0;

    if (!formValues.theoryPercentage) {
      newErrors.theoryPercentage = `${TheoryPercentage} ${isRequired}`;
      isError = true;
    }
    if (!formValues.practicalPercentage) {
      newErrors.practicalPercentage = `${PracticalPercentage} ${isRequired}`;
      isError = true;
    }
    if (
      formValues.theoryPercentage &&
      formValues.practicalPercentage &&
      theory + practical !== 100
    ) {
      newErrors.theoryPercentage = SumMustBe100;
      isError = true;
    }

    setQuestionsData(prevData =>
      prevData.map(question => {
        if (!question.response) {
          isError = true;
          return {
            ...question,
            error: ThisFieldRequired,
          };
        }
        return { ...question, error: "" };
      })
    );

    setErrors(newErrors);

    if (isError) {
      return isError;
    }
  };

  const handleSubmit = async () => {
    const isErrrp = handleError();
    if (isErrrp) {
      return;
    }

    const payload = createPayload();

    const response = await dispatch(postFeedback({ payload }));

    if (response.payload) {
      navigate(-1);
    }
  };

  const createPayload = () => {
    return {
      course_schedule_id: course_schedule_id,
      theory_percent: parseInt(formValues.theoryPercentage),
      practical_percent: parseInt(formValues.practicalPercentage),
      overall_feedback_comment: formValues.comment,
      question_responses: questionsData.map(question => {
        const response = question.response || "";
        return {
          question_id: question.id,
          response: response,
        };
      }),
    };
  };

  const formatString = input => {
    return input
      .replace(/_/g, " ")
      .toLowerCase()
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="page-body">
      <Card className="p-2">
        <CardBody className="mb-5">
          <H3 className="fw-bold">{course_name}</H3>
          <Row className="mt-3">
            <Col lg="4" className="mt-1">
              <DateRangeDisplay
                startDate={course_start_date}
                endDate={course_end_date}
              />
            </Col>
            <Col className="d-flex gap-5 mt-3">
              <StatusDisplay label={Location} number={location_name} />
              <StatusDisplay label={Room} number={classroom_name} />
              <StatusDisplay label={Instructor} number={tranierName} />
            </Col>
          </Row>
          <Divider />
          {!review && (
            <div>
              <P className="mt-1 mb-1">{PleaseRateYourLevelAgreement}</P>
              <P className="m-0">{FeedbackNumberList}</P>
              <Row className="mt-3">
                <Col md="6">
                  <FormGroup>
                    <LabelTooltip
                      label={TheoryPercentage}
                      tooltipText={`${Enter} ${TheoryPercentage}`}
                      important={true}
                      placement={"bottom"}
                    />
                    <Input
                      type="number"
                      className="option-input"
                      name="theoryPercentage"
                      placeholder={`${Enter} ${TheoryPercentage}`}
                      value={formValues?.theoryPercentage}
                      onChange={handleChange}
                      maxLength={100}
                      onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                      invalid={!!errors.theoryPercentage}
                    />
                    {errors.theoryPercentage && (
                      <div className="invalid-feedback">
                        {errors.theoryPercentage}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <LabelTooltip
                      label={PracticalPercentage}
                      tooltipText={`${Enter} ${PracticalPercentage}`}
                      important={true}
                      placement={"bottom"}
                    />
                    <Input
                      type="number"
                      className="option-input"
                      name="practicalPercentage"
                      placeholder={`${Enter} ${PracticalPercentage}`}
                      value={formValues?.practicalPercentage}
                      onChange={handleChange}
                      maxLength={100}
                      onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                      invalid={!!errors.practicalPercentage}
                    />
                    {errors.practicalPercentage && (
                      <div className="invalid-feedback">
                        {errors.practicalPercentage}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Divider />
            </div>
          )}
          <div className="mt-3">
            {Object.entries(groupQuestionsByCategory())?.map(
              ([category, questionsList]) => (
                <div className="mb-4" key={category}>
                  <CourseContentTable
                    key={category}
                    questions={questionsList}
                    handleRating={handleRating}
                    handleInputChange={handleInputChange}
                    category={formatString(category)}
                    review={review}
                  />
                </div>
              )
            )}
          </div>
          <LabelTooltip
            label={Comment}
            tooltipText={`${Enter} ${Comment}`}
            important={false}
            placement={"bottom"}
          />
          {review ? (
            <div>{formValues?.comment || "-"}</div>
          ) : (
            <Input
              type="textarea"
              name="comment"
              rows={4}
              placeholder={`${Enter} ${Comment}`}
              value={formValues?.comment}
              onChange={handleChange}
              maxLength={100}
            />
          )}
          {!review && (
            <FixedFooter>
              <DefaultButton color="primary" onClick={handleSubmit}>
                {Submit}
              </DefaultButton>
            </FixedFooter>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default CourseFeedback;
