import { FixedSizeList as List } from "react-window";

import "./style.scss";
import { NoMatchesFound } from "../../utils/Constant";

const SearchDropDownPaginator = ({
  options,
  children,
  maxHeight,
  getValue,
  itemSize = 35,
}: any) => {
  const [value] = getValue();

  const flatOptions = options?.flatMap((option: any) =>
    "options" in option ? option?.options : option
  );

  const initialOffset =
    flatOptions?.findIndex((option: any) => option?.value === value?.value) *
    itemSize;

  const hasMatches = children?.length > 0;

  return hasMatches ? (
    <List
      height={maxHeight}
      itemCount={children?.length}
      itemSize={itemSize + 5}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => (
        <div className="dropdown-item" style={style}>
          {children[index]}
        </div>
      )}
    </List>
  ) : (
    <div className="p-2">{NoMatchesFound}</div>
  );
};

export default SearchDropDownPaginator;
