import PropTypes from "prop-types";

import { H3 } from "../../../../AbstractElements";
import "./style.scss";

interface ProfileNameDataProps {
  name: string;
  designation: string;
  empId: string;
}

const ProfileName: React.FC<ProfileNameDataProps> = ({
  name,
  designation,
  empId,
}) => {
  return (
    <div className="user-profile-designation">
      <H3>{name}</H3>
      <div className="desc">
        {designation || empId ? (
          <>
            {designation || "-"} | {empId || "-"}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

ProfileName.propTypes = {
  name: PropTypes.string.isRequired,
  designation: PropTypes.string.isRequired,
  empId: PropTypes.string.isRequired,
};

export default ProfileName;
