import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  GET,
  GET_BUSINESS_GROUP,
  GET_BUSINESS_GROUP_LIST_URL,
  GET_COMPETENCY,
  GET_COMPETENCY_REQUEST_URL,
  GET_CURICULUM,
  GET_CURICULUM_REQUEST_URL,
  GET_DESIGNATION,
  GET_DESIGNATION_BY_ID,
  GET_DESIGNATION_BY_ID_URL,
  GET_DESIGNATION_LIST_URL,
  GET_DOMAIN,
  GET_DOMAIN_REQUEST_URL,
  GET_LEARNING_OUTCOME,
  GET_LEARNING_OUTCOME_REQUEST_URL,
  GET_MODULE,
  GET_MODULES_URL,
  GET_POLICY,
  GET_POLICY_URL,
  GET_PRE_REQUISITES,
  GET_PRE_REQUISITES_URL,
  GET_PROFICIENCY,
  GET_PROFICIENCY_REQUEST_URL,
  GET_QUESTION_BANK,
  GET_QUESTION_BANK_URL,
  GET_REFERENCE,
  GET_REFERNCE_REQUEST_URL,
  GET_REGION,
  GET_REGION_REQUEST_URL,
  GET_RESOURCE,
  GET_RESOURCE_REQUEST_URL,
  PUT,
  REVIEW_LEARNING_OUTCOME,
  REVIEW_LEARNING_OUTCOME_URL,
  GET_ROLES,
  GET_ROLES_URL,
  GET_GRADES,
  GET_GRADES_URL,
  GET_TOOLS,
  GET_TOOLS_URL,
  GET_TRAINING_AID,
  GET_TRAINING_AID_URL,
  GET_SBU,
  GET_SBU_URL,
  GET_LEVEL,
  GET_LEVEL_URL,
  GET_USER_MASTER,
  GET_USER_MASTER_URL,
  GET_TAGS,
  GET_TAGS_URL,
  GET_FILTER_ROLES,
  GET_FILTER_ROLES_URL,
  GET_USER_MASTER_WITHOUT_FILTER_URL,
  GET_INDUSTRY,
  GET_INDUSTRY_URL,
  GET_CLASSROOM,
  GET_CLASSROOM_URL,
  FETCH_CALENDAR,
  GET_CALENDAR_URL,
  GET_INDUSTRY_JOB_ROLES_URL,
  GET_INDUSTRY_JOB_ROLES,
  GET_LOCATION,
  GET_LOCATION_URL,
  FETCH_ASSETS_FOR_SCHEDULE,
  GET_ASSETS_URL_FOR_SCHEDULE,
  POST,
  POST_ASSETS_FOR_SCHEDULE,
  DELETE,
  DELETE_ASSETS_FOR_SCHEDULE,
  DELETE_ASSETS_URL_FOR_SCHEDULE,
  SUPERSET_REPORT_LIST,
  SUPERSET_REPORT_LIST_URL,
  GET_USER_LOCATION,
  GET_USER_LOCATION_URL,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface MasterData {
  regionList: RegionResponse;
  resourceList: ResourceResponse;
  refernceList: RefernceResponse;
  learningOutcomeList: LearningOutcomeResponse;
  domainList: DomainResponse;
  competencyList: CompetencyResponse;
  proficiencyList: ProficiencyResponse;
  curiculumList: CuriculumResponse;
  preRequisiteList: PrerquisiteResponse;
  questionBankList: QuestionBankData;
  policyList: PoliciesData;
  supersetRepostList: any;
  moduleList: ModulesData;
  designationList: DesignationResponse;
  businessGroupList: BusinessGroupResponse;
  toolsList: any;
  tagList: any;
  trainingList: any;
  loading: boolean;
  error: string | null;
  roleList: RoleListResponse;
  roleOnFilter: RoleListResponse;
  sbuList: SbuListResponse;
  levelList: LevelListResponse;
  gradeList: GradeListResponse;
  userMasterList: UserMasterListResponse;
  industryList: IndustryListResponse;
  locationList: LocationListResponse;
  userLocationList: LocationListResponse;
  classRoomList: ClassRoomListResponse;
  calendarData: { calendar: [] };
  assetsList: any;
}

const initialState: MasterData = {
  regionList: { regions: [] },
  resourceList: { resources: [] },
  refernceList: { references: [] },
  learningOutcomeList: { learning_outcomes: [] },
  domainList: { domain: [] },
  competencyList: { competency: [] },
  proficiencyList: { proficiency: [] },
  preRequisiteList: { preRequisite: [] },
  curiculumList: { curriculum: [] },
  questionBankList: { question_banks: [] },
  policyList: { policies: [] },
  supersetRepostList: {},
  moduleList: { modules: [] },
  designationList: { designation: [] },
  businessGroupList: { business_group: [] },
  toolsList: { resources: [] },
  trainingList: { resources: [] },
  loading: false,
  error: null,
  roleList: { job_role: [] },
  roleOnFilter: { job_role: [] },
  sbuList: { sbu: [] },
  levelList: { levels: [] },
  userMasterList: { users: [] },
  tagList: { tags: [] },
  gradeList: { grades: [] },
  industryList: { industry: [] },
  locationList: { location: [] },
  userLocationList: { location: [] },
  classRoomList: { class_room: [] },
  calendarData: { calendar: [] },
  assetsList: {
    resources: [],
  },
};

export const getRegionList = createAsyncThunk(GET_REGION, async () => {
  const response = await apiRequest(GET, GET_REGION_REQUEST_URL());
  return response.data;
});

export const getToolsList = createAsyncThunk(GET_TOOLS, async () => {
  const response = await apiRequest(GET, GET_TOOLS_URL());
  return response.data;
});

export const getTrainingAidList = createAsyncThunk(
  GET_TRAINING_AID,
  async () => {
    const response = await apiRequest(GET, GET_TRAINING_AID_URL());
    return response.data;
  }
);

export const getResourceList = createAsyncThunk(GET_RESOURCE, async () => {
  const response = await apiRequest(GET, GET_RESOURCE_REQUEST_URL());
  return response.data;
});

export const getRefernceList = createAsyncThunk(GET_REFERENCE, async () => {
  const response = await apiRequest(GET, GET_REFERNCE_REQUEST_URL());
  return response.data;
});

export const getQuestionBankList = createAsyncThunk(
  GET_QUESTION_BANK,
  async () => {
    const response = await apiRequest(GET, GET_QUESTION_BANK_URL());
    return response.data;
  }
);
export const getModuleList = createAsyncThunk(
  GET_MODULE,
  async ({ id }: { id: string }) => {
    const response = await apiRequest(GET, GET_MODULES_URL(id));
    return response.data;
  }
);

export const getDesignationList = createAsyncThunk(
  GET_DESIGNATION,
  async () => {
    const response = await apiRequest(GET, GET_DESIGNATION_LIST_URL());
    return response.data;
  }
);

export const getDesignationById = createAsyncThunk(
  GET_DESIGNATION_BY_ID,
  async ({ id }: { id: string }) => {
    const response = await apiRequest(GET, GET_DESIGNATION_BY_ID_URL(id));
    return response.data?.designation[0]?.name;
  }
);

export const getBusinessGroupList = createAsyncThunk(
  GET_BUSINESS_GROUP,
  async () => {
    const response = await apiRequest(GET, GET_BUSINESS_GROUP_LIST_URL());
    return response.data;
  }
);

export const getPolicyList = createAsyncThunk(GET_POLICY, async () => {
  const response = await apiRequest(GET, GET_POLICY_URL());
  return response.data;
});

export const getSupersetReportsList = createAsyncThunk(
  SUPERSET_REPORT_LIST,
  async ({ type, dashboard_id }: { type?: string; dashboard_id?: string }) => {
    const response = await apiRequest(
      GET,
      SUPERSET_REPORT_LIST_URL(type, dashboard_id)
    );
    return response.data;
  }
);

export const getLearningOutcomeList = createAsyncThunk(
  GET_LEARNING_OUTCOME,
  async ({
    domainId,
    competencyId,
    proficiencyId,
  }: {
    domainId: string;
    competencyId: string;
    proficiencyId: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_LEARNING_OUTCOME_REQUEST_URL(domainId, competencyId, proficiencyId)
    );
    return response.data;
  }
);

export const reviewLearningOutcome = createAsyncThunk(
  REVIEW_LEARNING_OUTCOME,
  async ({ approveLo }: { approveLo: ApproveLearningOutcome }) => {
    const response = await apiRequest(
      PUT,
      REVIEW_LEARNING_OUTCOME_URL(),
      approveLo
    );
    return response;
  }
);

export const getDomainList = createAsyncThunk(GET_DOMAIN, async () => {
  const response = await apiRequest(GET, GET_DOMAIN_REQUEST_URL());
  return response.data;
});

export const getCompetencyList = createAsyncThunk(
  GET_COMPETENCY,
  async ({ domainId }: { domainId: string }) => {
    const response = await apiRequest(
      GET,
      GET_COMPETENCY_REQUEST_URL(domainId)
    );
    return response.data;
  }
);

export const getProficiencyList = createAsyncThunk(
  GET_PROFICIENCY,
  async () => {
    const response = await apiRequest(GET, GET_PROFICIENCY_REQUEST_URL());
    return response.data;
  }
);

export const getPrerequisiteList = createAsyncThunk(
  GET_PRE_REQUISITES,
  async () => {
    const response = await apiRequest(GET, GET_PRE_REQUISITES_URL());
    return response.data;
  }
);

export const getCuriculumList = createAsyncThunk(GET_CURICULUM, async () => {
  const response = await apiRequest(GET, GET_CURICULUM_REQUEST_URL());
  return response.data;
});

export const getIndustryList = createAsyncThunk(GET_INDUSTRY, async () => {
  const response = await apiRequest(GET, GET_INDUSTRY_URL());
  return response.data;
});

export const getRolesList = createAsyncThunk(
  GET_ROLES,
  async ({ getMappedRole }: { getMappedRole?: string }) => {
    const response = await apiRequest(GET, GET_ROLES_URL(getMappedRole));
    return response.data;
  }
);

export const getFilterRolesList = createAsyncThunk(
  GET_FILTER_ROLES,
  async () => {
    const response = await apiRequest(GET, GET_FILTER_ROLES_URL());
    return response.data;
  }
);

export const getIndustryJobRolesList = createAsyncThunk(
  GET_INDUSTRY_JOB_ROLES,
  async ({ ind_id }: { ind_id?: string }) => {
    const response = await apiRequest(GET, GET_INDUSTRY_JOB_ROLES_URL(ind_id));
    return response.data;
  }
);

export const getSbuList = createAsyncThunk(GET_SBU, async () => {
  const response = await apiRequest(GET, GET_SBU_URL());
  return response.data;
});

export const getLevelList = createAsyncThunk(GET_LEVEL, async () => {
  const response = await apiRequest(GET, GET_LEVEL_URL());
  return response.data;
});

export const getGradeList = createAsyncThunk(GET_GRADES, async () => {
  const response = await apiRequest(GET, GET_GRADES_URL());
  return response.data;
});

export const getUserMasterList = createAsyncThunk(
  GET_USER_MASTER,
  async ({
    roleId,
    sbuId,
    type,
  }: {
    roleId: string;
    sbuId: string;
    type?: string;
  }) => {
    let url = GET_USER_MASTER_WITHOUT_FILTER_URL(type);
    if (roleId || sbuId) {
      url = GET_USER_MASTER_URL(roleId, sbuId, type);
    }
    const response = await apiRequest(GET, url);
    return response.data;
  }
);

export const getTagsList = createAsyncThunk(
  GET_TAGS,
  async ({ tagType }: { tagType: string }) => {
    const response = await apiRequest(GET, GET_TAGS_URL(tagType));
    return response?.data;
  }
);

export const getLocationList = createAsyncThunk(GET_LOCATION, async () => {
  const response = await apiRequest(GET, GET_LOCATION_URL());
  return response?.data;
});

export const getUserLocationList = createAsyncThunk(
  GET_USER_LOCATION,
  async () => {
    const response = await apiRequest(GET, GET_USER_LOCATION_URL());
    return response?.data;
  }
);

export const getClassRoomList = createAsyncThunk(
  GET_CLASSROOM,
  async ({
    id,
    start_date,
    end_date,
    schedule_id,
  }: {
    id?: string;
    start_date?;
    end_date?;
    schedule_id?;
  }) => {
    const response = await apiRequest(
      GET,
      GET_CLASSROOM_URL(id, start_date, end_date, schedule_id)
    );
    return response?.data;
  }
);

export const fetchCalendar = createAsyncThunk(
  FETCH_CALENDAR,
  async ({ date, location_id }: { date: string; location_id: string }) => {
    const response = await apiRequest(GET, GET_CALENDAR_URL(date, location_id));
    return response.data;
  }
);

export const fetchAssetsListForSchedule = createAsyncThunk(
  FETCH_ASSETS_FOR_SCHEDULE,
  async ({ schedule_id }: { schedule_id?: string }) => {
    const response = await apiRequest(
      GET,
      GET_ASSETS_URL_FOR_SCHEDULE(schedule_id)
    );
    return response.data;
  }
);

export const postAssetsForSchedule = createAsyncThunk(
  POST_ASSETS_FOR_SCHEDULE,
  async ({ assetPayload }: { assetPayload: any }) => {
    const response = await apiRequest(
      assetPayload?.resource_history_id ? PUT : POST,
      GET_ASSETS_URL_FOR_SCHEDULE(),
      assetPayload
    );
    return response;
  }
);

export const deleteAssetsForSchedule = createAsyncThunk(
  DELETE_ASSETS_FOR_SCHEDULE,
  async ({ assetPayload }: { assetPayload: any }) => {
    const response = await apiRequest(
      DELETE,
      DELETE_ASSETS_URL_FOR_SCHEDULE(),
      assetPayload
    );
    return response;
  }
);

const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    setRegion(state, action) {
      state.regionList = action.payload;
    },
    setLearningOutcomeToDefault(state) {
      state.learningOutcomeList = { learning_outcomes: [] };
    },
    setResource(state, action) {
      state.resourceList = action.payload;
    },
    setReference(state, action) {
      state.refernceList = action.payload;
    },
    setLearningOutcome(state, action) {
      state.learningOutcomeList = action.payload;
    },
    setDomain(state, action) {
      state.domainList = action.payload;
    },
    setCompetency(state, action) {
      state.competencyList = action.payload;
    },
    setProficiency(state, action) {
      state.proficiencyList = action.payload;
    },
    setCuriculum(state, action) {
      state.curiculumList = action.payload;
    },
    setUserMasterListToInitial(state) {
      state.userMasterList = initialState.userMasterList;
    },
    setClassRoomToInitial(state) {
      state.classRoomList = initialState?.classRoomList;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getFilterRolesList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFilterRolesList.fulfilled, (state, action) => {
        state.loading = false;
        state.roleOnFilter = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getFilterRolesList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getIndustryJobRolesList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getIndustryJobRolesList.fulfilled, (state, action) => {
        state.loading = false;
        state.roleOnFilter = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getIndustryJobRolesList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(reviewLearningOutcome.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(reviewLearningOutcome.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(reviewLearningOutcome.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getTagsList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTagsList.fulfilled, (state, action) => {
        state.loading = false;
        state.tagList = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getTagsList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getLocationList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLocationList.fulfilled, (state, action) => {
        state.loading = false;
        state.locationList = action.payload;
      })
      .addCase(getLocationList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getUserLocationList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserLocationList.fulfilled, (state, action) => {
        state.loading = false;
        state.userLocationList = action.payload;
      })
      .addCase(getUserLocationList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getClassRoomList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getClassRoomList.fulfilled, (state, action) => {
        state.loading = false;
        state.classRoomList = action.payload;
      })
      .addCase(getClassRoomList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getRegionList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getRegionList.fulfilled,
        (state, action: PayloadAction<RegionResponse>) => {
          state.loading = false;
          state.regionList = action.payload;
        }
      )
      .addCase(getRegionList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getToolsList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getToolsList.fulfilled, (state, action) => {
        state.loading = false;
        state.toolsList = action.payload;
      })
      .addCase(getToolsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || SomethingWentWrong;
        showToast(state?.error, "error");
      });
    builder
      .addCase(getTrainingAidList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTrainingAidList.fulfilled, (state, action) => {
        state.loading = false;
        state.trainingList = action.payload;
      })
      .addCase(getTrainingAidList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || SomethingWentWrong;
        showToast(state?.error, "error");
      });

    builder
      .addCase(getDesignationList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getDesignationList.fulfilled,
        (state, action: PayloadAction<DesignationResponse>) => {
          state.loading = false;
          state.designationList = action.payload;
        }
      )
      .addCase(getDesignationList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getDesignationById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDesignationById.fulfilled, state => {
        state.loading = false;
      })
      .addCase(getDesignationById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getBusinessGroupList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getBusinessGroupList.fulfilled,
        (state, action: PayloadAction<BusinessGroupResponse>) => {
          state.loading = false;
          state.businessGroupList = action.payload;
        }
      )
      .addCase(getBusinessGroupList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getResourceList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getResourceList.fulfilled,
        (state, action: PayloadAction<ResourceResponse>) => {
          state.loading = false;
          state.resourceList = action.payload;
        }
      )
      .addCase(getResourceList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getRefernceList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getRefernceList.fulfilled,
        (state, action: PayloadAction<RefernceResponse>) => {
          state.loading = false;
          state.refernceList = action.payload;
        }
      )
      .addCase(getRefernceList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getLearningOutcomeList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getLearningOutcomeList.fulfilled,
        (state, action: PayloadAction<LearningOutcomeResponse>) => {
          state.loading = false;
          state.learningOutcomeList = action.payload;
        }
      )
      .addCase(getLearningOutcomeList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getDomainList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getDomainList.fulfilled,
        (state, action: PayloadAction<DomainResponse>) => {
          state.loading = false;
          state.domainList = action.payload;
        }
      )
      .addCase(getDomainList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getCompetencyList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCompetencyList.fulfilled,
        (state, action: PayloadAction<CompetencyResponse>) => {
          state.loading = false;
          state.competencyList = action.payload;
        }
      )
      .addCase(getCompetencyList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          state.competencyList = { competency: [] };
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getProficiencyList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getProficiencyList.fulfilled,
        (state, action: PayloadAction<ProficiencyResponse>) => {
          state.loading = false;
          state.proficiencyList = action.payload;
        }
      )
      .addCase(getProficiencyList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getCuriculumList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCuriculumList.fulfilled,
        (state, action: PayloadAction<CuriculumResponse>) => {
          state.loading = false;
          state.curiculumList = action.payload;
        }
      )
      .addCase(getCuriculumList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getIndustryList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getIndustryList.fulfilled,
        (state, action: PayloadAction<IndustryListResponse>) => {
          state.loading = false;
          state.industryList = action.payload;
        }
      )
      .addCase(getIndustryList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getPrerequisiteList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPrerequisiteList.fulfilled,
        (state, action: PayloadAction<PrerquisiteResponse>) => {
          state.loading = false;
          state.preRequisiteList = action.payload;
        }
      )
      .addCase(getPrerequisiteList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getQuestionBankList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getQuestionBankList.fulfilled,
        (state, action: PayloadAction<QuestionBankData>) => {
          state.loading = false;
          state.questionBankList = action.payload;
        }
      )
      .addCase(getQuestionBankList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getModuleList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getModuleList.fulfilled,
        (state, action: PayloadAction<ModulesData>) => {
          state.loading = false;
          state.moduleList = action.payload;
        }
      )
      .addCase(getModuleList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getPolicyList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPolicyList.fulfilled,
        (state, action: PayloadAction<PoliciesData>) => {
          state.loading = false;
          state.policyList = action.payload;
        }
      )
      .addCase(getPolicyList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getSupersetReportsList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getSupersetReportsList.fulfilled,
        (state, action: PayloadAction<PoliciesData>) => {
          state.loading = false;
          state.supersetRepostList = action.payload;
        }
      )
      .addCase(getSupersetReportsList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getRolesList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getRolesList.fulfilled,
        (state, action: PayloadAction<RoleListResponse>) => {
          state.loading = false;
          state.roleList = action.payload;
        }
      )
      .addCase(getRolesList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getSbuList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getSbuList.fulfilled,
        (state, action: PayloadAction<SbuListResponse>) => {
          state.loading = false;
          state.sbuList = action.payload;
        }
      )
      .addCase(getSbuList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getLevelList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getLevelList.fulfilled,
        (state, action: PayloadAction<LevelListResponse>) => {
          state.loading = false;
          state.levelList = action.payload;
        }
      )
      .addCase(getLevelList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getUserMasterList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getUserMasterList.fulfilled,
        (state, action: PayloadAction<UserMasterListResponse>) => {
          state.loading = false;
          state.userMasterList = action.payload;
        }
      )
      .addCase(getUserMasterList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getGradeList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getGradeList.fulfilled,
        (state, action: PayloadAction<GradeListResponse>) => {
          state.loading = false;
          state.gradeList = action.payload;
        }
      )
      .addCase(getGradeList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(fetchCalendar.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCalendar.fulfilled, (state, action) => {
        state.loading = false;
        state.calendarData = action.payload;
      })
      .addCase(fetchCalendar.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(postAssetsForSchedule.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postAssetsForSchedule.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(postAssetsForSchedule.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(fetchAssetsListForSchedule.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAssetsListForSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.assetsList = action.payload;
      })
      .addCase(fetchAssetsListForSchedule.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(deleteAssetsForSchedule.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAssetsForSchedule.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(deleteAssetsForSchedule.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const {
  setRegion,
  setResource,
  setReference,
  setLearningOutcome,
  setDomain,
  setCompetency,
  setProficiency,
  setCuriculum,
  setLearningOutcomeToDefault,
  setUserMasterListToInitial,
  setClassRoomToInitial,
} = masterSlice.actions;
export default masterSlice.reducer;
