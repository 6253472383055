import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Row } from "reactstrap";

import { DefaultButton, H4, Image } from "../../AbstractElements";
import { STATUS_200 } from "../../Api/constants";
import LabelTooltip from "../../CommonElements/LabelTooltip";
import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import { createPasswordExternalUser } from "../../ReduxToolkit/Reducers/AuthenticationSlice";
import { getEncryptionToken } from "../../ReduxToolkit/Reducers/UserManagement";
import { dynamicImage } from "../../Service";
import {
  ResetPasswordText,
  NewPassword,
  NewPasswordConfirmation,
  PleaseEnterPassword,
  PasswordValidation,
  PasswordNotMatch,
  PasswordResetSuccess,
} from "../../utils/Constant";
import {
  encryptPayload,
  PASSWORD_REGEX,
  showToast,
} from "../../utils/helper/helper";
import useIsMobile from "../../utils/helper/responsive";

const ResetPasswordForm = () => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const location = useLocation();
  const navigate = useNavigate();
  const [enteredPassword, setEnteredPassword] = useState("");
  const [loginToken, setLoginToken] = useState(null);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [newPasswordConfirmationShow, setNewPasswordConfirmationShow] =
    useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const token = new URLSearchParams(location.search).get("token");

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    const recievedToken = await dispatch(getEncryptionToken());
    if (recievedToken?.payload?.key) {
      setLoginToken(recievedToken?.payload?.key);
    } else {
      setLoginToken(null);
    }
  };

  const validatePassword = password => {
    const passwordRegex = PASSWORD_REGEX;
    return passwordRegex.test(password);
  };

  const onResetPassword = async () => {
    if (!enteredPassword) {
      showToast(PleaseEnterPassword, "error");
      return;
    }
    if (!validatePassword(enteredPassword)) {
      showToast(PasswordValidation, "error");
      return;
    }
    if (enteredPassword !== passwordConfirmation) {
      showToast(PasswordNotMatch, "error");
      return;
    }

    const iv = CryptoJS.enc.Utf8.parse(loginToken?.slice(0, 16));
    const password = encryptPayload(enteredPassword, loginToken, iv);
    const payload: any = { password };
    const res: any = await dispatch(
      createPasswordExternalUser({ data: payload, emailToken: token })
    );

    if (res?.payload?.status_code === STATUS_200) {
      showToast(PasswordResetSuccess, "success");
      navigate(`${process.env.PUBLIC_URL}/login`);
    } else {
      const parsedResponse = JSON.parse(res?.error?.message);
      const message = parsedResponse.message;
      showToast(message, "error");
    }
  };

  return (
    <Row className="min-vh-100">
      {!isMobile && (
        <Col className="bg-white d-flex align-items-center justify-content-center">
          <Image
            className="img-fluid for-light"
            src={dynamicImage("login/login_page.png")}
            alt="logo"
          />
        </Col>
      )}
      <Col lg="6" sm="12" xs="12" className="bg-white">
        <Container fluid className="p-0 min-vh-100 d-flex align-items-center">
          <Row className="m-0 w-100 justify-content-center">
            <Col>
              <div className="p-5">
                <div className="mb-2 text-center">
                  <Image
                    className="img-fluid for-light"
                    src={dynamicImage("logo/login_logo.png")}
                    alt="loginpage"
                  />
                </div>
                <div className="login-main">
                  <H4 className="mt-3">{ResetPasswordText}</H4>
                  <Form className="theme-form">
                    <FormGroup>
                      <LabelTooltip
                        important={false}
                        label={NewPassword}
                        tooltipText={NewPassword}
                      />
                      <div className="form-input position-relative mb-2">
                        <Input
                          type={newPasswordShow ? "text" : "password"}
                          required
                          placeholder={NewPassword}
                          value={enteredPassword}
                          onChange={event =>
                            setEnteredPassword(event.target.value)
                          }
                        />
                        <div className="show-hide">
                          <span
                            onClick={() => setNewPasswordShow(!newPasswordShow)}
                            className={!newPasswordShow ? "show" : ""}
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <LabelTooltip
                        important={false}
                        label={NewPasswordConfirmation}
                        tooltipText={NewPasswordConfirmation}
                      />
                      <div className="form-input position-relative mb-5">
                        <Input
                          type={
                            newPasswordConfirmationShow ? "text" : "password"
                          }
                          required
                          placeholder={NewPasswordConfirmation}
                          value={passwordConfirmation}
                          onChange={event =>
                            setPasswordConfirmation(event.target.value)
                          }
                        />
                        <div className="show-hide">
                          <span
                            onClick={() =>
                              setNewPasswordConfirmationShow(
                                !newPasswordConfirmationShow
                              )
                            }
                            className={
                              !newPasswordConfirmationShow ? "show" : ""
                            }
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-0 form-sub-title">
                      <div className="mt-3">
                        <DefaultButton
                          onClick={e => {
                            e.preventDefault();
                            onResetPassword();
                          }}
                          type="button"
                          color="primary"
                          block
                        >
                          {ResetPasswordText}
                        </DefaultButton>
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

export default ResetPasswordForm;
