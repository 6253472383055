import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H4, H5, SVG } from "../../AbstractElements";
import "./style.scss";

interface TotalCountCardProps {
  data: TotalCountData[];
  onIconClick?: any;
  activeStatus?: string;
  showColor?: boolean;
}

interface TotalCountData {
  count: number;
  title: string;
  icon: string;
  sideTitle?: string;
  id?: string;
}

const AssetFilterCards: React.FC<TotalCountCardProps> = ({
  data,
  onIconClick,
  activeStatus,
  showColor = false,
}) => {
  return (
    <Row>
      {data.map((item, index) => (
        <Col
          key={index}
          onClick={() => {
            if (item?.id) onIconClick(item?.id);
          }}
          className={`${item?.id ? "pointer " : ""}`}
        >
          <Card
            className={`mb-3 ${activeStatus === item?.id && showColor ? "primary-bg-color" : ""}`}
          >
            <CardBody>
              <Row>
                <Col>
                  <SVG
                    iconId={item.icon}
                    className={`total-count-icon-circles ${item.icon === "total-asset" ? "pt-1" : ""}`}
                  />
                </Col>
                <Col>
                  <H4 className="fw-bold fs-14 mt-3">{item.count}</H4>
                </Col>
              </Row>

              <div className="d-flex gap-2 align-items-center">
                <H5 className="fw-bold">{item.title}</H5>
                {item.sideTitle && <H5>{item.sideTitle}</H5>}
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default AssetFilterCards;
