import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";

import { Btn, H4 } from "../../../../AbstractElements";
import { UpsertSessionForMenteePayload } from "../../../../Api/entities/MentoringCoachingEntity";
import Divider from "../../../../CommonElements/Divider";
import FilterSliderModal from "../../../../CommonElements/FilterSliderModal";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import SearchDropDownPaginator from "../../../../CommonElements/SearchDropDownPaginator";
import CheckboxButton from "../../../../CommonElements/SwitchButton";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  createSession,
  getAllMenteesUnderMentorList,
  updateSession,
} from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import { getDomainList } from "../../../../ReduxToolkit/Reducers/MasterSlice";
import {
  Add,
  Dates,
  Descriptions,
  Domain,
  Done,
  DoYouWantToAddNextSession,
  Duration,
  DurationInMinutes,
  DurationLimitValidationMessage,
  DurationValidationMessage,
  Enter,
  EnterTopicValidationMessage,
  KeyHighlights,
  KeyThoughtsOrSteps,
  KeyThoughtsOrStepsLimitationMessage,
  KeyThoughtsOrStepsValidationMessage,
  Mentee,
  MenteeValidationMessage,
  NextSessionValidationMessage,
  ResourceRequiredLimitationMessage,
  ResourceRequiredValidationMessage,
  ResourcesRequired,
  Select,
  Session,
  SessionDetails,
  StartDateValidationMessage,
  Topic,
  TopicValidationMessage,
  WhatsNotWorkingWell,
  WhatsNotWorkingWellLimitationMessage,
  WhatsNotWorkingWellValidationMessage,
  WhatsWorkingWell,
  WhatsWorkingWellLimitationMessage,
  WhatsWorkingWellValidationMessage,
} from "../../../../utils/Constant";
import {
  customStyles,
  formatDate,
  handleKeyDownOnNumericWithoutDecimal,
} from "../../../../utils/helper/helper";
import useIsMobile from "../../../../utils/helper/responsive";

interface FormErrors {
  [key: string]: string;
}

const AddSessionForm = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const { isUpdate, sessionId, sessionDate, mentorMenteeId } = state || {};
  const { domainList } = useAppSelector(state => state.master);
  const { getAllMenteesUnderMentor } = useAppSelector(state => state.learner);

  const [formState, setFormState] = useState({
    selectedMentee: 0,
    selectedDuration: null,
    startDate: isUpdate ? sessionDate : new Date(),
    topic: "",
    keyThoughts: "",
    workingWell: "",
    notWorkingWell: "",
    resourcesRequired: "",
    nextSession: false,
    nextSessionDate: new Date(),
  });

  const [domainFilterList, setDomainFilterList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [errors, setErrors] = useState<FormErrors>({});

  useEffect(() => {
    dispatch(getAllMenteesUnderMentorList({ status: null }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDomainList());
  }, [dispatch]);

  useEffect(() => {
    if (
      getAllMenteesUnderMentor?.user_dict &&
      getAllMenteesUnderMentor?.mentees
    ) {
      const formattedUsers = getAllMenteesUnderMentor?.mentees.map(
        (mentee: any) => {
          const menteeData =
            getAllMenteesUnderMentor?.user_dict[mentee.mentee_id];
          return {
            name: mentee.mentee_id,
            label: `${menteeData?.employee_code || "-"} - ${menteeData?.user_name} - ${menteeData?.email || "-"}`,
            value: `${menteeData?.employee_code || "-"} - ${menteeData?.user_name} - ${menteeData?.email || "-"}`,
            mentor_mentee_id: mentee?.mentor_mentee_id,
          };
        }
      );
      setUsersList(formattedUsers);
    }
  }, [getAllMenteesUnderMentor]);

  useEffect(() => {
    const value = [];
    if (domainList) {
      domainList?.domain?.forEach(item => {
        value.push({
          name: item.id.toString(),
          value: item.domain_name,
          label: item.domain_name,
        });
      });
    }
    setDomainFilterList(value);
  }, [domainList]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: undefined,
    }));

    const checked =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : undefined;

    setFormState(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelectChange = value => {
    setErrors(prevErrors => ({
      ...prevErrors,
      selectedMentee: undefined,
    }));

    setFormState(prevState => ({
      ...prevState,
      selectedMentee: value && value !== "" ? Number(value) : 0,
    }));
  };

  const handleCheckboxChange = () => {
    setFormState(prevState => ({
      ...prevState,
      nextSession: !prevState.nextSession,
    }));
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};

    if (!isUpdate) {
      if (!formState.selectedMentee) {
        newErrors.selectedMentee = MenteeValidationMessage;
      }
    }

    if (!formState.selectedDuration) {
      newErrors.selectedDuration = DurationValidationMessage;
    } else {
      const duration = Number(formState.selectedDuration);
      if (isNaN(duration) || duration < 1 || duration > 1440) {
        newErrors.selectedDuration = DurationLimitValidationMessage;
      }
    }

    if (!formState.startDate) {
      newErrors.startDate = StartDateValidationMessage;
    }

    if (!formState.topic) {
      newErrors.topic = EnterTopicValidationMessage;
    } else if (formState.topic.length > 100) {
      newErrors.topic = TopicValidationMessage;
    }

    if (!formState.keyThoughts) {
      newErrors.keyThoughts = KeyThoughtsOrStepsValidationMessage;
    } else if (formState.keyThoughts.length > 255) {
      newErrors.keyThoughts = KeyThoughtsOrStepsLimitationMessage;
    }

    if (!formState.workingWell) {
      newErrors.workingWell = WhatsWorkingWellValidationMessage;
    } else if (formState.workingWell.length > 255) {
      newErrors.workingWell = WhatsWorkingWellLimitationMessage;
    }

    if (!formState.notWorkingWell) {
      newErrors.notWorkingWell = WhatsNotWorkingWellValidationMessage;
    } else if (formState.notWorkingWell.length > 255) {
      newErrors.notWorkingWell = WhatsNotWorkingWellLimitationMessage;
    }

    if (!formState.resourcesRequired) {
      newErrors.resourcesRequired = ResourceRequiredValidationMessage;
    } else if (formState.resourcesRequired.length > 255) {
      newErrors.resourcesRequired = ResourceRequiredLimitationMessage;
    }

    if (formState.nextSession) {
      if (!formState.nextSessionDate) {
        newErrors.nextSessionDate = NextSessionValidationMessage;
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDoneClick = async () => {
    if (validateForm()) {
      const selectedMenteeData = getAllMenteesUnderMentor?.mentees?.find(
        mentee => {
          return mentee?.mentee_id === formState.selectedMentee;
        }
      );

      const payload: UpsertSessionForMenteePayload = {
        mentor_mentee_id: isUpdate
          ? mentorMenteeId
          : selectedMenteeData?.mentor_mentee_id,
        duration: formState.selectedDuration,
        start_date: formatDate(formState.startDate),
        topic: formState.topic,
        key_thoughts: formState.keyThoughts,
        whats_working_well: formState.workingWell,
        whats_not_working: formState.notWorkingWell,
        resources_required: formState.resourcesRequired,
        is_upcoming: formState.nextSession,
        status: formState.nextSession ? "upcoming" : "completed",
      };

      if (formState.nextSession) {
        payload.next_session_date = formatDate(formState.nextSessionDate);
      }

      if (isUpdate) {
        payload.session_id = sessionId;
      }

      if (isUpdate) {
        await dispatch(updateSession({ upsertSessionPayload: payload }));
      } else {
        await dispatch(createSession({ upsertSessionPayload: payload }));
      }
      navigate(`${process.env.PUBLIC_URL}/mentoring/i-am-mentor`);
    }
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    await dispatch(
      getAllMenteesUnderMentorList({
        domainId: values["domain"]?.toString(),
      })
    );
  };

  const handleClear = async () => {
    setUsersList([]);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  const handleDateChange = (name: string, date: Date) => {
    setFormState(prevState => ({
      ...prevState,
      [name]: date || null,
    }));
  };

  return (
    <div className="page-body page-body-margin">
      <Card>
        <CardBody className="p-4">
          <H4 className="p-2 fw-bold">
            {Add} {SessionDetails}
          </H4>
          <Divider />

          {!isUpdate && (
            <Row className="align-items-center">
              <Col md={5}>
                <FormGroup>
                  <LabelTooltip
                    label={Mentee}
                    important
                    tooltipText={`${Select} ${Mentee}`}
                  />
                  <ReactSelect
                    name="selectedMentee"
                    className={`text-gray ${errors?.assessment ? "is-invalid" : ""}`}
                    onChange={selectedOption =>
                      handleSelectChange(
                        selectedOption ? selectedOption?.value : ""
                      )
                    }
                    value={
                      usersList?.find(
                        user => user.name === formState?.selectedMentee
                      ) || null
                    }
                    options={usersList?.map(user => ({
                      value: user?.name,
                      label: user?.label,
                    }))}
                    isSearchable={true}
                    isClearable={false}
                    placeholder={`${Select} ${Mentee}`}
                    classNamePrefix="react-select"
                    styles={customStyles}
                    components={{
                      MenuList: props => (
                        <SearchDropDownPaginator {...props} maxHeight={400} />
                      ),
                    }}
                    filterOption={(option, inputValue) =>
                      option?.label
                        ?.toLowerCase()
                        ?.includes(inputValue?.toLowerCase())
                    }
                  />
                  {errors.selectedMentee && (
                    <div className="text-danger">{errors.selectedMentee}</div>
                  )}
                </FormGroup>
              </Col>

              <Col md={7}>
                <div className="mt-4">
                  <FilterSliderModal
                    dropdowns={[
                      {
                        label: Domain,
                        key: "domain",
                        tooltipText: `${Select} ${Domain}`,
                        options: domainFilterList,
                        isMultiSelect: true,
                      },
                    ]}
                    selectedFilterValues={selectedFilterValues}
                    defaultFilterValues={defaultFilterValues}
                    setSelectedFilterValues={setSelectedFilterValues}
                    setDefaultFilterValues={setDefaultFilterValues}
                    onDone={handleDone}
                    onClear={handleClear}
                  />
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={Dates}
                  important
                  tooltipText={`${Select} ${Dates}`}
                />

                <DatePicker
                  selected={formState.startDate}
                  onChange={(date: Date | null) =>
                    handleDateChange("startDate", date)
                  }
                  maxDate={new Date()}
                  placeholderText={`${Select} ${Dates}`}
                  className={`form-control ${errors?.startDate ? "is-invalid" : ""}`}
                  dateFormat="dd-MM-yyyy"
                  disabledKeyboardNavigation={true}
                  onKeyDown={e => e.preventDefault()}
                />

                {errors.startDate && (
                  <div className="text-danger">{errors.startDate}</div>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={Duration}
                  important
                  tooltipText={`${Enter} ${DurationInMinutes}`}
                />
                <Input
                  type="number"
                  name="selectedDuration"
                  onChange={handleInputChange}
                  value={formState.selectedDuration}
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                  placeholder={`${Enter} ${DurationInMinutes}`}
                  invalid={!!errors.selectedDuration}
                  maxLength={3}
                  min={1}
                />
                {errors.selectedDuration && (
                  <div className="text-danger">{errors.selectedDuration}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <LabelTooltip
                  label={Topic}
                  important
                  tooltipText={`${Enter} ${Topic}`}
                />
                <Input
                  type="textarea"
                  name="topic"
                  placeholder={`${Enter} ${Topic}`}
                  onChange={handleInputChange}
                  value={formState.topic}
                  invalid={!!errors.topic}
                  maxLength={100}
                />
                {errors.topic && (
                  <div className="text-danger">{errors.topic}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <LabelTooltip
                  label={KeyThoughtsOrSteps}
                  important
                  tooltipText={`${Enter} ${Descriptions}`}
                />
                <Input
                  type="textarea"
                  name="keyThoughts"
                  placeholder={`${Enter} ${Descriptions}`}
                  onChange={handleInputChange}
                  value={formState.keyThoughts}
                  invalid={!!errors.keyThoughts}
                  maxLength={255}
                />
                {errors.keyThoughts && (
                  <div className="text-danger">{errors.keyThoughts}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <H4 className="mt-3 mb-3 fw-bold">{KeyHighlights}</H4>
          <Row>
            <Col md={12}>
              <FormGroup>
                <LabelTooltip
                  label={WhatsWorkingWell}
                  important
                  tooltipText={`${Enter} ${Descriptions}`}
                />
                <Input
                  type="textarea"
                  name="workingWell"
                  placeholder={`${Enter} ${Descriptions}`}
                  onChange={handleInputChange}
                  value={formState.workingWell}
                  invalid={!!errors.workingWell}
                  maxLength={255}
                />
                {errors.workingWell && (
                  <div className="text-danger">{errors.workingWell}</div>
                )}
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup>
                <LabelTooltip
                  label={WhatsNotWorkingWell}
                  important
                  tooltipText={`${Enter} ${Descriptions}`}
                />
                <Input
                  type="textarea"
                  name="notWorkingWell"
                  placeholder={`${Enter} ${Descriptions}`}
                  onChange={handleInputChange}
                  value={formState.notWorkingWell}
                  invalid={!!errors.notWorkingWell}
                  maxLength={255}
                />
                {errors.notWorkingWell && (
                  <div className="text-danger">{errors.notWorkingWell}</div>
                )}
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup>
                <LabelTooltip
                  label={ResourcesRequired}
                  important
                  tooltipText={`${Enter} ${Descriptions}`}
                />
                <Input
                  type="textarea"
                  name="resourcesRequired"
                  placeholder={`${Enter} ${Descriptions}`}
                  onChange={handleInputChange}
                  value={formState.resourcesRequired}
                  invalid={!!errors.resourcesRequired}
                  maxLength={255}
                />
                {errors.resourcesRequired && (
                  <div className="text-danger">{errors.resourcesRequired}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col md={12} className="d-flex align-items-center">
              <LabelTooltip
                label={DoYouWantToAddNextSession}
                tooltipText=""
                important={false}
              />
              <div className="mt-1 ms-3">
                <CheckboxButton
                  checked={formState.nextSession}
                  onChange={handleCheckboxChange}
                />
              </div>
            </Col>
          </Row>

          {formState.nextSession && (
            <Row className="mt-3">
              <Col md={6}>
                <FormGroup>
                  <LabelTooltip
                    label={`${Session} ${Dates}`}
                    important
                    tooltipText={`${Select} ${Session} ${Dates}`}
                  />
                  <DatePicker
                    selected={formState.nextSessionDate}
                    onChange={(date: Date | null) =>
                      handleDateChange("nextSessionDate", date)
                    }
                    minDate={new Date()}
                    placeholderText={`${Select} ${Dates}`}
                    className={`form-control ${errors?.nextSessionDate ? "is-invalid" : ""}`}
                    dateFormat="dd-MM-yyyy"
                    disabledKeyboardNavigation={true}
                    onKeyDown={e => e.preventDefault()}
                  />

                  {errors.nextSessionDate && (
                    <div className="text-danger">{errors.nextSessionDate}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          )}

          <Divider />
          <Row className="d-flex justify-content-end mt-3">
            <Col xs="auto" className={isMobile && "mb-5"}>
              <Btn color="primary" onClick={handleDoneClick}>
                {Done}
              </Btn>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddSessionForm;
