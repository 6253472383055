import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";

import { DefaultButton, H3, H4 } from "../../../AbstractElements";
import { STATUS_200 } from "../../../Api/constants";
import { MultiselectOption } from "../../../Api/entities/SgaEntity";
import Divider from "../../../CommonElements/Divider";
import FixedFooter from "../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import MultiSelectDropdown from "../../../CommonElements/MultiSelectDropdown";
import SearchDropDownPaginator from "../../../CommonElements/SearchDropDownPaginator";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getDesignationList,
  getGradeList,
  getUserLocationList,
  getRolesList,
  getSbuList,
  getUserMasterList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  createExternalUser,
  getLMSRoles,
  getUserDetailsById,
  mapRolesToUser,
  unMapRolesToUser,
  updateExternalUser,
} from "../../../ReduxToolkit/Reducers/UserManagement";
import {
  AddExternalEmployee,
  AdditionalInformation,
  Create,
  Description,
  CWSAndCatRecId,
  CWSAndCatRecIdError,
  Designation,
  DesignationRequired,
  EmailId,
  EmailIDToolTipMessage,
  EmployeeType,
  EmployeeTypeRequired,
  EmployeeTypeToolTipMessage,
  Enter,
  ExternalNameError,
  ExternalReasonError,
  Grade,
  GradeIsRequired,
  GradeToolTipMessage,
  JobRole,
  JobRoleRequired,
  LMSRole,
  LMSRoleRequired,
  LMSRoleToolTipMessage,
  Location,
  LocationToolTipMessage,
  Name,
  NameRequired,
  NameToolTipMessage,
  OfficeCityIsRequired,
  PhoneNumber,
  PhoneNumberLength,
  PhoneNumberToolTipMessage,
  PleaseProvideRequiredInformation,
  ReasonForAccountCreation,
  ReasonRequired,
  ReportingManager,
  RMIsRequired,
  SBU,
  SBUIsRequired,
  Select,
  SelectDesignation,
  Update,
  UpdateExternalEmployee,
  UserJobRoleToolTipMessage,
  ValidEmailId,
  ValidTill,
  ValidTillToolTipMessage,
} from "../../../utils/Constant";
import {
  customStyles,
  EMAIL_REGEX,
  employeeTypeOptions,
  getTomorrowDate,
  handleKeyDownOnNumericWithoutDecimal,
  roleMapping,
  scrollToTop,
  showToast,
} from "../../../utils/helper/helper";

interface FormValues {
  employee_type: string;
  name: string;
  email_id: string;
  phone_number: string;
  lms_role: any[];
  job_role: string;
  valid_till: Date | null;
  reason: string;
}

interface AdditionalFormValues {
  cws_cat_rec: string;
  designation: string;
  grade: string;
  sbu: string;
  rm_id: string;
  office_city: string;
}
type Errors = {
  [K in keyof (FormValues & AdditionalFormValues)]?: string;
};

const AddExternalUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = location.state || "";
  const [gradeOptions, setGradeOptions] = useState<MultiselectOption[]>([]);

  const [usersList, setUsersList] = useState([]);
  const [sbuFilterList, setSbuFilterList] = useState([]);
  const [designationListData, setDesignationListData] = useState(null);

  const [formValues, setFormValues] = useState<FormValues | any>({
    employee_type: "",
    name: "",
    email_id: "",
    phone_number: "",
    lms_role: [],
    job_role: "",
    valid_till: getTomorrowDate(),
    reason: "",
    cws_cat_rec: "",
    designation: "",
    grade_id: "",
    sbu: "",
    rm_id: "",
    office_city: "",
  });
  const { roleList, userLocationList, designationList } = useAppSelector(
    state => state.master
  );
  const { lmsRole, userDetails } = useAppSelector(
    state => state.userManagement
  );
  const { sbuList, userMasterList, gradeList } = useAppSelector(
    state => state.master
  );
  const [errors, setErrors] = useState<Errors>({});
  const [preSelectedLMSRoles, setPreSelectedLMSRoles] = useState([]);
  const [dbSelectedLMSRoles, setDBSelectedLMSRoles] = useState([]);
  const [lmsRolesOptions, setLMSRolesOptions] = useState([]);

  useEffect(() => {
    dispatch(getSbuList());
    dispatch(getLMSRoles());
    dispatch(
      getUserMasterList({
        roleId: null,
        sbuId: null,
      })
    );
    dispatch(getDesignationList());
    dispatch(getGradeList());
    dispatch(getRolesList({}));
    dispatch(getUserLocationList());
  }, [dispatch]);

  useEffect(() => {
    const designations = [];
    designations.push({ value: "", label: SelectDesignation });
    designationList?.designation?.forEach(designation => {
      const userObj = {
        value: designation.name,
        label: designation.name,
      };
      designations.push(userObj);
    });
    setDesignationListData(designations);
  }, [designationList]);

  useEffect(() => {
    if (userId) {
      dispatch(getUserDetailsById({ id: userId }));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (userDetails?.users?.[0]) {
      const userVal = userDetails?.users[0];
      const ids = [];
      const idMap = [];
      userVal?.mapped_lms_roles?.forEach(item => {
        const roleDetails = roleMapping?.[item?.role_name];
        if (roleDetails) {
          ids.push(item?.role_id?.toString());
          idMap.push({
            name: item?.role_id?.toString(),
            value: roleMapping?.[item?.role_name]?.value,
            label: roleMapping?.[item?.role_name]?.value,
            unique_id: item?.id,
          });
        }
      });
      setDBSelectedLMSRoles(idMap);
      setPreSelectedLMSRoles(idMap);
      setFormValues(prevValues => ({
        ...prevValues,
        employee_type: userVal?.employee_type,
        name: userVal?.user_name,
        email_id: userVal?.email,
        phone_number: userVal?.primary_mobile_number,
        lms_role: ids || [],
        job_role: userVal?.job_role_id,
        valid_till: userVal?.valid_till
          ? new Date(userVal?.valid_till)
          : getTomorrowDate(),
        reason: userVal?.reason_for_account_creation || "",
        cws_cat_rec: userVal?.cws_cat_rec || "",
        designation: userVal?.designation || "",
        grade_id: userVal?.grade_id?.toString() || "",
        sbu: userVal?.sbu || "",
        rm_id: userVal?.mapped_rm?.[0]?.id || "",
        office_city:
          userVal?.office_city &&
          userVal?.office_city !== null &&
          userVal?.office_city !== ""
            ? userVal?.office_city
            : "",
      }));
    } else {
      const roleNames = [];
      const idMap = [];
      lmsRole?.lms_role?.forEach(item => {
        const roleDetails = roleMapping?.[item?.role_name];
        if (roleDetails) {
          if (item?.role_name === "el") {
            idMap.push(item?.id?.toString());
            roleNames.push({
              name: item?.id?.toString(),
              value: roleMapping?.[item?.role_name]?.value,
              label: roleMapping?.[item?.role_name]?.value,
              key: item?.role_name,
            });
          }
        }
      });

      setFormValues(prevValues => ({
        ...prevValues,
        lms_role: idMap,
      }));
      setPreSelectedLMSRoles(roleNames);
    }
  }, [userDetails, lmsRole]);

  useEffect(() => {
    const data = gradeList?.grades?.map(grade => ({
      label: grade.name,
      value: grade.name,
      name: grade?.id.toString(),
    }));
    setGradeOptions(data);
  }, [gradeList]);

  useEffect(() => {
    const value = [];
    if (sbuList) {
      sbuList?.sbu?.forEach(item => {
        value.push({
          name: item?.id,
          label: item?.name,
          value: item?.name,
        });
      });
    }
    setSbuFilterList(value);
  }, [sbuList]);

  useEffect(() => {
    const value = [];
    const nameMap = {};
    if (userMasterList) {
      userMasterList?.users?.forEach((item: any) => {
        nameMap[item?.id] = item;
        if (item?.employee_type && item?.employee_type === "internal") {
          value.push({
            name: item?.id,
            label: `${item?.employee_code || "-"} - ${item?.user_name} - ${item?.email || "-"}`,
            value: `${item?.employee_code || "-"} - ${item?.user_name} - ${item?.email || "-"}`,
          });
        }
      });
    }
    setUsersList(value);
  }, [userMasterList]);

  useEffect(() => {
    const roles = [];
    lmsRole?.lms_role?.forEach(item => {
      const roleDetails = roleMapping?.[item?.role_name];
      if (roleDetails) {
        roles.push({
          name: item?.id?.toString(),
          value: roleMapping?.[item?.role_name]?.value,
          label: roleMapping?.[item?.role_name]?.value,
          key: item?.role_name,
        });
      }
    });
    setLMSRolesOptions(roles);
  }, [lmsRole]);

  useEffect(() => {
    const perselectedLMSRolesMap =
      formValues.lms_role?.map((role: any) => ({
        name: role,
        value: lmsRolesOptions.find(item => item.name === role)?.label || "",
        label: lmsRolesOptions.find(item => item.name === role)?.label || "",
        key: lmsRolesOptions.find(item => item.name === role)?.key || "",
      })) || [];

    setPreSelectedLMSRoles(perselectedLMSRolesMap);
  }, [formValues.lms_role, lmsRolesOptions]);

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === "name" && value.length > 100) {
      setErrors(prevErrors => ({
        ...prevErrors,
        name: ExternalNameError,
      }));
      return;
    } else if (name === "employee_type") {
      setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
      setFormValues(prevValues => ({
        ...prevValues,
        cws_cat_rec: "",
        designation: "",
        grade_id: "",
        sbu: "",
        rm_id: "",
        office_city: "",
      }));
    } else if (name === "reason" && value.length > 255) {
      setErrors(prevErrors => ({
        ...prevErrors,
        reason: ExternalReasonError,
      }));
      return;
    } else if (name === "cws_cat_rec" && value.length > 100) {
      setErrors(prevErrors => ({
        ...prevErrors,
        cws_cat_rec: CWSAndCatRecIdError,
      }));
      return;
    } else {
      setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
    }

    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "email_id" && !EMAIL_REGEX.test(value)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email_id: ValidEmailId,
      }));
    }
  };

  const handleDateChange = (date: Date | null) => {
    setFormValues(prevValues => ({
      ...prevValues,
      valid_till: date,
    }));
    setErrors(prevErrors => ({ ...prevErrors, valid_till: "" }));
  };

  const handleSubmit = async () => {
    const newErrors: Errors = {};

    if (!formValues.employee_type) {
      newErrors.employee_type = EmployeeTypeRequired;
    }
    if (!formValues.name) {
      newErrors.name = NameRequired;
    }
    if (!formValues.email_id) {
      newErrors.email_id = ValidEmailId;
    }
    if (formValues.phone_number.length !== 10) {
      newErrors.phone_number = PhoneNumberLength;
    }
    if (!preSelectedLMSRoles || preSelectedLMSRoles?.length === 0) {
      newErrors.lms_role = LMSRoleRequired;
    }
    if (
      !formValues.job_role ||
      formValues.job_role === "" ||
      formValues.job_role === null
    ) {
      newErrors.job_role = JobRoleRequired;
    }
    if (!formValues.reason) {
      newErrors.reason = ReasonRequired;
    }
    if (
      formValues.employee_type === "gmmco_contract" ||
      formValues.employee_type === "third_party_contract"
    ) {
      if (
        !formValues.rm_id ||
        formValues.rm_id === "" ||
        formValues.rm_id === null
      ) {
        newErrors.rm_id = RMIsRequired;
      }
    }
    if (formValues.employee_type === "gmmco_contract") {
      if (
        !formValues.designation ||
        formValues.designation === "" ||
        formValues.designation === null
      ) {
        newErrors.designation = DesignationRequired;
      }
      if (
        !formValues.grade_id ||
        formValues.grade_id === "" ||
        formValues.grade_id === null
      ) {
        newErrors.grade = GradeIsRequired;
      }
      if (!formValues.sbu || formValues.sbu === "" || formValues.sbu === null) {
        newErrors.sbu = SBUIsRequired;
      }

      if (
        !formValues.office_city ||
        formValues.office_city === "" ||
        formValues.office_city === null
      ) {
        newErrors.office_city = OfficeCityIsRequired;
      }
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      showToast(PleaseProvideRequiredInformation, "error");
      scrollToTop();

      return;
    }

    const payload: any = {
      employee_type: formValues.employee_type,
      company_email_id: formValues.email_id,
      first_name: formValues.name,
      last_name: "",
      valid_till: formValues.valid_till?.toISOString().split("T")[0] || "",
      reason_for_account_creation: formValues.reason,
      primary_mobile_number: formValues.phone_number,
      office_mobile_number: formValues.phone_number,
      cws_cat_rec: formValues?.cws_cat_rec || null,
      designation: formValues?.designation || null,
      grade_id: formValues?.grade_id || null,
      sbu: formValues?.sbu || null,
      rm_id: formValues?.rm_id || null,
      office_city: formValues?.office_city?.toString() || "",
    };
    let res: any = {};
    if (userId) {
      payload.id = userId;
      payload.is_suspended = 0;
      payload.is_update = true;
      res = await dispatch(updateExternalUser({ payload: payload }));
    } else {
      res = await dispatch(createExternalUser({ payload: payload }));
    }
    if (res?.payload?.id) {
      const lms_role_id = preSelectedLMSRoles?.map(item =>
        parseInt(item?.name)
      );

      const difference = dbSelectedLMSRoles
        ?.filter(role => !lms_role_id?.includes(Number(role?.name)))
        .map(role => role?.unique_id);

      if (difference && difference.length > 0) {
        const unMap = {
          user_id: res?.payload?.id,
          lms_role_id: difference,
        };

        await dispatch(unMapRolesToUser({ payload: unMap }));
      }

      const payload = {
        user_id: res?.payload?.id,
        job_role_id: parseInt(formValues?.job_role),
        lms_role_id: lms_role_id,
      };
      const recievedStatus = await dispatch(
        mapRolesToUser({ payload: payload })
      );

      if (recievedStatus?.payload?.status_code === STATUS_200)
        return navigate("/user-management");
    }
  };

  return (
    <div className="page-body pb-5 mb-5">
      <Card className="p-2">
        <CardBody>
          <H3 className="mt-3">
            {userId ? UpdateExternalEmployee : AddExternalEmployee}
          </H3>
          <Divider />
          <div className="mt-3">
            <Row>
              <Col>
                <LabelTooltip
                  label={EmployeeType}
                  tooltipText={EmployeeTypeToolTipMessage}
                  important={true}
                />
                <Input
                  type="select"
                  value={formValues.employee_type}
                  onChange={handleChange}
                  name="employee_type"
                  invalid={!!errors.employee_type}
                >
                  <option value="" disabled>
                    {Select} {EmployeeType}
                  </option>
                  {employeeTypeOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
                {errors.employee_type && (
                  <div className="invalid-feedback">{errors.employee_type}</div>
                )}
              </Col>
              <Col>
                <LabelTooltip
                  label={Name}
                  tooltipText={NameToolTipMessage}
                  important
                />
                <Input
                  type="text"
                  name="name"
                  placeholder={`${Enter} ${Name}`}
                  value={formValues.name}
                  onChange={handleChange}
                  invalid={!!errors.name}
                />
                {errors.name && (
                  <div className="text-danger">{errors.name}</div>
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <LabelTooltip
                  label={EmailId}
                  tooltipText={EmailIDToolTipMessage}
                  important
                />
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={formValues.email_id}
                  name="email_id"
                  type="email"
                  placeholder={`${Enter} ${EmailId}`}
                  invalid={!!errors.email_id}
                  disabled={userDetails?.users?.[0]?.is_email_verified || false}
                />
                <div className="invalid-feedback">{errors.email_id}</div>
              </Col>
              <Col>
                <LabelTooltip
                  label={PhoneNumber}
                  tooltipText={PhoneNumberToolTipMessage}
                  important
                />
                <Input
                  name="phone_number"
                  type="text"
                  value={formValues.phone_number}
                  onChange={handleChange}
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                  maxLength={10}
                  pattern="\d*"
                  inputMode="numeric"
                  placeholder={`${Enter} ${PhoneNumber}`}
                  invalid={!!errors.phone_number}
                />
                <div className="invalid-feedback">{errors.phone_number}</div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <LabelTooltip
                  label={LMSRole}
                  tooltipText={LMSRoleToolTipMessage}
                  important
                />
                <MultiSelectDropdown
                  onChange={values => {
                    setFormValues(prevValues => ({
                      ...prevValues,
                      lms_role: values,
                    }));
                  }}
                  options={lmsRolesOptions}
                  placeholder={`${Select} ${LMSRole}`}
                  defaultSelected={preSelectedLMSRoles}
                />
                <div className="mt-1 text-sm text-danger">
                  {errors.lms_role}
                </div>
              </Col>
              <Col>
                <FormGroup>
                  <LabelTooltip
                    label={JobRole}
                    tooltipText={UserJobRoleToolTipMessage}
                    important
                  />
                  <ReactSelect
                    name="job_role"
                    className="text-gray"
                    onChange={selectedOption => {
                      handleChange({
                        target: {
                          name: "job_role",
                          value: selectedOption?.value || "",
                        },
                      });
                    }}
                    value={
                      roleList?.job_role
                        ?.map(role => ({
                          value: role.id,
                          label: role.name,
                        }))
                        .find(
                          option => option.value === formValues?.job_role
                        ) || null
                    }
                    options={roleList?.job_role?.map(role => ({
                      value: role.id,
                      label: role.name,
                    }))}
                    isSearchable
                    isClearable={false}
                    placeholder={`${Select} ${JobRole}`}
                    styles={customStyles}
                    components={{
                      MenuList: props => (
                        <SearchDropDownPaginator {...props} maxHeight={400} />
                      ),
                    }}
                    filterOption={(option, inputValue) =>
                      option?.label
                        ?.toLowerCase()
                        .includes(inputValue?.toLowerCase())
                    }
                  />
                  {errors.job_role && (
                    <div className="text-danger">{errors.job_role}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <LabelTooltip
                  label={ValidTill}
                  tooltipText={ValidTillToolTipMessage}
                  important
                />
                <div className="w-100">
                  <DatePicker
                    selected={formValues.valid_till}
                    onChange={handleDateChange}
                    minDate={getTomorrowDate()}
                    placeholderText={`${Select} ${ValidTill}`}
                    className={`form-control ${errors.valid_till ? "is-invalid" : ""}`}
                    dateFormat="dd-MM-yyyy"
                    disabledKeyboardNavigation
                    onKeyDown={e => e.preventDefault()}
                  />
                  {errors.valid_till && (
                    <div className="invalid-feedback">{errors.valid_till}</div>
                  )}
                </div>
              </Col>
              <Col />
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <LabelTooltip
                  label={`${ReasonForAccountCreation}`}
                  tooltipText={`${Enter} ${ReasonForAccountCreation}`}
                  important
                />
                <Input
                  type="textarea"
                  name="reason"
                  placeholder={`${Enter} ${Description}`}
                  value={formValues.reason}
                  onChange={handleChange}
                  invalid={!!errors.reason}
                />
                {errors.reason && (
                  <div className="text-danger">{errors.reason}</div>
                )}
              </Col>
              <Col></Col>
            </Row>
          </div>
          {formValues.employee_type &&
            formValues.employee_type !== "customer_employee" && (
              <div
                className={
                  formValues.employee_type === "third_party_contract"
                    ? "pb-5 mb-5"
                    : ""
                }
              >
                <Divider />
                <H4 className="mt-2 mb-2">{AdditionalInformation}</H4>
                <Row>
                  <Col md="6" lg="6" sm="12" xs="12">
                    <FormGroup>
                      <LabelTooltip
                        label={ReportingManager}
                        important
                        tooltipText={`${Select} ${ReportingManager}`}
                      />
                      <ReactSelect
                        name="rm_id"
                        className="text-gray"
                        onChange={selectedOption => {
                          handleChange({
                            target: {
                              name: "rm_id",
                              value: selectedOption?.value || "",
                            },
                          });
                        }}
                        value={
                          usersList.find(
                            user => user?.name === formValues?.rm_id
                          ) || null
                        }
                        options={usersList.map(user => ({
                          value: user?.name,
                          label: user?.label,
                        }))}
                        isSearchable
                        isClearable={false}
                        placeholder={`${Select} ${ReportingManager}`}
                        styles={customStyles}
                        components={{
                          MenuList: props => (
                            <SearchDropDownPaginator
                              {...props}
                              maxHeight={400}
                            />
                          ),
                        }}
                        filterOption={(option, inputValue) =>
                          option?.label
                            ?.toLowerCase()
                            ?.includes(inputValue?.toLowerCase())
                        }
                      />

                      {errors.rm_id && (
                        <div className="text-danger">{errors.rm_id}</div>
                      )}
                    </FormGroup>
                  </Col>

                  {formValues.employee_type &&
                    formValues.employee_type === "gmmco_contract" && (
                      <Col>
                        <FormGroup>
                          <LabelTooltip
                            label={Location}
                            tooltipText={LocationToolTipMessage}
                            important
                          />
                          <ReactSelect
                            name="office_city"
                            className="text-gray"
                            onChange={(selectedOption: any) => {
                              handleChange({
                                target: {
                                  name: "office_city",
                                  value: selectedOption?.value || "",
                                },
                              });
                            }}
                            value={
                              userLocationList?.location
                                ?.map(option => ({
                                  value: option.id,
                                  label: option.name,
                                }))
                                .find((option: any) => {
                                  return (
                                    option?.value === formValues?.office_city
                                  );
                                }) || null
                            }
                            options={userLocationList?.location?.map(
                              option => ({
                                value: option.id,
                                label: option.name,
                              })
                            )}
                            isSearchable
                            isClearable={false}
                            placeholder={`${Select} ${Location}`}
                            styles={customStyles}
                            components={{
                              MenuList: props => (
                                <SearchDropDownPaginator
                                  {...props}
                                  maxHeight={400}
                                />
                              ),
                            }}
                            filterOption={(option, inputValue) =>
                              option?.label
                                ?.toLowerCase()
                                ?.includes(inputValue?.toLowerCase())
                            }
                          />
                          {errors.office_city && (
                            <div className="text-danger">
                              {errors.office_city}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    )}
                </Row>
              </div>
            )}
          {formValues.employee_type &&
            formValues.employee_type === "gmmco_contract" && (
              <>
                <Row>
                  <Col>
                    <FormGroup>
                      <LabelTooltip
                        label={CWSAndCatRecId}
                        tooltipText={CWSAndCatRecId}
                        important={false}
                        placement={"bottom"}
                      />
                      <Input
                        type="text"
                        name="cws_cat_rec"
                        placeholder={CWSAndCatRecId}
                        max={100}
                        value={formValues.cws_cat_rec}
                        invalid={!!errors.cws_cat_rec}
                        onChange={handleChange}
                      />
                      {errors.cws_cat_rec && (
                        <div className="text-danger">{errors.cws_cat_rec}</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <LabelTooltip
                        label={Designation}
                        tooltipText={Designation}
                        important={true}
                      />
                      <ReactSelect
                        name="designation"
                        className="text-gray"
                        onChange={selectedOption => {
                          handleChange({
                            target: {
                              name: "designation",
                              value: selectedOption?.value || "",
                            },
                          });
                        }}
                        value={
                          designationListData?.find(
                            designation =>
                              designation.value === formValues?.designation
                          ) || null
                        }
                        options={designationListData?.map(option => ({
                          value: option.value,
                          label: option.label,
                        }))}
                        isSearchable
                        isClearable={false}
                        placeholder={`${Select} ${Designation}`}
                        styles={customStyles}
                        components={{
                          MenuList: props => (
                            <SearchDropDownPaginator
                              {...props}
                              maxHeight={400}
                            />
                          ),
                        }}
                        filterOption={(option, inputValue) =>
                          option?.label
                            ?.toLowerCase()
                            ?.includes(inputValue?.toLowerCase())
                        }
                      />
                      {errors.designation && (
                        <div className="text-danger">{errors.designation}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <LabelTooltip
                        label={Grade}
                        tooltipText={GradeToolTipMessage}
                        important
                      />
                      <ReactSelect
                        name="grade_id"
                        className="text-gray"
                        onChange={selectedOptions => {
                          handleChange({
                            target: {
                              name: "grade_id",
                              value: selectedOptions?.value || "",
                            },
                          });
                        }}
                        value={
                          gradeOptions
                            ?.map(role => ({
                              value: role.name,
                              label: role.label,
                            }))
                            .find(
                              option => option.value === formValues?.grade_id
                            ) || null
                        }
                        options={gradeOptions?.map(option => ({
                          value: option.name,
                          label: option.label,
                        }))}
                        isSearchable
                        isClearable={false}
                        placeholder={`${Select} ${Grade}`}
                        styles={customStyles}
                        components={{
                          MenuList: props => (
                            <SearchDropDownPaginator
                              {...props}
                              maxHeight={400}
                            />
                          ),
                        }}
                        filterOption={(option, inputValue) =>
                          option?.label
                            ?.toLowerCase()
                            ?.includes(inputValue?.toLowerCase())
                        }
                      />
                      {errors.grade && (
                        <div className="mt-1 text-danger">{errors.grade}</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <LabelTooltip
                        label={SBU}
                        tooltipText={SBU}
                        important={true}
                      />
                      <ReactSelect
                        name="sbu"
                        className="text-gray"
                        onChange={selectedOption => {
                          handleChange({
                            target: {
                              name: "sbu",
                              value: selectedOption?.value || "",
                            },
                          });
                        }}
                        value={
                          sbuFilterList?.find(
                            option => option.value === formValues?.sbu
                          ) || null
                        }
                        options={sbuFilterList?.map(option => ({
                          value: option.value,
                          label: option.label,
                        }))}
                        isSearchable
                        isClearable={false}
                        placeholder={"Select SBU"}
                        styles={customStyles}
                        components={{
                          MenuList: props => (
                            <SearchDropDownPaginator
                              {...props}
                              maxHeight={400}
                            />
                          ),
                        }}
                        filterOption={(option, inputValue) =>
                          option?.label
                            ?.toLowerCase()
                            ?.includes(inputValue?.toLowerCase())
                        }
                      />
                      {errors.sbu && (
                        <div className="text-danger">{errors.sbu}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
          <FixedFooter>
            <DefaultButton color="primary" onClick={handleSubmit}>
              {userId ? Update : Create}
            </DefaultButton>
          </FixedFooter>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddExternalUser;
