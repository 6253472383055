import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { Input } from "reactstrap";

import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import { mapCourse } from "../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  Course,
  CourseType,
  MapCourse,
  PleaseSelectCourse,
  PleaseSelectCourseType,
  PleaseSelectYear,
  Select,
  Submit,
  Year,
} from "../../utils/Constant";
import {
  courseTypeOptions,
  customStyles,
  generateFiscalYearSeries,
  showToast,
} from "../../utils/helper/helper";
import CommonModal from "../Modal";
import SearchDropDownPaginator from "../SearchDropDownPaginator";
interface MapCourseProps {
  isOpen: boolean;
  toggle: () => void;
  data: Object;
  userId?: string;
  fetchCourses: () => void;
}

const MapCourseModal: React.FC<MapCourseProps> = ({
  userId,
  isOpen,
  toggle,
  data,
  fetchCourses,
}) => {
  const dispatch = useAppDispatch();
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [selectedCourseType, setSelectedCourseType] = useState<string>("");
  const [filteredCourses, setFilteredCourses] = useState<string[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<string>("");
  const fiscalYears = generateFiscalYearSeries(0, 10);

  useEffect(() => {
    setSelectedYear("");
    setSelectedCourseType("");
    setSelectedCourse("");
  }, []);

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedYear(event.target.value);
  };

  const handleCourseTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedType = event.target.value;
    setSelectedCourseType(selectedType);

    const selectedCourses = data[selectedType] || [];

    setFilteredCourses(selectedCourses);
    setSelectedCourse("");
  };

  const courseOptions = filteredCourses.map((course: any) => ({
    value: course.id,
    label: course.course_name,
  }));

  const getMapCourseModalBody = () => {
    return (
      <>
        <div className="mb-3">
          <span className="text-black d-block mb-1">{CourseType}</span>
          <Input
            type="select"
            value={selectedCourseType}
            onChange={handleCourseTypeChange}
          >
            {courseTypeOptions?.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
        </div>

        <div className="mb-3">
          <span className="text-black d-block mb-1">{Course}</span>
          <ReactSelect
            name="course"
            className="text-gray"
            onChange={selectedOption => {
              setSelectedCourse(selectedOption ? selectedOption.value : "");
            }}
            value={
              courseOptions.find(option => option.value === selectedCourse) ||
              null
            }
            options={courseOptions.map(option => ({
              value: option.value,
              label: option.label,
            }))}
            isSearchable
            isClearable={false}
            placeholder={`${Select} ${Course}`}
            styles={customStyles}
            components={{
              MenuList: props => (
                <SearchDropDownPaginator {...props} maxHeight={200} />
              ),
            }}
            filterOption={(option, inputValue) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase())
            }
          />
        </div>

        <div className="mb-3">
          <span className="text-black d-block mb-1">{Year}</span>
          <Input type="select" value={selectedYear} onChange={handleYearChange}>
            <option value="" disabled>
              {Select} {Year}
            </option>
            {fiscalYears.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Input>
        </div>
      </>
    );
  };

  const handleSubmit = async () => {
    if (selectedCourseType === "") {
      showToast(PleaseSelectCourseType, "error");
      return;
    }
    if (selectedCourse === "") {
      showToast(PleaseSelectCourse, "error");
      return;
    }
    if (selectedYear === "") {
      showToast(PleaseSelectYear, "error");
      return;
    }

    const extractedYear = parseInt(selectedYear.split(" ")[1].split("-")[0]);
    const startYear =
      extractedYear < 50 ? 2000 + extractedYear : 1900 + extractedYear;
    const payload = {
      type: "course",
      id: selectedCourse,
      year: startYear,
      user_id: userId,
    };

    await dispatch(mapCourse({ courseContent: payload }));
    fetchCourses();
    setSelectedYear("");
    setSelectedCourseType("");
    setSelectedCourse("");
    toggle();
  };

  return (
    <CommonModal
      backdrop="static"
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        setSelectedYear("");
        setSelectedCourseType("");
        setSelectedCourse("");
        toggle();
      }}
      sizeTitle={MapCourse}
      showFooter={true}
      primaryBtnText={Submit}
      onPrimaryBtnClick={handleSubmit}
    >
      {getMapCourseModalBody()}
    </CommonModal>
  );
};

MapCourseModal.propTypes = {
  userId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  fetchCourses: PropTypes.func,
};

export default MapCourseModal;
